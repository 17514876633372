import React, { PureComponent } from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faMapPin, faTag } from '@fortawesome/pro-regular-svg-icons';
import Media from 'react-media';

const Meta = Card.Meta;

class SimilarPropertyCard extends PureComponent {
  static propTypes = {
    property: PropTypes.object,
  };

  static defaultProps = {
    property: {},
  };

  renderAlertPropertyCard = (cover, title, description) => {
    return (
      <Media query="(max-width: 320px)">
        {matches =>
          matches ? (
            <Card
              className="mx-2"
              hoverable
              style={{ width: 200 }}
              onClick={this.handleRedirectToPropertyPage}
              cover={cover}
            >
              <Meta title={title} description={description} />
            </Card>
          ) : (
            <Card
              className="mx-2"
              hoverable
              style={{ width: 280 }}
              onClick={this.handleRedirectToPropertyPage}
              cover={cover}
            >
              <Meta title={title} description={description} />
            </Card>
          )
        }
      </Media>
    );
  };

  render() {
    const { property } = this.props;
    const {
      title,
      number,
      streetName: streetAddress,
      district,
      area,
      objects,
      slugUrl,
      listingType,
      rentPriceMonthly,
      price,
      typeGroup,
    } = property;
    const mainObjectUrl =
      objects && objects.length
        ? objects[0].url
        : `${process.env.REACT_APP_CDN_URI}/${encodeURIComponent(
            'properties/no_image_property.jpg'
          )}`;
    const cardCover = (
      <div
        className="preview block relative align-bottom overflow-hidden bg-gray-100"
        style={{ height: 200 }}
      >
        <div
          className="absolute inset-0 text-center overflow-hidden"
          style={{
            backgroundImage: `url(${mainObjectUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </div>
    );
    const cardTitle = (
      <h4 className="overflow-hidden whitespace-no-wrap truncate">{title}</h4>
    );
    const cardDescription = (
      <div className="flex flex-col justify-start">
        <div className="flex flex-col text-sm text-gray-800">
          <span className="overflow-hidden whitespace-no-wrap truncate">
            <FA size="lg" icon={faMapPin} className="align-top mr-1" />
            <span>
              {number} {streetAddress}
            </span>
            {area && (
              <span>
                {(number || streetAddress) && (
                  <span className="mx-1">&bull;</span>
                )}
                {area}
              </span>
            )}
            {district && (
              <span>
                {area && <span className="mx-1">&bull;</span>}D{district}
              </span>
            )}
          </span>
          <span className="mt-2">
            <FA size="lg" icon={faTag} className="align-top mr-1" />
            S${' '}
            {listingType === 'RENT' ? (
              <span>
                <FormattedNumber value={rentPriceMonthly} /> /mo
              </span>
            ) : (
              <FormattedNumber value={price} />
            )}
            <span className="mx-1">&bull;</span>
            <span>
              {typeGroup === 'HDB' && 'HDB'}
              {typeGroup === 'LANDED' && 'Landed'}
              {typeGroup === 'CONDOMINIUM' && 'Condo'}
            </span>
          </span>
        </div>
      </div>
    );

    return (
      <Link to={`/property/${slugUrl}`}>
        {this.renderAlertPropertyCard(cardCover, cardTitle, cardDescription)}
      </Link>
    );
  }
}

export default SimilarPropertyCard;
