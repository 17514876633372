import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import PropertyCard from '../PropertyCard';
import markerBlue from './marker-blue.png';
import markerGold from './marker-gold.png';

class PropertyMapMarkerWithInfoBox extends React.Component {
  static propTypes = {
    propertyId: PropTypes.string.isRequired,
    property: PropTypes.object.isRequired,
    markerType: PropTypes.oneOf(['MARKER_GOLD', 'MARKER_BLUE', 'MARKER_DOT']),
    lat: PropTypes.number,
    lng: PropTypes.number,
    onOpen: PropTypes.func,

    isOpen: PropTypes.bool,
    isBouncing: PropTypes.bool,
  };

  static defaultProps = {
    lat: -19.56703,
    lng: 146.15156,
    onOpen: () => {},
    isOpen: false,
    isBouncing: false,
    markerType: 'MARKER_BLUE',
  };

  getMarker = () => {
    const { markerType, isOpen } = this.props;

    let propertyMarker = markerBlue;

    switch (markerType) {
      case 'MARKER_GOLD': {
        propertyMarker = markerGold;
        break;
      }
      case 'MARKER_BLUE': {
        propertyMarker = markerBlue;
        break;
      }
      case 'MARKER_DOT': {
        propertyMarker = {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: '#4ac7f4',
          fillOpacity: isOpen ? 0 : 1,
          strokeColor: '#4ac7f4',
        };
        break;
      }
      default: {
        propertyMarker = markerBlue;
      }
    }

    return propertyMarker;
  };

  render() {
    const {
      property,
      propertyId,
      lat,
      lng,
      isOpen,
      onOpen,
      isBouncing,
      markerType,
      searchParam,
    } = this.props;

    return (
      <Marker
        key={propertyId}
        position={{
          lat,
          lng,
        }}
        onClick={() => onOpen(propertyId)}
        onMouseOver={() => onOpen(propertyId)}
        icon={this.getMarker()}
        animation={isBouncing ? window.google.maps.Animation.BOUNCE : null}
      >
        {isOpen && (
          <InfoBox
            options={{
              closeBoxURL: '',
              enableEventPropagation: true,
              alignBottom: true,
              pixelOffset: new window.google.maps.Size(
                -150,
                markerType === 'MARKER_DOT' ? 0 : -30
              ),
            }}
          >
            <div style={{ width: 300 }}>
              <PropertyCard
                property={property}
                searchParam={searchParam}
                sr="sr"
              />
            </div>
          </InfoBox>
        )}
      </Marker>
    );
  }
}

export default PropertyMapMarkerWithInfoBox;
