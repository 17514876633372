import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import padStart from 'lodash/padStart';

class PropertyAddress extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.bool,
    newLine: PropTypes.bool,
    property: PropTypes.object,
  };

  static defaultProps = {
    className: 'text-overflow capitalize inline-block leading-normal',
    icon: false,
    newLine: false,
    property: {},
  };

  render() {
    const { property, icon, className } = this.props;
    const { number, streetName, postalCode, area, district } = property || [];

    const street = <>{(number && `${number} ${streetName}`) || streetName}</>;

    return (
      <span className={`${className} w-full`}>
        {icon && (
          <Icon
            type="environment-o"
            className="mr-3 text-gray-800 text-lg cursor-pointer text-blue-300 hover:text-blue-500"
          />
        )}
        <span>
          {street} {postalCode}, {area}, D{padStart(district, 2, '0')}
        </span>
      </span>
    );
  }
}

export default PropertyAddress;
