import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import LoadingDots from '../LoadingDots';
import { LocationTypeSearch } from './Operations.graphql';

class LocationResolver extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    locationType: PropTypes.object,
    data: PropTypes.object,
  };

  static defaultProps = {
    locationType: {},
    data: {},
  };

  render() {
    const {
      data: { loading, locationTypeSearch: locationType },
      children,
    } = this.props;

    if (loading) {
      return (
        <div className="py-4">
          <LoadingDots />
        </div>
      );
    }

    return children(locationType);
  }
}

const LocationResolverWithData = graphql(LocationTypeSearch, {
  options: ({ location }) => ({
    variables: { ...location },
  }),
})(LocationResolver);

export default LocationResolverWithData;
