import React from 'react';
import { Button, Icon } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

export const CarouselPrevArrow = props => {
  const { className, onClick } = props;
  return (
    <FA
      icon={faChevronLeft}
      theme="outlined"
      onClick={onClick}
      className={`arrow prev ${className}`}
    />
  );
};

CarouselPrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CarouselPrevArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

export const CarouselNextArrow = props => {
  const { className, onClick } = props;
  return (
    <FA
      icon={faChevronRight}
      theme="outlined"
      onClick={onClick}
      className={`arrow next ${className}`}
    />
  );
};

CarouselNextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CarouselNextArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

export const OfiButton = props => {
  const { showOfiModal } = props;
  return (
    <Button
      type="primary"
      onClick={showOfiModal}
      className="rounded"
      size="large"
    >
      <div className="flex items-center">
        <Icon type="clock-circle-o" className="h-auto md:text-base text-2xl" />
      </div>
    </Button>
  );
};

OfiButton.propTypes = { showOfiModal: PropTypes.func };
OfiButton.defaultProps = {
  showOfiModal: () => {},
};
