/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, message, Slider, Upload } from 'antd';
import AvatarEditor from 'react-avatar-editor';

class ConsumerAvatarUpload extends Component {
  static propTypes = {
    profileImage: PropTypes.string,
    contributorUploadImage: PropTypes.func,
    refetch: PropTypes.func,
    consumerPage: PropTypes.object,
    handleAvatarModal: PropTypes.func,
  };

  static defaultProps = {
    profileImage: null,
    contributorUploadImage: () => {},
    refetch: () => {},
    consumerPage: {},
    handleAvatarModal: () => {},
  };

  state = {
    imageFile: null,
    imageScale: 1.2,
    isSaving: false,
  };

  componentWillUnmount() {
    this.editor = null;
  }

  onChangeScale = value => {
    this.setState({ imageScale: value });
  };

  setEditorRef = editor => (this.editor = editor);

  handleUpload = async e => {
    e.preventDefault();
    message.config({ top: 24 });

    const {
      contributorUploadImage,
      refetch,
      consumerPage,
      handleAvatarModal,
    } = this.props;

    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas();
      try {
        this.setState({ isSaving: true });
        canvasScaled.toBlob(async blob => {
          await contributorUploadImage(consumerPage.id, 'profileImage', blob)
            .then(() => {
              refetch();
              setTimeout(() => {
                this.setState({ isSaving: false });
                handleAvatarModal();
              }, 3000);
            })
            .catch(() => {
              this.setState({ isSaving: false });
              message.destroy();
              message.error(
                'There was an error uploading your logo. Please try again later.'
              );
            });
        });
      } catch (error) {
        console.log(error);
        this.setState({ isSaving: false });
        message.destroy();
        message.error(
          'There was an error uploading your logo. Please try again later.'
        );
      }
    }
  };

  onBeforeUpload = file => {
    message.config({ top: 24 });
    const imageFormats = ['image/jpeg', 'image/png'];

    if (!imageFormats.includes(file.type)) {
      message.destroy();
      message.error('You can only upload JPG or PNG file!');
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.destroy();
      message.error('Image must be smaller than 2MB!');
      return false;
    }

    this.setState({ imageFile: file });
    return false;
  };

  render() {
    const { imageScale, imageFile, isSaving } = this.state;
    const { profileImage } = this.props;
    const newAvatar = imageFile || profileImage;

    return (
      <Fragment>
        <div className="flex justify-center align-middle">
          <AvatarEditor
            ref={this.setEditorRef}
            image={newAvatar}
            width={300}
            height={300}
            border={50}
            color={[0, 0, 0, 0.7]} // RGBA
            scale={imageScale}
            rotate={0}
            crossOrigin="anonymous"
          />
        </div>
        <div className="px-2 pt-2">
          <Upload
            accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
            className="block w-full"
            name="user-avatar"
            showUploadList={false}
            beforeUpload={this.onBeforeUpload}
          >
            <div className="p-2">
              <div className="italic text-sm pb-2">
                *Valid format are only JPG/JPEG or PNG images and must not be
                greater than 2MB.
              </div>
              <Button className=" w-full">
                <Icon type="upload" /> Select a photo
              </Button>
            </div>
          </Upload>

          <Slider
            step={0.1}
            min={0.6}
            max={2}
            onChange={this.onChangeScale}
            defaultValue={1.2}
          />

          <Button
            size="large"
            type="primary"
            className="mt-2 w-full"
            onClick={this.handleUpload}
            loading={isSaving}
          >
            {isSaving ? 'Saving' : 'Save'}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default ConsumerAvatarUpload;
