
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LocationsConnectionQuerySubLocationFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Location"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"suburb"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"full"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":214}};
    doc.loc.source = {"body":"fragment LocationsConnectionQuerySubLocationFragment on Location {\n  id\n  suburb\n  #state\n  #postalCode\n  full\n  #stateFull\n  type\n  #isFeaturedByBroker\n  #isFeaturedByAgent\n  #isReserved\n  #agentDirectoryStatus\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
