import React, { Fragment, memo, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faBath, faBed, faGem } from '@fortawesome/pro-regular-svg-icons';
import isEmpty from 'lodash.isempty';
import { faVideo } from '@fortawesome/pro-solid-svg-icons';
import some from 'lodash/some';
import './PropertyCarousel.scss';
import { buildURL } from '../../utils/realty-cdn';
import PropertyImageSlider from '../PropertyImageSlider';
import BookmarkProperty from '../BookmarkProperty';
import {
  trackPropertyImage,
  trackPropertyImageSlider,
} from '../../utils/analytics';
import { HIGHLIGHT_199, PREMIERE } from '../../constants/property-ad-types';
import defaultImage from '../../assets/default-property-image.svg';

const Carouselmage = memo(
  ({ imageUrl, width, linkTo, isFromSearch, sr, alt }) => (
    <div className="Carouselmage each-fade">
      <div className="image-container w-full">
        <Link
          to={{
            pathname: linkTo,
            search: sr ? `?${sr}` : '',
            state: { fromSearch: isFromSearch },
          }}
        >
          <img
            alt={alt}
            key={imageUrl}
            data-src={`${imageUrl}?&auto=format&w=${width}`}
            src={`${imageUrl}?&fit=crop&crop=entropy&px=1&blur=200&auto=format&w=70&height=50`}
            className="property-picture lazyload"
          />
        </Link>
      </div>
    </div>
  )
);

Carouselmage.propTypes = {
  alt: PropTypes.string,
  imageUrl: PropTypes.any,
  isFromSearch: PropTypes.bool,
  linkTo: PropTypes.string,
  sr: PropTypes.string,
  width: PropTypes.number,
};

Carouselmage.defaultProps = {
  alt: '',
  imageUrl: '',
  isFromSearch: false,
  linkTo: '',
  sr: '',
  width: 200,
};

class PropertyCarousel extends PureComponent {
  static propTypes = {
    agentUrl: PropTypes.string,
    className: PropTypes.string,
    collectionId: PropTypes.string,
    fromReview: PropTypes.bool,
    height: PropTypes.string,
    isFromSearch: PropTypes.bool,
    ofiTime: PropTypes.bool,
    property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    reviewId: PropTypes.string,
    showOfiModal: PropTypes.func,
    showViewVideosModal: PropTypes.func,
    sr: PropTypes.string,
    isPropertyInView: PropTypes.bool,
  };

  static defaultProps = {
    agentUrl: '',
    className: '',
    collectionId: '',
    fromReview: false,
    height: '16rem',
    isFromSearch: false,
    ofiTime: false,
    reviewId: '',
    property: {},
    showOfiModal: () => {},
    showViewVideosModal: () => {},
    sr: '',
    objects: [],
    isPropertyInView: false,
  };

  constructor(props) {
    super(props);

    const { property, ofiTime } = props;
    const { featuredTier, ofiTimes } = property || {};

    this.state = {
      filteredOfiTimes:
        ofiTime &&
        ofiTimes.filter(
          time =>
            time.datesFrom && time.datesTo && moment(time.datesTo) >= moment()
        ),
      isPremium: featuredTier === PREMIERE || featuredTier === HIGHLIGHT_199,
      currentImageIndex: 0,
      propertyInView: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      isPropertyInView,
      property: { id, objects, agent, shortId },
    } = props;

    let arrayOfKeys = [];
    if (some(objects, 'objectId')) {
      if (objects) {
        for (let i = 0; i < objects.length; i++) {
          const splitKey = objects[i].objectId.split('/');
          const selectKey = splitKey[1];
          arrayOfKeys.push(selectKey);
        }
      } else {
        arrayOfKeys = '';
      }
    }

    const { currentImageIndex, propertyInView } = state;
    if (!isEmpty(objects[currentImageIndex])) {
      if (isPropertyInView !== propertyInView) {
        if (isPropertyInView) {
          trackPropertyImage(
            shortId,
            arrayOfKeys[currentImageIndex],
            agent.id,
            objects[currentImageIndex].type
          );
        }
        return { propertyInView: isPropertyInView };
      }
    }
    return null;
  }

  handleSwipeImage = index => {
    const {
      isPropertyInView,
      property: { id, objects, agent, shortId },
    } = this.props;

    let arrayOfKeys = [];
    if (some(objects, 'objectId')) {
      if (objects) {
        for (let i = 0; i < objects.length; i++) {
          const splitKey = objects[i].objectId.split('/');
          const selectKey = splitKey[1];
          arrayOfKeys.push(selectKey);
        }
      } else {
        arrayOfKeys = '';
      }
    }

    if (!isEmpty(objects[index])) {
      if (isPropertyInView) {
        trackPropertyImageSlider(
          shortId,
          arrayOfKeys[index],
          agent.id,
          objects[index].type
        );
      }
    }

    this.setState({ currentImageIndex: index });
  };

  render() {
    const {
      property,
      showViewVideosModal,
      className,
      showOfiModal,
      collectionId,
      fromReview,
      reviewId,
      agentUrl,
      height,
      isFromSearch,
      sr,
    } = this.props;

    const {
      id,
      objects,
      isLovedByConsumer,
      slugUrl,
      status,
      videoLink,
      featuredTier,
      features,
      priceView,
      isUnderOffer,
      address,
      typeGroup,
      title,
    } = property || {};

    const { bedrooms, bathrooms } = features || {};

    const { isPremium } = this.state;

    const propertyStatus = (status || 'current').toUpperCase();

    return (
      <Fragment>
        {propertyStatus === 'SOLD' && (
          <div className="absolute top-0 left-0 z-30 rounded-tr-lg rounded-br-lg mt-2 mr-0 bg-blue-300 text-white px-2 py-1">
            <span>SOLD</span>
          </div>
        )}

        {isPremium && propertyStatus !== 'SOLD' && (
          <div
            className={`text-base absolute top-0 left-0 z-30 rounded-tr-lg rounded-br-lg mt-2 mr-0 ${
              featuredTier === PREMIERE ? 'bg-yellow-700' : 'bg-blue-300'
            } text-white text-shadow-sm px-2 py-1`}
          >
            <FA icon={faGem} className="icon-shadow-sm mr-1" />
            <span>Featured</span>
          </div>
        )}

        <div className="absolute bottom-0 left-0 z-20 ml-1 mb-1 pl-2 pr-3 text-left text-white text-lg mb-2 text-overflow md:w-5/6 w-4/5 font-normal">
          <div className="opacity-75 bg-black inline-block rounded px-2">
            <span className="mr-2">
              <FA icon={faBed} className="mr-2" />
              {bedrooms}
            </span>
            <span>
              <FA icon={faBath} className="mr-2" />
              {bathrooms}
            </span>
          </div>
        </div>
        <div className="absolute top-0 right-0 z-20 m-3 flex flex-col items-center">
          <BookmarkProperty propertyId={id} title={title} />
        </div>
        <div className="w-full h-full block carousel-container z-1">
          <PropertyImageSlider
            duration={5000}
            transitionDuration={500}
            infinite
            arrows={objects && objects.length > 1}
            indicators={false}
            autoplay={false}
            className={`property-card-carousel ${className}`}
            style={{ height }}
            handleSwipeImage={this.handleSwipeImage}
          >
            {objects && objects.length > 0 ? (
              objects.map(({ url, params }) => (
                <Carouselmage
                  linkTo={`/property/${slugUrl}`}
                  key={url}
                  imageUrl={buildURL(url, params)}
                  width={isPremium ? 700 : 700}
                  isFromSearch={isFromSearch}
                  sr={sr}
                  alt={address}
                />
              ))
            ) : (
              <div className="each-fade">
                <div className="image-container w-full">
                  <Link
                    to={{
                      pathname: `/property/${slugUrl}`,
                      search: sr ? `?${sr}` : '',
                      state: { fromSearch: isFromSearch },
                    }}
                  >
                    <img
                      alt=""
                      key={defaultImage}
                      src={defaultImage}
                      className="property-picture"
                    />
                  </Link>
                </div>
              </div>
            )}
          </PropertyImageSlider>
        </div>
        <section className="mb-2 absolute bottom-0 right-0 z-10 flex items-end flex-col">
          {videoLink && (
            <Tooltip title="View video">
              <Button
                style={{
                  border: '0px',
                  fontSize: 20,
                }}
                ghost
                size="large"
                className="font-bold"
                onClick={showViewVideosModal}
              >
                <FA icon={faVideo} className="icon-shadow" />
              </Button>
            </Tooltip>
          )}
        </section>
      </Fragment>
    );
  }
}

export default PropertyCarousel;
