import React, { PureComponent } from 'react';
import {
  GoogleMap,
  Marker,
  OverlayView,
  StreetViewPanorama,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import PropTypes from 'prop-types';
import NearbyPlacesMapMarker from '../NearbyPlacesMapMarker';
import marker from './marker-blue.png';
import schoolMarker from './school-marker.png';
import trainMarker from './train-marker.png';
import postOfficeMarker from './post-office.png';

class PropertyMap extends PureComponent {
  static propTypes = {
    isMarkerShown: PropTypes.bool,
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultView: PropTypes.oneOf(['map', 'street']),
    nearbySchools: PropTypes.array,
    nearbyMrtStations: PropTypes.array,
    nearbyPostOffices: PropTypes.array,
    center: PropTypes.any,
    handleChangeMarkerCenter: PropTypes.func,
    defaultMarker: PropTypes.string,
  };

  static defaultProps = {
    isMarkerShown: false,
    lat: '',
    lng: '',
    defaultView: 'map',
    nearbySchools: [],
    nearbyMrtStations: [],
    nearbyPostOffices: [],
    center: null,
    handleChangeMarkerCenter: () => {},
    defaultMarker: 'schools',
  };

  state = { hasPanoramaLocation: false };

  componentDidMount() {
    //https://stackoverflow.com/questions/8945283/google-maps-streetviewpanorama-display-error
    const { lat, lng } = this.props;
    const location = new window.google.maps.LatLng(lat, lng);

    const sv = new window.google.maps.StreetViewService();

    sv.getPanoramaByLocation(location, 50, (data, status) => {
      if (status === 'OK') {
        this.setState({ hasPanoramaLocation: true });
      } else {
        this.setState({ hasPanoramaLocation: false });
      }
    });
  }

  render() {
    const {
      isMarkerShown,
      lat,
      lng,
      defaultView,
      nearbySchools,
      nearbyMrtStations,
      nearbyPostOffices,
      center,
      handleChangeMarkerCenter,
      defaultMarker,
    } = this.props;
    const { hasPanoramaLocation } = this.state;
    const getPixelPositionOffset = (width, height) => ({
      x: -(width / 2),
      y: -(height / 2),
    });

    const googleMapProps = {
      ref: map => (this.map = map),
      defaultZoom: 15,
      defaultCenter: {
        lat,
        lng,
      },
    };

    if (center) {
      googleMapProps.center = { lat: center.lat, lng: center.lng };
    }

    return (
      <GoogleMap {...googleMapProps}>
        {isMarkerShown && (
          <Marker
            position={{
              lat,
              lng,
            }}
            icon={marker}
          />
        )}
        {defaultMarker === 'schools' && nearbySchools.length > 0 && (
          <NearbyPlacesMapMarker
            data={nearbySchools}
            icon={schoolMarker}
            center={center}
            onChangeCenter={handleChangeMarkerCenter}
            details={item => (
              <div className="p-2">
                <a
                  href={item.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="md:text-sm text-xs"
                >
                  {item.name}
                </a>
                <p className="mt-1 mb-1">{item.address}</p>
                <p>Distance: {item.distance.toFixed(2)} km</p>
              </div>
            )}
          />
        )}
        {defaultMarker === 'stations' && nearbyMrtStations.length > 0 && (
          <NearbyPlacesMapMarker
            data={nearbyMrtStations}
            icon={trainMarker}
            center={center}
            onChangeCenter={handleChangeMarkerCenter}
            details={item => (
              <div className="p-2">
                <p className="md:text-sm text-xs font-semibold mb-1">
                  {item.name}
                </p>
                <p>Distance: {item.distance.toFixed(2)} km</p>
              </div>
            )}
          />
        )}
        {defaultMarker === 'postOffices' && nearbyPostOffices.length > 0 && (
          <NearbyPlacesMapMarker
            data={nearbyPostOffices}
            icon={postOfficeMarker}
            center={center}
            onChangeCenter={handleChangeMarkerCenter}
            details={item => (
              <div className="p-2">
                <p className="md:text-sm text-xs font-semibold mb-1">
                  {item.branch}
                </p>
                <p>Distance: {item.distance.toFixed(2)} km</p>
              </div>
            )}
          />
        )}
        {hasPanoramaLocation && defaultView === 'street' && (
          <StreetViewPanorama
            defaultPosition={{
              lat,
              lng,
            }}
            visible
          >
            <OverlayView
              position={{
                lat,
                lng,
              }}
              mapPaneName={OverlayView.OVERLAY_LAYER}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <div
                style={{
                  background: 'red',
                  color: 'white',
                  padding: 5,
                  borderRadius: '50%',
                  display: 'none',
                }}
              >
                OverlayView
              </div>
            </OverlayView>
          </StreetViewPanorama>
        )}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(PropertyMap));
