/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon, Menu, Modal } from 'antd';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import {
  AcceptConsumerFriendRequestMutation,
  CancelUserFriendRequestMutation,
  RemoveConsumerFriendtMutation,
  SendConsumerFriendRequestMutation,
} from './Operations.graphql';
import GuestLoginForm from '../GuestLoginForm';
import { loginUser } from '../../../actions';

export const STATUS_REQUEST = 'REQUEST';
export const STATUS_ACCEPTED = 'ACCEPTED';

class FriendRequestButton extends Component {
  static propTypes = {
    pageOwnerId: PropTypes.string.isRequired,
    friendInfo: PropTypes.object,
    onLoginUser: PropTypes.func,
    addAsFriend: PropTypes.func,
    acceptFriendRequest: PropTypes.func,
    cancelFriendRequest: PropTypes.func,
    removeAsFriend: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    isPageOwner: PropTypes.bool,
    refetch: PropTypes.func,
  };

  static defaultProps = {
    onLoginUser: () => {},
    addAsFriend: () => {},
    acceptFriendRequest: () => {},
    cancelFriendRequest: () => {},
    removeAsFriend: () => {},
    isAuthenticated: false,
    isPageOwner: false,
    refetch: () => {},
  };

  state = {
    isLoading: false,
    consumerLoginDecisionModal: false,
    isPageOwner: this.props.isPageOwner,
  };

  addAsFriend = () => {
    const { addAsFriend, pageOwnerId } = this.props;

    this.setState({ isLoading: true });
    addAsFriend(pageOwnerId)
      .then(() => {})
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  acceptFriendRequest = () => {
    const { acceptFriendRequest, pageOwnerId } = this.props;

    this.setState({ isLoading: true });
    acceptFriendRequest(pageOwnerId)
      .then(() => {})
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  cancelFriendRequest = () => {
    const { cancelFriendRequest, pageOwnerId } = this.props;

    this.setState({ isLoading: true });
    cancelFriendRequest(pageOwnerId)
      .then(() => {})
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  removeAsFriend = () => {
    const { removeAsFriend, pageOwnerId } = this.props;

    this.setState({ isLoading: true });
    removeAsFriend(pageOwnerId)
      .then(() => {})
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onLoginSuccess = loginData => {
    const { onLoginUser, addAsFriend, pageOwnerId, refetch } = this.props;

    const { accessToken, expiresIn, refreshToken, user, type } = loginData;
    onLoginUser({
      accessToken,
      expiresIn,
      refreshToken,
      user,
      type,
    });

    this.setState({
      isLoading: true,
      isPageOwner: user.id === pageOwnerId,
    });

    if (user.id !== pageOwnerId) {
      addAsFriend(pageOwnerId)
        .then(() => {})
        .finally(() => {
          this.setState({ isLoading: false }, () => {
            this.closeConsumerLoginModal();
          });
        });
    }
    refetch();
  };

  showDecisionModal = bool => {
    this.setState({ consumerLoginDecisionModal: bool });
  };

  closeConsumerLoginModal = () => {
    this.setState({ consumerLoginDecisionModal: false });
  };

  checkBeforeAddRequest = () => {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.addAsFriend();
    } else {
      this.showDecisionModal(true);
    }
  };

  render() {
    const { friendInfo } = this.props;
    const { isLoading, consumerLoginDecisionModal, isPageOwner } = this.state;

    let button = null;
    const status = friendInfo ? friendInfo.status : null;
    const isRequester = friendInfo ? friendInfo.isRequester : false;

    if (isPageOwner) return null;

    switch (status) {
      case STATUS_REQUEST: {
        if (isRequester) {
          button = (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={this.cancelFriendRequest}>
                    <Icon type="close" />
                    Cancel Friend Request
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="primary" loading={isLoading}>
                Friend Request Sent <Icon type="down" />
              </Button>
            </Dropdown>
          );
        } else {
          button = (
            <Button
              type="primary"
              loading={isLoading}
              onClick={this.acceptFriendRequest}
            >
              <Icon type="check" /> Respond To Friend Request
            </Button>
          );
        }

        break;
      }
      case STATUS_ACCEPTED: {
        button = (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={this.removeAsFriend}>
                  <Icon type="close" />
                  Unfriend
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="primary" loading={isLoading}>
              Friends <Icon type="down" />
            </Button>
          </Dropdown>
        );
        break;
      }
      default: {
        button = (
          <Button
            type="primary"
            onClick={this.checkBeforeAddRequest}
            loading={isLoading}
          >
            Add Friend
          </Button>
        );
        break;
      }
    }

    return (
      <Fragment>
        {button}
        <Modal
          wrapClassName="vertical-center-modal"
          visible={consumerLoginDecisionModal}
          onOk={() => this.showDecisionModal(false)}
          onCancel={() => this.showDecisionModal(false)}
          footer={null}
          zIndex={1060}
        >
          <div>
            <GuestLoginForm
              onSuccess={this.onLoginSuccess}
              onSuccessAccount={this.onLoginSuccess}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const FriendRequestButtonWithStateAndMutation = compose(
  graphql(SendConsumerFriendRequestMutation, {
    name: 'addAsFriend',
    props: ({ addAsFriend }) => ({
      addAsFriend: consumerId => addAsFriend({ variables: { consumerId } }),
    }),
  }),
  graphql(CancelUserFriendRequestMutation, {
    name: 'cancelFriendRequest',
    props: ({ cancelFriendRequest }) => ({
      cancelFriendRequest: consumerId =>
        cancelFriendRequest({ variables: { consumerId } }),
    }),
  }),
  graphql(AcceptConsumerFriendRequestMutation, {
    name: 'acceptFriendRequest',
    props: ({ acceptFriendRequest }) => ({
      acceptFriendRequest: friendId =>
        acceptFriendRequest({ variables: { friendId } }),
    }),
  }),
  graphql(RemoveConsumerFriendtMutation, {
    name: 'removeAsFriend',
    props: ({ removeAsFriend }) => ({
      removeAsFriend: friendId => removeAsFriend({ variables: { friendId } }),
    }),
  }),
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      userType: state.auth.type,
    }),
    dispatch => ({
      onLoginUser(accessToken) {
        dispatch(loginUser(accessToken));
      },
    })
  )
)(FriendRequestButton);

export default FriendRequestButtonWithStateAndMutation;
