import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { AgentSearch } from './Operations.graphql';
import { List, Button, Row, Col } from 'antd';
import Loading from '../Loading';
import LoadingDots from '../LoadingDots';
import AgentCardList from '../AgentCardList';
import emptySearch from '../PropertySearchResults/search.png';
import './AgentSearchResults.scss';

function LoadMoreContent(props) {
  return (
    <div className="loading-container">
      {props.loadingMore && <LoadingDots />}
      {!props.loadingMore && <Button onClick={props.onClick}>Load More</Button>}
    </div>
  );
}

LoadMoreContent.propTypes = {
  loadingMore: PropTypes.any,
  onClick: PropTypes.func,
};

class AgentSearchResults extends Component {
  state = {
    isLoadingMore: false,
  };

  handleLoadMore = async () => {
    const {
      data: { fetchMore, agentSearch },
      searchOptions: {
        region,
        specialty,
        name,
        budgetRange,
        listingType,
        typeGroup,
      },
    } = this.props;

    this.setState({ isLoadingMore: true });

    return new Promise(async resolve => {
      try {
        await fetchMore({
          variables: {
            filter: {
              first: 24,
              region,
              specialty,
              name,
              budgetRange,
              listingType,
              typeGroup,
              after: agentSearch.pageInfo.endCursor,
            },
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const { nodes } = fetchMoreResult.agentSearch;

            return nodes.length
              ? {
                  ...previousResult,
                  agentSearch: {
                    ...fetchMoreResult.agentSearch,
                    nodes: [...previousResult.agentSearch.nodes, ...nodes],
                  },
                }
              : previousResult;
          },
        });
        resolve(true);
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingMore: false });
      }
    });
  };

  render() {
    const {
      data: { loading, agentSearch },
    } = this.props;
    const { isLoadingMore } = this.state;

    if (loading) return <Loading />;

    const showLoadingMore = agentSearch
      ? agentSearch.pageInfo.hasNextPage
      : false;
    let dataSource = agentSearch ? agentSearch.nodes : [];

    const loadMoreContent =
      !isLoadingMore && showLoadingMore ? (
        <LoadMoreContent
          loadingMore={isLoadingMore}
          onClick={() => this.handleLoadMore()}
        />
      ) : null;

    return (
      <>
        {dataSource.length === 0 && (
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={22} lg={24} xl={24} xxl={18}>
              <div className="p-6 rounded bg-white text-center">
                <img
                  src={emptySearch}
                  alt="empty search results"
                  style={{ width: 250 }}
                />
                <h2 className="font-thin mt-4 text-gray-500">
                  Sorry, no agent results found
                </h2>
              </div>
            </Col>
          </Row>
        )}
        {dataSource.length > 0 && (
          <>
            <div className="md:text-lg text-base text-center text-gray-700 uppercase mb-2">
              Showing <span className="font-bold">{dataSource.length}</span> of
              <span className="font-bold"> {agentSearch.totalCount}</span>{' '}
              Agents
            </div>
            <List
              className="search-results"
              key="search-results"
              loadMore={<div className="mt-4 mb-10">{loadMoreContent}</div>}
              locale={{ emptyText: null }}
            >
              <Row
                type="flex"
                justify="center"
                gutter={{
                  sm: 16,
                  md: 16,
                  lg: 16,
                  xl: 16,
                  xxl: 32,
                }}
              >
                {dataSource.map(agent => (
                  <Col
                    xs={23}
                    sm={20}
                    md={24}
                    lg={12}
                    xl={8}
                    xxl={8}
                    className="flex items-stretch mb-4"
                    key={agent.id}
                  >
                    <AgentCardList agent={agent} />
                  </Col>
                ))}
              </Row>
            </List>
          </>
        )}
      </>
    );
  }
}

const AgentSearchResultsWithData = compose(
  graphql(AgentSearch, {
    options: (
      {
        searchOptions: {
          region,
          specialty,
          name,
          budgetRange,
          listingType,
          typeGroup,
        } = {},
      } = {
        searchOptions: {},
      }
    ) => ({
      variables: {
        filter: {
          first: 24,
          region,
          specialty,
          name,
          budgetRange,
          listingType,
          typeGroup,
        },
      },
    }),
  })
)(AgentSearchResults);

export default AgentSearchResultsWithData;
