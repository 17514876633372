import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Card, Icon } from 'antd';
import { ConsumerFriendsQuery } from './Operations.graphql';
import Error from '../../Error/Error';
import FollowCardTitleCount from '../FollowCardTitleCount/FollowCardTitleCount';

class ConsumerProfileFriendList extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    pageId: PropTypes.string,
    editable: PropTypes.bool,
  };

  static defaultProps = {
    data: {},
    pageId: null,
    editable: null,
  };

  state = {
    visible: false,
    currentlyFollowing: null,
    suburb: null,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const {
      data: { loading, error, consumerPage },
      editable,
    } = this.props;
    if (error) return <Error error={error} />;

    const {
      owner: { consumerFriendsConnection },
    } = consumerPage || { owner: { consumerFriendsConnection: { nodes: [] } } };
    const { nodes: friends } = consumerFriendsConnection || [];

    return (
      <Card
        loading={loading}
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className="shadow rounded mt-4"
        title={<FollowCardTitleCount title="Friends" count={friends.length} />}
        // extra={
        //   editable && (
        //     <Link to="/lifestyle" className="rounded-full p-2">
        //       <Icon type="plus" />
        //     </Link>
        //   )
        // }
      >
        <main className="flex flex-wrap p-3">
          {friends.length > 0 ? (
            <>
              {friends.map(
                ({
                  id,
                  initials,
                  firstName,
                  lastName,
                  profilePageId,
                  profilePicture,
                }) => {
                  const firstLetter = initials.charAt(0).toLowerCase();
                  return (
                    <Link to={`/${profilePageId || ''}`} key={profilePageId}>
                      <div className="flex align-middle">
                        <Avatar
                          className={`bg-blue-500 m-1 contributor-avatar avatar-${firstLetter}`}
                          key={id}
                          size={50}
                          src={profilePicture}
                          title={
                            <>
                              {firstName}
                              &nbsp;
                              {lastName}
                            </>
                          }
                        >
                          {initials}
                        </Avatar>
                        <div className="ml-2 text-black flex items-center">
                          <div>
                            <div className="font-semibold">
                              {firstName} {lastName}{' '}
                            </div>

                            <span className="text-xs font-normal inline-block">
                              @{profilePageId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
              )}
            </>
          ) : (
            <span>No friends yet</span>
          )}
        </main>
      </Card>
    );
  }
}

const ConsumerFollowingContributorListWithData = graphql(ConsumerFriendsQuery, {
  options: ({ pageId }) => ({
    variables: {
      pageId,
      filter: { first: 7, status: 'ACCEPTED' },
    },
  }),
})(ConsumerProfileFriendList);

export default ConsumerFollowingContributorListWithData;
