import { Component } from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class AgentSearchPageQueryParameterParser extends Component {
  static propTypes = {
    urlPath: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.any,
  };

  static defaultProps = {
    urlPath: '/agents',
    history: {},
    location: {},
    children: {},
  };

  parseSearchOptionsFromUrl = () => {
    const { location } = this.props;

    let searchOptions = qs.parse(location.search);

    if (searchOptions.region) {
      let data = searchOptions.region;

      if (!Array.isArray(data)) {
        data = [data];
      }

      Object.assign(searchOptions, { region: data });
    }

    if (searchOptions.specialty) {
      let data = searchOptions.specialty;

      if (!Array.isArray(data)) {
        data = [data];
      }

      Object.assign(searchOptions, { specialty: data });
    }

    if (searchOptions.name) {
      Object.assign(searchOptions, { name: searchOptions.name });
    }

    if (searchOptions.budgetRange) {
      Object.assign(searchOptions, { budgetRange: searchOptions.budgetRange });
    }

    if (searchOptions.listingType) {
      Object.assign(searchOptions, { listingType: searchOptions.listingType });
    }

    if (searchOptions.typeGroup) {
      Object.assign(searchOptions, { typeGroup: searchOptions.typeGroup });
    }

    return searchOptions;
  };

  buildQueryString = searchOptions =>
    qs.stringify(
      {
        region: searchOptions.region,
        specialty: searchOptions.specialty,
        name: searchOptions.name,
        budgetRange: searchOptions.budgetRange,
        listingType: searchOptions.listingType,
        typeGroup: searchOptions.typeGroup,
      },
      { arrayFormat: 'repeat' }
    );

  changeUrl = searchOptions => {
    const { history } = this.props;

    history.push(`/agents?${this.buildQueryString(searchOptions)}`);
  };

  render() {
    const searchOptions = this.parseSearchOptionsFromUrl();

    return this.props.children({
      searchOptions,
      changeUrl: this.changeUrl,
    });
  }
}

export default withRouter(AgentSearchPageQueryParameterParser);
