import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Button, Icon, Modal } from 'antd';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { CreateConsumerTokenUsingFbLogin } from './Operations.graphql';
import './GuestLoginUsingFb.scss';

class GuestLoginUsingFb extends Component {
  static propTypes = {
    onSuccess: PropTypes.func,
    isDisabled: PropTypes.bool,
    setSignInButtonLoading: PropTypes.func,
  };

  static defaultProps = {
    onSuccess: () => {},
    isDisabled: false,
    setSignInButtonLoading: () => {},
  };

  state = {
    modalSigningInVisible: false,
    name: null,
  };

  okButton = () => {
    this.setState({ modalSigningInVisible: false });
  };

  responseFacebook = async response => {
    // message.loading('Signing you in...').then(() => message.success('Signed In', 2.5));
    const { email, name, picture, id } = response;

    const {
      loginConsumerUsingFbMutation,
      onSuccess,
      setSignInButtonLoading,
    } = this.props;

    if (!response.email) {
      this.setState({
        modalSigningInVisible: true,
        noEmail: true,
      });

      return;
    }

    try {
      this.setState({
        modalSigningInVisible: true,
        name,
      });

      const {
        data: { createConsumerTokenUsingFbLogin },
      } = await loginConsumerUsingFbMutation(email, name, picture.data.url, id);
      setSignInButtonLoading();
      onSuccess(createConsumerTokenUsingFbLogin).then(() => {
        this.setState({ modalSigningInVisible: false });
      });
    } catch (error) {
      this.setState({ modalSigningInVisible: false });
      console.log(error);
    }
  };

  render() {
    const { noEmail, modalSigningInVisible, name } = this.state;
    let contentModal = null;

    if (noEmail) {
      contentModal = (
        <Modal
          centered
          visible={modalSigningInVisible}
          footer={null}
          closable={false}
          zIndex={1061}
        >
          <div className="text-center">
            <h2>
              Your facebook account does not associated with email address
            </h2>
            <Button className="m-auto" type="primary" onClick={this.okButton}>
              Okay
            </Button>
          </div>
        </Modal>
      );
    } else {
      contentModal = (
        <Modal
          centered
          visible={modalSigningInVisible}
          footer={null}
          closable={false}
          zIndex={1061}
        >
          <div className="flex items-center justify-center">
            <div className="text-center text-3xl mr-5">
              <Icon type="loading" theme="outlined" />
            </div>
            <div className="text-center">
              <h2>Signing you in as {name}</h2>
            </div>
          </div>
        </Modal>
      );
    }

    // const { isDisabled } = this.props;
    //
    // const disabledFb = isDisabled ? 'pointer-events-none' : '';
    return (
      <div className="fb-btn-container">
        <FacebookLogin
          version="3.2"
          // autoLoad
          xfbml
          appId={process.env.REACT_APP_FB_APP_ID}
          // autoLoad\
          redirectUri={`https://${window.location.hostname}/login`}
          // redirectUri="localhost:4000/login"
          fields="name,email,picture"
          callback={this.responseFacebook}
          render={renderProps => (
            <Button
              size="large"
              onClick={renderProps.onClick}
              className="fb-btn rounded"
            >
              <FA icon={faFacebookF} className="mr-2" /> via Facebook
            </Button>
          )}
          onFailure={() => {}}
        />
        {contentModal}
      </div>
    );
  }
}

export default graphql(CreateConsumerTokenUsingFbLogin, {
  props: ({ mutate }) => ({
    loginConsumerUsingFbMutation: (email, name, profileUrl, fbId) =>
      mutate({
        variables: {
          email,
          name,
          profileUrl,
          fbId,
        },
      }),
  }),
})(GuestLoginUsingFb);
