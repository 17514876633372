import React, { Component, Fragment } from 'react';
import copy from 'copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import { FacebookProvider } from 'react-facebook';
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { CreateTopic, ConsumerFriendsQuery } from './Operations.graphql';
import { Modal, Form, Input, Button, Select, message } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faComments, faCopy } from '@fortawesome/free-regular-svg-icons';
import LoadingDots from '../../LoadingDots';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';

class CreateTopicModal extends Component {
  state = {
    isLoading: false,
    isCopied: false,
    isSearching: false,
    data: [],
    uniqueId: null,
  };

  handleSubmit = e => {
    e.preventDefault();

    const {
      form: { validateFields },
      createTopic,
      data: { property },
    } = this.props;
    const { id } = property || [];
    const propertyId = id;

    this.setState({ isLoading: true });

    validateFields(async (err, values) => {
      if (!err) {
        createTopic({
          ...values,
          propertyId,
        })
          .then(res => {
            if (res.data.createTopic) {
              message.success('Topic created successfully.');

              this.setState({
                uniqueId: res ? res.data.createTopic.uniqueId : null,
              });
            }
          })
          .catch(err => {
            message.error('Something went wrong. Please try again.');
            throw err;
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });

        return;
      }

      this.setState({ isLoading: false });
    });
  };

  handleSearch = async (value, isInitial = false) => {
    const { client } = this.props;

    if (value === '') {
      this.setState({ data: [] });
      return;
    }

    try {
      this.setState({ isSearching: true });

      const {
        data: { viewer },
      } = await client.query({
        query: ConsumerFriendsQuery,
        variables: {
          filter: {
            first: 7,
            keyword: value,
          },
        },
        fetchPolicy: isInitial ? null : 'network-only',
      });
      const {
        consumerFriendsConnection: { nodes },
      } = viewer || [];

      this.setState({
        data: nodes || [],
        isSearching: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleCopyUrl = () => {
    const { uniqueId } = this.state;

    copy(`${window.origin}/t/${uniqueId}`);

    this.setState({ isCopied: true });
  };

  handleMessengerShareEventClick = () => {
    const { uniqueId } = this.state;
    const url = `${window.origin}/t/${uniqueId}`;

    if (isMobile) {
      window.open(
        'fb-messenger://share?link=' +
          encodeURIComponent(url) +
          '&app_id=' +
          encodeURIComponent(process.env.REACT_APP_FB_APP_ID)
      );

      return;
    }

    window.FB.ui(
      {
        method: 'send',
        link: url,
      },
      () => {}
    );
  };

  render() {
    const {
      visible,
      form: { getFieldDecorator },
      data: { loading, error, property },
      onCancel,
    } = this.props;
    const { isLoading, isCopied, isSearching, data, uniqueId } = this.state;

    if (loading) return <Loading className="full-page" />;

    if (error) return <Error error={error} />;

    return (
      <FacebookProvider appId={process.env.REACT_APP_FB_APP_ID} version="v3.2">
        <Modal onCancel={onCancel} visible={visible} footer={null}>
          <Form onSubmit={this.handleSubmit}>
            {!uniqueId && (
              <Fragment>
                <h4 className="text-gray-800 text-xs text-center uppercase font-semibold py-1">
                  Share this property
                </h4>
                <span className="text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
                  Topic
                </span>
                <Form.Item>
                  {getFieldDecorator('name', {
                    initialValue: property.title,
                    rules: [
                      {
                        required: true,
                        message: 'Topic is required.',
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <FA
                          color="pink"
                          icon={faComments}
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                    />
                  )}
                </Form.Item>
                <span className="text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
                  Members
                </span>
                <Form.Item>
                  {getFieldDecorator('members', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select at least one member.',
                      },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select Members"
                      notFoundContent={
                        isSearching ? <LoadingDots /> : 'No members found.'
                      }
                      filterOption={false}
                      onSearch={this.handleSearch}
                      style={{ width: '100%' }}
                    >
                      {data.map(
                        ({
                          id,
                          firstName,
                          lastName,
                          profilePageId,
                          email,
                          fullName,
                        }) => (
                          <Select.Option
                            key={`${profilePageId} ${email} ${fullName}`}
                            value={id}
                          >
                            {firstName} {lastName}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item className="text-right">
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Create
                  </Button>
                </Form.Item>
              </Fragment>
            )}
            {uniqueId && (
              <Fragment>
                <span className="text-gray-800 font-semibold py-1 inline-block mr-4">
                  Share with others through URL:
                </span>
                <div className="p-3 border rounded bg-gray-300 flex justify-around items-center">
                  <div>
                    https://singapore.realty.com.au/t/
                    {uniqueId}
                  </div>
                  <div>
                    <Button onClick={this.handleCopyUrl}>
                      <FA icon={faCopy} className="mr-1" />{' '}
                      {isCopied ? ' Copied' : ' Copy'}
                    </Button>
                  </div>
                </div>
                <div className="flex justify-center my-2">or</div>
                <div className="flex justify-center">
                  <Button
                    type="primary"
                    onClick={this.handleMessengerShareEventClick}
                  >
                    Share to messenger
                  </Button>
                </div>
              </Fragment>
            )}
          </Form>
        </Modal>
      </FacebookProvider>
    );
  }
}

const PropertyQuery = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      title
    }
  }
`;

const CreateTopicModalWithMutation = compose(
  Form.create(),
  withApollo,
  graphql(PropertyQuery, {
    options: ({ propertyId }) => ({
      variables: { id: propertyId },
    }),
  }),
  graphql(CreateTopic, {
    props: ({ mutate }) => ({
      createTopic: ({ name, members, propertyId }) =>
        mutate({
          variables: {
            name,
            members,
            propertyId,
          },
        }),
    }),
  })
)(CreateTopicModal);

export default CreateTopicModalWithMutation;
