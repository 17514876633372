import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import './SearchTypeMenu.scss';

const searchMenu = [
  {
    link: '/buy',
    text: 'Buy',
  },
  {
    link: '/rent',
    text: 'Rent',
  },
];

const commercialSearchMenu = [
  {
    link: '/commercial/buy',
    text: 'Buy',
  },
  {
    link: '/commercial/rent',
    text: 'Rent',
  },
];

const SearchTypeMenu = React.memo(({ pathname }) => {
  const renderMenuItem = (link, text) => (
    <NavLink
      to={link}
      key={link}
      className={`md:mr-1 md:px-6 px-3 sm:py-2 xs:px-2 uppercase search-type inline-block ${(link ===
        pathname ||
        (link === '/buy' && pathname === '/') ||
        (link === '/commercial/buy' && pathname === '/commercial')) &&
        'search-type-active'}`}
    >
      {text}
    </NavLink>
  );

  return (
    <main className="flex flex-row flex-wrap justify-center py-2">
      <section className="bg-transparent sm:mb-3 text-left search-type-list border-0 text-center inline-block sm:w-auto w-full">
        {pathname.startsWith('/commercial') &&
          commercialSearchMenu.map(({ text, link }) =>
            renderMenuItem(link, text)
          )}
        {!pathname.startsWith('/commercial') &&
          searchMenu.map(({ text, link }) => renderMenuItem(link, text))}
      </section>
    </main>
  );
});

SearchTypeMenu.propTypes = { pathname: PropTypes.string };

SearchTypeMenu.defaultProps = { pathname: '/' };

export default withRouter(({ location }) => (
  <SearchTypeMenu pathname={location.pathname} />
));
