import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import NumberPlusMinusInput from '../../NumberPlusMinusInput';

class ModalRadio extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    // value: PropTypes.object,
    name: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    // value: {},
    name: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value.min !== state.value) {
      return { value: props.value.min };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = { value: value.min };
  }

  handleClear = () => {
    const { onChange } = this.props;
    const min = undefined;
    this.setState({ value: min }, () => {
      onChange({ min });
    });
  };

  handleChange = min => {
    const { onChange } = this.props;
    this.setState({ value: min }, () => {
      onChange({ min });
    });
  };

  render() {
    const { value } = this.state;
    const { name } = this.props;

    return (
      <Row type="flex" justify="space-between" align="middle">
        <Col className="">
          <div className=" inline-block text-base">{name}</div>
          {/* <Button disabled={!value} className="-ml-6 border-0" onClick={this.handleClear}> */}
          {/* {' '} */}
          {/* Clear */}
          {/* </Button> */}
        </Col>
        <Col>
          <NumberPlusMinusInput value={value} onChange={this.handleChange} />
        </Col>
      </Row>
    );
  }
}

export default ModalRadio;
