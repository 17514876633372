import React from 'react';
import { Collapse, Icon } from 'antd';
import PropTypes from 'prop-types';
import './Error.scss';

const Error = ({
  title,
  description,
  showButton,
  buttonText,
  onClickButton,
  error,
  className,
}) => (
  <div className="flex items-center justify-center custom-error">
    <div
      className={`flex flex-col items-center justify-center border-2 rounded-lg border-red-200 ${className}`}
    >
      <div className="p-8 flex flex-col items-center justify-center">
        <Icon
          type="warning"
          theme="outlined"
          className="text-5xl mx-2 error-icon text-red-300"
        />
        <div className="text-4xl">{title}</div>
        <div className="text-2xl font-hairline">{description}</div>
        {process.env.NODE_ENV === 'development' && error && (
          <Collapse>
            <Collapse.Panel header={JSON.stringify(error).slice(0, 50)} key="1">
              <div className="text-base font-thin text-gray-700">
                {JSON.stringify(error)}
              </div>
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
      {showButton && (
        <div
          className="h-12 text-center leading-loose cursor-pointer bg-red-300 w-full text-white text-2xl"
          onClick={onClickButton}
        >
          {buttonText}
        </div>
      )}
    </div>
  </div>
);

Error.propTypes = {
  error: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
};

Error.defaultProps = {
  error: null,
  className: '',
  title: 'Oops!',
  showButton: false,
  onClickButton: () => {},
  buttonText: 'Dismiss',
  description: 'Something went wrong',
};

export default Error;
