/* eslint-disable react/style-prop-object */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { IntlProvider, FormattedNumber } from 'react-intl';
import cls from 'classnames';

const PropertyPrice = ({
  currencyClassName,
  className,
  price,
  showDecimal,
  isSold,
}) => {
  const currencySymbolCls = cls('currency', currencyClassName, {
    'text-sm': !currencyClassName,
  });

  const amountCls = cls('amount', className, {
    'text-2xl tracking-tight font-hairline': !className,
  });

  return (
    <IntlProvider locale="en-SG">
      <Fragment>
        {!isSold ? (
          <span className="property-price-unsold">
            <span className={currencySymbolCls} style={{ marginRight: 2 }}>
              <Tooltip title="Singapore Dollar">S$</Tooltip>
            </span>
            <span className={amountCls}>
              <FormattedNumber
                minimumFractionDigits={showDecimal ? 2 : 0}
                maximumFractionDigits={showDecimal ? 2 : 0}
                style="decimal"
                value={price || 0}
              />
            </span>
          </span>
        ) : (
          'Sold'
        )}
      </Fragment>
    </IntlProvider>
  );
};

PropertyPrice.propTypes = {
  className: PropTypes.string,
  currencyClassName: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showDecimal: PropTypes.bool,
};
PropertyPrice.defaultProps = {
  className: '',
  currencyClassName: '',
  price: 0,
  showDecimal: true,
};

export default PropertyPrice;
