import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './PriceFilterRange.scss';

class PriceFilterRange extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    defaultValue: PropTypes.arrayOf(PropTypes.number),
    miniInfo: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    // formatter: PropTypes.bool,
    // clear: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: [0, 0],
    defaultValue: [100000, 5000000],
    min: 100000,
    max: 5000000,
    step: 50000,
    miniInfo: true,
    onChange: () => {},
  };

  handleChange = value => {
    const { onChange } = this.props;
    this.setState(
      {
        valueMin: value[0],
        valueMax: value[1],
      },
      () => {
        onChange(value[0], value[1]);
      }
    );
  };

  constructor(props) {
    super(props);
    const { value, defaultValue } = this.props;

    this.state = {
      valueMin: value && value[0] ? value[0] : defaultValue[0],
      valueMax: value && value[1] ? value[1] : defaultValue[1],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.value[0] !== state.valueMin ||
      props.value[1] !== state.valueMax
    ) {
      return {
        valueMin:
          props.value && props.value[0]
            ? props.value[0]
            : props.defaultValue[0],
        valueMax:
          props.value && props.value[1]
            ? props.value[1]
            : props.defaultValue[1],
      };
    }
    return null;
  }

  render() {
    const { valueMin, valueMax } = this.state;
    const { min, max, step, miniInfo } = this.props;

    const handle = props => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Slider.Handle value={value} {...restProps} key={index}>
          {index === 0 && (
            <span>
              {value === min ? '$Min' : numeral(value).format('($0[.]00a)')}
            </span>
          )}
          {index === 1 && (
            <span>
              {' '}
              {value === max ? '$Max' : numeral(value).format('($0[.]00a)')}
            </span>
          )}
        </Slider.Handle>
      );
    };

    return (
      <div className="PriceFilterRange px-6">
        {miniInfo && (
          <div className="text-xs text-center slider-label" key="slider-label">
            {valueMin === min ? '$Min' : numeral(valueMin).format('($0[.]00a)')}{' '}
            -{' '}
            {valueMax === max ? '$Max' : numeral(valueMax).format('($0[.]00a)')}
          </div>
        )}

        <div className="slider">
          <Slider.Range
            handle={handle}
            defaultValue={[min, max]}
            value={[valueMin, valueMax]}
            max={max}
            min={min}
            step={step}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default PriceFilterRange;
