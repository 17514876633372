import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { FormattedNumber } from 'react-intl';
import GoogleAnalytics from 'react-ga';

import './PropertyCardPremiere.scss';
// import PropertyViewVideos from '../modals/PropertyViewVideos';
// import PropertyViewPhotos from '../modals/PropertyViewPhotos';
// import AgentPopover from '../AgentPopover';
import PropertyCarousel from '../PropertyCarousel';
import PropertyCardAgentPopover from '../PropertyCardAgentPopover';
// import PropertyCollage from '../PropertyCollage';
// import PropertyAddress from '../PropertyAddress';
// import MainFeatures from '../MainFeatures';

class PropertyCardPremiere extends React.Component {
  static propTypes = {
    property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onCardMouseEnter: PropTypes.func,
    onCardMouseLeave: PropTypes.func,
    ofiTime: PropTypes.bool,
    searchParam: PropTypes.string,
    hasScrolled: PropTypes.bool,
  };

  static defaultProps = {
    property: {},
    ofiTime: false,
    onCardMouseEnter: () => {},
    onCardMouseLeave: () => {},
    searchParam: '',
    hasScrolled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isShowViewVideosModal: false,
      isShowOfiTimesModal: false,

      isShowViewPhotosModal: false,
    };

    this.slider = React.createRef();

    this.getCardPosition = debounce(this.getCardPosition, 50);
  }

  componentDidMount() {
    const { hasScrolled } = this.props;

    if (!hasScrolled) {
      this.getCardPosition();
    }

    window.addEventListener('scroll', this.getCardPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getCardPosition);
  }

  getCardPosition = () => {
    const {
      property: { shortId, agent },
      offsetHeaderHeight,
    } = this.props;
    const winHeight = window.innerHeight;
    let agentId = 0;

    if (agentId) {
      agentId = agent.id;
    }

    if (!isEmpty(this.card) && agentId) {
      const cardBounds = this.card.getBoundingClientRect();

      if (
        cardBounds.top >= offsetHeaderHeight &&
        cardBounds.bottom < winHeight
      ) {
        GoogleAnalytics.ga(
          'send',
          'event',
          'Property View',
          'Search Result Appearance',
          { dimension1: shortId, dimension7: agentId }
        );

        this.setState({ isPropertyInView: true });
      } else {
        this.setState({ isPropertyInView: false });
      }
    }
  };

  onCloseViewVideosModal = () => {
    this.setState({ isShowViewVideosModal: false });
  };

  onCloseOfiTimesModal = () => {
    this.setState({ isShowOfiTimesModal: false });
  };

  onCloseViewPhotosModal = () => {
    this.setState({ isShowViewPhotosModal: false });
  };

  showViewVideosModal = () => {
    this.setState({ isShowViewVideosModal: true });
  };

  showOfiModal = () => {
    this.setState({ isShowOfiTimesModal: true });
  };

  showViewPhotosModal = () => {
    this.setState({ isShowViewPhotosModal: true });
  };

  render() {
    const {
      property,
      onCardMouseEnter,
      onCardMouseLeave,
      ofiTime,
      searchParam,
    } = this.props;
    const {
      isShowViewVideosModal,
      isShowViewPhotosModal,
      isShowOfiTimesModal,
    } = this.state;

    const {
      id,
      address,
      slugUrl,
      features,
      area,
      number,
      district,
      agent,
      price,
      title,
      videoLink,
      objects,
      ofiTimes,
      isCollage,
      typeGroup,
      landArea,
      floorArea,
      psf,
      segment,
      commercialType,
    } = property || {};

    // const propertyWithAgent = {...property};
    // propertyWithAgent.agents = [mainAgent];

    const streetAddress = property.streetName;
    const {
      firstName,
      lastName,
      profilePictureUrl,
      agencyName,
      agencyLogoUrl,
      agencyMiniLogoUrl,
      bannerColor,
    } = agent || {};

    let cardBorderStyle = {};
    if (bannerColor) {
      cardBorderStyle.borderColor = bannerColor;
    }

    return (
      <Fragment>
        {/*<PropertyViewVideos*/}
        {/*video={videoLink}*/}
        {/*showModal={isShowViewVideosModal}*/}
        {/*onClose={this.onCloseViewVideosModal}*/}
        {/*/>*/}

        {/*<PropertyViewPhotos*/}
        {/*property={propertyWithAgent}*/}
        {/*showModal={isShowViewPhotosModal}*/}
        {/*showViewPhotosModal={this.showViewPhotosModal}*/}
        {/*onClose={this.onCloseViewPhotosModal}*/}
        {/*/>*/}
        <div
          className="w-full"
          ref={ref => {
            this.card = ref;
          }}
        >
          <Row
            className="PropertyCardPremiere w-full relative overflow-hidden"
            key={id}
            type="flex"
            onMouseEnter={() => onCardMouseEnter(id)}
            onMouseLeave={() => onCardMouseLeave(id)}
          >
            <Col
              xs={24}
              className="text-center relative bg-white rounded overflow-hidden"
            >
              <Row type="flex" className="h-full flex-col">
                <Col
                  xs={24}
                  className="h-12 relative text-right p-1"
                  style={{ background: bannerColor || '#2F3940' }}
                >
                  <div className="xxxl:w-1/6 xxl:w-1/5 w-1/3 h-full inline-block">
                    <img
                      src={agencyMiniLogoUrl || agencyLogoUrl}
                      alt={agencyName}
                      className="cover-img rounded-lg"
                    />
                  </div>
                </Col>
                <Col
                  xs={24}
                  className="border-b-4 border-gray-800"
                  style={cardBorderStyle}
                >
                  <div className="w-full relative photos">
                    <PropertyCarousel
                      property={property}
                      ofiTime={ofiTime}
                      showViewVideosModal={this.showViewVideosModal}
                      height="100%"
                      showOfiModal={this.showOfiModal}
                      isFromSearch={!isEmpty(searchParam)}
                    />
                  </div>
                </Col>
                <Col xs={24} className="pl-2 pt-1  relative self-center  ">
                  <Row type="flex" justify="space-between">
                    <Col className={'card-details flex flex-col text-left'}>
                      <div className="card-title">
                        <h3 className="font-semibold text-gray-900 text-lg text-overflow ">
                          {title}
                        </h3>
                      </div>
                      <div className="card-price text-base font-medium">
                        <span className=" font-semibold text-xs font-normal text-gray-900 mr-1">
                          S$
                        </span>
                        <span className="text-lg font-semibold text-gray-900">
                          <FormattedNumber value={price} />
                        </span>

                        {segment === 'RESIDENTIAL' ? (
                          <span className="font-normal text-xs">
                            <span className="mx-1">&bull;</span>
                            {segment}
                            <span className="mx-1">&bull;</span>
                            {typeGroup === 'HDB' && 'HDB'}
                            {typeGroup === 'LANDED' && 'Landed'}
                            {typeGroup === 'CONDOMINIUM' && 'Condo'}
                          </span>
                        ) : (
                          <span className="font-normal text-xs">
                            <span className="mx-1">&bull;</span>
                            {segment}
                            <span className="mx-1">&bull;</span>
                            {commercialType === 'RETAIL' && 'Retail'}
                            {commercialType === 'OFFICE' && 'Office'}
                            {commercialType === 'INDUSTRIAL' && 'Industrial'}
                            {commercialType === 'LAND' && 'Land'}
                          </span>
                        )}
                      </div>
                      <div className="card-address">
                        <h3 className="font-normal text-sm text-overflow ">
                          <Link
                            to={{
                              pathname: `/property/${slugUrl}`,
                              state: { fromSearch: !isEmpty(searchParam) },
                            }}
                            className="text-gray-900"
                          >
                            <span>
                              {number} {streetAddress}
                            </span>

                            {area && (
                              <span>
                                {(number || streetAddress) && (
                                  <span className="mx-1">&bull;</span>
                                )}
                                {area}
                              </span>
                            )}
                            {district && (
                              <span>
                                {area && <span className="mx-1">&bull;</span>}D
                                {district}
                              </span>
                            )}

                            {/*<PropertyAddress property={property} newLine icon/>*/}
                          </Link>
                        </h3>
                      </div>

                      <div className="card-areas text-gray-800">
                        {typeGroup === 'LANDED' && landArea && landArea.area ? (
                          <span>
                            {landArea.area} {landArea.unit}
                          </span>
                        ) : null}

                        {typeGroup !== 'LANDED' &&
                        floorArea &&
                        floorArea.area ? (
                          <span>
                            {floorArea.area} {floorArea.unit}
                          </span>
                        ) : null}

                        {psf ? (
                          <>
                            {(typeGroup === 'LANDED' &&
                              landArea &&
                              landArea.area) ||
                              (typeGroup !== 'LANDED' &&
                                floorArea &&
                                floorArea.area && (
                                  <span className="mx-1">&bull;</span>
                                ))}
                            <span className="">
                              <span className="card-price text-xs font-normal">
                                S$&nbsp;
                              </span>
                              <FormattedNumber value={psf} /> psf
                            </span>
                          </>
                        ) : null}
                      </div>
                    </Col>
                    {agent && (
                      <Col className={'agent-info w-24'}>
                        <PropertyCardAgentPopover
                          agent={agent}
                          propertyId={property.id}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
                {/*{mainAgent && (*/}
                {/*<Col xs={24}*/}
                {/*className="agent-details flex self-end cursor-pointer mt-auto">*/}
                {/*<div className="w-full">*/}
                {/*<AgentPopover*/}
                {/*className="bg-blue-lightest text-center rounded-b agent-picture"*/}
                {/*property={property}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*</Col>*/}
                {/*)}*/}
              </Row>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default PropertyCardPremiere;
