import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { Col, Row } from 'antd';
import { FormattedPlural } from 'react-intl';
import Error from '../../Error';
import { ConsumerProfileStatisticsQuery } from './Operations.graphql';

class ConsumerProfileStatistics extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    pageId: PropTypes.string,
  };

  static defaultProps = {
    data: {},
    pageId: null,
  };

  render() {
    const {
      data: { error, consumerPage },
    } = this.props;

    if (error) return <Error error={error} />;

    const {
      owner: {
        statistics: { postCount, followersCount, likesCount, friendsCount },
      },
    } = consumerPage || {
      owner: {
        statistics: {
          postCount: 0,
          followersCount: 0,
          likesCount: 0,
          friendsCount: 0,
        },
      },
    };

    return (
      <Row type="flex" className="pt-3 pb-1">
        <Col className="cursor-pointer w-16">
          <h5 className="text-center font-normal text-white">
            <FormattedPlural
              value={followersCount}
              one="Follower"
              other="Followers"
            />
          </h5>
          <h1 className="text-center text-white">{followersCount}</h1>
        </Col>
        <Col className="cursor-pointer w-16">
          <h5 className="text-center font-normal text-white">
            <FormattedPlural value={postCount} one="Post" other="Posts" />
          </h5>
          <h1 className="text-center text-white">{postCount}</h1>
        </Col>
        <Col className="cursor-pointer w-16">
          <h5 className="text-center font-normal text-white">
            <FormattedPlural value={likesCount} one="Like" other="Likes" />
          </h5>
          <h1 className="text-center text-white">{likesCount}</h1>
        </Col>
        <Col className="cursor-pointer w-16">
          <h5 className="text-center font-normal text-white">
            <FormattedPlural
              value={friendsCount}
              one="Friend"
              other="Friends"
            />
          </h5>
          <h1 className="text-center text-white">{friendsCount}</h1>
        </Col>
      </Row>
    );
  }
}

export default graphql(ConsumerProfileStatisticsQuery, {
  options: ({ pageId }) => ({
    variables: { pageId },
  }),
})(ConsumerProfileStatistics);
