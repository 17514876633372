import React from 'react';
import { Avatar, Button, Col, Row } from 'antd';
import PropTypes from 'prop-types';

function WizardCompleted({ onSubmit, agents }) {
  const { totalCount, nodes } = agents;

  return (
    <>
      <p className="px-4 text-center text-2xl md:text-3xl text-white">
        Agent search successfully completed.
      </p>
      <p className="mt-2 text-center text-sm text-white">
        We will display your recommended agents on the next page.
      </p>
      {totalCount !== 0 && (
        <Row type="flex" justify="center" className="mt-6 px-4">
          <Col>
            <div className="final-step-images">
              <p className="text-center mt-4 px-10 text-2xl text-white">
                Top Recommended{' '}
                {nodes.filter(agent => agent.profilePictureUrl).length > 1
                  ? 'Agents'
                  : 'Agent'}
              </p>
              <div className="flex justify-center">
                {totalCount > 0 &&
                  nodes.map(agent => {
                    if (agent.profilePictureUrl) {
                      return (
                        <div key={agent.id} className="avatar">
                          <Avatar size="large" src={agent.profilePictureUrl} />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row type="flex" justify="center" className="mt-6 px-4">
        <Col xs={24} md={15} lg={11} xl={8} xxl={5}>
          <Button
            size="large"
            type="primary"
            className="text-center w-full"
            onClick={onSubmit}
          >
            See My Recommended Agents
          </Button>
        </Col>
      </Row>
    </>
  );
}

WizardCompleted.propTypes = {
  agents: PropTypes.object,
  onSubmit: PropTypes.func,
};

WizardCompleted.defaultProps = {
  agents: {},
  onSubmit: () => {},
};

export default WizardCompleted;
