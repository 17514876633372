import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Modal } from 'antd';
import AgentContactNumber from '../AgentContactNumber';

class AgentContactModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    agent: PropTypes.object,
    onOk: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    agent: {},
    onOk: () => {},
  };

  render() {
    const { visible, agent, onOk } = this.props;

    return (
      <Modal
        title={
          <div className="flex justify-center items-center">
            <Avatar
              size={100}
              alt={`${agent.name}`}
              icon="user"
              src={agent.profilePicture}
              className="shadow border-4 border-white bg-gray-300"
            />
          </div>
        }
        style={{ top: 20 }}
        visible={visible}
        onCancel={onOk}
        footer={[]}
      >
        <div className="flex justify-between text-lg">
          <div>Mobile</div>
          {agent.mobileNumber ? (
            <AgentContactNumber
              useTel
              agentId={agent.id}
              contactNo={agent.mobileNumber}
            />
          ) : (
            'Not Available'
          )}
        </div>
      </Modal>
    );
  }
}

export default AgentContactModal;
