import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Popover, Button, Icon, Radio } from 'antd';

const RadioGroup = Radio.Group;

const PopoverWrapper = props => {
  return (
    <>
      <Media query="(max-width: 425px)">
        {matches =>
          matches ? (
            <Popover placement="bottomRight" {...props}>
              {props.children}
            </Popover>
          ) : (
            <Popover placement="bottom" {...props}>
              {props.children}
            </Popover>
          )
        }
      </Media>
    </>
  );
};

class PropertyListingFilter extends Component {
  static propTypes = {
    listingType: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    listingType: '',
    onChange: () => {},
  };

  state = {
    listingType: '',
  };

  handleOnApply = () => {
    this.props.onChange(this.state.listingType);
  };

  handleOnClear = () => {
    this.props.onChange('');
  };

  render() {
    const { listingType } = this.props;

    const props = {};

    if (listingType) {
      props.defaultValue = listingType;
    }

    return (
      <PopoverWrapper
        content={
          <div>
            <div className="flex justify-start py-4 pr-24">
              <RadioGroup
                {...props}
                onChange={e => {
                  this.setState({ listingType: e.target.value });
                }}
              >
                <Radio value="SALE" className="block">
                  <span className="text-base">SALE</span>
                </Radio>
                <Radio value="RENT" className="block">
                  <span className="text-base">RENT</span>
                </Radio>
              </RadioGroup>
            </div>
            <div className="flex justify-end  mt-1">
              <Button className="mr-2" onClick={this.handleOnClear}>
                Clear
              </Button>
              <Button type="primary" onClick={this.handleOnApply}>
                Apply
              </Button>
            </div>
          </div>
        }
        trigger="click"
      >
        {listingType ? (
          <Button type="primary" size="large">
            {listingType} <Icon type="caret-down" />
          </Button>
        ) : (
          <Button size="large">
            Property Listing <Icon type="caret-down" />
          </Button>
        )}
      </PopoverWrapper>
    );
  }
}

export default PropertyListingFilter;
