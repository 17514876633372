import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { Button, Col, List, Row } from 'antd';
import AgencyPropertiesQuery from '../../queries/AgencyPropertiesQuery.graphql';
import LoadingDots from '../LoadingDots';
import PropertyCard from '../PropertyCard';
import Error from '../Error';
import Loading from '../Loading';

class AgencyPropertyInfiniteList extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    first: PropTypes.number,
    group: PropTypes.string,
    isFull: PropTypes.bool,
  };

  static defaultProps = {
    data: {},
    first: 9,
    group: '',
    isFull: false,
  };

  state = { loadingMore: false };

  handleLoadMore = async () => {
    const {
      data: { fetchMore, agent },
      first,
      group,
    } = this.props;

    this.setState({ loadingMore: true });

    await fetchMore({
      variables: {
        filter: {
          after: agent.propertiesConnection.pageInfo.endCursor,
          first,
          group,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { nodes } = fetchMoreResult.agent.propertiesConnection;

        return nodes.length
          ? {
              agent: {
                ...fetchMoreResult.agent,
                propertiesConnection: {
                  ...fetchMoreResult.agent.propertiesConnection,
                  nodes: [
                    ...previousResult.agent.propertiesConnection.nodes,
                    ...nodes,
                  ],
                },
              },
            }
          : previousResult;
      },
    });

    this.setState({ loadingMore: false });
  };

  render() {
    const { loadingMore } = this.state;
    const {
      data: { agent, loading, error },
      isFull,
    } = this.props;

    if (loading) return <Loading className="full-page" />;
    if (error) return <Error error={error} />;

    const { propertiesConnection } = agent;

    const showLoadingMore = propertiesConnection
      ? propertiesConnection.pageInfo.hasNextPage
      : false;
    const properties = propertiesConnection ? propertiesConnection.nodes : [];

    const loadMore =
      !loading && showLoadingMore ? (
        <div className="loading-container">
          {loadingMore && <LoadingDots />}
          {!loadingMore && (
            <Button onClick={this.handleLoadMore}>Load More</Button>
          )}
        </div>
      ) : null;

    const colWidth = isFull
      ? {}
      : {
          sm: 22,
          md: 22,
          lg: 22,
          xl: 22,
          xxl: 16,
        };

    return properties.length > 0 ? (
      <Row className="mt-10" justify="center" type="flex" id="properties">
        <Col xs={24} {...colWidth}>
          <List
            loadMore={loadMore}
            className="search-results"
            locale={{ emptyText: null }}
            key="search-results"
          >
            <Row type="flex" justify="center" gutter={16}>
              {properties.map(item => (
                <Col
                  xs={23}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={8}
                  className="flex items-stretch my-2"
                  key={item.id}
                >
                  <PropertyCard property={item} carouselImageHeight="11rem" />
                </Col>
              ))}
            </Row>
          </List>
        </Col>
      </Row>
    ) : (
      <div className="text-center py-8">NO RESULTS FOUND</div>
    );
  }
}

const AgencyPropertyInfiniteListWithData = graphql(AgencyPropertiesQuery, {
  options: ({ id, group, first = 9 }) => ({
    variables: {
      id,
      filter: {
        first,
        group,
      },
    },
  }),
})(AgencyPropertyInfiniteList);

export default AgencyPropertyInfiniteListWithData;
