import React, { Component } from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { withApollo } from 'react-apollo';
import { loginUser, logoutUser } from '../../actions';
import Loading from '../LoadingDots';

const VERIFY_TOKEN = gql`
  query verifyToken($accessToken: String!) {
    verifyToken(accessToken: $accessToken) {
      id
      accessToken
      expiresIn
      type
      refreshToken
      isLoginAsMode
      user {
        ... on Agent {
          id
          email
        }
        ... on Consumer {
          id
          email
          firstName
          lastName
          mobileNumber
        }
        ... on Admin {
          id
        }
      }
    }
  }
`;

const getToken = () =>
  localStorage ? localStorage.getItem('access_token') : null;

class Startup extends Component {
  state = { isLoading: true };

  checkToken = () => {
    const {
      onVerifyToken,
      onVerifyTokenError,
      location: { search },
    } = this.props;
    let token = getToken();

    if (!token) {
      const tokenData = queryString.parse(search);

      if (tokenData && tokenData.authToken) {
        token = tokenData.authToken;
      }
    }

    if (token) {
      this.verifyToken(token)
        .then(result => {
          if (result.data.verifyToken) {
            onVerifyToken(result.data.verifyToken);
          } else {
            onVerifyTokenError();
          }
          this.setState({ isLoading: false });
        })
        .catch(error => {
          // console.log(error);
          // onVerifyTokenError();
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  verifyToken = accessToken => {
    const { client } = this.props;
    return client.query({
      query: VERIFY_TOKEN,
      variables: { accessToken },
    });
  };

  componentDidMount() {
    this.checkToken();
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) return <Loading height="100vh" />;

    return this.props.children;
  }
}

const StartupWithVerifyHandler = withApollo(Startup);

const AppStartUp = connect(
  () => ({}),
  dispatch => ({
    onVerifyToken(accessToken) {
      if (accessToken && accessToken.accessToken) {
        dispatch(loginUser(accessToken));
      }
    },
    onVerifyTokenError() {
      dispatch(logoutUser());
    },
  })
)(StartupWithVerifyHandler);

export default withRouter(AppStartUp);
