import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Icon, message } from 'antd';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import CreateATopicModal from '../../CreateATopicModal';
import MessengerWebShareButton from './MessengerWebShareButton';
import MessengerShareButton from './MessengerShareButton';
import ShareModal from './ShareModal';

class ShareActions extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    property: PropTypes.object,
    shareEvent: PropTypes.func,
    uuid: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    property: {},
    shareEvent: () => {},
    uuid: '',
  };

  state = { isShowShareModal: false, isShowTopicModal: false };

  handleShareModal = () => {
    this.setState(prevState => ({
      isShowShareModal: !prevState.isShowShareModal,
    }));
  };

  handleTopicModal = () => {
    this.setState(prevState => ({
      isShowTopicModal: !prevState.isShowTopicModal,
    }));
  };

  getErrorMessage = () => {
    let errorMessage = null;
    const { formErrorCode } = this.state;

    switch (formErrorCode) {
      case 'SERVER_ERROR':
        errorMessage = 'There was a problem copying this event.';
        break;
      default:
        errorMessage = 'Unable to like event.';
        break;
    }
    return errorMessage;
  };

  saveShareEvent = platform => {
    const { id, shareEvent, property } = this.props;
    const { id: propertyId } = property || [];

    message.config({ top: '90%' });
    const text =
      platform === 'copy'
        ? 'Event copied to clipboard'
        : `Event successfully shared to ${platform}`;
    return shareEvent(id, platform, propertyId)
      .then(() => {
        message.success(text);
      })
      .catch(error => {
        const errors = error.graphqlErrors || [];
        const formErrorCode =
          errors.length > 0 ? errors[0].extensions.code : null;
        this.setState({ formErrorCode }, () => {
          message.error(this.getErrorMessage());
        });
      });
  };

  render() {
    const { id, property, uuid } = this.props;
    const { isShowShareModal, isShowTopicModal } = this.state;

    return (
      <>
        <ShareModal
          uuid={uuid}
          id={id}
          visible={isShowShareModal}
          handleCancel={this.handleShareModal}
          property={property}
          saveShareEvent={this.saveShareEvent}
        />
        <CreateATopicModal
          eventId={id}
          property={property}
          saveShareEvent={this.saveShareEvent}
          handleModal={this.handleTopicModal}
          visible={isShowTopicModal}
        />
        <div className="h-12 flex items-center">
          <Button
            className="h-12 rounded-none leading-loose bg-transparent border-none text-gray-800"
            onClick={this.handleTopicModal}
          >
            <FA icon={faComments} />
          </Button>
          {isMobile ? (
            <MessengerShareButton
              uuid={uuid}
              id={id}
              property={property}
              saveShareEvent={this.saveShareEvent}
            />
          ) : (
            <MessengerWebShareButton
              uuid={uuid}
              id={id}
              property={property}
              saveShareEvent={this.saveShareEvent}
            />
          )}
          <Button
            onClick={this.handleShareModal}
            className="h-12 rounded-none leading-loose bg-transparent border-none text-gray-800"
          >
            <Icon type="share-alt" />
          </Button>
        </div>
      </>
    );
  }
}

const SHARE_EVENT = gql`
  mutation shareEvent(
    $input: CreateEventConsumerShareInput
    $platform: String
  ) {
    shareEvent(input: $input) {
      id
      totalShares(platform: $platform)
    }
  }
`;
const ShareActionsWithMutation = compose(
  graphql(SHARE_EVENT, {
    name: 'shareEvent',
    props: ({ shareEvent }) => ({
      shareEvent: (id, platform, propertyId) =>
        shareEvent({
          variables: {
            input: {
              id,
              platform,
              propertyId,
            },
            platform,
          },
        }),
    }),
  })
)(ShareActions);

export default ShareActionsWithMutation;
