import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducers';

export default (initialState) => {
    const devToolsOnWindow = (typeof window === 'object');

    return createStore(
        combineReducers({ ...appReducers }),
        initialState,
        compose(
            applyMiddleware(thunk),
            // If you are using the devToolsExtension, you can add it here also
            (devToolsOnWindow && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
        ),
    );
};
