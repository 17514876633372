import React, { Component } from 'react';
import { Alert, Row, Col } from 'antd';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import './ConsumerLoginPage.scss';
import PublicLayout from '../../layouts/PublicLayout';
import ConsumerLoginForm from '../../components/consumer/ConsumerLoginForm';
import { loginUser } from '../../actions/index';

class ConsumerLoginPage extends Component {
  static propTypes = {
    onLoginUser: PropTypes.func,
    isAuthenticated: PropTypes.bool,
  };

  static defaultProps = {
    onLoginUser: () => {},
    isAuthenticated: false,
  };

  state = { showThankYou: false };

  onLoginSuccess = loginData => {
    const { onLoginUser } = this.props;
    const { accessToken, expiresIn, refreshToken, user, type } = loginData;

    onLoginUser({
      accessToken,
      expiresIn,
      refreshToken,
      user,
      type,
    });
  };

  static getDerivedStateFromProps(props) {
    const { location } = props;

    const searchOption = qs.parse(location.search);

    if (searchOption.fromVerify === 'true') {
      return { showThankYou: true };
    }

    return null;
  }

  render() {
    const { isAuthenticated } = this.props;
    const { showThankYou } = this.state;

    if (isAuthenticated) return <Redirect to="/_/consumer/feed" />;
    return (
      <PublicLayout>
        <Row
          align="middle"
          justify="center"
          type="flex"
          className="login-form-holder full-page ConsumerLoginPage"
        >
          <Col xs={24} sm={16} md={12} lg={10} xl={7} xxl={7}>
            {showThankYou && (
              <Alert
                closable
                showIcon
                className="text-center text-xl mb-4"
                message="Thank you for verifying your consumer account!"
                type="success"
              />
            )}
            <div className="mt-8">
              <ConsumerLoginForm onSuccess={this.onLoginSuccess} />
            </div>
          </Col>
        </Row>
      </PublicLayout>
    );
  }
}

const ConsumerLoginPageWithStateAndMutation = compose(
  connect(
    state => ({
      isAuthenticated:
        state.auth.isAuthenticated && state.auth.type === 'CONSUMER',
    }),
    dispatch => ({
      onLoginUser(accessToken) {
        dispatch(loginUser(accessToken));
      },
    })
  )
)(ConsumerLoginPage);

export default ConsumerLoginPageWithStateAndMutation;
