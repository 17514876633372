export const BUY_PRICE_RANGE = [
  {
    key: 1,
    value: ['0', '300000'],
    description: 'Less than $300k',
  },
  {
    key: 2,
    value: ['300000', '400000'],
    description: '$300k to $400k',
  },
  {
    key: 3,
    value: ['400000', '500000'],
    description: '$400k to $500k',
  },
  {
    key: 4,
    value: ['500000'],
    description: '$500k or more',
  },
];

export const RENT_PRICE_RANGE = [
  {
    key: 1,
    value: ['0', '1500'],
    description: 'Less than $1,500',
  },
  {
    key: 2,
    value: ['1500', '2250'],
    description: '$1,500 to $2,250',
  },
  {
    key: 3,
    value: ['2250', '3000'],
    description: '$2,250 to $3,000',
  },
  {
    key: 4,
    value: ['3000'],
    description: '$3,000 or more',
  },
];
