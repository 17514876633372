import { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import replace from 'lodash/replace';
import isEmpty from 'lodash.isempty';

if (process.env.REACT_APP_GA) {
  GoogleAnalytics.initialize(process.env.REACT_APP_GA);
}

// const withTracker = (WrappedComponent, options = {}) => {
//     const trackPage = (page) => {
//         GoogleAnalytics.set({
//             page,
//             ...options,
//         });
//         GoogleAnalytics.pageview(page);
//     };

//     return class extends Component {
//         componentDidMount() {
//             console.log(this.props);
//             // const page = this.props.location.pathname;
//             // trackPage(page);
//         }

//         componentWillReceiveProps(nextProps) {
//             console.log(this.props);
//             const currentPage = this.props.location.pathname;
//             const nextPage = nextProps.location.pathname;

//             if (currentPage !== nextPage) {
//                 trackPage(nextPage);
//             }
//         }

//         render() {
//             return <WrappedComponent {...this.props} />;
//         }
//     };
// };

// export default withTracker;
class GoogleAnalyticsTracker extends Component {
  static propTypes = {
    location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    location: {},
    children: {},
  };

  componentDidMount() {
    const { location } = this.props;
    if (!isEmpty(location)) {
      const page = location.pathname;
      this.trackPage(page);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    if (!isEmpty(location)) {
      const currentPage = location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        this.trackPage(nextPage);
      }
    }
  }

  trackPage = page => {
    const pageContent = page.split('/')[1];
    // 'agency', 'agent', 'mortgagebroker',
    if (includes(['property'], pageContent)) {
      const slugUrl = replace(page, `/${pageContent}/`, '');
      const pageContentId = slugUrl.split('-')[slugUrl.split('-').length - 1];
      let customDimension = '';

      switch (pageContent) {
        case 'property':
          customDimension = 'dimension1';
          break;
        // case 'agency':
        //   customDimension = '';
        //   break;
        // case 'agent':
        //   customDimension = '';
        //   break;
        // case 'mortgagebroker':
        //   customDimension = '';
        //   break;
        default:
          break;
      }

      GoogleAnalytics.set({ page });
      GoogleAnalytics.ga('set', customDimension, pageContentId);
      GoogleAnalytics.ga('send', 'pageview', page);
    } else {
      GoogleAnalytics.set({ page });
      GoogleAnalytics.pageview(page);
    }
  };

  render() {
    const { children } = this.props;

    return children;
  }
}

export default GoogleAnalyticsTracker;
