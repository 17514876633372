import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import copy from 'copy-to-clipboard';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Button, Icon } from 'antd';
import gql from 'graphql-tag';

class CopyEventButton extends Component {
  static propTypes = {
    eventTotalShares: PropTypes.object,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
  };

  static defaultProps = {
    eventTotalShares: {},
    property: {},
    saveShareEvent: () => {},
    uuid: '',
  };

  state = { isLoading: false };

  handleCopyEventClick = () => {
    const { uuid, property, saveShareEvent } = this.props;
    const { slugUrl } = property || [];
    this.setState({ isLoading: true });

    if (property) {
      copy(`https://${window.location.hostname}/property/${slugUrl}`);
    } else {
      copy(`https://${window.location.hostname}/event/${uuid}`);
    }

    return saveShareEvent('copy').then(() =>
      this.setState({ isLoading: false })
    );
  };

  render() {
    const { isLoading } = this.state;
    const { eventTotalShares } = this.props;
    const { loading } = eventTotalShares;

    if (loading) {
      return (
        <div className="h-12 px-4 relative inline-block">
          <Icon type="loading" className="center-align" />
        </div>
      );
    }

    // const { event } = eventTotalShares;
    // const { totalShares } = event || [];
    const icon = isLoading ? (
      <Icon type="loading" theme="outlined" />
    ) : (
      <FA icon={faCopy} fixedWidth />
    );

    return (
      <Button
        className="border-none leading-loose h-12 px-4 rounded-none bg-transparent text-gray-800"
        onClick={this.handleCopyEventClick}
      >
        {icon}
        &nbsp;
        {/* {totalShares} */}
      </Button>
    );
  }
}

const EVENT_TOTAL_SHARES = gql`
  query eventTotalShares($id: ID!) {
    event(id: $id) {
      id
      totalShares(platform: "copy")
    }
  }
`;

const CopyEventButtonWithMutation = compose(
  graphql(EVENT_TOTAL_SHARES, {
    name: 'eventTotalShares',
    options: ({ id }) => ({ variables: { id } }),
  })
)(CopyEventButton);

export default CopyEventButtonWithMutation;
