import { Component } from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

class SearchPageQueryParameterParser extends Component {
  static propTypes = {
    urlPath: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.any,
  };

  static defaultProps = {
    urlPath: '/search',
    history: {},
    location: {},
    children: {},
  };

  parseSearchOptionsFromUrl = () => {
    const { location } = this.props;

    let searchOptions = qs.parse(location.search);

    const type = {};
    const price = {};
    const bed = {};
    const bathroom = {};
    const floorArea = {};
    const landArea = {};
    let segment;

    if (searchOptions.propertyGroup) {
      Object.assign(type, {
        group: Array.isArray(searchOptions.propertyGroup)
          ? searchOptions.propertyGroup
          : [searchOptions.propertyGroup],
      });
    }

    if (searchOptions.propertyCategory) {
      Object.assign(type, {
        category: Array.isArray(searchOptions.propertyCategory)
          ? searchOptions.propertyCategory
          : [searchOptions.propertyCategory],
      });
    }

    if (searchOptions.priceMin) {
      Object.assign(price, { min: Number.parseFloat(searchOptions.priceMin) });
    }

    if (searchOptions.priceMax) {
      Object.assign(price, { max: Number.parseFloat(searchOptions.priceMax) });
    }

    if (searchOptions.bedMin) {
      Object.assign(bed, { min: Number.parseFloat(searchOptions.bedMin) });
    }

    if (searchOptions.bedMax) {
      Object.assign(bed, { max: Number.parseFloat(searchOptions.bedMax) });
    }

    if (searchOptions.floorAreaMin) {
      Object.assign(floorArea, {
        min: Number.parseFloat(searchOptions.floorAreaMin),
      });
    }

    if (searchOptions.floorAreaMax) {
      Object.assign(floorArea, {
        max: Number.parseFloat(searchOptions.floorAreaMax),
      });
    }

    if (searchOptions.landAreaMin) {
      Object.assign(landArea, {
        min: Number.parseFloat(searchOptions.landAreaMin),
      });
    }

    if (searchOptions.landAreaMax) {
      Object.assign(landArea, {
        max: Number.parseFloat(searchOptions.landAreaMax),
      });
    }

    if (searchOptions.bathroomMin) {
      Object.assign(bathroom, {
        min: Number.parseFloat(searchOptions.bathroomMin),
      });
    }

    if (searchOptions.bathroomMax) {
      Object.assign(bathroom, {
        max: Number.parseFloat(searchOptions.bathroomMax),
      });
    }

    const selectedKey = [
      {
        path: '/buy',
        group: 'BUY',
      },
      {
        path: '/rent',
        group: 'RENT',
      },
      {
        path: '/sold',
        group: 'SOLD',
      },
    ].find(({ path }) => location.pathname.includes(path));

    const group = selectedKey ? selectedKey.group : 'BUY';

    if (searchOptions.segment) {
      segment = searchOptions.segment;
    } else {
      segment = location.pathname.startsWith('/commercial')
        ? 'COMMERCIAL'
        : 'RESIDENTIAL';
    }

    if (searchOptions.commercialType) {
      Object.assign(searchOptions, {
        commercialType: searchOptions.commercialType,
      });
    }

    if (searchOptions.location && searchOptions.locationType) {
      Object.assign(searchOptions, {
        location: {
          name: searchOptions.location,
          type: searchOptions.locationType,
        },
      });
    }

    if (searchOptions.keyword) {
      Object.assign(searchOptions, { keyword: searchOptions.keyword });
    }

    if (searchOptions.showOfiTime === 'false') {
      searchOptions = omit(searchOptions, 'ofiTime');
    }

    if (searchOptions.furnishType) {
      let data = searchOptions.furnishType;

      if (!Array.isArray(data)) {
        data = [data];
      }

      Object.assign(searchOptions, { furnishType: data });
    }

    searchOptions = omit(searchOptions, [
      'locationType',
      'propertyGroup',
      'propertyCategory',
    ]);

    Object.assign(searchOptions, {
      showMap: searchOptions.showMap !== 'false',
      isIncludeSurrounding: searchOptions.isIncludeSurrounding !== 'false',
      sorting: searchOptions.sorting,
      price,
      bathroom,
      bed,
      type,
      floorArea,
      landArea,
      group,
      segment,
    });

    return searchOptions;
  };

  buildQueryString = searchOptions => {
    let params;

    switch (searchOptions.segment) {
      case 'RESIDENTIAL':
        params = qs.stringify(
          {
            location: searchOptions.location.name,
            locationType: searchOptions.location.type,
            keyword: searchOptions.keyword,
            propertyGroup: searchOptions.type.group,
            propertyCategory: searchOptions.type.category,
            furnishType: searchOptions.furnishType,
            priceMin: searchOptions.price.min,
            priceMax: searchOptions.price.max,
            bedMin: searchOptions.bed.min,
            bedMax: searchOptions.bed.max,
            bathroomMin: searchOptions.bathroom.min,
            bathroomMax: searchOptions.bathroom.max,
            floorAreaMin: searchOptions.floorArea.min,
            floorAreaMax: searchOptions.floorArea.max,
            landAreaMin: searchOptions.landArea.min,
            landAreaMax: searchOptions.landArea.max,
            mapLocation: searchOptions.mapLocation,
            showMap: searchOptions.showMap === true ? undefined : false,
            ofiTime: searchOptions.ofiTime,
            isIncludeSurrounding:
              searchOptions.isIncludeSurrounding === true ? undefined : false,
            sorting: searchOptions.sorting,
            segment: searchOptions.segment,
          },
          [{ arrayFormat: 'repeat' }]
        );
        break;
      case 'COMMERCIAL':
        params = qs.stringify(
          {
            location: searchOptions.location.name,
            locationType: searchOptions.location.type,
            keyword: searchOptions.keyword,
            commercialType: searchOptions.commercialType,
            furnishType: searchOptions.furnishType,
            priceMin: searchOptions.price.min,
            priceMax: searchOptions.price.max,
            bedMin: searchOptions.bed.min,
            bedMax: searchOptions.bed.max,
            bathroomMin: searchOptions.bathroom.min,
            bathroomMax: searchOptions.bathroom.max,
            floorAreaMin: searchOptions.floorArea.min,
            floorAreaMax: searchOptions.floorArea.max,
            landAreaMin: searchOptions.landArea.min,
            landAreaMax: searchOptions.landArea.max,
            mapLocation: searchOptions.mapLocation,
            showMap: searchOptions.showMap === true ? undefined : false,
            ofiTime: searchOptions.ofiTime,
            isIncludeSurrounding:
              searchOptions.isIncludeSurrounding === true ? undefined : false,
            sorting: searchOptions.sorting,
            segment: searchOptions.segment,
          },
          [{ arrayFormat: 'repeat' }]
        );
        break;
    }

    return params;
  };

  changeUrl = searchOptions => {
    const { history } = this.props;
    let pathname;

    switch (searchOptions.segment) {
      case 'RESIDENTIAL':
        pathname = `${searchOptions.group.toLowerCase()}/search`;
        break;
      case 'COMMERCIAL':
        pathname = `commercial/${searchOptions.group.toLowerCase()}/search`;
        break;
    }

    history.push(`/${pathname}?${this.buildQueryString(searchOptions)}`);
  };

  render() {
    const searchOptions = this.parseSearchOptionsFromUrl();

    return this.props.children({
      searchOptions,
      changeUrl: this.changeUrl,
    });
  }
}

export default withRouter(SearchPageQueryParameterParser);
