import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import toLower from 'lodash/toLower';

const TagSelectOption = ({ value, children, checked, onChange }) => (
  <Button
    type={checked ? 'primary' : undefined}
    className="rounded mr-2 mb-2"
    size="large"
    checked={checked}
    key={value}
    onClick={() => onChange(value)}
  >
    <span className="px-1">{children}</span>
  </Button>
);

TagSelectOption.isTagSelectOption = true;

export default class TagSelect extends PureComponent {
  static TagSelectOption = TagSelectOption;

  static propTypes = {
    value: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: [],
    onChange: () => {},
  };

  onSelectAll = () => {
    const { onChange, value } = this.props;
    const checkedAll = this.getAllOptions().length === value.length;
    let selected = [];
    if (!checkedAll) {
      selected = this.getAllOptions();
    }
    onChange(selected);
  };

  getAllOptions() {
    let { children } = this.props;
    children = React.Children.toArray(children);
    const selected = children
      .filter(child => this.isTagSelectOption(child))
      .map(child => child.props.value);
    return selected || [];
  }

  isTagSelectOption = node =>
    node &&
    node.type &&
    (node.type.isTagSelectOption ||
      node.type.displayName === 'TagSelectOption');

  handleChange = option => {
    const { value, onChange } = this.props;
    const selected = [...value];
    const index = selected.indexOf(option);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(option);
    }
    onChange(selected);
  };

  render() {
    const { className, children, value } = this.props;
    const checkedAll = this.getAllOptions().length === value.length;
    return (
      <div className={className}>
        <TagSelectOption
          checked={checkedAll}
          key="tag-select-__all__"
          onChange={this.onSelectAll}
        >
          All
        </TagSelectOption>
        {React.Children.map(children, child => {
          if (this.isTagSelectOption(child)) {
            return React.cloneElement(child, {
              key: `tag-select-${toLower(child.props.value)}`,
              value: child.props.value,
              checked: value.indexOf(child.props.value) > -1,
              onChange: this.handleChange,
            });
          }
          return child;
        })}
      </div>
    );
  }
}
