import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Col, Row } from 'antd';
import FollowAgent from '../FollowAgent';
import AgentContactNumber from '../AgentContactNumber';
import { winWidth } from '../../utils/windowDimensions';

import './AgentCardList.scss';

class AgentCardList extends Component {
  static propTypes = {
    agent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = { agent: {} };

  constructor(props) {
    super(props);

    this.slider = React.createRef();
  }

  render() {
    const {
      agent: {
        id,
        profilePictureUrl,
        lastName,
        slugUrl: agentSlugUrl,
        firstName,
        propertiesConnection,
        isFollowedByConsumer,
        agencyName,
        bannerColor,
      },
      agent,
    } = this.props;
    const propertyListings = propertiesConnection.totalCount;

    return (
      <main className="agent-card-list">
        <div className="bg-white rounded-lg overflow-hidden flex-col w-full lg:flex md:hidden flex">
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="p-3 h-32"
            style={{
              background:
                bannerColor && bannerColor !== '#fff' ? bannerColor : '#3D4852',
            }}
          >
            <Col className="mr-2 relative">
              <Link to={`/agent/${agentSlugUrl}`}>
                <Avatar
                  alt={`${agencyName}`}
                  size={winWidth > 768 ? 175 : 150}
                  icon="user"
                  src={profilePictureUrl}
                  className="shadow border-4 border-white bg-gray-300 -mb-16"
                />
              </Link>
            </Col>
          </Row>
          <div className={`text-center lg:mt-20 mt-20 flex-grow flex flex-col`}>
            <Link to={`/agent/${agentSlugUrl}`} className="block">
              <h3 className="text-gray-800 font-semibold text-xl">
                {firstName} {lastName}
              </h3>
            </Link>
            <h4 className="text-gray-700 font-normal">{agencyName}</h4>{' '}
            <AgentContactNumber
              agentId={agent.id}
              contactNo={agent.mobileNumber}
              propertyId="0"
              className="font-normal text-gray-700 text-lg poppins"
            />
          </div>
          <Row type="flex" justify="center" align="middle" className="py-4">
            <Col xs={24} sm={8}>
              <p className="text-gray-800 font-normal text-2xl text-center">
                {propertyListings}
              </p>
              <p className="text-center text-gray-700 font-normal break-all uppercase text-xs">
                Listings
              </p>
            </Col>
          </Row>
          <div className="py-6 w-full text-center">
            <FollowAgent agentId={id} isFollowed={isFollowedByConsumer} />
          </div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden  flex-col w-full lg:hidden md:flex hidden">
          <Row
            type="flex"
            align="middle"
            style={{
              background:
                bannerColor && bannerColor !== '#fff' ? bannerColor : '#3D4852',
            }}
          >
            <Col className="flex flex-row items-center z-20 relative">
              <div className="mx-4 mt-4 agent-follow -mb-24">
                <Link to={`/agent/${agentSlugUrl}`}>
                  <Avatar
                    alt={`${agencyName}`}
                    size={125}
                    icon="user"
                    src={profilePictureUrl}
                    className="shadow border-4 border-white bg-gray-300"
                  />
                </Link>

                <div className="py-2 w-full text-center">
                  <FollowAgent agentId={id} isFollowed={isFollowedByConsumer} />
                </div>
              </div>
              <div className="text-left flex flex-col">
                <Link to={`/agent/${agentSlugUrl}`} className="block">
                  <h3 className="text-white font-semibold text-lg">
                    {firstName} {lastName}
                  </h3>
                </Link>
                <h4 className="text-gray-200 font-normal text-sm">
                  {agencyName}
                </h4>{' '}
                <AgentContactNumber
                  agentId={agent.id}
                  contactNo={agent.mobileNumber}
                  propertyId="0"
                  className="font-normal text-gray-200 text-sm poppins"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="flex-grow bg-white flex flex-row md:h-24">
              <div className="mx-2 agent-follow invisible">
                <Link to={`/agent/${agentSlugUrl}`}>
                  <Avatar
                    alt={`${agencyName}`}
                    size={125}
                    icon="user"
                    src={profilePictureUrl}
                    style={{ height: 50 }}
                    className="shadow border-4 border-white bg-gray-300"
                  />
                </Link>
              </div>
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="w-full"
              >
                <Col xs={24} sm={8}>
                  <p className="text-gray-800 font-normal text-2xl text-center">
                    {propertyListings}
                  </p>
                  <p className="text-center text-gray-700 font-normal break-all uppercase text-xs">
                    Listings
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </main>
    );
  }
}

export default AgentCardList;
