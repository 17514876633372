import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { AgentSearch } from '../AgentSearchResults/Operations.graphql';
import { Col, Progress, Row } from 'antd';
import './FindAgentWizard.scss';
import PropertyListingStep from './PropertyListingStep';
import PropertyTypeStep from './PropertyTypeStep';
import BudgetRangeStep from './BudgetRangeStep';
import AgentQualitiesStep from './AgentQualitiesStep';
import LoadingScreen from './LoadingScreen';
import WizardCompleted from './WizardCompleted';

class FindAgentWizard extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    onSubmit: () => {},
  };

  state = {
    progress: 20.5,
    step: 0,
    searchParams: {},
    isFetching: false,
    agents: [],
  };

  onChangeProgress = () =>
    this.setState(prevState => ({
      step: prevState.step + 1,
      progress: prevState.progress + 20.5,
    }));

  renderWizardStep = () => {
    const {
      step,
      searchParams: { listingType },
      agents,
    } = this.state;

    switch (step) {
      case 0:
        return (
          <PropertyListingStep
            onChange={e => {
              this.setState(
                prevState => ({
                  searchParams: {
                    ...prevState.searchParams,
                    ...{ listingType: e.target.value },
                  },
                }),
                this.onChangeProgress()
              );
            }}
          />
        );
      case 1:
        return (
          <PropertyTypeStep
            onChange={e => {
              this.setState(
                prevState => ({
                  searchParams: {
                    ...prevState.searchParams,
                    ...{ typeGroup: e.target.value },
                  },
                }),
                this.onChangeProgress()
              );
            }}
          />
        );
      case 2:
        return (
          <BudgetRangeStep
            listingType={listingType}
            onChange={e => {
              this.setState(
                prevState => ({
                  searchParams: {
                    ...prevState.searchParams,
                    ...{ budgetRange: e.target.value },
                  },
                }),
                this.onChangeProgress()
              );
            }}
          />
        );
      case 3:
        return (
          <AgentQualitiesStep
            onChange={async () => {
              await this.handleSearchAgents();
              this.onChangeProgress();
            }}
          />
        );
      case 4:
        return (
          <WizardCompleted
            onSubmit={() => {
              const { onSubmit } = this.props;
              const { searchParams } = this.state;

              onSubmit(searchParams);
            }}
            agents={agents}
          />
        );
    }
  };

  handleSearchAgents = async () => {
    const { client } = this.props;

    this.setState({ isFetching: true });

    try {
      const {
        data: { agentSearch },
      } = await client.query({
        query: AgentSearch,
        variables: {
          filter: {
            first: 3,
            ...this.state.searchParams,
          },
        },
        fetchPolicy: 'network-only',
      });

      this.setState({ agents: agentSearch });
    } catch (err) {
      throw err;
    } finally {
      this.setState({ isFetching: false });
    }
  };

  handleSkipWizard = async e => {
    e.preventDefault();
    await this.handleSearchAgents();
    this.setState({ step: 4 });
  };

  render() {
    const { progress, isFetching, step } = this.state;

    if (isFetching) {
      return <LoadingScreen />;
    }

    return (
      <div className="FindAgentWizard">
        <div>{this.renderWizardStep()}</div>
        {step !== 4 && (
          <div className="text-center mt-10">
            <a
              href=""
              className="underline hover:underline text-white hover:text-white"
              onClick={this.handleSkipWizard}
            >
              I don't know yet
            </a>
          </div>
        )}
        {step !== 4 && (
          <Row type="flex" justify="center">
            <Col xs={20} md={12} xl={6}>
              <Progress
                strokeColor="#4ac7f4"
                percent={progress}
                showInfo={false}
                className="mt-10"
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withApollo(FindAgentWizard);
