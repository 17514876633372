import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import GoogleAnalytics from 'react-ga';
import debounce from 'lodash.debounce';
import './PropertyCard.scss';
import PropertyCarousel from '../PropertyCarousel';
import PropertyCardAgentPopover from '../PropertyCardAgentPopover';

class PropertyCard extends React.Component {
  static propTypes = {
    property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onCardMouseEnter: PropTypes.func,
    onCardMouseLeave: PropTypes.func,
    ofiTime: PropTypes.bool,
    searchParam: PropTypes.string,
  };

  static defaultProps = {
    property: {},
    ofiTime: false,
    onCardMouseEnter: () => {},
    onCardMouseLeave: () => {},
    searchParam: '',
  };

  constructor(props) {
    super(props);

    this.state = { isPropertyInView: false };

    this.slider = React.createRef();

    this.getCardPosition = debounce(this.getCardPosition, 50);
  }

  componentDidMount() {
    const { hasScrolled } = this.props;

    if (!hasScrolled) {
      this.getCardPosition();
    }

    window.addEventListener('scroll', this.getCardPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getCardPosition);
  }

  getCardPosition = () => {
    const {
      property: { shortId, agent },
      offsetHeaderHeight,
    } = this.props;
    const winHeight = window.innerHeight;
    let agentId = 0;

    if (agentId) {
      agentId = agent.id;
    }

    if (!isEmpty(this.card) && agentId) {
      const cardBounds = this.card.getBoundingClientRect();

      if (
        cardBounds.top >= offsetHeaderHeight &&
        cardBounds.bottom < winHeight
      ) {
        GoogleAnalytics.ga(
          'send',
          'event',
          'Property View',
          'Search Result Appearance',
          { dimension1: shortId, dimension7: agentId }
        );

        this.setState({ isPropertyInView: true });
      } else {
        this.setState({ isPropertyInView: false });
      }
    }
  };

  render() {
    const {
      property,
      onCardMouseEnter,
      onCardMouseLeave,
      ofiTime,
      searchParam,
    } = this.props;

    const {
      id,
      address,
      slugUrl,
      features,
      area,
      number,
      district,
      agent,
      price,
      title,
      rentPriceMonthly,
      listingType,
      videoLink,
      objects,
      ofiTimes,
      isCollage,
      typeGroup,
      landArea,
      floorArea,
      psf,
      segment,
      commercialType,
    } = property || {};

    const { isPropertyInView } = this.state;

    const streetAddress = property.streetName;
    const {
      firstName,
      lastName,
      profilePictureUrl,
      agencyMiniLogoUrl,
      bannerColor,
    } = agent || {};

    const cardBorderStyle = {};
    if (bannerColor) {
      cardBorderStyle.borderColor = bannerColor;
    }

    return (
      <>
        <div
          className="w-full"
          ref={ref => {
            this.card = ref;
          }}
        >
          <Row
            className="PropertyCard w-full relative overflow-hidden"
            key={id}
            type="flex"
            onMouseEnter={() => onCardMouseEnter(id)}
            onMouseLeave={() => onCardMouseLeave(id)}
          >
            <Col
              xs={24}
              className="text-center relative bg-white rounded overflow-hidden"
            >
              <Row type="flex" className="h-full flex-col pb-1">
                <Col
                  xs={24}
                  className="border-b-4 border-gray-800"
                  style={cardBorderStyle}
                >
                  <div className="w-full relative photos">
                    <PropertyCarousel
                      property={property}
                      ofiTime={ofiTime}
                      showViewVideosModal={this.showViewVideosModal}
                      height="100%"
                      showOfiModal={this.showOfiModal}
                      isFromSearch={!isEmpty(searchParam)}
                      isPropertyInView={isPropertyInView}
                    />
                  </div>
                </Col>
                <Col xs={24} className="pl-2 pt-1 relative self-center">
                  <Row type="flex" justify="space-between">
                    <Col className="card-details flex flex-col text-left">
                      <div className="card-title">
                        <h3 className="font-semibold text-gray-900 text-lg text-overflow ">
                          {title}
                        </h3>
                      </div>
                      <div className="card-price text-base font-medium">
                        <span className=" font-semibold text-xs font-normal text-gray-900 mr-1">
                          S$
                        </span>
                        <span className="text-lg font-semibold text-gray-900">
                          {listingType === 'RENT' ? (
                            <span>
                              <FormattedNumber value={rentPriceMonthly} /> /mo
                            </span>
                          ) : (
                            <FormattedNumber value={price} />
                          )}
                        </span>

                        <span className="font-normal text-xs capitalize">
                          {segment && (
                            <>
                              <span className="mx-1">&bull;</span>
                              {segment.toLowerCase()}
                            </>
                          )}
                          {segment === 'RESIDENTIAL'
                            ? typeGroup && (
                                <>
                                  <span className="mx-1">&bull;</span>
                                  {typeGroup.toLowerCase()}
                                </>
                              )
                            : commercialType && (
                                <>
                                  <span className="mx-1">&bull;</span>
                                  {commercialType.toLowerCase()}
                                </>
                              )}
                        </span>
                      </div>

                      <div className="card-address">
                        <h3 className="font-normal text-sm text-overflow ">
                          <Link
                            to={{
                              pathname: `/property/${slugUrl}`,
                              state: { fromSearch: !isEmpty(searchParam) },
                            }}
                            className="text-gray-900"
                          >
                            <span>
                              {number} {streetAddress}
                            </span>

                            {area && (
                              <span>
                                {(number || streetAddress) && (
                                  <span className="mx-1">&bull;</span>
                                )}
                                {area}
                              </span>
                            )}
                            {district && (
                              <span>
                                {area && <span className="mx-1">&bull;</span>}D
                                {district}
                              </span>
                            )}

                            {/* <PropertyAddress property={property} newLine icon/> */}
                          </Link>
                        </h3>
                      </div>

                      <div className="card-areas text-gray-800">
                        {typeGroup === 'LANDED' && landArea && landArea.area ? (
                          <span>
                            {landArea.area} {landArea.unit}
                          </span>
                        ) : null}

                        {typeGroup !== 'LANDED' &&
                        floorArea &&
                        floorArea.area ? (
                          <span>
                            {floorArea.area} {floorArea.unit}
                          </span>
                        ) : null}

                        {psf ? (
                          <>
                            {(typeGroup === 'LANDED' &&
                              landArea &&
                              landArea.area) ||
                              (typeGroup !== 'LANDED' &&
                                floorArea &&
                                floorArea.area && (
                                  <span className="mx-1">&bull;</span>
                                ))}
                            <span className="">
                              <span className="card-price text-xs font-normal">
                                S$&nbsp;
                              </span>
                              <FormattedNumber value={psf} /> psf
                            </span>
                          </>
                        ) : null}
                      </div>
                    </Col>
                    {agent && (
                      <Col className="agent-info w-24">
                        <PropertyCardAgentPopover
                          agent={agent}
                          propertyId={property.id}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PropertyCard;
