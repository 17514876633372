import React, { Fragment } from 'react';
import { Avatar, Col, Icon, List, Row, Dropdown, Button, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import './PublicFooter.scss';

const quickLinks = [
  {
    text: 'Home',
    link: '/',
  },
  {
    text: 'Agent Login',
    link: '/agent-login',
  },
];

const siteLinks = [
  {
    text: 'Australia',
    url: '//realty.com.au',
  },
  {
    text: 'Philippines',
    url: '//realestate.ph',
  },
];

const menu = (
  <Menu>
    {siteLinks.map(({ text, url }) => (
      <Menu.Item key={url}>
        <Link to={url} target="_blank" alt={text}>
          {text}
        </Link>
      </Menu.Item>
    ))}
  </Menu>
);

const PublicFooter = props => {
  return (
    <div className="footer">
      <Row
        key="address"
        type="flex"
        className="text-gray-500 py-8 bg-gray-900 public-footer relative md:justify-center justify-start z-10 relative"
      >
        <Col
          xs={24}
          sm={12}
          md={9}
          lg={6}
          xl={6}
          className="sm:p-0 p-4 relative z-10"
        >
          <h1 className="text-gray-200 mb-2 md:text-left text-center md:text-3xl text-xl md:font-thin font-light">
            About Us
          </h1>
          <h2 className="text-gray-300 md:text-2xl text-lg md:text-left text-center">
            Inspirations for your Home
          </h2>
          <p className="text-gray-300 md:text-justify text-center">
            We are the social media network for your home.
          </p>
          <div className="social-links py-3 md:text-left text-center">
            <a
              href="https://www.facebook.com/Realestatesg-625509744571478/?ref=br_rs"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            />
          </div>
          <Row
            type="flex"
            align="middle"
            className="md:justify-start justify-center"
          >
            <Col className="flex bg-black rounded py-1 px-2 items-center mt-2 mr-2">
              <FA icon={faApple} size="2x" className="text-gray-200" />
              <div className="px-2">
                <h6 className="capitalize text-gray-200">Coming soon to</h6>
                <h4 className="text-gray-200">App Store</h4>
              </div>
            </Col>
            <Col className="flex bg-black rounded p-1 items-center mt-2">
              <FA icon={faGooglePlay} size="lg" className="text-gray-200" />
              <div className="px-2">
                <h6 className="capitalize text-gray-200">Coming soon to</h6>
                <h4 className="text-gray-200">Google Play</h4>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={9}
          lg={6}
          xl={4}
          className="px-4 relative z-10"
        >
          <h1 className="text-gray-200 mb-2 md:text-3xl text-xl md:font-thin font-light">
            Contact Us
          </h1>
          <List itemLayout="horizontal">
            <List.Item className="footer-list">
              <List.Item.Meta
                avatar={
                  <Avatar
                    icon="environment-o"
                    className="text-gray-800 bg-white avatar"
                  />
                }
                description={
                  <Link
                    className="text-gray-100"
                    to="//goo.gl/maps/kKhtBeu3FMR2"
                    target="blank"
                  >
                    The Element Building
                    <br />
                    Level 4
                    <br />
                    200 Central Coast Highway
                    <br />
                    Erina NSW 2250
                  </Link>
                }
              />
            </List.Item>
            <List.Item className="footer-list">
              <List.Item.Meta
                avatar={
                  <Avatar
                    icon="phone"
                    className="text-gray-800 bg-white avatar"
                  />
                }
                description={
                  <span className="text-gray-100">
                    (AU)
                    <a href="tel:1800001212" className="text-gray-100">
                      1800 001 212
                    </a>
                  </span>
                }
              />
            </List.Item>
            <List.Item className="footer-list">
              <List.Item.Meta
                avatar={
                  <Avatar
                    icon="mail"
                    className="text-gray-800 bg-white avatar"
                  />
                }
                description={
                  <a
                    href="mailto:support@realty.com.au"
                    className="text-gray-100"
                  >
                    support@realty.com.au
                  </a>
                }
              />
            </List.Item>
          </List>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={9}
          lg={6}
          xl={4}
          className="px-4 relative z-10"
        >
          <h1 className="text-gray-200 mb-2 md:text-3xl text-xl md:font-thin font-light">
            Quick Links
          </h1>
          <List itemLayout="horizontal">
            {quickLinks.map(({ text, link }) => (
              <List.Item className="quick-links p-1" key={text}>
                <List.Item.Meta
                  description={
                    <Fragment>
                      <Icon
                        type="caret-right"
                        className="text-gray-100 text-sm"
                      />{' '}
                      <Link className="text-gray-100" to={link}>
                        {text}
                      </Link>
                    </Fragment>
                  }
                />
              </List.Item>
            ))}
          </List>
        </Col>
      </Row>
      <Row type="flex" justify="center" className="py-4 bg-gray-900">
        <Col className="text-gray-200">
          International Sites:{' '}
          <a
            href="http://philippines.realty.com.au"
            className="text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Philippines
          </a>
        </Col>
      </Row>
      <Row
        type="flex"
        key="company"
        justify="center"
        className="bg-gray-1000 w-full py-2"
      >
        <Col
          xs={24}
          sm={12}
          md={9}
          lg={6}
          xl={6}
          className="sm:text-right text-center text-gray-100 py-1"
        >
          © {new Date().getFullYear()} Singapore.realty.com.au | All rights
          reserved.
        </Col>

        <Col
          xs={24}
          sm={12}
          md={9}
          lg={6}
          xl={3}
          className="xs:text-right text-center"
        >
          <Dropdown className="text-white" overlay={menu}>
            <Button className="bg-transparent">
              Partners <Icon type="down" />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
};
export default PublicFooter;
