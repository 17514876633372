import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PropertyPrice from '../PropertyPrice';

export default class PropertyPriceParse extends PureComponent {
  static propTypes = {
    property: PropTypes.object,
    rentBondClass: PropTypes.string,
  };

  static defaultProps = {
    property: {},
    rentBondClass: 'text-xs text-gray-700',
  };

  getPrice = ({
    address,
    sellingPrice,
    price,
    isShowSoldPrice,
    priceView,
    rentPriceMonthly,
    rentBond,
    listingType,
    status,
    soldPrice,
    soldDate,
  }) => {
    const newStatus = status || 'CURRENT';
    const { rentBondClass } = this.props;

    if (newStatus.toUpperCase() === 'CURRENT') {
      if (listingType === 'RENT') {
        const monthly = rentPriceMonthly && (
          <Fragment>
            <PropertyPrice
              showDecimal={false}
              className="font-bold"
              price={rentPriceMonthly}
            />{' '}
            /month
          </Fragment>
        );
        const bond = rentBond && (
          <Fragment>
            {' '}
            <span className={rentBondClass}>| Rent Bond: ${rentBond}</span>
          </Fragment>
        );
        return (
          <Fragment>
            {monthly} {bond}
          </Fragment>
        );
      }
      return sellingPrice ? (
        <Fragment>
          <PropertyPrice
            showDecimal={false}
            className="font-bold"
            price={sellingPrice}
          />
        </Fragment>
      ) : (
        <Fragment>
          <PropertyPrice
            showDecimal={false}
            className="font-bold"
            price={price}
          />
        </Fragment>
      );
    }
    return isShowSoldPrice ? (
      soldPrice && (
        <Fragment>
          <span className="block">
            Sold at{' '}
            <PropertyPrice
              showDecimal={false}
              className="font-bold"
              price={soldPrice}
            />
          </span>
          <span className="text-sm text-gray-500 block">
            {moment.utc(soldDate).format('MMMM D, YYYY')}
          </span>
        </Fragment>
      )
    ) : (
      <Fragment>
        <span className="block">{priceView}</span>
        <span className="block text-sm text-gray-500">
          {soldDate ? (
            <Fragment>
              Sold on&nbsp;
              {moment.utc(soldDate).format('MMMM D, YYYY')}
            </Fragment>
          ) : (
            'Sold'
          )}
        </span>
      </Fragment>
    );
  };

  render() {
    const { property } = this.props;
    const price = this.getPrice(property);
    return <span className="property-price-parse text-overflow">{price}</span>;
  }
}
