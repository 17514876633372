import React from 'react';
import { Col, Radio, Row } from 'antd';
import PropTypes from 'prop-types';

const { Group: RadioGroup, Button: RadioButton } = Radio;

function AgentQualitiesStep({ onChange }) {
  return (
    <>
      <Row className="flex justify-center">
        <Col>
          <p className="text-2xl md:text-3xl text-white text-center px-4">
            It's most important that my agent...
          </p>
        </Col>
      </Row>
      <Row className="flex justify-center">
        <Col className="mt-8 text-center md:flex">
          <RadioGroup
            className="agent-survey"
            buttonStyle="solid"
            size="large"
            onChange={onChange}
          >
            <RadioButton
              value="a"
              className="agent-survey-option text-center mr-4 p-4"
            >
              Takes care of everything
            </RadioButton>
            <RadioButton
              value="b"
              className="agent-survey-option text-center mr-4 p-4"
            >
              Gets me the best price
            </RadioButton>
            <RadioButton
              value="c"
              className="agent-survey-option mt-2 md:mt-0 text-center p-4"
            >
              Can buy quickly
            </RadioButton>
          </RadioGroup>
        </Col>
      </Row>
    </>
  );
}

AgentQualitiesStep.propTypes = {
  onChange: PropTypes.func,
};

AgentQualitiesStep.defaultProps = {
  onChange: () => {},
};

export default AgentQualitiesStep;
