/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ConsumerAvatarUpload from '../ConsumerAvatarUpload';

class ConsumerAvatarModal extends PureComponent {
  static propTypes = {
    profileImage: PropTypes.string,
    handleAvatarModal: PropTypes.func,
    showAvatarModal: PropTypes.bool,
    contributorUploadImage: PropTypes.func,
    refetch: PropTypes.func,
    consumerPage: PropTypes.object,
  };

  static defaultProps = {
    profileImage: null,
    handleAvatarModal: () => {},
    showAvatarModal: false,
    contributorUploadImage: () => {},
    refetch: () => {},
    consumerPage: {},
  };

  render() {
    const {
      showAvatarModal,
      handleAvatarModal,
      profileImage,
      contributorUploadImage,
      refetch,
      consumerPage,
    } = this.props;

    return (
      <Modal
        wrapClassName="avatar-upload-modal"
        title="Change Profile Picture"
        visible={showAvatarModal}
        onCancel={handleAvatarModal}
        footer={null}
        onOk={handleAvatarModal}
        destroyOnClose
        maskClosable={false}
      >
        <ConsumerAvatarUpload
          profileImage={profileImage}
          contributorUploadImage={contributorUploadImage}
          refetch={refetch}
          consumerPage={consumerPage}
          handleAvatarModal={handleAvatarModal}
        />
      </Modal>
    );
  }
}

export default ConsumerAvatarModal;
