import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Alert, Button, Form, Icon, Input, Row, Col, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import GuestLoginUsingFb from '../GuestLoginUsingFb';
import GuestLoginUsingGoogle from '../GuestLoginUsingGoogle';
import './ConsumerLoginForm.scss';

class ConsumerLoginForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    loginConsumerMutation: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    form: {},
    loginConsumerMutation: () => {},
    onSuccess: () => {},
  };

  state = { isLoading: false };

  getErrorMessage = () => {
    let message = null;
    const { formErrorCode } = this.state;
    console.log('f ' + formErrorCode);
    switch (formErrorCode) {
      case 'CONSUMER_NOT_EXISTS': {
        message = 'It seems that your email is not yet registered ';
        break;
      }
      case 'PASSWORD_MISMATCH': {
        message = 'It seems that your password is incorrect ';
        break;
      }
      case 'CONSUMER_NOT_ACTIVE': {
        message = 'It seems that the account you provided is inactive  ';
        break;
      }
      default: {
        message = 'Unable to login';
        break;
      }
    }

    return message;
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
    } = this.props;

    this.setState({
      formErrorCode: null,
      isLoading: true,
    });

    validateFields(async (err, values) => {
      if (!err) {
        const { email, password } = values;
        const { loginConsumerMutation, onSuccess } = this.props;

        try {
          const {
            data: { createConsumerToken },
          } = await loginConsumerMutation(email.trim(), password);
          onSuccess(createConsumerToken);
        } catch (error) {
          const errors = error.graphQLErrors || [];
          const formErrorCode =
            errors.length > 0 ? errors[0].extensions.code : null;
          this.setState({
            formErrorCode,
            isLoading: false,
          });
        }
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      onSuccess,
    } = this.props;
    const { isLoading, formErrorCode } = this.state;

    return (
      <main className="ConsumerLoginForm sm:pt-12 pt-4 pb-6 sm:px-8 px-4 mt-16 mb-8 lg:w-5/6">
        <section className="consumer-avatar-holder text-center">
          <div className="bg-gray-300 rounded-full inline-block h-20 w-20 consumer-avatar mb-2 mt-6 relative hidden sm:inline-block">
            <FA
              icon={faUserAlt}
              className="sm:text-3xl text-2xl text-gray-800 center-align"
            />
          </div>
          <h1 className="text-center font-medium text-white uppercase font-medium md:text-2xl text-xl">
            Member Area
          </h1>
        </section>
        <h2 className="text-center text-lg mb-2 text-gray-700 font-normal">
          Sign in to your account
        </h2>
        <div className="flex justify-center my-4">
          <GuestLoginUsingFb onSuccess={onSuccess} />
          <GuestLoginUsingGoogle onSuccess={onSuccess} />
        </div>
        <Divider className="py-1 text-center mb-2 font-normal login-divider">
          <span className="text-gray-500">or</span>
        </Divider>
        <Form onSubmit={this.handleSubmit} className="consumer-login-form">
          <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
            Email
          </span>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { transform: value => (value ? value.trim() : value) },
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
                {
                  required: true,
                  message: 'Email is required!',
                },
              ],
            })(
              <Input
                size="large"
                prefix={<Icon type="user" className="text-gray-300" />}
                className="input-field"
              />
            )}
          </Form.Item>
          <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
            Password
          </span>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Password is required!',
                },
              ],
            })(
              <Input.Password
                size="large"
                prefix={<Icon type="lock" className="text-gray-300" />}
                type="password"
                className="input-field"
              />
            )}
          </Form.Item>
          <Form.Item className="mt-10">
            <Button
              className="w-full rounded-full"
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              {isLoading ? 'Logging you in' : 'Login'}
            </Button>

            <div className="h-6 py-2">
              {formErrorCode && (
                <Alert message={this.getErrorMessage()} type="error" showIcon />
              )}
            </div>
          </Form.Item>
        </Form>

        <Row type="flex" justify="space-between" className="mb-8">
          <Col className="text-white">
            Need Account?{' '}
            <Link to="sign-up" className="underline">
              Get Started!
            </Link>
          </Col>
          <Col className="text-gray-300">
            <Link to="/consumer-forgot-password"> Forgot Password?</Link>
          </Col>
        </Row>
      </main>
    );
  }
}

const LOGIN_MUTATION = gql`
  mutation createConsumerToken($email: String!, $password: String!) {
    createConsumerToken(email: $email, password: $password) {
      id
      accessToken
      expiresIn
      refreshToken
      type
      user {
        ... on Consumer {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

const WrappedConsumerLoginForm = Form.create()(ConsumerLoginForm);

const WrappedConsumerLoginFormWithMutation = graphql(LOGIN_MUTATION, {
  props: ({ mutate }) => ({
    loginConsumerMutation: (email, password) =>
      mutate({
        variables: {
          email,
          password,
        },
      }),
  }),
})(WrappedConsumerLoginForm);

export default WrappedConsumerLoginFormWithMutation;
