import React, { Component } from 'react';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
} from 'antd';
import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import GoogleAnalytics from 'react-ga';
import './AgentEnquiryForm.scss';

import { CreateAgentEnquiryMutation } from './Operations.graphql';

class AgentEnquiryForm extends Component {
  static propTypes = {
    agentId: PropTypes.string,
    agentFirstName: PropTypes.string,
    agentLastName: PropTypes.string,
    agencyName: PropTypes.string,
    bannerColor: PropTypes.string,
    createAgentEnquiry: PropTypes.func,
    form: PropTypes.object,
    trackEmailAttempt: PropTypes.func,
  };

  static defaultProps = {
    agentId: null,
    agentFirstName: '',
    agentLastName: '',
    agencyName: '',
    bannerColor: '',
    createAgentEnquiry: () => {},
    form: {},
    trackEmailAttempt: () => {},
  };

  state = {
    chosen: null,
    isLoading: false,
    next: false,
  };

  handleSubmit = e => {
    e.preventDefault();

    GoogleAnalytics.event({
      category: 'Agent Enquiry',
      action: 'Submit Agent Enquiry',
    });

    this.setState({ isLoading: true });

    const {
      form: { validateFields, resetFields },
      createAgentEnquiry,
      agentId,
      agentFirstName,
      agentLastName,
      agencyName,
    } = this.props;

    const { chosen } = this.state;

    validateFields((err, values) => {
      if (!err) {
        const transformedValues = Object.assign(
          {},
          values,
          { agentId },
          { enquiries: chosen }
        );

        const firstName = transformedValues.name.split(' ');
        const lastName = firstName.splice(-1, 1);

        const params = {
          firstName: firstName.join(' '),
          lastName: lastName.join(' '),
          email: transformedValues.email,
          message: transformedValues.message,
          mobileNumber: transformedValues.mobileNumber,
          agencyName,
          agentFirstName,
          agentLastName,
          agentId,
        };

        createAgentEnquiry(params)
          .then(() => {
            Modal.success({
              title: 'Enquiry Successfully Sent',
              onOk: () =>
                this.setState({ chosen: null }, () => {
                  resetFields();
                }),
            });
          })
          .catch(() => {
            message.error('Encountered an Error');
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  onChange = ({ target: { value } }) => {
    this.setState({ chosen: value });
  };

  back = () => {
    this.setState({ chosen: null, next: false });
  };

  handleNext = () => {
    this.setState({ next: true });
    this.props.trackEmailAttempt();
  };

  render() {
    const { isLoading, chosen, next } = this.state;
    const {
      form: { getFieldDecorator, getFieldValue },
      bannerColor,
    } = this.props;

    const isNotWhiteColor = bannerColor && bannerColor !== '#fff';
    const buttonStyle = {
      background: (isNotWhiteColor && bannerColor) || '#4ac7f4',
      color: '#fff',
    };

    return (
      <Form onSubmit={this.handleSubmit} className="AgentEnquiryForm">
        <Row type="flex" justify="center" align="middle" className="w-full">
          <Col xs={22} sm={22} md={22} lg={18} xl={14} xxl={14}>
            {!next && (
              <>
                <Form.Item className="w-full">
                  {getFieldDecorator('enquiries', {
                    initialValue: getFieldValue('enquiries')
                      ? this.onChange
                      : null,
                    rules: [{ required: true }],
                  })(
                    <Radio.Group onChange={this.onChange} className="w-full">
                      <Radio.Button
                        value="Selling a property"
                        className="w-full mb-2 choices"
                      >
                        <span className="sm:text-lg text-sm">
                          Selling a property
                        </span>
                      </Radio.Button>
                      <br />
                      <Radio.Button
                        value="Property management"
                        className="w-full mb-2 choices"
                      >
                        <span className="sm:text-lg text-sm">
                          Property management
                        </span>
                      </Radio.Button>
                      <br />
                      <Radio.Button
                        value="Information about a property"
                        className="w-full mb-2 choices"
                      >
                        <span className="sm:text-lg text-sm">
                          Information about a property
                        </span>
                      </Radio.Button>
                      <br />
                      <Radio.Button
                        value="General enquiry"
                        className="w-full mb-2 choices"
                      >
                        <span className="sm:text-lg text-sm">
                          General enquiry
                        </span>
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    size="large"
                    htmlType="submit"
                    className="w-full poppins text-overflow uppercase text-sm"
                    disabled={!getFieldValue('enquiries')}
                    style={{ ...buttonStyle }}
                    onClick={this.handleNext}
                  >
                    Next
                  </Button>
                </Form.Item>
              </>
            )}
            {next && (
              <>
                <h2 className="font-semibold text-gray-800 mb-4 bg-white py-1 px-4 rounded-full border border-gray-300 text-base">
                  {chosen}
                </h2>
                <span className="text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
                  Name
                </span>
                <Form.Item className="w-full">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Your name is required',
                      },
                    ],
                  })(
                    <Input
                      className="w-full form-input"
                      size="large"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                    />
                  )}
                </Form.Item>
                <span className="text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
                  Email
                </span>
                <Form.Item className="w-full">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: 'Your email is required',
                      },
                    ],
                  })(
                    <Input
                      className="w-full form-input"
                      size="large"
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                    />
                  )}
                </Form.Item>
                <span className="text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
                  Phone
                </span>
                <Form.Item className="w-full">
                  {getFieldDecorator('mobileNumber', {})(
                    <Input
                      className="w-full form-input"
                      size="large"
                      prefix={
                        <Icon
                          type="phone"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                    />
                  )}
                </Form.Item>
                <span className="text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
                  Message
                </span>
                <Form.Item className="w-full">
                  {getFieldDecorator('message', {
                    rules: [
                      {
                        required: true,
                        message: 'Your message is required',
                      },
                    ],
                  })(<Input.TextArea rows={4} className="form-input" />)}
                </Form.Item>
                <div className="flex flex-row flex-no-wrap">
                  <Button
                    size="large"
                    className="mb-4 flex-1 mr-1 uppercase text-sm"
                    onClick={() => this.back()}
                    style={{ ...buttonStyle }}
                  >
                    <Icon type="left" className="text-xs font-bold" />
                    &nbsp;Back
                  </Button>
                  <Button
                    size="large"
                    htmlType="submit"
                    className="poppins text-overflow w-3/4 uppercase text-sm"
                    loading={isLoading}
                    style={{ ...buttonStyle }}
                  >
                    Send Enquiry
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

const AgentEnquiryFormWithData = compose(
  graphql(CreateAgentEnquiryMutation, {
    props: ({ mutate }) => ({
      createAgentEnquiry: input => mutate({ variables: { input } }),
    }),
  })
)(AgentEnquiryForm);

export default Form.create()(AgentEnquiryFormWithData);
