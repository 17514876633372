import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

export default class ConsumerPostMeta extends PureComponent {
  static propTypes = {
    url: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    host: PropTypes.string,
    editable: PropTypes.bool,
    onRemoveMeta: PropTypes.func,
  };

  static defaultProps = {
    url: null,
    image: null,
    title: null,
    description: null,
    host: null,
    editable: false,
    onRemoveMeta: () => {},
  };

  removeMeta = () => {
    const { onRemoveMeta, editable } = this.props;

    if (editable) {
      onRemoveMeta();
    }
  };

  render() {
    const { url, image, title, description, host, editable } = this.props;

    return (
      <div className="relative max-w-2xl">
        {editable && (
          <span className="absolute right-0 cursor-pointer bg-white rounded-full m-2 border-2 border-solid border-gray-800">
            <Icon
              type="close"
              theme="outlined"
              className="p-2"
              onClick={this.removeMeta}
            />
          </span>
        )}

        <a rel="noopener noreferrer" target="_blank" href={url}>
          <div className="max-w-2xl overflow-hidden shadow-sm">
            <div style={{ height: 340 }} className={image ? 'block' : 'hidden'}>
              {image && (
                <img
                  className="w-full max-h-full cover-img"
                  src={image}
                  alt={title}
                />
              )}
            </div>
            <div className="px-6 py-4 bg-white">
              <div className=" mb-2 uppercase text-gray-800 tracking-wide text-sm font-normal">
                {host}
              </div>
              <p className="text-gray-900 text-base">{description}</p>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
