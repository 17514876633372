/*
 * action types
 */

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const ON_CHAT_SEARCH = 'ON_CHAT_SEARCH';

export const LOGIN_AS_USER = 'LOGIN_AS_USER';
export const LOGOUT_AS_USER = 'LOGOUT_AS_USER';

/*
 * action creators
 */

export function loginUser(accessToken) {
    return {
        type: LOGIN_USER,
        accessToken,
    };
}

export function logoutUser() {
    return { type: LOGOUT_USER };
}

export function onChatSearch(keyword) {
  return {
    type: ON_CHAT_SEARCH,
    keyword,
  };
}

export function loginAsUser(accessToken) {
  return {
    type: LOGIN_AS_USER,
    accessToken,
  };
}

export function logoutAsUser() {
  return { type: LOGOUT_AS_USER };
}
