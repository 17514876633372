import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Icon, Popover } from 'antd';
import './PropertyCardAgentPopover.scss';
import AgentContactNumber from '../AgentContactNumber';
import { Link } from 'react-router-dom';

class PropertyCardAgentPopover extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    className: 'bg-gray-100 rounded-b w-full pointer h-full',
    property: {},
  };

  agencyName;
  mobileNumber;

  agentDetails = (agent, propertyId) =>
    agent && (
      <div>
        <Link to={`/agent/${agent.slugUrl}`} className="inline-block">
          <h2 className="text-gray-800">
            <Icon type="idcard" className="mr-2" /> {agent.firstName}{' '}
            {agent.lastName}
          </h2>
        </Link>
        <h3>
          <a href={`tel:${agent.mobileNumber}`} className="text-gray-700">
            <Icon type="phone" className="mr-2" />
          </a>{' '}
          <AgentContactNumber
            useTel
            agentId={agent.id}
            contactNo={agent.mobileNumber}
            propertyId={propertyId}
            className="text-gray-700"
          />
        </h3>
        {agent.agencyName && (
          <h3 className="text-gray-700">
            <Icon type="shop" className="mr-2" /> {agent.agencyName}
          </h3>
        )}
      </div>
    );

  render() {
    const { propertyId, agent } = this.props;

    const { firstName, lastName, profilePictureUrl, bannerColor } = agent || {};

    let cardBorderStyle = {};
    if (bannerColor) {
      cardBorderStyle.borderColor = bannerColor;
    }

    return (
      <>
        {agent && (
          <Popover
            content={this.agentDetails(agent, propertyId)}
            trigger="click"
            className="PropertyCardAgentPopover"
          >
            <div className="cursor-pointer">
              <div>
                <Avatar
                  size={64}
                  icon="user"
                  src={profilePictureUrl}
                  className="z-10 border-gray-800 border-4 bg-gray-500"
                  style={cardBorderStyle}
                />
              </div>
              {firstName} {lastName}
            </div>
          </Popover>
        )}
      </>
    );
  }
}

export default PropertyCardAgentPopover;
