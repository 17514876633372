import React from 'react';
import EventEmitter from 'events';
import debounce from 'lodash.debounce';

export const EventNames = { ON_PROPERTY_MOUSE_OVER: 'ON_PROPERTY_MOUSE_OVER' };
export const FeedEventsNames = {
  ON_POST_ADDED: 'ON_POST_ADDED',
  ON_POST_DELETED: 'ON_POST_DELETED',
  ON_POST_UPDATED: 'ON_POST_UPDATED',
};
export const LocationEventNames = {
  ON_LOCATION_MOUSE_OVER: 'ON_LOCATION_MOUSE_OVER',
};

const emitter = new EventEmitter();

export const EmitterContext = React.createContext({
  addListener: (eventName, callback) =>
    emitter.addListener(eventName, callback),
  once: (eventName, callback) => emitter.once(eventName, callback),
  removeAllListeners: eventName => {
    emitter.removeAllListeners(eventName);
  },
  removeListener: (eventName, callback) => {
    emitter.removeListener(eventName, callback);
  },
  listeners: eventName => emitter.listeners(eventName),
  emit: debounce((eventName, ...args) => {
    emitter.emit(eventName, ...args);
  }, 500),
});

export const withEmitterContext = WrappedComponent => props => (
  <EmitterContext.Consumer>
    {emitter => <WrappedComponent emitter={emitter} {...props} />}
  </EmitterContext.Consumer>
);
export default EmitterContext;
