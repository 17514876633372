import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, List, Row, Tag } from 'antd';

class NearbySchools extends Component {
  static propTypes = {
    nearbySchools: PropTypes.any,
  };

  static defaultProps = {
    nearbySchools: [],
  };

  renderTagColors = level => {
    switch (level.toLowerCase()) {
      case 'primary':
        return '#5aaf64';
      case 'secondary':
        return '#108ee9';
      case 'junior':
        return '#705D56';
      case 'mixed_level':
      case 'integrated_programme':
        return 'grey';
    }
  };

  renderLevel = level => {
    switch (level.toLowerCase()) {
      case 'primary':
        return 'Primary';
      case 'secondary':
        return 'Secondary';
      case 'junior':
        return 'Junior';
      case 'mixed_level':
        return 'Mixed Level';
      case 'integrated_programme':
        return 'Integrated Programme';
    }
  };

  render() {
    const { nearbySchools } = this.props;

    return (
      <div className="px-4">
        <Row>
          <Col>
            <List
              itemLayout="horizontal"
              dataSource={nearbySchools}
              renderItem={({ id, name, type, lat, lng, distance }) => (
                <List.Item className="flex sm:flex-row flex-col">
                  <List.Item.Meta
                    title={
                      <a
                        onClick={e => {
                          e.preventDefault();
                          this.props.handleChangeMarkerCenter({ id, lat, lng });
                        }}
                      >
                        {name}
                      </a>
                    }
                    description={
                      <div>
                        {type.map(level => (
                          <Tag
                            key={level}
                            className="mr-1 mb-1"
                            color={this.renderTagColors(level)}
                          >
                            {this.renderLevel(level)}
                          </Tag>
                        ))}
                      </div>
                    }
                  />
                  <div>
                    <span className="text-xs">{distance.toFixed(2)} km</span>
                  </div>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default NearbySchools;
