import React, { lazy, PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import './PublicPage.scss';
import NotFound from '../../components/NotFound';
import SuspenseRoute from '../../components/SuspenseRoute';
import HomePage from '../HomePage';
import SearchPage from '../SearchPage';
import AgentSearchPage from '../AgentSearchPage';
import FindAgentPage from '../FindAgentPage';
import ExclusiveOpportunityPage from '../ExclusiveOpportunityPage';
import AgentLoginPage from '../AgentLoginPage';
import PropertyPage from '../PropertyPage';
import AgentForgotPassword from '../agent/CreateAgentForgotPasswordPage';
import ConsumerJoinPage from '../ConsumerJoinPage';
import ConsumerLoginPageLoadable from '../ConsumerLoginPage';
import AgentPage from '../AgentPage';
import ConsumerPageRenderer from '../../components/ConsumerPageRenderer';
import GoogleAnalyticsTracker from '../../components/GoogleAnalyticsTracker';

const AgentForgotPasswordPageLoadable = lazy(() =>
  import('../agent/AgentForgotPasswordPage')
);
const AgentSignUpSubscriptionPageLoadable = lazy(() =>
  import('../AgentSignUpSubscription')
);
const AgentNewPasswordVerificationPageLoadable = lazy(() =>
  import('../agent/AgentNewPasswordVerificationPage')
);
const AgentFreeSignUpPageLoadable = lazy(() => import('../AgentFreeSignUp'));
const PrivacyPageLoadable = lazy(() => import('../PrivacyPage'));
const TermsOfServicePageLoadable = lazy(() => import('../TermsOfServicePage'));
const AboutUsPageLoadable = lazy(() => import('../AboutUsPage'));
const ConsumerVerificationPageLoadable = lazy(() =>
  import('../consumer/ConsumerVerificationPage')
);
const AdminLoginPageLoadable = lazy(() => import('../AdminLoginPage'));
const TopicPageLoadable = lazy(() => import('../TopicPage'));
const DistrictPageLoadable = lazy(() => import('../DistrictPage'));
const HDBDirectoryPageLoadable = lazy(() => import('../HDBDirectoryPage'));
const HDBEstatePageLoadable = lazy(() => import('../HDBEstatePage'));
const HDBStreetPageLoadable = lazy(() => import('../HDBStreetPage'));

// const DistrictSegmentPageLoadable = lazy(() =>
//   import('../DistrictSegmentPage')
// );
// const AgentSubscribePageLoadable = lazy(
//     () => import('../AgentSubscribe'));
// const AgentSubscribePlansPageLoadable = lazy(
//     () => import('../AgentSubscribePlans'));

const routes = [
  {
    path: '/t/:uniqueId',
    component: TopicPageLoadable,
    suspense: true,
    name: 'Topic Page',
  },
  {
    path: '/agent-login',
    component: AgentLoginPage,
    suspense: true,
    name: 'Agent Login Page',
  },
  {
    path: '/admin-login',
    component: AdminLoginPageLoadable,
    suspense: true,
    name: 'Admin Login Page',
  },
  {
    path: '/agent-forgot-password',
    component: AgentForgotPasswordPageLoadable,
    suspense: true,
    name: 'Agent Forgot Password',
  },
  {
    path: '/agent/new-password/:verificationKey',
    component: AgentNewPasswordVerificationPageLoadable,
    suspense: true,
    name: 'Agent New Password Verify',
  },
  {
    path: '/create-agent-forgot-password',
    component: AgentForgotPassword,
    name: 'Agent Forgot Password',
  },
  {
    path: '/exclusive-agent-signup',
    component: AgentSignUpSubscriptionPageLoadable,
    suspense: true,
    name: 'Singapore Exclusive Agent Sign Up',
  },
  {
    path: '/free-agent-signup',
    component: AgentFreeSignUpPageLoadable,
    suspense: true,
    name: 'Singapore Free Agent Sign Up',
  },
  {
    path: '/login',
    component: ConsumerLoginPageLoadable,
    suspense: true,
    name: 'Consumer Login Page',
  },
  {
    path: '/sign-up',
    component: ConsumerJoinPage,
    name: 'Consumer Join Page',
  },
  {
    path: '/my-verify/:verificationKey',
    component: ConsumerVerificationPageLoadable,
    suspense: true,
    name: 'Consumer Verify',
  },
  {
    path: '/privacy',
    component: PrivacyPageLoadable,
    suspense: true,
    name: 'Privacy',
  },
  {
    path: '/terms-of-service',
    component: TermsOfServicePageLoadable,
    suspense: true,
    name: 'Terms Of Service Page',
  },
  {
    path: '/',
    component: HomePage,
    // component: LandingPage,
    name: 'Singapore Agent Pricing',
  },
  {
    path: '/buy',
    component: HomePage,
    name: 'Properties for Sale',
  },
  {
    path: '/rent',
    component: HomePage,
    name: 'Properties for Rent',
  },
  {
    path: ['/commercial', '/commercial/rent', '/commercial/buy'],
    component: HomePage,
  },
  {
    path: ['/:searchType/search', '/commercial/:searchType/search'],
    component: SearchPage,
    name: 'Search Properties',
  },
  {
    path: '/find-agent',
    component: FindAgentPage,
    name: 'Search Agent',
  },
  {
    path: '/agents',
    component: AgentSearchPage,
    name: 'Search Agent',
  },
  {
    path: '/exclusive-foundation-opportunity',
    component: ExclusiveOpportunityPage,
    // suspense: true,
    name: 'Exclusive Foundation Opportunity',
  },
  {
    path: '/united',
    component: ExclusiveOpportunityPage,
    // suspense: true,
    name: 'Exclusive Foundation Opportunity',
  },
  {
    path: '/knightfrank',
    component: ExclusiveOpportunityPage,
    // suspense: true,
    name: 'Exclusive Foundation Opportunity',
  },
  {
    path: '/property/:slugUrl',
    component: PropertyPage,
    suspense: true,
    name: 'Property Page',
  },
  {
    path: '/agent/:slugUrl',
    component: AgentPage,
    suspense: true,
    name: 'Agent Page',
  },
  {
    path: '/about-us',
    component: AboutUsPageLoadable,
    suspense: true,
    name: 'About Us Page',
  },
  // {
  //   path: '/districts/:segment',
  //   component: DistrictSegmentPageLoadable,
  //   suspense: true,
  //   name: 'All Districts',
  // },
  {
    path: '/districts',
    component: DistrictPageLoadable,
    suspense: true,
    name: 'All Districts',
  },
  // {
  //   path: '/test',
  //   component: AgentSubscribePlansPageLoadable,
  //   suspense: true,
  //   name: 'Singapore Agent Pricing',
  // },
  // {
  //   path: '/singapore/agent/:plan',
  //   component: AgentSubscribePageLoadable,
  //   suspense: true,
  //   name: 'Singapore Agent Pricing',
  // },
  {
    path: '/hdb',
    component: HDBDirectoryPageLoadable,
    suspense: true,
    name: 'HDB Directory',
  },
  {
    path: '/hdb/:slugUrl',
    component: HDBEstatePageLoadable,
    suspense: true,
    name: 'HDB Estate Page',
  },
  {
    path: '/hdb/:estateSlugUrl/:slugUrl',
    component: HDBStreetPageLoadable,
    suspense: true,
    name: 'HDB Street Page',
  },
  {
    path: '/:pageId/:subRoute?',
    component: ConsumerPageRenderer,
    suspense: true,
    name: 'Consumer Page',
  },
];

class PublicPage extends PureComponent {
  static propTypes = {
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  static defaultProps = { location: {} };

  render() {
    const { location } = this.props;

    return (
      <GoogleAnalyticsTracker location={location}>
        <Switch location={location}>
          {routes.map(route => (
            <SuspenseRoute
              exact
              key={route.path}
              path={route.path}
              component={route.component}
              suspense={route.suspense}
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </GoogleAnalyticsTracker>
    );
  }
}

export default PublicPage;
