import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './NumberPlusMinusInput.scss';

class NumberPlusMinusInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.number,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    label: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    defaultValue: 0,
    value: 0,
    min: 0,
    max: 10,
    label: '',
  };

  constructor(props) {
    super(props);
    const { value, defaultValue } = this.props;

    this.state = { value: value === undefined ? defaultValue : value };
  }

  onClickMinus = () => {
    this.setState(
      ({ value }, { min }) => ({ value: value > min ? value - 1 : value }),
      () => {
        this.triggerChange(this.state.value);
      }
    );
  };

  onClickAdd = () => {
    this.setState(
      ({ value }, { max }) => ({ value: value < max ? value + 1 : value }),
      () => {
        this.triggerChange(this.state.value);
      }
    );
  };

  triggerChange = value => {
    this.props.onChange(value);
  };

  render() {
    //   const { value } = this.state;
    const { label, min, max, value: propsValue } = this.props;

    return (
      <span className="NumberPlusMinusInput inline-block">
        <Button
          disabled={!(propsValue > min)}
          type="primary"
          shape="circle"
          icon="minus"
          ghost
          size="large"
          className="button-minus"
          onClick={this.onClickMinus}
        />
        <span className="text-lg mx-2 font-medium">
          {propsValue}+ {label}
        </span>
        <Button
          disabled={!(propsValue < max)}
          type="primary"
          shape="circle"
          icon="plus"
          ghost
          size="large"
          className="button-plus"
          onClick={this.onClickAdd}
        />
      </span>
    );
  }
}

export default NumberPlusMinusInput;
