import React, { Component } from 'react';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';

class WhatsappButton extends Component {
  render() {
    const { contactNo, propertyTitle, trackWhatsAppEnquiry } = this.props;
    const defaultMessage = `Hi, please send me more information about the "${propertyTitle}" property.`;

    return (
      <a
        href={`https://wa.me/${contactNo}/?text=${defaultMessage}`}
        target="_blank"
      >
        <Button
          className="tracking-wide whatsapp-button"
          shape="round"
          onClick={trackWhatsAppEnquiry}
        >
          <FA icon={faWhatsapp} className="mr-2" /> Ask on Whatsapp
        </Button>
      </a>
    );
  }
}

export default WhatsappButton;
