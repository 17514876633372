import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { fbButton } from 'vanilla-sharing';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Button, Icon } from 'antd';
import gql from 'graphql-tag';

class FacebookShareButton extends Component {
  static propTypes = {
    eventTotalShares: PropTypes.object,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
  };

  static defaultProps = {
    eventTotalShares: {},
    property: {},
    saveShareEvent: () => {},
    uuid: '',
  };

  state = { isLoading: false };

  getErrorMessage = () => {
    let errorMessage = null;
    const { formErrorCode } = this.state;

    switch (formErrorCode) {
      case 'SERVER_ERROR':
        errorMessage = 'There was a problem liking this event.';
        break;
      default:
        errorMessage = 'Unable to like event.';
        break;
    }
    return errorMessage;
  };

  handleFacebookShareEventClick = () => {
    const { property, uuid, saveShareEvent } = this.props;
    const { slugUrl } = property || [];

    this.setState({ isLoading: true });

    const url = property
      ? `https://${window.location.hostname}/property/${slugUrl}`
      : `https://${window.location.hostname}/event/${uuid}`;

    fbButton({ url });

    return saveShareEvent('facebook').then(() =>
      this.setState({ isLoading: false })
    );
  };

  render() {
    const { eventTotalShares } = this.props;
    const { isLoading } = this.state;
    const { loading } = eventTotalShares;

    if (loading) {
      return (
        <div className="h-12 px-4 relative inline-block">
          <Icon type="loading" className="center-align" />
        </div>
      );
    }

    const { event } = eventTotalShares;
    const { totalShares } = event || [];

    const icon = isLoading ? (
      <Icon type="loading" theme="outlined" />
    ) : (
      <FA icon={faFacebookF} fixedWidth />
    );
    return (
      <Button
        className="border-none leading-loose h-12 px-4 rounded-none bg-transparent text-gray-800 hover:text-blue-500"
        onClick={this.handleFacebookShareEventClick}
      >
        {icon}
        &nbsp;
        {totalShares}
      </Button>
    );
  }
}

const EVENT_TOTAL_SHARES = gql`
  query eventTotalShares($id: ID!) {
    event(id: $id) {
      id
      totalShares(platform: "facebook")
    }
  }
`;

const FacebookShareButtonWithMutation = compose(
  graphql(EVENT_TOTAL_SHARES, {
    name: 'eventTotalShares',
    options: ({ id }) => ({ variables: { id } }),
  })
)(FacebookShareButton);

export default FacebookShareButtonWithMutation;
