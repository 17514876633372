import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Icon, Modal } from 'antd';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import CreateConsumerTokenUsingGoogleLogin from './Operations.graphql';
import googleImage from './google.png';
import './GuestLoginUsingGoogle.scss';

class GuestLoginUsingGoogle extends Component {
  static propTypes = {
    onSuccess: PropTypes.func,
    isDisabled: PropTypes.bool,
    setSignInButtonLoading: PropTypes.func,
  };

  static defaultProps = {
    onSuccess: () => {},
    isDisabled: false,
    setSignInButtonLoading: () => {},
  };

  state = {
    modalSigningInVisible: false,
    name: null,
  };

  responseGoogle = async response => {
    const {
      profileObj: { givenName, familyName, email, googleId, imageUrl },
    } = response;

    const {
      loginConsumerUsingGoogleMutation,
      onSuccess,
      setSignInButtonLoading,
    } = this.props;

    try {
      this.setState({
        modalSigningInVisible: true,
        name: givenName,
      });
      const {
        data: { createConsumerTokenUsingGoogleLogin },
      } = await loginConsumerUsingGoogleMutation(
        email,
        givenName,
        familyName,
        imageUrl,
        googleId
      );
      setSignInButtonLoading();
      onSuccess(createConsumerTokenUsingGoogleLogin).then(() => {
        this.setState({ modalSigningInVisible: false });
      });
    } catch (error) {
      this.setState({ modalSigningInVisible: false });
      console.log(error);
    }
  };

  render() {
    const { modalSigningInVisible, name } = this.state;
    // const { isDisabled } = this.props;
    //
    // const disabledGoogle = isDisabled ? 'pointer-events-none' : '';

    return (
      <div className="google-btn-container">
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="via Google"
          onSuccess={this.responseGoogle}
          className="w-full rounded border-2 border-gray-200"
        />
        <Modal
          centered
          visible={modalSigningInVisible}
          footer={null}
          closable={false}
          zIndex={1061}
        >
          <div className="flex items-center justify-center">
            <div className="text-center text-3xl mr-5">
              <Icon type="loading" theme="outlined" />
            </div>
            <div className="text-center">
              <h2>Signing you in as {name}</h2>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default graphql(CreateConsumerTokenUsingGoogleLogin, {
  props: ({ mutate }) => ({
    loginConsumerUsingGoogleMutation: (
      email,
      firstName,
      lastName,
      imageUrl,
      googleId
    ) =>
      mutate({
        variables: {
          email,
          firstName,
          lastName,
          imageUrl,
          googleId,
        },
      }),
  }),
})(GuestLoginUsingGoogle);
