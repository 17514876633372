export const findIndexById = (cacheRecordList, id) =>
  cacheRecordList.findIndex(record => record.id === id);

export const removeRecordFromCache = (
  store,
  query,
  variables,
  getRecordList,
  findIndexMethod
) => {
  if (!findIndexMethod) throw new Error('Must implement findIndexMethod');
  if (!getRecordList) throw new Error('Must implement getRecordList');

  const data = store.readQuery({
    query,
    variables,
  });

  const recordList = getRecordList(data);
  const recordIndex = findIndexMethod(recordList);

  if (recordIndex > -1) {
    recordList.splice(recordIndex, 1);

    store.writeQuery({
      query,
      data,
      variables,
    });
  }
};

export const addRecordToCache = (
  store,
  query,
  variables,
  recordToPush,
  getRecordList
) => {
  if (!getRecordList) throw new Error('Must implement getRecordList');

  const data = store.readQuery({ query, variables });

  const recordList = getRecordList(data);

  recordList.push(recordToPush);

  store.writeQuery({
    query,
    data,
    variables,
  });
};
