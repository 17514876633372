import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
  FollowConsumerMutation,
  UnfollowConsumerMutation,
} from './Operations.graphql';

import GuestLoginForm from '../consumer/GuestLoginForm';
import { loginUser } from '../../actions';

class FollowConsumerButton extends Component {
  static propTypes = {
    consumerId: PropTypes.string.isRequired,
    userType: PropTypes.string,
    onLoginUser: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    isFollowed: PropTypes.bool,
    followConsumer: PropTypes.func,
    isPageOwner: PropTypes.bool,
    refetch: PropTypes.func,
  };

  static defaultProps = {
    onLoginUser: () => {},
    isAuthenticated: false,
    userType: null,
    isFollowed: false,
    followConsumer: () => {},
    isPageOwner: false,
    refetch: () => {},
  };

  constructor(props) {
    super(props);
    const { isFollowed, isPageOwner } = this.props;

    this.state = {
      consumerLoginDecisionModal: false,
      isLoading: false,
      isFollowedByConsumer: isFollowed,
      isPageOwner: isPageOwner,
    };
  }

  onLoginSuccess = loginData => {
    const { onLoginUser, consumerId, followConsumer, refetch } = this.props;
    const { isFollowedByConsumer } = this.state;

    const { accessToken, expiresIn, refreshToken, user, type } = loginData;
    onLoginUser({
      accessToken,
      expiresIn,
      refreshToken,
      user,
      type,
    });
    this.setState({
      isLoading: true,
      isPageOwner: user.id === consumerId,
    });

    if (user.id !== consumerId) {
      followConsumer(consumerId).then(() => {
        this.setState(
          {
            isFollowedByConsumer: !isFollowedByConsumer,
            isLoading: false,
          },
          () => {
            this.closeConsumerLoginModal();
          }
        );
      });
    }
    refetch();
  };

  showDecisionModal = bool => {
    this.setState({ consumerLoginDecisionModal: bool });
  };

  closeConsumerLoginModal = () => {
    this.setState({ consumerLoginDecisionModal: false });
  };

  toggleFollowButton = async () => {
    const { consumerId, followConsumer, unFollowConsumer } = this.props;
    const { isFollowedByConsumer } = this.state;
    this.setState({ isLoading: true });

    if (isFollowedByConsumer) {
      await unFollowConsumer(consumerId);
    } else {
      await followConsumer(consumerId);
    }
    this.setState({
      isFollowedByConsumer: !isFollowedByConsumer,
      isLoading: false,
    });
  };

  handleFollowButtonClick = () => {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.toggleFollowButton();
    } else {
      this.showDecisionModal(true);
    }
  };

  render() {
    const { isAuthenticated, userType } = this.props;
    const {
      isFollowedByConsumer,
      isLoading,
      consumerLoginDecisionModal,
      isPageOwner,
    } = this.state;
    const buttonText = isFollowedByConsumer ? 'Unfollow' : 'Follow';

    if (isPageOwner) return null;

    return (
      <div className="love-heart inline-block">
        <div className="relative -mt-4">
          {isAuthenticated && userType !== 'CONSUMER' && <span />}
          {!isAuthenticated && (
            <Button
              type="primary"
              onClick={this.handleFollowButtonClick}
              loading={isLoading}
            >
              {buttonText}
            </Button>
          )}
          {isAuthenticated && userType === 'CONSUMER' && (
            <Button
              type="primary"
              onClick={this.handleFollowButtonClick}
              loading={isLoading}
            >
              {buttonText}
            </Button>
          )}
        </div>
        <Modal
          wrapClassName="vertical-center-modal"
          visible={consumerLoginDecisionModal}
          onOk={() => this.showDecisionModal(false)}
          onCancel={() => this.showDecisionModal(false)}
          footer={null}
          zIndex={1060}
        >
          <GuestLoginForm
            onSuccess={this.onLoginSuccess}
            onSuccessAccount={this.onLoginSuccess}
          />
        </Modal>
      </div>
    );
  }
}

const GuestLoginWithStateAndMutation = compose(
  graphql(FollowConsumerMutation, {
    name: 'followConsumer',
    props: ({ followConsumer }) => ({
      followConsumer: consumerId =>
        followConsumer({ variables: { consumerId } }),
    }),
  }),
  graphql(UnfollowConsumerMutation, {
    name: 'unFollowConsumer',
    props: ({ unFollowConsumer }) => ({
      unFollowConsumer: consumerId =>
        unFollowConsumer({ variables: { consumerId } }),
    }),
  }),
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      userType: state.auth.type,
    }),
    dispatch => ({
      onLoginUser(accessToken) {
        dispatch(loginUser(accessToken));
      },
    })
  )
)(FollowConsumerButton);

export default GuestLoginWithStateAndMutation;
