import React, { PureComponent } from 'react';
import { Col, Row } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { winHeight, winWidth } from '../../utils/windowDimensions';
import hero from './AgentPM.jpg';
import heroMobileA from './hero-mobile-a.png';
import heroMobileB from './hero-mobile-b.png';
import logoWhite from '../../assets/logo-white.png';
import './Hero.scss';

class HeroText extends PureComponent {
  state = { stat: false };

  componentDidMount() {
    this.setState({ stat: true });
  }

  render() {
    const { stat } = this.state;

    return (
      <>
        <p className="tagline xxl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl sm:text-3xl text-3xl font-semibold text-white w-full poppins my-3">
          <span>Accelerate with Real Estate</span>
        </p>
        <h3 className="lg:text-3xl md:text-xl text-lg text-white text-shadow-lg mx-4 text-center tagline">
          Helping you sell more listings &amp; save on your marketing costs!
        </h3>
      </>
    );
  }
}

class Hero extends PureComponent {
  render() {
    return (
      <div
        className="hero w-full relative hero-mobile"
        style={{ height: winHeight - (winWidth > 1024 ? 280 : 0) }}
      >
        <Row className="hero-row">
          <Col>
            <div className="logo absolute z-10" style={{ width: 250, top: 13 }}>
              <Link to="/">
                <img src={logoWhite} alt="realestate-sg" className="" />
              </Link>
            </div>
          </Col>
          <Col className="hero-col text-center">
            <div className="hero-carousel">
              <div className="relative hero-carousel-img-container">
                <img
                  src={hero}
                  alt="realestate-hero"
                  className="w-full hidden md:block"
                />
                <img
                  src={heroMobileB}
                  alt="realestate-hero-mobile"
                  className="block md:hidden"
                />
              </div>
            </div>

            <div className="center-align w-full z-10">
              <HeroText />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Hero;
