import React, { PureComponent, Fragment } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Avatar, Col, Icon, Row } from 'antd';
import Error from '../Error';
import Loading from '../Loading';
import AgentEnquiryForm from '../AgentEnquiryForm';

class AgentEnquiry extends PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    trackEnquiry: PropTypes.func,
  };

  static defaultProps = {
    data: {},
    trackEnquiry: () => {},
  };

  render() {
    const {
      data: { agent, loading, error },
      trackEnquiry,
    } = this.props;

    if (loading) return <Loading className="full-page" />;
    if (error) return <Error error={error} />;

    const { firstName, lastName, profilePictureUrl, agencyName, id } = agent;

    return (
      <Fragment>
        <Row
          justify="center"
          type="flex"
          className="py-8 bg-gray-100"
          id="enquiry"
        >
          <Col className="" xs={22} sm={22} md={22} lg={18} xl={14} xxl={10}>
            <div className="text-center lg:py-8 md:py-4 py-8">
              {profilePictureUrl ? (
                <Avatar
                  src={profilePictureUrl || null}
                  size="large"
                  icon="user"
                  className="md:w-48 w-24 md:h-48 h-24 border-4 mb-4"
                />
              ) : (
                <div className=" md:w-48 w-24 md:h-48 h-24 bg-gray-500 my-8 rounded-full inline-block relative">
                  <Icon
                    type="user"
                    className="text-gray-100 text-5xl center-align"
                  />
                </div>
              )}
              <p className="md:text-2xl text-lg text-center font-semibold">
                Contact&nbsp;
                {firstName}
                &nbsp;
                {lastName}
                &nbsp;at
                <br />
                {agencyName}
              </p>
              <p className="md:text-lg text-center mt-6">
                What can&nbsp;
                {firstName}
                &nbsp;help you with today?
              </p>
            </div>
            <AgentEnquiryForm
              agentId={id}
              agentFirstName={firstName}
              agentLastName={lastName}
              agencyName={agencyName}
              trackEmailAttempt={() => trackEnquiry('Attempt Mail Enquiry')}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const AGENT_DATA = gql`
  query agentQuery($id: ID!) {
    agent(id: $id) {
      id
      firstName
      lastName
      mobileNumber
      email
      profilePictureUrl
      agencyName
    }
  }
`;
const AgentProfileWithData = graphql(AGENT_DATA, {
  options: ({ id }) => ({ variables: { id } }),
})(AgentEnquiry);

export default AgentProfileWithData;
