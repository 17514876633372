import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import PropertyMapMarkerWithInfoBox from '../PropertyMapMarkerWithInfoBox';
import {
  FEATURED_69,
  FEATURED_99,
  HIGHLIGHT_199,
  PREMIERE,
} from '../../constants/property-ad-types';
import { EmitterContext, EventNames } from '../../context/emitter-context';

class PropertyMapWithMarkerClusterer extends PureComponent {
  static propTypes = {
    onPropertyMouseOverPropertyId: PropTypes.string,
    markers: PropTypes.any,
  };

  static defaultProps = {
    markers: [],
    onPropertyMouseOverPropertyId: null,
  };

  state = { zoom: 11 };

  onClosePropertyMarker = () => {
    this.setState({ openedPropertyMarkerId: null });
  };

  onOpenPropertyMarker = openedPropertyMarkerId => {
    this.setState({ openedPropertyMarkerId });
  };

  getDefaultBounds = () =>
    new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(-29.46542, 120.13594),
      new window.google.maps.LatLng(-19.56703, 146.15156)
    );

  fitMarkersToMap = markers => {
    if (markers.length > 0) {
      if (markers.length === 1) {
        const marker = markers[0];
        this.setState({ zoom: 18 }, () => {
          this.map.panTo(new window.google.maps.LatLng(marker.lat, marker.lng));
        });
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(marker => {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        });
        this.map.fitBounds(bounds);
      }
    } else {
      this.map.fitBounds(this.getDefaultBounds());
    }
  };

  onMarkerClustererClick = markerClusterer => {
    markerClusterer.getMarkers();
  };

  isPropertyOpened = propertyId => {
    const { openedPropertyMarkerId } = this.state;
    return propertyId === openedPropertyMarkerId;
  };

  isPropertyHovered = propertyId => {
    const { hoveredMarkerId } = this.state;
    return propertyId === hoveredMarkerId;
  };

  onZoomChanged = () => {
    this.setState({ zoom: this.map.getZoom() });
  };

  closeAllInfoBox = () => {
    this.setState({ openedPropertyMarkerId: null });
  };

  getMarkerType = property => {
    let markerType = 'MARKER_DOT';

    switch (property.featuredTier) {
      case PREMIERE: {
        markerType = 'MARKER_GOLD';
        break;
      }
      case FEATURED_69:
      case FEATURED_99:
      case HIGHLIGHT_199: {
        markerType = 'MARKER_BLUE';
        break;
      }
      default: {
        markerType = 'MARKER_DOT';
      }
    }
    return markerType;
  };

  isBouncingMarker = property =>
    [PREMIERE, HIGHLIGHT_199].includes(property.featuredTier) ||
    this.isPropertyHovered(property.id);

  componentWillMount() {
    this.setState({ openedPropertyMarkerId: null });
  }

  componentWillUnmount() {
    this.props.emitter.removeListener(
      EventNames.ON_PROPERTY_MOUSE_OVER,
      () => {}
    );
  }

  componentDidMount() {
    // this.fitMarkersToMap(this.props.markers);

    this.listener = this.props.emitter.addListener(
      EventNames.ON_PROPERTY_MOUSE_OVER,
      propertyId => {
        const { markers = [] } = this.props;

        if (propertyId) {
          const property = markers.find(
            propertyItem => propertyItem.id === propertyId
          );

          if (property) {
            this.setState(
              {
                hoveredMarkerId: propertyId,
                zoom: 15,
              },
              () => {
                this.map.panTo(
                  new window.google.maps.LatLng(property.lat, property.lng)
                );
              }
            );
          }
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    // this.fitMarkersToMap(nextProps.markers);
  }

  render() {
    const { markers, searchParam } = this.props;
    return (
      <GoogleMap
        ref={map => (this.map = map)}
        defaultZoom={11}
        zoom={this.state.zoom}
        // onZoomChanged={this.onZoomChanged}
        defaultCenter={{
          lat: 1.355696,
          lng: 103.820559,
        }}
        onClick={this.closeAllInfoBox}
        // onDragEnd={this.closeAllInfoBox}
      >
        <MarkerClusterer
          key="marker-clusterer"
          onClick={this.onMarkerClustererClick}
          averageCenter
          maxZoom={14}
          gridSize={60}
          enableRetinaIcons
        >
          {markers.map(property => (
            <PropertyMapMarkerWithInfoBox
              markerType={this.getMarkerType(property)}
              isBouncing={this.isBouncingMarker(property)}
              propertyId={property.id}
              property={property}
              lat={property.lat}
              lng={property.lng}
              key={property.id}
              isOpen={this.isPropertyOpened(property.id)}
              onOpen={this.onOpenPropertyMarker}
              onClose={this.onClosePropertyMarker}
              searchParam={searchParam}
            />
          ))}
        </MarkerClusterer>
      </GoogleMap>
    );
  }
}

const PropertyMapWithMarkerClustererWithGoogleMap = compose(
  withScriptjs,
  withGoogleMap
)(PropertyMapWithMarkerClusterer);

export default props => (
  <EmitterContext.Consumer>
    {emitter => (
      <PropertyMapWithMarkerClustererWithGoogleMap
        emitter={emitter}
        {...props}
      />
    )}
  </EmitterContext.Consumer>
);
