import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import { CarouselNextArrow, CarouselPrevArrow } from '../Buttons';
import LatestResidentialPropertyForRentQuery from '../../queries/LatestResidentialPropertyForRentQuery.graphql';
import PropertyCard from '../PropertyCard';
import './LatestResidentialPropertyForRent.scss';

class LatestResidentialPropertyForRent extends Component {
  render() {
    const {
      data: { loading, error, latestResidentialPropertyForRent },
    } = this.props;

    const properties = latestResidentialPropertyForRent;

    const settings = {
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      infinite: false,
      swipeToSlide: true,
      arrows: true,
      nextArrow: <CarouselNextArrow />,
      prevArrow: <CarouselPrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      !!properties && (
        <div className="LatestResidentialPropertyForRent my-4">
          <div className="w-full max-w-6xl mx-auto p-4">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg sm:text-3xl font-semibold">
                Latest Residential Property For Rent
              </h3>
              <Link
                className="text-blue-500 text-base font-medium hidden md:block"
                to="/rent/search"
              >
                View All
              </Link>
            </div>

            <Row gutter={8}>
              <Carousel {...settings}>
                {properties.map(item => (
                  <Col xs={8} className=" my-2" key={item.id}>
                    <PropertyCard property={item} carouselImageHeight="100%" />
                  </Col>
                ))}
              </Carousel>
            </Row>
          </div>
        </div>
      )
    );
  }
}

const LatestResidentialPropertyForRentWithData = graphql(
  LatestResidentialPropertyForRentQuery
)(LatestResidentialPropertyForRent);

export default LatestResidentialPropertyForRentWithData;
