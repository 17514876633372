import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import IsAuthenticated from '../IsAuthenticated';

const PrivateRoute = ({
  component: RenderComponent,
  userType,
  redirectPath,
  ...rest
}) => (
  <IsAuthenticated>
    {({ isAuthenticated, type }) =>
      isAuthenticated && userType === type ? (
        <RenderComponent {...rest} />
      ) : (
        <Redirect to={{ pathname: redirectPath }} />
      )
    }
  </IsAuthenticated>
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  userType: PropTypes.string,
  redirectPath: PropTypes.string,
};

PrivateRoute.defaultProps = {
  component: null,
  userType: 'AGENT',
  redirectPath: '/login',
};

export default PrivateRoute;
