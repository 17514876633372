import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import './App.scss';
import NotFound from '../NotFound';
import PublicPage from '../../pages/PublicPage';
import PrivateRoute from '../PrivateRoute';
import StripeLoader from '../StripeLoader';
import AppStartup from '../AppStartup';
import Loading from '../LoadingDots';

const AgentPageLoadable = lazy(() => import('../../pages/agent/AgentPage'));
const ConsumerPageLoadable = lazy(() =>
  import('../../pages/consumer/ConsumerPage')
);
const AdminPageLoadable = lazy(() => import('../../pages/admin/AdminPage'));

class App extends Component {
  render() {
    return (
      <AppStartup>
        <DragDropContextProvider backend={HTML5Backend}>
          <StripeLoader>
            <Switch>
              <PrivateRoute
                userType="AGENT"
                redirectPath="/agent-login"
                path="/_/agent"
                component={props => (
                  <Suspense fallback={<Loading height="100vh" />}>
                    <AgentPageLoadable {...props} />
                  </Suspense>
                )}
              />
              <PrivateRoute
                userType="CONSUMER"
                redirectPath="/login"
                path="/_/consumer"
                component={props => (
                  <Suspense fallback={<Loading height="100vh" />}>
                    <ConsumerPageLoadable {...props} />
                  </Suspense>
                )}
              />
              <PrivateRoute
                userType="ADMIN"
                redirectPath="/admin-login"
                path="/admin"
                component={props => (
                  <Suspense fallback={<Loading height="100vh" />}>
                    <AdminPageLoadable {...props} />
                  </Suspense>
                )}
              />
              <Route path="/" component={PublicPage} />
              <Route component={NotFound} />
            </Switch>
          </StripeLoader>
        </DragDropContextProvider>
      </AppStartup>
    );
  }
}

export default App;
