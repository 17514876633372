/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import isEmpty from 'lodash.isempty';
import './ImagesSliderModal.scss';
import ImagesSlider, { Carouselmage } from '../ImagesSlider';

class ImagesSliderModal extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
    visible: PropTypes.bool,
    initialSlide: PropTypes.number,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    initialSlide: 0,
    images: [],
    onClose: () => {},
  };

  render() {
    const { visible, images, initialSlide, onClose } = this.props;

    return (
      <Modal
        className="ImagesSliderModal"
        visible={visible}
        footer={null}
        // width={1000}
        centered
        onCancel={onClose}
        destroyOnClose
      >
        <ImagesSlider
          duration={5000}
          transitionDuration={500}
          infinite
          arrows={images && images.length > 1}
          indicators={false}
          autoplay={false}
          initialSlide={initialSlide}
          style={{ height: '90vh' }}
        >
          {!isEmpty(images) &&
            images.map(({ url }) => <Carouselmage key={url} imageUrl={url} />)}
        </ImagesSlider>
      </Modal>
    );
  }
}

export default ImagesSliderModal;
