import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Radio,
} from 'antd';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { loginUser } from '../../actions';
import PropTypes from 'prop-types';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import googleImage from './google.png';
import GuestLoginUsingFb from '../../components/consumer/GuestLoginUsingFb';
import GuestLoginUsingGoogle from '../../components/consumer/GuestLoginUsingGoogle';
import './ConsumerJoinPage.scss';
import PublicLayout from '../../layouts/PublicLayout';

const RadioGroup = Radio.Group;

class ConsumerJoinPage extends Component {
  static propTypes = {
    createConsumer: PropTypes.func,
    history: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    onLoginUser: PropTypes.func,
  };

  static defaultProps = {
    createConsumer: () => {},
    history: {},
    isAuthenticated: false,
    onLoginUser: () => {},
  };

  state = {
    formErrorCode: null,
    isLoading: false,
    isSuccess: false,
  };

  resetErrors = () => {
    this.setState({ formErrorCode: null });
  };

  getErrorMessage = email => {
    let errorMessage = null;
    const { formErrorCode } = this.state;
    console.log('a ' + formErrorCode);
    switch (formErrorCode) {
      case 'CONSUMER_ALREADY_EXIST':
        errorMessage = `Email ${email} already exists.`;
        break;
      case 'SERVER_ERROR':
        errorMessage = 'There was a problem saving user.';
        break;
      default:
        errorMessage = 'Unable to Create account.';
        break;
    }
    return errorMessage;
  };

  success = email => {
    const { history } = this.props;
    Modal.success({
      title: 'Thank you for signing up',
      content: (
        <p>
          Kindly check your email (<span className="font-bold">{email}</span>)
          for verification.
        </p>
      ),
      onOk() {
        history.push('/_/consumer/feed');
      },
    });
  };

  handleSignUp = values => {
    const { email } = values;
    const { createConsumer } = this.props;
    this.resetErrors();
    this.setState({ isLoading: true });

    // const agencyIds = values.agencyIds ? values.agencyIds.map(({ id }) => id) : null;
    // const locationIds = values.locationIds ? values.locationIds.map(({ id }) => id) : null;
    const { confirmPassword, ...newValues } = values;

    createConsumer({
      ...newValues,
      // agencyIds,
      // locationIds,
    })
      .then(() => {
        this.setState(
          {
            isLoading: false,
            isSuccess: true,
          },
          () => {
            this.success(email);
          }
        );
      })
      .catch(error => {
        console.log('errrr ' + error);
        const errors = error.graphQLErrors || [];
        const formErrorCode =
          errors.length > 0 ? errors[0].extensions.code : null;
        this.setState(
          {
            formErrorCode,
            isLoading: false,
          },
          () => {
            message.error(this.getErrorMessage(email));
          }
        );
      });
  };

  onLoginSuccess = loginData =>
    new Promise(resolve => {
      const { onLoginUser } = this.props;
      const { accessToken, expiresIn, refreshToken, user, type } = loginData;
      onLoginUser({
        accessToken,
        expiresIn,
        refreshToken,
        user,
        type,
      });
      resolve(true);
    });

  render() {
    const { isLoading, isSuccess } = this.state;
    const { isAuthenticated } = this.props;
    if (isAuthenticated) return <Redirect to="/_/consumer/feed" />;

    return (
      <PublicLayout>
        <Row
          align="middle"
          justify="center"
          type="flex"
          className="signup-form-container full-page JoinConsumerPage lg:flex-row flex-col"
        >
          <Col xs={22} sm={20} md={13} lg={13} xl={14} xxl={14}>
            <h1 className="lg:text-left text-center text-white xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-lg">
              Join Now!
            </h1>
            <h3 className="sm:mb-6 lg:text-left text-center text-gray-700 font-normal xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-sm">
              We are the Social Media Network for your Home!
            </h3>
            <div className="lg:flex hidden w-full">
              <section className="flex flex-col justify-center">
                <div className="text-gray-300 flex items-center p-2">
                  <Icon type="home" className="text-3xl" />
                  <span className="lg:px-4 px-2 xl:text-lg text-base">
                    Follow an agency, an agent and property
                  </span>
                </div>
                <div className="text-gray-300 flex items-center p-2">
                  <Icon type="heart" className="text-3xl" />
                  <span className="lg:px-4 px-2 xl-text-lg text-base">
                    Save your inspirations
                  </span>
                </div>
                <div className="text-gray-300 flex items-center p-2">
                  <Icon type="alert" className="text-3xl" />
                  <span className="lg:px-4 px-2 xl:text-lg text-base">
                    Receive updates and email alerts
                  </span>
                </div>
                <div className="text-gray-300 flex items-center p-2">
                  <Icon type="team" className="text-3xl" />
                  <span className="lg:px-4 px-2 xl:text-lg text-base">
                    Add family, friends and contributors to your social network
                  </span>
                </div>
                <div className="text-gray-300 flex items-center p-2">
                  <Icon type="like" className="text-3xl" />
                  <span className="lg:px-4 px-2 xl:text-lg text-base">
                    Like, Share &amp; Chat with friends and family
                  </span>
                </div>
              </section>
            </div>
          </Col>
          <Col xs={22} sm={20} md={13} lg={10} xxl={6}>
            <WrappedGuestForm
              loading={isLoading}
              onSubmit={this.handleSignUp}
              success={isSuccess}
              onSuccessAccount={this.onLoginSuccess}
            />
          </Col>
        </Row>
      </PublicLayout>
    );
  }
}

const CREATE_GUEST_CONSUMER_MUTATION = gql`
  mutation createConsumer($input: CreateConsumerInput!) {
    createConsumer(input: $input) {
      id
      email
      firstName
      lastName
      mobileNumber
    }
  }
`;

const JoinPageWithStateAndMutation = compose(
  withRouter,
  graphql(CREATE_GUEST_CONSUMER_MUTATION, {
    props: ({ mutate }) => ({
      createConsumer: input => mutate({ variables: { input } }),
    }),
  }),
  connect(
    state => ({
      isAuthenticated:
        state.auth.isAuthenticated && state.auth.type === 'CONSUMER',
    }),
    dispatch => ({
      onLoginUser(accessToken) {
        dispatch(loginUser(accessToken));
      },
    })
  )
)(ConsumerJoinPage);

class GuestForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSuccessAccount: PropTypes.func,
  };

  static defaultProps = {
    form: {},
    loading: false,
    success: false,
    onSubmit: () => {},
    onSuccessAccount: () => {},
  };

  state = {
    socialType: null,
    // agencyType: 'AGENCY',
    formFilled: false,
    userRole: null,
  };

  componentWillReceiveProps(nextProps) {
    const {
      form: { resetFields },
    } = this.props;
    if (nextProps.success) resetFields();
  }

  handleSubmitConsumer = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      onSubmit,
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  handleConfirmPassword = (rule, value, callback) => {
    const {
      form: { getFieldValue },
    } = this.props;
    if (value && value !== getFieldValue('password')) {
      callback('Password mismatch');
    }
    callback();
  };

  handleChangeForm = () => {
    const {
      form: { getFieldsValue },
    } = this.props;
    if (JSON.parse(JSON.stringify(getFieldsValue()))) {
      this.setState({ formFilled: true });
    }
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      onSuccessAccount,
      loading,
    } = this.props;

    return (
      <main>
        <main className="GuestForm md:shadow-lg sm:px-8 sm:py-12 py-4 px-1">
          <>
            <div className="flex justify-center mb-2">
              <GuestLoginUsingFb onSuccess={onSuccessAccount} />
              <GuestLoginUsingGoogle onSuccess={onSuccessAccount} />
            </div>
            <Divider className="py-1 text-center mb-2 text-gray-700 font-normal login-divider">
              or
            </Divider>
            <Form
              className="guest-login-form"
              onSubmit={this.handleSubmitConsumer}
            >
              <Input.Group>
                <Col xs={24} sm={24} md={12}>
                  <span className="ubuntu text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
                    First name
                  </span>
                  <Form.Item>
                    {getFieldDecorator('firstName', {
                      validateFirst: true,
                      rules: [
                        {
                          required: true,
                          message: 'Your first name is required',
                        },
                        {
                          pattern: '[a-zA-Z]',
                          message: 'Please enter a valid first name',
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span className="ubuntu text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
                    Last name
                  </span>
                  <Form.Item>
                    {getFieldDecorator('lastName', {
                      validateFirst: true,
                      rules: [
                        {
                          required: true,
                          message: 'Your last name is required',
                        },
                        {
                          pattern: '[a-zA-Z]',
                          message: 'Please enter a valid last name',
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Input.Group>
              <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py1 inline-block">
                Mobile Number
              </span>
              <Form.Item>
                {getFieldDecorator('mobileNumber', {})(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py1 inline-block">
                Email
              </span>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'Please enter a valid email',
                    },
                    {
                      required: true,
                      message: 'Your email is required',
                    },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                  />
                )}
              </Form.Item>
              <Input.Group>
                <Col xs={24} sm={24} md={12}>
                  <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
                    Password
                  </span>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          min: 6,
                          message: 'Password should at least have 6 characters',
                        },
                        {
                          required: true,
                          message: 'Your password is required',
                        },
                      ],
                    })(
                      <Input.Password
                        size="large"
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
                    Confirm Password
                  </span>
                  <Form.Item>
                    {getFieldDecorator('confirmPassword', {
                      rules: [
                        {
                          required: true,
                          message: 'Please type your password again',
                        },
                        { validator: this.handleConfirmPassword },
                      ],
                    })(
                      <Input.Password
                        size="large"
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        type="password"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Input.Group>
              <Form.Item classNmae="mt-5 mb-8">
                <Button
                  loading={loading}
                  className="w-full rounded-full"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Create Account
                </Button>
              </Form.Item>
            </Form>
            <section className="text-white">
              Already have an account?
              <Link to="/login"> Sign in!</Link>
            </section>
          </>
        </main>
      </main>
    );
  }
}

const WrappedGuestForm = Form.create()(GuestForm);
export default JoinPageWithStateAndMutation;
