import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { linkedin } from 'vanilla-sharing';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Button, Icon } from 'antd';
import gql from 'graphql-tag';

class LinkedInShareButton extends Component {
  static propTypes = {
    eventTotalShares: PropTypes.object,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
  };

  static defaultProps = {
    eventTotalShares: {},
    property: {},
    saveShareEvent: () => {},
    uuid: '',
  };

  state = { isLoading: false };

  getErrorMessage = () => {
    let errorMessage = null;
    const { formErrorCode } = this.state;

    switch (formErrorCode) {
      case 'SERVER_ERROR':
        errorMessage = 'There was a problem sharing this event.';
        break;
      default:
        errorMessage = 'Unable to share event.';
        break;
    }
    return errorMessage;
  };

  handleLinkedInShareEventClick = () => {
    const { uuid, property, saveShareEvent } = this.props;
    const { slugUrl } = property || [];

    this.setState({ isLoading: true });

    const url = property
      ? `https://${window.location.hostname}/property/${slugUrl}`
      : `https://${window.location.hostname}/event/${uuid}`;
    const type = property ? 'property' : 'event';

    linkedin({
      url,
      title: `Check out this ${type}`,
      description: `Check out this ${type} ${url}`,
    });

    return saveShareEvent('linkedin').then(() =>
      this.setState({ isLoading: false })
    );
  };

  render() {
    const { isLoading } = this.state;
    const { eventTotalShares } = this.props;
    const { loading } = eventTotalShares;

    if (loading) {
      return (
        <div className="h-12 px-4 relative inline-block">
          <Icon type="loading" className="center-align" />
        </div>
      );
    }

    const { event } = eventTotalShares;
    const { totalShares } = event || [];
    const icon = isLoading ? (
      <Icon type="loading" theme="outlined" />
    ) : (
      <FA icon={faLinkedinIn} fixedWidth />
    );

    return (
      <Button
        className="border-none leading-loose h-12 px-4 rounded-none bg-transparent text-gray-800 hover:text-blue-500"
        onClick={this.handleLinkedInShareEventClick}
      >
        {icon}
        &nbsp;
        {totalShares}
      </Button>
    );
  }
}

const EVENT_TOTAL_SHARES = gql`
  query eventTotalShares($id: ID!) {
    event(id: $id) {
      id
      totalShares(platform: "linkedin")
    }
  }
`;

const LinkedInShareButtonWithMutation = compose(
  graphql(EVENT_TOTAL_SHARES, {
    name: 'eventTotalShares',
    options: ({ id }) => ({ variables: { id } }),
  })
)(LinkedInShareButton);

export default LinkedInShareButtonWithMutation;
