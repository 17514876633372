import React from 'react';
import PropTypes from 'prop-types';
import {
  faHandHoldingUsd,
  faSackDollar,
} from '@fortawesome/pro-regular-svg-icons';
import { Col, Radio, Row } from 'antd';
import PropertyTypeOption from '../PropertyTypeOption';

const { Group: RadioGroup } = Radio;

function PropertyListingStep(props) {
  const { onChange } = props;

  return (
    <>
      <Row className="flex justify-center">
        <Col>
          <p className="text-2xl md:text-3xl text-white text-center px-4">
            You're looking for a property to
          </p>
        </Col>
      </Row>
      <Row className="flex justify-center">
        <Col>
          <RadioGroup className="option-group flex" onChange={onChange}>
            <PropertyTypeOption value="SALE" icon={faSackDollar} label="Buy" />
            <PropertyTypeOption
              value="RENT"
              icon={faHandHoldingUsd}
              label="Rent"
            />
          </RadioGroup>
        </Col>
      </Row>
    </>
  );
}

PropertyListingStep.propTypes = {
  onChange: PropTypes.func,
};

PropertyListingStep.defaultProps = {
  onChange: () => {},
};

export default PropertyListingStep;
