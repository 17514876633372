import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import { logoutUser } from '../../actions';

class IsAuthenticated extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    onLogoutUser: PropTypes.func,
  };

  static defaultProps = {
    isAuthenticated: false,
    onLogoutUser: () => {},
  };

  render() {
    const {
      onLogoutUser,
      isAuthenticated,
      children,
      type,
      ...rest
    } = this.props;
    return children({
      isAuthenticated,
      logoutUser: onLogoutUser,
      originalProps: rest,
      type,
    });
  }
}

const IsAuthenticatedWithState = compose(
  withApollo,
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      type: state.auth.type,
    }),
    (dispatch, { client }) => ({
      onLogoutUser() {
        // client.resetStore();
        dispatch(logoutUser());
      },
    }),
    null,
    { pure: false }
  )
)(IsAuthenticated);

export default IsAuthenticatedWithState;
