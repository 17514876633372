import React, { PureComponent, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';

import PublicHeader from '../../components/PublicHeader';
import PublicFooter from '../../components/PublicFooter';
import Loading from '../../components/Loading';
import './PublicLayout.scss';

const ConsumerLayoutHeaderLoadable = lazy(() =>
  import('../../components/consumer/ConsumerLayoutHeader')
);

class PublicLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    isAuthenticated: PropTypes.bool,
    location: PropTypes.object,
    routes: PropTypes.array,
    type: PropTypes.string,
    hideFooter: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    location: {},
    routes: [],
    type: '',
    hideFooter: false,
  };

  render() {
    const {
      children,
      location,
      routes,
      hideFooter,
      isAuthenticated,
    } = this.props;

    const currentRoute = routes.find(
      route =>
        route.path.split('/:')[0] === `/${location.pathname.split('/')[1]}`
    );
    const isConsumerPage = !routes.filter(route =>
      route.path.match(
        new RegExp(
          `${(currentRoute && currentRoute.path) || location.pathname}.*`
        )
      )
    ).length;

    if (currentRoute) document.title = `Realestate | ${currentRoute.name}`;

    return (
      <div className="default-theme">
        <Layout className="public-layout">
          {isConsumerPage && isAuthenticated ? (
            <Suspense fallback={<Loading height="100vh" />}>
              <ConsumerLayoutHeaderLoadable />
            </Suspense>
          ) : (
            <PublicHeader />
          )}
          <Layout.Content className="full-page">{children}</Layout.Content>
          {!hideFooter && <PublicFooter />}
        </Layout>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({
    isAuthenticated:
      state.auth.isAuthenticated && state.auth.type === 'CONSUMER',
  }))
)(PublicLayout);
