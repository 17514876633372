/*
Modified from original source
https://github.com/imgix/react-imgix/blob/master/src/constructUrl.js
*/

import { Base64 } from 'js-base64';
import extractQueryParams from './extractQueryParams';

const PARAM_EXPANSION = {
  'auto-features': 'auto',
  format: 'fm',
  quality: 'q',
  'crop-mode': 'crop',
  'fit-mode': 'fit',
  'image-height': 'h',
  'image-width': 'w',
  blurring: 'blur',
  height: 'h',
  width: 'w',
};

function constructUrl(src, options) {
  if (!src) {
    return '';
  }
  const keys = Object.keys(options);
  const keysLength = keys.length;
  let url = `${src}?`;
  for (let i = 0; i < keysLength; i++) {
    let key = keys[i];
    let val = options[key];

    if (PARAM_EXPANSION[key]) {
      key = PARAM_EXPANSION[key];
    } else {
      key = encodeURIComponent(key);
    }

    if (key.substr(-2) === '64') {
      val = Base64.encodeURI(val);
    }

    url += `${key}=${encodeURIComponent(val)}&`;
  }

  return url.slice(0, -1);
}

export function buildURL(src, imgixParams = {}, options = {}) {
  const [rawSrc, params] = extractQueryParams(src);
  return constructUrl(rawSrc, Object.assign({}, params, imgixParams));
}
