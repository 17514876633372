import React, { Component } from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { Button, Form, Icon, Input, message, Row, Select } from 'antd';
import './PropertyEnquiryForm.scss';

class PropertyEnquiryForm extends Component {
  state = { isEnquiring: false };

  handleSubmit = e => {
    e.preventDefault();
    const {
      propertyId,
      form: { validateFields, resetFields },
      createPropertyEnquiry,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        this.setState(prevState => ({ isEnquiring: !prevState.isEnquiring }));

        createPropertyEnquiry({
          ...values,
          propertyId,
          source: 'PROPERTY_PAGE',
        })
          .then(res => {
            if (res.data.createPropertyEnquiry) {
              message.success('Property enquiry successfully sent.');
              resetFields();
            }
          })
          .catch(err => {
            message.error('Something went wrong in sending enquiry.');
            throw err;
          })
          .finally(() => {
            this.setState(prevState => ({
              isEnquiring: !prevState.isEnquiring,
            }));
          });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { isEnquiring } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row type="flex" justify="space-between" align="top" className="w-full">
          <span className="ml-4 text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
            My Name
          </span>
          <Form.Item className="w-full">
            {getFieldDecorator('fullName', {
              rules: [
                {
                  required: true,
                  message: 'Your name is required',
                },
              ],
            })(
              <Input
                className="w-full"
                prefix={<Icon type="user" className="text-blue-500" />}
              />
            )}
          </Form.Item>
          <span className="ml-4 text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
            Email
          </span>
          <Form.Item className="w-full">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
                {
                  required: true,
                  message: 'Your email is required',
                },
              ],
            })(
              <Input prefix={<Icon type="mail" className="text-blue-500" />} />
            )}
          </Form.Item>
          <span className="ml-4 text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
            Phone Number <span className="text-gray-700">(optional)</span>
          </span>
          <Form.Item className="w-full">
            {getFieldDecorator('phoneNumber', { rules: [] })(
              <Input prefix={<Icon type="phone" className="text-blue-500" />} />
            )}
          </Form.Item>
          <span className="ml-4 text-gray-800 text-xs uppercase font-semibold py-1 inline-block">
            My Enquiries
          </span>
          <Form.Item className="w-full">
            {getFieldDecorator('enquiries', { initialValue: [] })(
              <Select mode="multiple" style={{ width: '100%' }}>
                <Select.Option key="PRICE_GUIDE">Price Guide</Select.Option>
                <Select.Option key="OFI">Inspection Times</Select.Option>
                <Select.Option key="PROPERTY_SIZE">Property Size</Select.Option>
                <Select.Option key="CONTRACT_SALE">
                  Contract of Sale
                </Select.Option>
                <Select.Option key="SIMILAR_PROPERTIES">
                  Similar Properties
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          <span className="ml-4 text-gray-800 text-xs uppercase font-semibold inline-block mb-2">
            Your Message
          </span>
          <Form.Item className="w-full">
            {getFieldDecorator('message', {
              initialValue:
                'Hi, Please send me more information about the property.',
              rules: [],
            })(<Input.TextArea rows={7} />)}
          </Form.Item>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-full poppins text-overflow bg-red-300 border-red-300 hover:bg-red-700 hover:border-red-700 rounded-lg"
            loading={isEnquiring}
          >
            Enquire Now
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const CreatePropertyEnquiry = gql`
  mutation createPropertyEnquiry($input: CreatePropertyEnquiryInput!) {
    createPropertyEnquiry(input: $input) {
      id
      fullName
      email
    }
  }
`;

const PropertyEnquiryFormWithMutation = compose(
  Form.create(),
  graphql(CreatePropertyEnquiry, {
    props: ({ mutate }) => ({
      createPropertyEnquiry: input => mutate({ variables: { input } }),
    }),
  })
)(PropertyEnquiryForm);

export default PropertyEnquiryFormWithMutation;
