import React, { Component, Fragment } from 'react';
import { Button, Modal } from 'antd/lib/index';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo/index';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import GuestLoginForm from '../consumer/GuestLoginForm';
import { loginUser } from '../../actions';

class FollowAgent extends Component {
  static propTypes = {
    agentId: PropTypes.string.isRequired,
    userType: PropTypes.string,
    onLoginUser: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    isFollowed: PropTypes.bool,
    consumerFollowAgent: PropTypes.func,
  };

  static defaultProps = {
    onLoginUser: () => {},
    isAuthenticated: false,
    userType: null,
    isFollowed: false,
    consumerFollowAgent: () => {},
  };

  constructor(props) {
    super(props);
    const { isFollowed } = this.props;

    this.state = {
      consumerLoginDecisionModal: false,
      isFollowedByConsumer: isFollowed,
      isLoading: false,
    };
  }

  onLoginSuccess = loginData => {
    const { onLoginUser, agentId, consumerFollowAgent } = this.props;
    const { isFollowedByConsumer } = this.state;
    const { accessToken, expiresIn, refreshToken, user, type } = loginData;

    onLoginUser({
      accessToken,
      expiresIn,
      refreshToken,
      user,
      type,
    });
    this.setState({ isLoading: true });
    consumerFollowAgent(agentId).then(() => {
      this.setState(
        {
          isFollowedByConsumer: !isFollowedByConsumer,
          isLoading: false,
        },
        () => {
          this.closeConsumerLoginModal();
        }
      );
    });
  };

  showDecisionModal = bool => {
    this.setState({ consumerLoginDecisionModal: bool });
  };

  closeConsumerLoginModal = () => {
    this.setState({ consumerLoginDecisionModal: false });
  };

  toggleFollowButton = async () => {
    const { agentId, consumerFollowAgent, consumerUnfollowAgent } = this.props;
    const { isFollowedByConsumer } = this.state;

    this.setState({ isLoading: true });
    if (isFollowedByConsumer) {
      await consumerUnfollowAgent(agentId);
    } else {
      await consumerFollowAgent(agentId);
    }
    this.setState({
      isFollowedByConsumer: !isFollowedByConsumer,
      isLoading: false,
    });
  };

  handleFollowButtonClick = () => {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.toggleFollowButton();
    } else {
      this.showDecisionModal(true);
    }
  };

  render() {
    const { isAuthenticated, userType } = this.props;
    const {
      isFollowedByConsumer,
      isLoading,
      consumerLoginDecisionModal,
    } = this.state;

    const buttonText = isFollowedByConsumer ? 'Unfollow' : 'Follow';
    return (
      <div className="love-heart inline-block">
        <div style={{ marginTop: 5 }}>
          {isAuthenticated && userType !== 'CONSUMER' && (
            <Fragment>
              <span />
            </Fragment>
          )}
          {!isAuthenticated && (
            <Button
              size="large"
              type="primary"
              onClick={this.handleFollowButtonClick}
              loading={isLoading}
            >
              {buttonText}
            </Button>
          )}
          {isAuthenticated && userType === 'CONSUMER' && (
            <Button
              size="large"
              type="primary"
              onClick={this.handleFollowButtonClick}
              loading={isLoading}
            >
              {buttonText}
            </Button>
          )}
        </div>
        <Modal
          wrapClassName="vertical-center-modal"
          visible={consumerLoginDecisionModal}
          onOk={() => this.showDecisionModal(false)}
          onCancel={() => this.showDecisionModal(false)}
          footer={null}
          zIndex={1060}
        >
          <GuestLoginForm
            onSuccess={this.onLoginSuccess}
            onSuccessAccount={this.onLoginSuccess}
          />
        </Modal>
      </div>
    );
  }
}

const CONSUMER_FOLLOW_AGENT = gql`
  mutation consumerFollowAgent($agentId: ID!) {
    consumerFollowAgent(agentId: $agentId)
  }
`;
const CONSUMER_UNFOLLOW_AGENT = gql`
  mutation consumerUnfollowAgent($agentId: ID!) {
    consumerUnfollowAgent(agentId: $agentId)
  }
`;

const GuestLoginWithStateAndMutation = compose(
  graphql(CONSUMER_FOLLOW_AGENT, {
    name: 'consumerFollowAgent',
    props: ({ consumerFollowAgent }) => ({
      consumerFollowAgent: agentId =>
        consumerFollowAgent({ variables: { agentId } }),
    }),
  }),
  graphql(CONSUMER_UNFOLLOW_AGENT, {
    name: 'consumerUnfollowAgent',
    props: ({ consumerUnfollowAgent }) => ({
      consumerUnfollowAgent: agentId =>
        consumerUnfollowAgent({ variables: { agentId } }),
    }),
  }),
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      userType: state.auth.type,
    }),
    dispatch => ({
      onLoginUser(accessToken) {
        dispatch(loginUser(accessToken));
      },
    })
  )
)(FollowAgent);

export default GuestLoginWithStateAndMutation;
