import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Helmet from 'react-helmet';
import Scrollchor from 'react-scrollchor';
import { Anchor, Avatar, Col, Divider, Icon, Row } from 'antd';
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import coverPhoto from './cover.png';
import './AgentProfile.scss';
import AgentContactInformationModal from '../AgentContactInformationModal';
import Error from '../Error';
import Loading from '../LoadingDots';

const ScrollToContactAgentForm = (
  <Scrollchor to="#enquiry">contact agent form.</Scrollchor>
);

class AgentProfile extends Component {
  state = { showContactModal: false, showSocialLinksModal: false };

  onContactModalOk = () => {
    this.setState({ showContactModal: false });
  };

  showContactModal = () => {
    this.setState({ showContactModal: true });
    this.props.trackEnquiry('Attempt Phone Enquiry');
  };

  render() {
    const {
      data: { agent, loading, error },
    } = this.props;

    const { showContactModal } = this.state;

    if (loading) return <Loading className="full-page" />;
    if (error) return <Error error={error} />;

    const {
      firstName,
      lastName,
      profilePictureUrl,
      agencyName,
      agencyLogoUrl,
      facebook,
      cea,
    } = agent;

    const title = `Realestate SG | ${firstName} ${lastName}`;

    return (
      <Fragment>
        <Helmet defer={false}>
          <title>{title}</title>
        </Helmet>
        <AgentContactInformationModal
          visible={showContactModal}
          agent={agent}
          onOk={this.onContactModalOk}
        />
        <div className="sticky top-0 z-40">
          <Row
            type="flex"
            justify="end"
            className="navigation"
            style={{
              backgroundColor: 'rgba(239, 248, 255, 0.75)',
            }}
          >
            <Col xs={24}>
              <Anchor
                className="agency-page-anchor"
                style={{
                  backgroundColor: 'rgba(239, 248, 255, 0.75)',
                }}
              >
                <Anchor.Link
                  href="#about"
                  title={
                    <span className="font-bold text-gray-900">
                      About &nbsp;
                      {firstName}
                    </span>
                  }
                />
                <Anchor.Link
                  href="#properties"
                  title={
                    <span className="font-bold text-gray-900">Properties</span>
                  }
                />
                <Anchor.Link
                  href="#enquiry"
                  title={
                    <span className="font-bold text-gray-900">Contact</span>
                  }
                />
              </Anchor>
            </Col>
          </Row>
        </div>
        <Row
          className="bg-gray-100 agent-banner py-5"
          type="flex"
          justify="center"
          style={{
            backgroundImage: `url(${coverPhoto})`,
          }}
          align="middle"
          id="about"
        >
          <Col xs={23} md={20} xl={18} className="z-10">
            <div className="text-center lg:py-8 md:py-4 py-8">
              {profilePictureUrl ? (
                <Avatar
                  src={profilePictureUrl || null}
                  size="large"
                  icon="user"
                  className="md:w-48 w-24 md:h-48 h-24 border-4"
                />
              ) : (
                <div className=" md:w-48 w-24 md:h-48 h-24 bg-gray-500 my-8 rounded-full inline-block relative">
                  <Icon
                    type="user"
                    className="text-gray-100 text-5xl center-align"
                  />
                </div>
              )}
            </div>
            <div className="pb-2">
              <h1 className="md:text-left md:text-3xl sm:text-2xl text-xl text-center text-white text-shadow">
                {`${firstName} ${lastName}`}
              </h1>
              {cea && cea.length && (
                <div className="font-bold text-white text-shadow-sm">
                  <span>CEA #</span>{' '}
                  <a
                    href={`https://www.cea.gov.sg/public-register?category=Salesperson&registrationNumber=${cea}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cea}
                  </a>
                </div>
              )}
              <Divider className="bg-gray-500 my-2" />
              <Row
                type="flex"
                className="md:flex-row flex-col items-center actions md:justify-between justify-center"
              >
                {agencyName && (
                  <Col>
                    <Avatar
                      alt={`${agencyName}`}
                      size={80}
                      icon="shop"
                      src={agencyLogoUrl}
                      className="shadow border-2 border-white"
                    />
                    <h3 className="inline-block ml-2 text-center text-white text-shadow-sm">
                      {agencyName}
                    </h3>
                  </Col>
                )}
                <Col className="md:text-right text-center">
                  <ul className="flex items-center">
                    <li>
                      <div className="text-center m-2 font-bold text-white text-shadow-sm" />
                    </li>
                    <li>
                      <Scrollchor to="#enquiry">
                        <div className="text-center m-2 font-bold text-white text-shadow-sm">
                          <div>
                            <FA icon={faEnvelope} />
                          </div>
                          <p className="md:text-sm text-xs md:block hidden">
                            Enquire
                          </p>
                        </div>
                      </Scrollchor>
                    </li>
                    <li>
                      <div
                        className="text-center m-2 font-bold cursor-pointer text-shadow-sm text-white"
                        onClick={this.showContactModal}
                        aria-hidden
                      >
                        <div>
                          <FA icon={faPhone} />
                        </div>
                        <p className="md:text-sm text-xs md:block hidden">
                          Contacts
                        </p>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          justify="center"
          type="flex"
          className="md:py-20 py-12 bg-gray-100"
        >
          <Col
            className="flex flex-col justify-center items-center"
            xs={22}
            sm={22}
            md={22}
            lg={18}
            xl={14}
            xxl={10}
          >
            <h2 className="text-center md:mb-4 mb-2 md:text-xl text-lg">
              {`About ${firstName} ${lastName}`}
            </h2>
            <p className="poppins text-gray-700 sm:text-base text-sm py-1 font-normal text-center">
              {firstName}
              &nbsp;
              {lastName}
              &nbsp;is a real estate professional representing&nbsp;
              {agencyName}. If you would like to speak with&nbsp;
              {agencyName}
              &nbsp;regarding houses for sale, property to rent or are
              considering listing your property for sale, simply complete
              the&nbsp;
              {ScrollToContactAgentForm}
            </p>
            {facebook && (
              <a
                className="agent-facebook-link"
                href={facebook}
                target="_blank"
              >
                <FA icon={faFacebook} />
              </a>
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const AGENT_DATA = gql`
  query agentQuery($id: ID!) {
    agent(id: $id) {
      id
      firstName
      lastName
      mobileNumber
      email
      profilePictureUrl
      agencyName
      agencyLogoUrl
      publicEmail
      isActive
      isVerified
      facebook
      cea
    }
  }
`;
const AgentProfileWithData = graphql(AGENT_DATA, {
  options: ({ id }) => ({ variables: { id } }),
})(AgentProfile);

export default AgentProfileWithData;
