import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../actions';
import { Button, message } from 'antd';
import { faComments } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import CreateTopicModal from '../modals/CreateTopicModal';
import ConsumerLoginModal from '../BookmarkProperty/ConsumerLoginModal';

class PropertyCreateTopic extends Component {
  state = {
    visible: false,
    isLoginModalVisible: false,
  };

  toggleModal = () => {
    this.setState(prevState => ({ visible: !prevState.visible }));
  };

  toggleLoginModal = () => {
    this.setState(prevState => ({
      isLoginModalVisible: !prevState.isLoginModalVisible,
    }));
  };

  handleLoginConsumer = loginData => {
    const { onLoginUser } = this.props;
    const { accessToken, expiresIn, refreshToken, user, type } = loginData;

    onLoginUser({
      accessToken,
      expiresIn,
      refreshToken,
      user,
      type,
    });

    message.success('Login successfully.');

    this.toggleLoginModal();
  };

  render() {
    const { propertyId, isAuthenticated, userType } = this.props;
    const { visible, isLoginModalVisible } = this.state;

    const btnAction =
      isAuthenticated && userType === 'CONSUMER'
        ? this.toggleModal
        : this.toggleLoginModal;

    return (
      <Fragment>
        <Button onClick={btnAction}>
          <FA icon={faComments} className="text-lg" />
        </Button>
        <CreateTopicModal
          onCancel={this.toggleModal}
          propertyId={propertyId}
          visible={visible}
        />
        <ConsumerLoginModal
          visible={isLoginModalVisible}
          onCancel={this.toggleLoginModal}
          onSuccess={this.handleLoginConsumer}
        />
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userType: state.auth.type,
  }),
  dispatch => ({
    onLoginUser(accessToken) {
      dispatch(loginUser(accessToken));
    },
  })
)(PropertyCreateTopic);
