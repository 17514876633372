import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../LoadingDots';

const SuspenseRoute = ({ component: RenderComponent, suspense, ...rest }) =>
  suspense === true ? (
    <Route
      {...rest}
      component={props => (
        <Suspense fallback={<Loading height="100vh" />}>
          <RenderComponent style={{ width: '100%' }} {...rest} {...props} />
        </Suspense>
      )}
    />
  ) : (
    <Route
      {...rest}
      component={props => <RenderComponent {...rest} {...props} />}
    />
  );

SuspenseRoute.propTypes = {
  component: PropTypes.any,
  suspense: PropTypes.bool,
};

SuspenseRoute.defaultProps = {
  component: null,
  suspense: false,
};

export default SuspenseRoute;
