import React, { Component } from 'react';
import { Tag, List } from 'antd';

class MrtStations extends Component {
  handleOnClick = station => {
    this.props.handleChangeMarkerCenter({
      id: station.id,
      lat: station.lat,
      lng: station.lng,
    });
  };

  getColor = color => {
    switch (color) {
      case 'OTHERS':
        return '#888885';
      case 'YELLOW':
        return '#FED766';
      default:
        return color;
    }
  };

  render() {
    const { mrtStations } = this.props;

    return (
      <div>
        <List
          dataSource={mrtStations}
          renderItem={station => (
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.handleOnClick(station);
                    }}
                  >
                    {station.name}
                  </a>
                }
                description={
                  <Tag color={this.getColor(station.color)}>
                    {station.number}
                  </Tag>
                }
              />
              <div>
                <p>{station.distance.toFixed(2)} km</p>
              </div>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

export default MrtStations;
