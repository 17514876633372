import React, { PureComponent } from 'react';
import LoadingDots from '../../LoadingDots';
import Error from '../../Error';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, message, Upload } from 'antd';
import FollowConsumerButton from '../../FollowConsumerButton';
import FriendRequestButton from '../FriendRequestButton';
import './ConsumerProfileBanner.scss';
import ConsumerProfileFollowing from '../ConsumerProfileStatistics';
import ConsumerAvatarModal from '../ConsumerAvatarModal';
import { ConsumerPageQuery } from './Operations.graphql';

class ConsumerProfileBanner extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    contributorUploadImage: PropTypes.func,
  };

  static defaultProps = {
    data: {},
    contributorUploadImage: () => {},
  };

  state = {
    uploading: null,
    showAvatarModal: false,
  };

  customRequest = (key, { file, onSuccess, onError }) =>
    new Promise(resolve => {
      message.config({ top: 24 });
      const imageFormats = ['image/jpeg', 'image/png'];
      const exceededFileSize =
        key === 'profileImage' ? file.size > 2000000 : file.size > 3000000;
      if (!imageFormats.includes(file.type)) {
        return false;
      }
      if (exceededFileSize) {
        return false;
      }
      this.setState({ uploading: key });
      const {
        contributorUploadImage,
        data: { consumerPage, refetch },
      } = this.props;
      contributorUploadImage(consumerPage.id, key, file)
        .then(({ data: { contributorUploadImage } }) => {
          onSuccess(contributorUploadImage, file);
          refetch();
          setTimeout(() => {
            this.setState({ uploading: null });
          }, 3000);
          resolve(true);
        })
        .catch(() => {
          onError();
          this.setState({ uploading: null });
          message.destroy();
          message.error('Upload failed');
        });
    });

  handleUpload = (key, info) => {
    message.config({ top: 24 });
    const { fileList } = info;

    const imageFormats = ['image/jpeg', 'image/png'];
    const newFileList = fileList.filter(item =>
      imageFormats.includes(item.type)
    );
    const validFileSize =
      key === 'profile'
        ? fileList.filter(item => item.size <= 2000000)
        : fileList.filter(item => item.size <= 3000000);

    if (newFileList.length < 1) {
      message.destroy();
      message.error('You can only upload JPG or PNG file!');
      return false;
    }

    if (validFileSize.length < 1) {
      message.destroy();
      message.error(
        `Image must be smaller than ${key === 'profile' ? '(2MB)' : '(3MB)'}.`
      );
      return false;
    }
  };

  handleAvatarModal = () => {
    this.setState(prevState => ({
      showAvatarModal: !prevState.showAvatarModal,
    }));
  };

  render() {
    const {
      data: { consumerPage, loading, error, refetch },
      contributorUploadImage,
    } = this.props;
    const { uploading, showAvatarModal } = this.state;

    if (loading) {
      return (
        <div className="h-64 relative bg-blue-100 mb-4">
          <LoadingDots className="center-align" />
        </div>
      );
    }
    if (error) return <Error error={error} />;

    const {
      owner: { firstName, lastName, initials, id, profilePicture, friendInfo },
      coverImage,
      pageId,
      isPageOwner,
      isFollowed,
    } = consumerPage || {};
    return (
      <main
        className="p-4 pt-6 z-0 ConsumerProfileBanner transition relative"
        style={{
          background: coverImage
            ? `url(${coverImage}) center top / cover no-repeat`
            : '#4ac7f4',
        }}
      >
        {!coverImage
          ? isPageOwner && (
              <div className="absolute z-20 pt-3 pl-3 inset-0 sm:pt-0 sm:pl-0 sm:pr-4 sm:right-0 sm:inset-auto lg:inset-auto lg:w-full lg:text-center lg:pt-8 lg:pr-0">
                <Upload
                  accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                  showUploadList={false}
                  customRequest={info => {
                    this.customRequest('coverImage', info);
                  }}
                  onChange={info => {
                    this.handleUpload('cover', info);
                  }}
                >
                  <Button loading={uploading === 'coverImage'} icon="upload">
                    {uploading === 'coverImage'
                      ? 'Uploading'
                      : 'Upload Cover Photo'}
                  </Button>
                </Upload>
              </div>
            )
          : isPageOwner && (
              <div className="absolute z-20 pt-3 pl-3 inset-0 sm:pt-0 sm:pl-0 sm:pr-4 sm:right-0 sm:inset-auto">
                <Upload
                  accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                  showUploadList={false}
                  customRequest={info => {
                    this.customRequest('coverImage', info);
                  }}
                  onChange={info => {
                    this.handleUpload('cover', info);
                  }}
                >
                  <Button loading={uploading === 'coverImage'} icon="edit">
                    {uploading === 'coverImage'
                      ? 'Uploading'
                      : 'Edit Cover Photo'}
                  </Button>
                </Upload>
              </div>
            )}
        <section className="bottom-0 left-0 absolute flex-col flex sm:flex-row items-center w-full justify-center">
          {!profilePicture && (
            <div className="noAvatar">
              <div className="noAvatarButtonWrapper">
                {/*{isPageOwner ? (*/}
                {/*<Button*/}
                {/*size="small"*/}
                {/*type="primary"*/}
                {/*icon="camera"*/}
                {/*onClick={this.handleAvatarModal}*/}
                {/*>*/}
                {/*Upload Picture*/}
                {/*</Button>*/}
                {/*) : (*/}
                <span
                  className="md:text-5xl text-3xl"
                  style={{ color: '#4ac7f4' }}
                >
                  {initials}
                </span>
                {/*)}*/}
              </div>
            </div>
          )}
          {profilePicture && (
            <div
              className="relative hasAvatar"
              style={{
                background: `url(${profilePicture}) center / cover no-repeat`,
              }}
            >
              {/*{isPageOwner && (*/}
              {/*<div className="editProfileImageButton absolute z-10">*/}
              {/*<Button*/}
              {/*shape="circle"*/}
              {/*icon="edit"*/}
              {/*onClick={this.handleAvatarModal}*/}
              {/*/>*/}
              {/*</div>*/}
              {/*)}*/}
            </div>
          )}
          {/*<ConsumerAvatarModal*/}
          {/*profileImage={profileImageS3}*/}
          {/*handleAvatarModal={this.handleAvatarModal}*/}
          {/*showAvatarModal={showAvatarModal}*/}
          {/*contributorUploadImage={contributorUploadImage}*/}
          {/*refetch={refetch}*/}
          {/*consumerPage={consumerPage}*/}
          {/*/>*/}
          <div className="flex-grow p-2 text-center sm:text-left md:pt-12 md:w-auto w-full">
            <h2 className="text-white text-overflow">
              {firstName} {lastName}{' '}
            </h2>
            <h4 className="text-gray-100 text-overflow py-1 font-medium">
              @{pageId}
            </h4>
            <div className="flex flex-wrap justify-center sm:justify-between items-center">
              <ConsumerProfileFollowing pageId={pageId} />
              {!isPageOwner && (
                <div>
                  <span className="mx-1">
                    <FriendRequestButton
                      refetch={refetch}
                      isPageOwner={isPageOwner}
                      pageOwnerId={id}
                      friendInfo={friendInfo}
                    />
                  </span>
                  <span className="mx-1">
                    <FollowConsumerButton
                      refetch={refetch}
                      consumerId={id}
                      isFollowed={isFollowed}
                      isPageOwner={isPageOwner}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const CONTRIBUTOR_UPLOAD_IMAGE_MUTATION = gql`
  mutation contributorUploadImage($id: ID!, $type: String, $file: Upload!) {
    contributorUploadImage(id: $id, type: $type, file: $file)
  }
`;

const ConsumerProfileBannerWithMutation = compose(
  graphql(ConsumerPageQuery, {
    options: ({ pageId }) => ({
      variables: {
        pageId: pageId || null,
      },
    }),
  }),
  graphql(CONTRIBUTOR_UPLOAD_IMAGE_MUTATION, {
    props: ({ mutate }) => ({
      contributorUploadImage: (id, type, file) =>
        mutate({
          variables: {
            id,
            type,
            file,
          },
        }),
    }),
  })
)(ConsumerProfileBanner);

export default ConsumerProfileBannerWithMutation;
