import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import ViewAllDistrictQuery from '../../../../queries/ViewAllDistrictQuery.graphql';
import Media from 'react-media';
import { Popover, Button, Icon, Select } from 'antd';
import './LocationFilter.scss';
import Error from '../../../Error';
import LoadingDots from '../../../LoadingDots';

const { Option, OptGroup } = Select;

const areas = {
  central: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21],
  east: [16, 17, 18],
  north: [19, 20, 25, 26, 27, 28],
  west: [22, 23],
};

const PopoverWrapper = props => {
  return (
    <Media query="(max-width: 425px)">
      {matches =>
        matches ? (
          <Popover placement="bottomRight" {...props}>
            {props.children}
          </Popover>
        ) : (
          <Popover placement="bottom" {...props}>
            {props.children}
          </Popover>
        )
      }
    </Media>
  );
};

class LocationFilter extends Component {
  static propTypes = {
    region: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    region: [],
    onChange: () => {},
  };

  state = {
    region: [],
  };

  handleOnApply = () => {
    this.props.onChange(this.state.region);
  };

  handleOnClear = () => {
    this.props.onChange([]);
  };

  render() {
    const { error, loading, viewAllDistrict: districts } = this.props.data;
    const { region } = this.props;
    let defaultRegions = [];

    if (error) {
      return <Error />;
    }

    if (loading) {
      return <LoadingDots className="w-full text-center p-2 ml-2" />;
    }

    if (region) {
      defaultRegions = districts
        .filter(district => region.includes(district.id))
        .map(district => district.districtNumber);
    }

    const sortedDistricts = sortBy(districts, 'districtNumber');

    return (
      <PopoverWrapper
        content={
          <>
            <div className="flex justify-start py-4">
              <Select
                defaultValue={defaultRegions}
                mode="multiple"
                allowClear
                filterOption={false}
                className="agent-search-location-filter"
                placeholder="Property Location"
                onChange={value => {
                  this.setState({ region: value });
                }}
              >
                <OptGroup label="Central">
                  {sortedDistricts
                    .filter(district =>
                      areas.central.includes(district.districtNumber)
                    )
                    .map(district => (
                      <Option key={district.id} value={district.districtNumber}>
                        {district.full}
                      </Option>
                    ))}
                </OptGroup>
                <OptGroup label="East">
                  {sortedDistricts
                    .filter(district =>
                      areas.east.includes(district.districtNumber)
                    )
                    .map(district => (
                      <Option key={district.id} value={district.districtNumber}>
                        {district.full}
                      </Option>
                    ))}
                </OptGroup>
                <OptGroup label="North">
                  {sortedDistricts
                    .filter(district =>
                      areas.north.includes(district.districtNumber)
                    )
                    .map(district => (
                      <Option key={district.id} value={district.districtNumber}>
                        {district.full}
                      </Option>
                    ))}
                </OptGroup>
                <OptGroup label="West">
                  {sortedDistricts
                    .filter(district =>
                      areas.west.includes(district.districtNumber)
                    )
                    .map(district => (
                      <Option key={district.id} value={district.districtNumber}>
                        {district.full}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </div>
            <div className="flex justify-end mt-1">
              <Button className="mr-2" onClick={this.handleOnClear}>
                Clear
              </Button>
              <Button type="primary" onClick={this.handleOnApply}>
                Apply
              </Button>
            </div>
          </>
        }
        trigger="click"
      >
        {region && region.length > 0 ? (
          <Button type="primary" size="large">
            <span className="truncate" style={{ width: 200 }}>
              {districts
                .filter(district => region.includes(district.id))
                .map(district => district.full)}
            </span>
            <Icon type="caret-down" />
          </Button>
        ) : (
          <Button size="large">
            Location <Icon type="caret-down" />
          </Button>
        )}
      </PopoverWrapper>
    );
  }
}

const LocationFilterWithQuery = graphql(ViewAllDistrictQuery, {})(
  LocationFilter
);

export default LocationFilterWithQuery;
