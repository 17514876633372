import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faWhatsapp,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import cls from 'classnames';
import { socialTracker } from '../../utils/analytics';

export default function({
  propertyShareLink,
  property,
  className,
  shortId,
  location,
}) {
  const { title } = property;
  const shareProps = {
    url: propertyShareLink,
    title,
    className: 'block mr-4',
  };
  const faIconSize = '2x';
  const containerCls = cls('flex flex-col', className);

  return (
    <div className={containerCls}>
      <span className="block text-sm text-gray-700 mb-2">
        Tell others about this property
      </span>
      <div className="flex items-center flex-wrap p2">
        <FacebookShareButton
          url={shareProps.url}
          quote={shareProps.title}
          className={shareProps.className}
          onShareWindowClose={() => {
            socialTracker({
              socialNetwork: 'Facebook',
              socialAction: 'Share',
              socialTarget: location.pathname,
              pageContentId: shortId,
            });
          }}
        >
          <FA icon={faFacebook} size={faIconSize} />
        </FacebookShareButton>
        <WhatsappShareButton
          {...shareProps}
          onShareWindowClose={() => {
            socialTracker({
              socialNetwork: 'WhatsApp',
              socialAction: 'Share',
              socialTarget: location.pathname,
              pageContentId: shortId,
            });
          }}
        >
          <FA icon={faWhatsapp} size={faIconSize} />
        </WhatsappShareButton>
        <TwitterShareButton
          {...shareProps}
          onShareWindowClose={() => {
            socialTracker({
              socialNetwork: 'Twitter',
              socialAction: 'Share',
              socialTarget: location.pathname,
              pageContentId: shortId,
            });
          }}
        >
          <FA icon={faTwitter} size={faIconSize} />
        </TwitterShareButton>
        <LinkedinShareButton
          {...shareProps}
          onShareWindowClose={() => {
            socialTracker({
              socialNetwork: 'LinkedIn',
              socialAction: 'Share',
              socialTarget: location.pathname,
              pageContentId: shortId,
            });
          }}
        >
          <FA icon={faLinkedin} size={faIconSize} />
        </LinkedinShareButton>
        <EmailShareButton
          url={shareProps.url}
          subject={shareProps.title}
          className="block"
          beforeOnClick={() => {
            socialTracker({
              socialNetwork: 'Email',
              socialAction: 'Share',
              socialTarget: location.pathname,
              pageContentId: shortId,
            });
          }}
        >
          <FA icon={faEnvelope} size={faIconSize} />
        </EmailShareButton>
      </div>
    </div>
  );
}
