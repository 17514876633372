import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'antd';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { compose } from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import './PropertyAdvancedSearch.scss';
import SearchPageQueryParameterParser from '../SearchPageQueryParameterParser';
import LocationFilterSelect from '../LocationFilterSelect';
import NumericRange from '../NumericRange';
import SearchMoreFiltersModal from '../SearchMoreFiltersModal';
import PropertyTypeFilterSelect from '../PropertyTypeFilterSelect';
import MaxTagCountKeywordSearchProvider from '../MaxTagCountKeywordSearchProvider';
import MapViewSwitch from '../MapViewSwitch';

export const SALE_VALUES = [
  200000,
  300000,
  400000,
  500000,
  600000,
  700000,
  800000,
  900000,
  1000000,
  1250000,
  1500000,
  2000000,
  2500000,
  3000000,
  4000000,
  5000000,
  6000000,
  8000000,
  10000000,
  15000000,
  20000000,
  30000000,
  50000000,
];

export const RENT_VALUES = [
  500,
  1000,
  1500,
  2000,
  2500,
  3000,
  3500,
  4000,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000,
  12000,
  15000,
  20000,
  30000,
  40000,
  50000,
];

class PropertyAdvancedSearch extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object,
    client: PropTypes.object,
    searchOptions: PropTypes.shape({
      keyword: PropTypes.string,
      showMap: PropTypes.bool,
      ofiTime: PropTypes.string,
      group: PropTypes.string,
      type: PropTypes.shape({
        group: PropTypes.arrayOf(PropTypes.string),
        category: PropTypes.arrayOf(PropTypes.string),
      }),
      bed: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      price: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      landArea: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      floorArea: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      bathroom: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      mapLocation: PropTypes.shape({
        radius: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      location: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
      }),
      sorting: PropTypes.string,
    }),
  };

  static defaultProps = {
    changeUrl: () => {},
    searchOptions: {},
  };

  state = {
    queryString: {},
    showMoreFilters: false,
    prevScrollpos: window.pageYOffset,
    visibleMoreFilter: true,
  };

  getValue = value => {
    const {
      form: { getFieldValue },
    } = this.props;

    if (
      getFieldValue(value) !== undefined &&
      (getFieldValue(value).min !== undefined ||
        getFieldValue(value).max !== undefined)
    ) {
      return getFieldValue(value);
    }
    if (
      getFieldValue(`${value}Modal`) !== undefined &&
      (getFieldValue(`${value}Modal`).min !== undefined ||
        getFieldValue(`${value}Modal`).max !== undefined)
    ) {
      return getFieldValue(`${value}Modal`);
    }
    return [];
  };

  getQueryString = () => {
    const {
      searchOptions,
      form: { getFieldValue },
    } = this.props;
    const searchData = getFieldValue('keyword');
    const type = getFieldValue('type') || { group: [], category: [] };
    const furnishType = getFieldValue('furnishType');
    const price = this.getValue('price');
    const bed = this.getValue('bed');
    const bathroom = this.getValue('bathroom');
    const floorArea = this.getValue('floorArea');
    const landArea = this.getValue('landArea');

    const { showMap, ofiTime, group } = searchOptions;

    let keyword;
    if (searchData && searchData.key.startsWith('k:')) {
      keyword = searchData.key.slice(2);
    }
    let location;
    if (searchData && !searchData.key.startsWith('k:')) {
      const parts = searchData.key.split(':');
      location = { type: parts[0], name: parts[1] };
    }

    let filters = {
      keyword,
      propertyGroup: type.group,
      propertyCategory: type.category,
      furnishType,
      bedMin: bed.min,
      bedMax: bed.max,
      bathroomMin: bathroom.min,
      bathroomMax: bathroom.max,
      floorAreaMin: floorArea.min,
      floorAreaMax: floorArea.max,
      landAreaMin: landArea.min,
      landAreaMax: landArea.max,
      showMap: showMap === true ? true : undefined,
      ofiTime,
    };

    if (location) {
      filters = {
        ...filters,
        location: location.name,
        locationType: location.type,
      };
    }

    if (group.toUpperCase() === 'RENT') {
      filters = {
        ...filters,
        priceMax: price.max === 2000 ? undefined : price.max,
        priceMin: price.min === 10 ? undefined : price.min,
      };
    } else {
      filters = {
        ...filters,
        priceMax: price.max === 5000000 ? undefined : price.max,
        priceMin: price.min === 100000 ? undefined : price.min,
      };
    }

    return filters;
  };

  buildQueryString = () => {
    const filters = this.getQueryString();
    return qs.stringify(filters);
  };

  onPressEnter = () => {
    const {
      form: { getFieldValue, setFieldsValue },
    } = this.props;
    const {
      history,
      searchOptions: { group, locations },
    } = this.props;
    const currentLocations = !isEmpty(locations)
      ? locations.map(loc => ({
          key: loc,
          label: loc,
        }))
      : [];
    const hasSearchValue = !isEmpty(getFieldValue('keyword'))
      ? getFieldValue('keyword')
      : currentLocations;

    if (hasSearchValue && hasSearchValue.length > 0) {
      setFieldsValue({ keyword: hasSearchValue });
      const path = `${group.toLowerCase()}/search`;
      history.push(`/${path}?${this.buildQueryString()}`);
    }
  };

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visibleMoreFilter = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visibleMoreFilter,
    });
  };

  onChangeSearchOptionsShowMap = showMap => {
    const { changeUrl, searchOptions } = this.props;
    changeUrl({
      ...searchOptions,
      showMap,
    });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleApplyFilters = (event, data) => {
    event.preventDefault();

    const {
      form: { setFieldsValue },
    } = this.props;

    this.setState({ showMoreFilters: false }, () => {
      setFieldsValue({
        furnishType: data.furnishTypeModal,
        bathroom: data.bathroomModal,
        bed: data.bedModal,
        price: data.priceModal,
        floorArea: data.floorAreaModal,
        landArea: data.landAreaModal,
      });
    });
  };

  showMoreFiltersModal = () => {
    this.setState({ showMoreFilters: true });
  };

  closeMoreFiltersModal = () => {
    this.setState({ showMoreFilters: false });
  };

  render() {
    const { form, searchOptions } = this.props;
    const { getFieldDecorator, getFieldsValue } = form;
    const { showMoreFilters } = this.state;

    getFieldDecorator('furnishType', {
      initialValue: searchOptions.furnishType || ['FULL', 'SEMI', 'NONE'],
    });

    getFieldDecorator('bed', {
      initialValue: {
        min: searchOptions.bed.min,
        max: searchOptions.bed.max,
      } || {
        min: 0,
        max: 12,
      },
    });

    getFieldDecorator('bathroom', {
      initialValue: {
        min: searchOptions.bathroom.min,
        max: searchOptions.bathroom.max,
      } || {
        min: 0,
        max: 6,
      },
    });

    getFieldDecorator('floorArea', {
      initialValue: {
        min: searchOptions.floorArea.min,
        max: searchOptions.floorArea.max,
      } || {
        min: 0,
        max: 10000,
      },
    });

    getFieldDecorator('landArea', {
      initialValue: {
        min: searchOptions.landArea.min,
        max: searchOptions.landArea.max,
      } || {
        min: 0,
        max: 10000,
      },
    });

    let locationValue;
    if (searchOptions.location) {
      locationValue = {
        key: `${searchOptions.location.type}:${searchOptions.location.name}`,
        label: searchOptions.location.name,
      };
    } else if (searchOptions.keyword) {
      locationValue = {
        key: `k:${searchOptions.keyword}`,
        label: searchOptions.keyword,
      };
    }

    let initialPropertyType = {
      group: searchOptions.type.group,
      category: searchOptions.type.category,
    } || {
      group: [],
      category: [],
    };

    if (searchOptions.segment === 'COMMERCIAL') {
      initialPropertyType = {
        group: searchOptions.commercialType,
        category: searchOptions.commercialType,
      } || {
        group: [],
        category: [],
      };
    }

    return (
      <>
        <SearchMoreFiltersModal
          visible={showMoreFilters}
          onClose={this.closeMoreFiltersModal}
          onOk={this.handleApplyFilters}
          data={{
            ...getFieldsValue([
              'furnishType',
              'price',
              'bed',
              'bathroom',
              'floorArea',
              'landArea',
            ]),
          }}
          type={searchOptions.group}
          segment={searchOptions.segment}
        />
        <Form>
          <div className="advanced-search overflow-hidden p-2">
            <Row type="flex" justify="start" gutter={10}>
              <Col xs={8} sm={8} md={5} lg={4} xl={3} xxl={3} className="h-12">
                <Form.Item>
                  {getFieldDecorator('type', {
                    initialValue: initialPropertyType,
                  })(
                    <PropertyTypeFilterSelect segment={searchOptions.segment} />
                  )}
                </Form.Item>
              </Col>
              <Col xs={16} sm={16} md={10} lg={9} xl={7} xxl={8}>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="search-keyword border-0 rounded shadow-sm "
                >
                  <Col span={24} className="h-12">
                    <MaxTagCountKeywordSearchProvider
                      xs={0}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      xxl={2}
                    >
                      {maxTagCount => (
                        <Form.Item>
                          {getFieldDecorator('keyword', {
                            initialValue: locationValue,
                          })(
                            <LocationFilterSelect
                              maxTagCount={maxTagCount}
                              onPressEnter={this.onPressEnter}
                            />
                          )}
                        </Form.Item>
                      )}
                    </MaxTagCountKeywordSearchProvider>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} sm={0} md={9} lg={8} xl={5} xxl={5}>
                <Form.Item className="w-full search-option xl:inline-block border p-1 shadow-sm bg-white rounded h-12">
                  {getFieldDecorator('price', {
                    initialValue: {
                      min: searchOptions.price.min,
                      max: searchOptions.price.max,
                    } || {
                      min: 0,
                      max: 2000000,
                    },
                  })(
                    <NumericRange
                      options={
                        searchOptions.group === 'BUY'
                          ? SALE_VALUES
                          : RENT_VALUES
                      }
                    >
                      {({ min, max }) => {
                        let priceContent;
                        if (min && !max) {
                          priceContent = (
                            <span className="block">Min S$ {min}</span>
                          );
                        }
                        if (min && max) {
                          priceContent = (
                            <span className="block">
                              S$ {min} - {max}
                            </span>
                          );
                        }
                        if (!min && max) {
                          priceContent = (
                            <span className="block">Max S$ {max}</span>
                          );
                        }
                        if (!min && !max) {
                          priceContent = (
                            <span className="block">Any Price</span>
                          );
                        }
                        return (
                          <div className="px-2 text-lg text-gray-800 cursor-pointer">
                            {priceContent}
                          </div>
                        );
                      }}
                    </NumericRange>
                    // <PriceFilterRangeFormItem
                    //   miniInfo={false}
                    //   type={searchOptions.group}
                    // />
                  )}
                </Form.Item>
              </Col>

              <Col
                xs={24}
                sm={4}
                md={4}
                lg={3}
                xl={9}
                xxl={8}
                // className="pr-2 xl:pt-0 pt-2"
                className={classnames('more-filters-wrapper', {
                  'more-filters-wrapper--hidden': !this.state.visibleMoreFilter,
                })}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <Button
                      size="large"
                      className="more-filters-btn bg-transparent border-none shadow-none"
                      onClick={this.showMoreFiltersModal}
                    >
                      <span className="inline-block font-semibold">
                        More filters
                      </span>
                    </Button>
                  </div>
                  <div className="xl:inline hidden">
                    <MapViewSwitch
                      defaultChecked={!!searchOptions.showMap}
                      onChange={this.onChangeSearchOptionsShowMap}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </>
    );
  }
}

const PropertyAdvancedSearchWithData = compose(withRouter)(
  Form.create({
    onValuesChange: (props, changedValues, allValues) => {
      props.onChange(changedValues, allValues);
    },
  })(PropertyAdvancedSearch)
);

export default props => (
  <SearchPageQueryParameterParser {...props}>
    {searchOptions => (
      <PropertyAdvancedSearchWithData {...searchOptions} {...props} />
    )}
  </SearchPageQueryParameterParser>
);
