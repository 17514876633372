import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Affix } from 'antd';
import ConsumerProfileBanner from '../../components/consumer/ConsumerProfileBanner';
import ConsumerProfileFriendList from '../../components/consumer/ConsumerProfileFriendList';
import ConsumerAbout from '../../components/consumer/ConsumerAbout';
import ConsumerProfileFollowersList from '../../components/consumer/ConsumerProfileFollowersList';
import ConsumerProfilePostForm from '../../components/consumer/ConsumerProfilePostForm';
import ConsumerProfilePosts from '../../components/consumer/ConsumerProfilePosts';
import './ConsumerProfilePage.scss';

class ConsumerProfilePage extends Component {
  static propTypes = {
    pageId: PropTypes.string,
    isPageOwner: PropTypes.bool,
  };

  static defaultProps = {
    pageId: null,
    isPageOwner: false,
  };

  render() {
    const { pageId, isPageOwner } = this.props;

    return (
      <div className="bg-gray-200" style={{ minHeight: '210vh' }}>
        <div className="ConsumerProfilePage">
          <main className="sm:bg-transparent bg-white">
            <ConsumerProfileBanner pageId={pageId} editable={isPageOwner} />
          </main>
          <Row type="flex" gutter={24} className="h-full" justify="center">
            <Col xs={0} sm={0} md={9} lg={9} xl={9}>
              <Affix offsetTop={5}>
                <ConsumerAbout pageId={pageId} editable={isPageOwner} />
                <div className="pt-3 pb-1">
                  <ConsumerProfileFollowersList
                    pageId={pageId}
                    editable={isPageOwner}
                  />
                </div>
                <ConsumerProfileFriendList
                  pageId={pageId}
                  editable={isPageOwner}
                />
              </Affix>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
              {isPageOwner && (
                <ConsumerProfilePostForm
                  pageId={pageId}
                  editable={isPageOwner}
                />
              )}
              <ConsumerProfilePosts pageId={pageId} editable={isPageOwner} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ConsumerProfilePage;
