import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import './MapViewSwitch.scss';

const MapViewSwitch = React.memo(props => (
  <Switch
    style={{ width: 70 }}
    checkedChildren={
      <span>
        Map
        <FA className="text-base mr-1" icon={faMapMarkedAlt} />
      </span>
    }
    unCheckedChildren={
      <span>
        Map
        <FA className="text-base mr-1" icon={faMapMarkedAlt} />
      </span>
    }
    defaultChecked={props.defaultChecked}
    checked={props.defaultChecked}
    onChange={props.onChange}
    className="MapViewSwitch"
  />
));

MapViewSwitch.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

MapViewSwitch.propTypes = { className: PropTypes.string };

MapViewSwitch.defaultProps = { className: 'w-full text-center' };

export default MapViewSwitch;
