const initialState = {
  isAuthenticated: false,
  accessToken: null,
  accessTokenExp: null,
  role: null,
  user: null,
  refreshToken: null,
  type: null,
  profilePageId: null,
  isLoginAsMode: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_USER': {
      const {
        accessToken,
        expiresIn,
        refreshToken,
        user,
        role,
        type,
        isLoginAsMode,
      } = action.accessToken;

      if (localStorage) {
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('access_token_exp', expiresIn);
        localStorage.setItem('refresh_token', refreshToken);
      }

      const userRole = role || '';

      return {
        ...state,
        accessToken,
        refreshToken,
        user,
        profilePageId: user ? user.profilePageId : null,
        role: userRole,
        isAuthenticated: true,
        accessTokenExp: expiresIn,
        type,
        isLoginAsMode,
      };
    }
    case 'LOGOUT_USER': {
      if (state.isLoginAsMode === true) {
        return {
          ...state,
        };
      }

      if (localStorage) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_exp');
        localStorage.removeItem('refresh_token');

        localStorage.removeItem('prev_access_token');
        localStorage.removeItem('prev_access_token_exp');
        localStorage.removeItem('prev_refresh_token');
      }

      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        accessTokenExp: null,
        refreshToken: null,
        profilePageId: null,
        role: null,
        user: null,
        type: null,
        isLoginAsMode: false,
      };
    }
    case 'LOGIN_AS_USER': {
      const {
        accessToken,
        expiresIn,
        refreshToken,
        user,
        role,
        type,
        isLoginAsMode,
      } = action.accessToken;

      if (localStorage) {
        localStorage.setItem(
          'prev_access_token',
          localStorage.getItem('access_token')
        );

        localStorage.setItem(
          'prev_access_token_exp',
          localStorage.getItem('access_token_exp')
        );

        localStorage.setItem(
          'prev_refresh_token',
          localStorage.getItem('refresh_token')
        );

        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('access_token_exp', expiresIn);
        localStorage.setItem('refresh_token', refreshToken);
      }

      const userRole = role || '';

      return {
        ...state,
        accessToken,
        refreshToken,
        user,
        profilePageId: user ? user.profilePageId : null,
        role: userRole,
        isAuthenticated: true,
        accessTokenExp: expiresIn,
        type,
        isLoginAsMode,
      };
    }
    case 'LOGOUT_AS_USER': {
      if (state.isLoginAsMode === true) {
        if (localStorage) {
          localStorage.setItem(
            'access_token',
            localStorage.getItem('prev_access_token')
          );

          localStorage.setItem(
            'access_token_exp',
            localStorage.getItem('prev_access_token_exp')
          );

          localStorage.setItem(
            'refresh_token',
            localStorage.getItem('prev_refresh_token')
          );

          localStorage.removeItem('prev_refresh_token');
          localStorage.removeItem('prev_access_token_exp');
          localStorage.removeItem('prev_refresh_token');
        }

        return {
          ...state,
          isAuthenticated: true,
          accessToken: localStorage.getItem('refresh_token'),
          accessTokenExp: localStorage.getItem('access_token_exp'),
          refreshToken: localStorage.getItem('refresh_token'),
          profilePageId: null,
          role: null,
          user: null,
          type: 'ADMIN',
          isLoginAsMode: false,
        };
      }

      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default auth;
