import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import AreaFilterRange from '../AreaFilterRange/AreaFilterRange';

class ModalAreaFilterRangeFormItem extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['land', 'floor']),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    type: 'land',
    onChange: () => {},
  };

  handleChange = (valueMin, valueMax) => {
    const { onChange } = this.props;
    this.setState(
      {
        valueMin,
        valueMax,
      },
      () => {
        onChange({
          max: valueMax,
          min: valueMin,
        });
      }
    );
  };

  clearOption = () => {
    const { onChange } = this.props;
    const min = undefined;
    const max = undefined;
    this.setState(
      {
        valueMin: min,
        valueMax: max,
      },
      () => {
        onChange({
          max,
          min,
        });
      }
    );
  };

  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = {
      valueMin: value.min,
      valueMax: value.max,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.value.min !== state.valueMin ||
      props.value.max !== state.valueMax
    ) {
      return {
        valueMin: props.value.min,
        valueMax: props.value.max,
      };
    }
    return null;
  }

  render() {
    const { type } = this.props;
    const { valueMin, valueMax } = this.state;

    const hasValue = valueMin || valueMax;

    return (
      <Row type="flex" justify="space-between" align="middle">
        <Col span={24} className="">
          <Row type="flex" justify="space-between" align="middle">
            <Col className="">
              <span className="w-13 inline-block lg:font-medium font-bold text-base">
                {type === 'floor' ? 'Floor' : 'Land'} Area
              </span>
            </Col>
            <Col className="">
              <Button
                disabled={!hasValue}
                className="border-0"
                onClick={this.clearOption}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="mb-3">
          <AreaFilterRange
            value={[valueMin, valueMax]}
            onChange={this.handleChange}
          />
        </Col>
      </Row>
    );
  }
}

export default ModalAreaFilterRangeFormItem;
