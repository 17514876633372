import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { Button, Col, Row } from 'antd';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from 'react-virtualized';
import isEmpty from 'lodash/isEmpty';
import store from 'store';
import './PropertySearchResultGridVirtualize.scss';
import { PREMIERE, STANDARD } from '../../constants/property-ad-types';
import LoadingDots from '../LoadingDots';
import PropertyCard from '../PropertyCard';
import PropertyCardPremiere from '../PropertyCardPremiere';
import { EmitterContext, EventNames } from '../../context/emitter-context';

// https://codesandbox.io/s/y2oz7m7wx

class PropertySearchResultGridVirtualize extends React.Component {
  static propTypes = {
    resultList: PropTypes.any,
    loading: PropTypes.bool,
    smLayout: PropTypes.bool,
    searchOptions: PropTypes.object,
    emmiter: PropTypes.object,
    searchParam: PropTypes.string,
    showLoadingMore: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    loadMore: PropTypes.func,
  };

  static defaultProps = {
    resultList: [],
    loading: false,
    smLayout: false,
    searchOptions: {},
    emmiter: {},
    searchParam: null,
    showLoadingMore: false,
    isLoadingMore: false,
    loadMore: () => {},
  };

  state = {
    rowGroupMap: [],
    hasScrolled: false,
  };

  constructor(props) {
    super(props);
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 300,
    });

    const { resultList, smLayout } = props;

    this.state = {
      rowGroupMap: this.generateRowGroupMap(resultList, smLayout),
    };
  }

  componentDidUpdate(prevProps) {
    const { resultList, smLayout } = this.props;
    const { resultList: previousResultList } = prevProps;

    if (resultList.length !== previousResultList.length) {
      this.setStateAfterComponentUpdate(resultList, smLayout);
    }
  }

  onPropertyMouseOver = propertyId => {
    const { emmiter } = this.props;
    emmiter.emit(EventNames.ON_PROPERTY_MOUSE_OVER, propertyId);
  };

  setStateAfterComponentUpdate = (resultList, smLayout) => {
    this.setState({
      rowGroupMap: this.generateRowGroupMap(resultList, smLayout),
    });
  };

  generateRowGroupMap = (dataList, smLayout) =>
    dataList
      .reduce((acc, item, index, array) => {
        if (smLayout) {
          acc.push([item]);
          return acc;
        }
        const isSplit = propertyData => {
          switch (propertyData.featuredTier) {
            case PREMIERE: {
              return false;
            }
            default: {
              return true;
            }
          }
        };

        if (!isSplit(item)) {
          acc.push([item]);
        } else if (index !== 0 && isSplit(array[index - 1])) {
          const currentArray = acc[acc.length - 1];

          if (currentArray.length === 2) {
            acc.push([item]);
          } else {
            acc[acc.length - 1] = [...currentArray, item];
          }
        } else {
          acc.push([item]);
        }

        return acc;
      }, [])
      .reduce((acc, item, index) => {
        acc.push(item);
        return acc;
      }, []);

  renderCard = (property, addPaddingLeft, addPaddingRight) => {
    const {
      searchOptions: { showMap, ofiTime },
      searchParam,
    } = this.props;
    const { hasScrolled } = this.state;
    const { featuredTier } = property;
    const advancedSearchFilterHeight = !isEmpty(
      document.getElementsByClassName('search-filter')
    )
      ? document.getElementsByClassName('search-filter')[0].clientHeight
      : 67;
    const menuHeight = !isEmpty(document.getElementsByClassName('header-dark'))
      ? document.getElementsByClassName('header-dark')[0].clientHeight
      : 56;
    const offsetHeaderHeight = advancedSearchFilterHeight + menuHeight;

    const cardProps = {
      property,
      ofiTime: !!ofiTime,
      searchParam,
    };

    const cardSmWidth = {
      xs: 24,
      sm: 18,
      md: showMap ? 12 : 12,
      lg: showMap ? 12 : 12,
      xl: showMap ? 12 : 12,
      xxl: showMap ? 12 : 12,
    };

    const addPaddingLeftStyle = addPaddingLeft ? 'pl-3 pr-6' : '';
    const addPaddingRightStyle = addPaddingRight ? 'pr-3 pl-6' : '';

    switch (featuredTier) {
      case STANDARD: {
        return (
          <Col
            {...cardSmWidth}
            style={{ height: 430 }}
            className={`flex items-stretch self-start my-2 px-1 ${addPaddingLeftStyle} ${addPaddingRightStyle}`}
            key={property.id}
            onMouseEnter={() => this.onPropertyMouseOver(property.id)}
            onMouseLeave={() => this.onPropertyMouseOver(null)}
          >
            <PropertyCard
              {...cardProps}
              hasScrolled={hasScrolled}
              offsetHeaderHeight={offsetHeaderHeight}
            />
          </Col>
        );
      }
      case PREMIERE: {
        return (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            style={{ height: 475 }}
            xl={showMap ? 24 : 24}
            xxl={showMap ? 24 : 24}
            className="flex items-stretch self-start my-2 sm:px-6 px-1"
            key={property.id}
            onMouseEnter={() => this.onPropertyMouseOver(property.id)}
            onMouseLeave={() => this.onPropertyMouseOver(null)}
          >
            <PropertyCardPremiere
              {...cardProps}
              hasScrolled={hasScrolled}
              offsetHeaderHeight={offsetHeaderHeight}
            />
          </Col>
        );
      }
      default: {
        return (
          <Col
            {...cardSmWidth}
            style={{ height: 430 }}
            className={`flex items-stretch self-start my-2 px-1 ${addPaddingLeftStyle} ${addPaddingRightStyle}`}
            // className="flex items-stretch"
            key={property.id}
            onMouseEnter={() => this.onPropertyMouseOver(property.id)}
            onMouseLeave={() => this.onPropertyMouseOver(null)}
          >
            <PropertyCard
              {...cardProps}
              hasScrolled={hasScrolled}
              offsetHeaderHeight={offsetHeaderHeight}
            />
          </Col>
        );
      }
    }
  };

  triggerLoadMore = index => {
    const { loadMore } = this.props;

    loadMore().then(() => {
      this.cache.clearAll();
      this.vlist.recomputeRowHeights(index);
    });

    this.vlist.recomputeRowHeights(index);
  };

  renderLoadingRow = index => {
    const { loading, showLoadingMore, isLoadingMore } = this.props;

    return !loading && showLoadingMore ? (
      <div className="loading-container">
        {isLoadingMore ? (
          <LoadingDots />
        ) : (
          <Button
            className="rounded"
            size="large"
            onClick={() => this.triggerLoadMore(index)}
          >
            More Properties
          </Button>
        )}
      </div>
    ) : null;
  };

  renderRow = ({ index, key, style, parent }) => {
    const { rowGroupMap } = this.state;

    const dataList = rowGroupMap[index] || [];
    const isLastItem = rowGroupMap.length === index;

    if (isLastItem) {
      return (
        <CellMeasurer
          key={key}
          cache={this.cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          <div key={key} style={style}>
            <Row className="row">{this.renderLoadingRow(index)}</Row>
          </div>
        </CellMeasurer>
      );
    }

    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div key={key} style={style}>
          <Row className="row">
            {dataList.length === 2 && (
              <>
                {this.renderCard(dataList[0], false, true)}
                {this.renderCard(dataList[1], true, false)}
              </>
            )}
            {dataList.length === 1 && this.renderCard(dataList[0])}
          </Row>
        </div>
      </CellMeasurer>
    );
  };

  setHasScrolled = () => {
    this.setState({ hasScrolled: true });
  };

  render() {
    const { rowGroupMap } = this.state;

    return (
      <div
        className={`PropertySearchResultGridVirtualize ${!rowGroupMap.length &&
          'empty-list'}`}
      >
        <div className="list">
          <WindowScroller
            scrollElement={window}
            ref={this.setRef}
            onScroll={this.setHasScrolled}
          >
            {({
              height,
              isScrolling,
              registerChild,
              onChildScroll,
              scrollTop,
            }) => (
              <div style={{ flex: '1 1 auto' }}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={registerChild}>
                      <List
                        autoHeight
                        width={width}
                        height={height}
                        isScrolling={isScrolling}
                        rowHeight={this.cache.rowHeight}
                        rowRenderer={this.renderRow}
                        rowCount={rowGroupMap.length + 1}
                        overscanRowCount={5}
                        // scrollToAlignment="start"
                        deferredMeasurementCache={this.cache}
                        scrollTop={scrollTop}
                        onScroll={onChildScroll}
                        ref={ref => {
                          this.vlist = ref;
                        }}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
            )}
          </WindowScroller>
        </div>
      </div>
    );
  }
}

export default props => (
  <EmitterContext.Consumer>
    {emitter => (
      <PropertySearchResultGridVirtualize emmiter={emitter} {...props} />
    )}
  </EmitterContext.Consumer>
);
