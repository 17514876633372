import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Icon, Row } from 'antd';
import Helmet from 'react-helmet';
import NotFound from './404.svg';

export default () => (
  <>
    <Helmet>
      <title defer={false}>Page Not Found</title>
    </Helmet>
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="bg-white full-page"
    >
      <Col className="center-align text-center">
        <img src={NotFound} alt="not found" />
        <h1 className="text-gray-500 uppercase text-center">
          {' '}
          Page not found{' '}
        </h1>
        <Link
          to="/"
          className="rounded-full py-2 px-4 mt-3 inline-block hover:shadow-inner transition shadow-none hover:text-gray-700 text-gray-700 bg-blue-100"
        >
          <Icon type="home" theme="filled" className="mr-2" />
          Return to Home
        </Link>
      </Col>
    </Row>
  </>
);
