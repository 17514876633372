import React, { PureComponent } from 'react';
import { Col, Row } from 'antd';
import { withRouter } from 'react-router-dom';
import { winHeight, winWidth } from '../../utils/windowDimensions';
import './HomePage.scss';
import PublicLayout from '../../layouts/PublicLayout';
import SearchTypeMenu from '../../components/SearchTypeMenu';
import PropertySearchSelect from '../../components/PropertySearchSelect';
import FeaturedDistricts from '../../components/FeaturedDistricts';
import LatestResidentialPropertyForRent from '../../components/LatestResidentialPropertyForRent';
import HeroTagline from '../../components/HeroTagline';

class HomePage extends PureComponent {
  render() {
    const {
      location: { pathname },
    } = this.props;

    return (
      <PublicLayout>
        <div className="page">
          <div className="page-wrapper">
            <div
              className={`${
                pathname.startsWith('/commercial') ? 'hero-c' : 'hero-b'
              } w-full relative`}
              style={{ height: winHeight - (winWidth > 1024 ? 90 : 0) }}
            >
              <div className="hero-bg-mask">
                <Row className="hero-row">
                  <Col className="hero-col text-center">
                    <Row type="flex" justify="center">
                      <Col
                        xs={24}
                        sm={22}
                        md={20}
                        lg={20}
                        xl={20}
                        xxl={16}
                        className="hero-box md:px-4 px-2 md:py-4 py-2"
                      >
                        <HeroTagline />
                        <SearchTypeMenu />
                        <PropertySearchSelect />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <FeaturedDistricts />
            <LatestResidentialPropertyForRent />
          </div>
        </div>
      </PublicLayout>
    );
  }
}

export default withRouter(HomePage);
