import React, { PureComponent } from 'react';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Card, Icon } from 'antd';
import { FollowedContributorQuery } from './Operations.graphql';
import Error from '../../Error';
import FollowCardTitleCount from '../FollowCardTitleCount';

class ConsumerProfileFollowersList extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    pageId: PropTypes.string,
    editable: PropTypes.bool,
  };

  static defaultProps = {
    data: {},
    pageId: null,
    editable: null,
  };

  state = {
    visible: false,
    currentlyFollowing: null,
    suburb: null,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const {
      data: { loading, error, consumerPage },
      editable,
    } = this.props;
    if (error) return <Error error={error} />;

    const {
      owner: { followersConnection },
    } = consumerPage || { owner: { followersConnection: { nodes: [] } } };
    const { nodes: followedConsumers } = followersConnection || [];

    return (
      <Card
        loading={loading}
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className="shadow rounded"
        title={
          <FollowCardTitleCount
            title="Followers"
            count={followedConsumers.length}
          />
        }
        // extra={
        //   editable && (
        //     <Link to="/lifestyle" className="rounded-full p-2">
        //       <Icon type="plus" />
        //     </Link>
        //   )
        // }
      >
        <main className="flex flex-wrap p-3">
          {followedConsumers.length > 0 ? (
            <>
              {followedConsumers.map(
                ({
                  id,
                  initials,
                  firstName,
                  lastName,
                  profilePageId,
                  profilePicture,
                }) => {
                  const firstLetter = initials.charAt(0).toLowerCase();
                  return (
                    <Link to={`/${profilePageId || ''}`} key={profilePageId}>
                      <div className="flex align-middle">
                        <Avatar
                          src={profilePicture}
                          className={`bg-blue-500 m-1 contributor-avatar avatar-${firstLetter}`}
                          key={id}
                          size={50}
                          title={`${firstName}${' '}${lastName}`}
                        >
                          {initials}
                        </Avatar>
                        <div className="ml-2 text-black flex items-center">
                          <div>
                            <div className="font-semibold">
                              {firstName} {lastName}
                            </div>

                            <span className="text-xs font-normal inline-block">
                              @{profilePageId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
              )}
            </>
          ) : (
            <span>No followers yet</span>
          )}
        </main>
      </Card>
    );
  }
}

const CONSUMER_FOLLOW_CONTRIBUTOR = gql`
  mutation consumerFollowConsumer($followeeId: ID!) {
    consumerFollowConsumer(followeeId: $followeeId)
  }
`;
const CONSUMER_UNFOLLOW_CONTRIBUTOR = gql`
  mutation consumerUnfollowConsumer($followeeId: ID!) {
    consumerUnfollowConsumer(followeeId: $followeeId)
  }
`;

const ConsumerFollowingContributorListWithData = compose(
  graphql(FollowedContributorQuery, {
    options: ({ pageId }) => ({
      variables: {
        pageId,
        filter: { first: 7 },
      },
    }),
  }),
  graphql(CONSUMER_FOLLOW_CONTRIBUTOR, {
    name: 'consumerFollowConsumer',
    props: ({ consumerFollowConsumer }) => ({
      consumerFollowConsumer: followeeId =>
        consumerFollowConsumer({ variables: { followeeId } }),
    }),
  }),
  graphql(CONSUMER_UNFOLLOW_CONTRIBUTOR, {
    name: 'consumerUnfollowConsumer',
    props: ({ consumerUnfollowConsumer }) => ({
      consumerUnfollowConsumer: followeeId =>
        consumerUnfollowConsumer({ variables: { followeeId } }),
    }),
  })
)(ConsumerProfileFollowersList);

export default ConsumerFollowingContributorListWithData;
