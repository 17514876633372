import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faGraduationCap,
  faSubway,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons';
import { Tabs, Empty, Row, Col } from 'antd';
import NearbySchools from '../NearbySchools';
import MrtStations from '../MrtStations';
import NearbyPostOffices from '../NearbyPostOffices';

const TabPane = Tabs.TabPane;

class NearbyPlaces extends Component {
  render() {
    const {
      nearbyMrtStations,
      nearbySchools,
      nearbyPostOffices,
      handleTabOnChange,
      handleChangeMarkerCenter,
    } = this.props;

    return (
      <Fragment>
        <h2 className="mb-4 sm:text-2xl text-lg">Nearby Places</h2>
        <Tabs tabPosition="top" onChange={handleTabOnChange}>
          <TabPane
            tab={
              <Row>
                <Col offset={6} span={18}>
                  <FA icon={faGraduationCap} size="2x" className="mt-2" />
                </Col>
                <Col span={24} offset={3}>
                  <small className="font-bold">SCHOOLS</small>
                </Col>
              </Row>
            }
            key="schools"
          >
            {nearbySchools.length > 0 && (
              <div className="overflow-auto h-64">
                <NearbySchools
                  nearbySchools={nearbySchools}
                  handleChangeMarkerCenter={handleChangeMarkerCenter}
                />
              </div>
            )}
            {nearbySchools.length === 0 && (
              <Empty description={<span>No nearby schools.</span>} />
            )}
          </TabPane>
          <TabPane
            tab={
              <Row>
                <Col offset={9} span={15}>
                  <FA icon={faSubway} size="2x" className="mt-2" />
                </Col>
                <Col span={24} offset={3}>
                  <small className="font-bold">MRT STATIONS</small>
                </Col>
              </Row>
            }
            key="stations"
          >
            {nearbyMrtStations.length !== 0 && (
              <div className="overflow-auto h-64">
                <MrtStations
                  mrtStations={nearbyMrtStations}
                  handleChangeMarkerCenter={handleChangeMarkerCenter}
                />
              </div>
            )}
            {nearbyMrtStations.length === 0 && (
              <Empty description={<span>No nearby stations.</span>} />
            )}
          </TabPane>
          <TabPane
            tab={
              <Row>
                <Col offset={9} span={15}>
                  <FA icon={faEnvelope} size="2x" className="mt-2" />
                </Col>
                <Col span={24} offset={3}>
                  <small className="font-bold">POST OFFICES</small>
                </Col>
              </Row>
            }
            key="postOffices"
          >
            {nearbyPostOffices.length !== 0 && (
              <div className="overflow-auto h-64">
                <NearbyPostOffices
                  postOffices={nearbyPostOffices}
                  handleChangeMarkerCenter={handleChangeMarkerCenter}
                />
              </div>
            )}
            {nearbyPostOffices.length === 0 && (
              <Empty description={<span>No nearby post offices.</span>} />
            )}
          </TabPane>
        </Tabs>
      </Fragment>
    );
  }
}

export default NearbyPlaces;
