import React from 'react';
import { InfoWindow, Marker } from 'react-google-maps';

function NearbyPlacesMapMarker(props) {
  const { data, icon, center, onChangeCenter, details } = props;

  return data.map((d, index) => (
    <Marker
      key={`${index}${d.id}`}
      position={{
        lat: d.lat,
        lng: d.lng,
      }}
      icon={icon}
      onClick={() =>
        onChangeCenter({
          id: d.id,
          lat: d.lat,
          lng: d.lng,
        })
      }
    >
      {(center && d.id === center.id && (
        <InfoWindow>{details(d)}</InfoWindow>
      )) || <div />}
    </Marker>
  ));
}

export default NearbyPlacesMapMarker;
