import GoogleAnalytics from 'react-ga';

export const socialTracker = ({
  socialNetwork,
  socialAction,
  socialTarget,
  pageContentId,
}) => {
  GoogleAnalytics.ga('set', 'dimension1', pageContentId);
  GoogleAnalytics.ga(
    'send',
    'social',
    socialNetwork,
    socialAction,
    socialTarget
  );
};

export const trackAttemptPropertyEnquiry = (
  propertyShortId,
  agentId,
  eventAction
) => {
  GoogleAnalytics.ga('set', 'dimension1', propertyShortId);
  GoogleAnalytics.ga('set', 'dimension7', agentId);
  GoogleAnalytics.ga('send', 'event', 'Property Enquiry', eventAction);
};

export const trackAttemptAgentEnquiry = (agentId, eventAction) => {
  GoogleAnalytics.ga('set', 'dimension7', agentId);
  GoogleAnalytics.ga('send', 'event', 'Agent Enquiry', eventAction);
};

export const trackPropertyPageView = (page, shortId, agentId) => {
  GoogleAnalytics.set({ page });
  GoogleAnalytics.ga('set', 'dimension1', shortId);
  GoogleAnalytics.ga('set', 'dimension2', agentId);
  GoogleAnalytics.ga('send', 'pageview', page);
};

export const trackPropertyVideoView = (page, propertyShortId, agentId) => {
  GoogleAnalytics.set({ page });
  GoogleAnalytics.ga('set', 'dimension11', 1);
  GoogleAnalytics.ga('set', 'dimension1', propertyShortId);
  GoogleAnalytics.ga('set', 'dimension7', agentId);
  GoogleAnalytics.ga(
    'send',
    'event',
    'Property Video View',
    'Property Page Video View'
  );
};

export const trackPropertyImage = (id, s3Key, agentId, imageType) => {
  GoogleAnalytics.ga('set', 'dimension1', id);
  GoogleAnalytics.ga('set', 'dimension7', agentId);
  GoogleAnalytics.ga('set', 'dimension9', s3Key);
  GoogleAnalytics.ga('set', 'dimension10', imageType);
  GoogleAnalytics.ga(
    'send',
    'event',
    'Property Image View',
    'Search Result Image View'
  );
};

export const trackPropertyImageSlider = (id, s3Key, agentId, imageType) => {
  GoogleAnalytics.ga('set', 'dimension1', id);
  GoogleAnalytics.ga('set', 'dimension7', agentId);
  GoogleAnalytics.ga('set', 'dimension9', s3Key);
  GoogleAnalytics.ga('set', 'dimension10', imageType);
  GoogleAnalytics.ga(
    'send',
    'event',
    'Property Image View',
    'Search Result Image View'
  );
};

export const trackPropertyPageImages = (id, s3Key, agentId, imageType) => {
  GoogleAnalytics.ga('set', 'dimension1', id);
  GoogleAnalytics.ga('set', 'dimension7', agentId);
  GoogleAnalytics.ga('set', 'dimension9', s3Key);
  GoogleAnalytics.ga('set', 'dimension10', imageType);
  GoogleAnalytics.ga(
    'send',
    'event',
    'Property Image View',
    'Property Page Image View'
  );
};

export const trackPropertyPageImagesModal = (id, s3Key, agentId, imageType) => {
  GoogleAnalytics.ga('set', 'dimension1', id);
  GoogleAnalytics.ga('set', 'dimension7', agentId);
  GoogleAnalytics.ga('set', 'dimension9', s3Key);
  GoogleAnalytics.ga('set', 'dimension10', imageType);
  GoogleAnalytics.ga(
    'send',
    'event',
    'Property Image View',
    'Property Page Image View'
  );
};
