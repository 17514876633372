import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Alert, Button, Col, Divider, Form, Icon, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import GuestLoginUsingFb from '../GuestLoginUsingFb';
import GuestLoginUsingGoogle from '../GuestLoginUsingGoogle';
import './GuestLoginForm.scss';

class GuestLoginForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    isPage: PropTypes.bool,
    loginConsumerMutation: PropTypes.func,
    onSuccess: PropTypes.func,
    onSuccessAccount: PropTypes.oneOfType([
      PropTypes.instanceOf(Promise),
      PropTypes.func,
    ]),
  };

  static defaultProps = {
    form: {},
    isPage: false,
    loginConsumerMutation: () => {},
    onSuccess: () => {},
    onSuccessAccount: () => Promise.resolve(),
  };

  state = { isLoading: false };

  getErrorMessage = () => {
    let message = null;
    const { formErrorCode } = this.state;

    switch (formErrorCode) {
      case 'CONSUMER_NOT_EXIST': {
        message = 'It seems that your email is not yet registered ';
        break;
      }
      case 'PASSWORD_MISMATCH': {
        message = 'It seems that your password is incorrect ';
        break;
      }
      case 'CONSUMER_NOT_ACTIVE': {
        message = 'It seems that the account you provided is inactive  ';
        break;
      }
      case 'CONSUMER_NOT_VERIFIED': {
        message = 'It seems that the account you provided is not verified';
        break;
      }
      default: {
        message = 'Unable to login ';
        break;
      }
    }

    return message;
  };

  setSignInButtonLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  loginConsumer = e => {
    e.preventDefault();
    const {
      form: { validateFields },
    } = this.props;

    this.setState({
      formErrorCode: null,
      isLoading: true,
    });

    validateFields(async (err, values) => {
      if (!err) {
        const { email, password } = values;
        const { loginConsumerMutation, onSuccess } = this.props;

        try {
          const {
            data: { createConsumerToken },
          } = await loginConsumerMutation(email, password);
          onSuccess(createConsumerToken);
        } catch (error) {
          const errors = error.graphQLErrors || [];
          const formErrorCode =
            errors.length > 0 ? errors[0].extensions.code : null;

          this.setState({
            formErrorCode,
            isLoading: false,
          });
        }
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      onSuccessAccount,
      isPage,
    } = this.props;

    const { isLoading, formErrorCode } = this.state;

    return (
      <div
        className={`pt-12 pb-6 sm:px-8 px-4 lg:my-0 md:my-4 GuestLoginForm ${isPage &&
          'sm:shadow-lg '}`}
      >
        {isPage ? (
          <section className="consumer-avatar-holder text-center">
            <div className="bg-gray-300 rounded-full inline-block sm:h-32 sm:w-32 h-24 w-24 consumer-avatar mb-2 relative">
              <FA
                icon={faUserAlt}
                className="sm:text-6xl text-5xl text-gray-800 center-align"
              />
            </div>
            <h1 className="text-center text-white uppercase font-medium md:text-2xl text-xl">
              Welcome!
            </h1>
            <h2 className="text-center text-lg mb-2 text-gray-700 font-normal">
              Sign in to your account
            </h2>
          </section>
        ) : (
          <section>
            <h2 className="text-center text-lg mb-2 text-gray-900 mb-6 uppercase">
              Sign in to your account
            </h2>
          </section>
        )}
        <div className="flex justify-center my-4">
          <GuestLoginUsingFb
            setSignInButtonLoading={this.setSignInButtonLoading}
            onSuccess={onSuccessAccount}
          />
          <GuestLoginUsingGoogle
            setSignInButtonLoading={this.setSignInButtonLoading}
            onSuccess={onSuccessAccount}
          />
        </div>
        <Divider className="py-1 text-center mb-2  font-normal login-divider">
          <span className={`${isPage ? 'text-gray-200' : 'text-gray-800'}`}>
            or
          </span>
        </Divider>
        <Form onSubmit={this.loginConsumer} className="guest-login-form">
          <span
            className={`${isPage &&
              'text-gray-200'} text-xs uppercase font-semibold ml-4 py-1 inline-block`}
          >
            Email
          </span>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { transform: value => (value ? value.trim() : value) },
                {
                  type: 'email',
                  message: 'Invalid email!',
                },
                {
                  required: true,
                  message: 'Email is required!',
                },
              ],
            })(
              <Input
                size="large"
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />
            )}
          </Form.Item>
          <span
            className={`${isPage &&
              'text-gray-200'} text-xs uppercase font-semibold ml-4 py-1 inline-block`}
          >
            Password
          </span>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Password is required!',
                },
              ],
            })(
              <Input.Password
                size="large"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
              />
            )}
          </Form.Item>
          <Form.Item className="mt-8 mb-2">
            <Button
              className="w-full"
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              {isLoading ? 'Signing you in' : 'Sign in'}
            </Button>
            <div className="h-6 py-2">
              {formErrorCode && (
                <Alert message={this.getErrorMessage()} type="error" showIcon />
              )}
            </div>
          </Form.Item>
          <Row type="flex" justify="space-between" className="mb-8">
            <Col className={`${isPage && 'text-white'}`}>
              Need Account?
              <Link to="/sign-up"> Get Started!</Link>
            </Col>
            <Col>
              <Link to="/forgot-password"> Forgot Password?</Link>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const GUEST_LOGIN_MUTATION = gql`
  mutation createConsumerToken($email: String!, $password: String!) {
    createConsumerToken(email: $email, password: $password) {
      id
      accessToken
      expiresIn
      refreshToken
      type
      user {
        ... on Consumer {
          id
          email
          firstName
          lastName
          profilePageId
        }
      }
    }
  }
`;

const WrappedGuestLoginForm = Form.create()(GuestLoginForm);

const WrappedGuestLoginFormWithMutate = graphql(GUEST_LOGIN_MUTATION, {
  props: ({ mutate }) => ({
    loginConsumerMutation: (email, password) =>
      mutate({
        variables: {
          email,
          password,
        },
      }),
  }),
})(WrappedGuestLoginForm);

export default WrappedGuestLoginFormWithMutate;
