import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import './AgentBar.scss';
import PropertyAddress from '../PropertyAddress';
import PropertyMainFeatures from '../PropertyMainFeatures';
import PropertyShare from '../PropertyShare';
import AgentContactNumber from '../AgentContactNumber';

function AgentBar({
  propertyShareLink,
  property,
  agent,
  scrolled,
  location,
  shortId,
}) {
  const {
    typeGroup,
    typeCategory,
    features,
    psf,
    floorArea,
    segment,
    commercialType,
  } = property;
  const {
    firstName,
    lastName,
    mobileNumber,
    profilePictureUrl,
    slugUrl: agentSlugUrl,
    bannerColor,
    cea,
  } = agent || {};
  const show = scrolled ? 'show-item' : 'hide-item';
  const zIndex = scrolled ? 'md:z-50' : 'md:z-0';

  return (
    <Row
      type="flex"
      justify="center"
      className={`property-bar fixed md:inset-auto md:top-0 bottom-0 w-full bg-gray-100 ${show} ${zIndex} z-50 md:shadow p-2`}
      style={{ borderBottom: `2px solid ${bannerColor || '#4ac7f4'}` }}
    >
      <Col xs={24} sm={24} md={22} xl={16}>
        <Row type="flex" className="items-stretch">
          <Col
            xs={0}
            md={8}
            className="lg:flex hidden flex-row items-center border-r border-gray-300"
          >
            <div className="w-full">
              {segment === 'RESIDENTIAL' ? (
                <span className="block text-sm text-gray-800">
                  {typeGroup} &#x25CF; {typeCategory.label}
                </span>
              ) : (
                <span className="block text-sm text-gray-800">
                  {commercialType}
                </span>
              )}

              <PropertyAddress
                property={property}
                className="block text-base font-semibold mt-2"
              />
              <PropertyMainFeatures
                features={{
                  ...features,
                  psf,
                  floorArea,
                }}
                compact
              />
            </div>
          </Col>
          <Col className="flex-grow flex items-center">
            <Row type="flex" justify="space-between" align="middle">
              <Col className="text-center flex md:justify-between items-center pl-4 md:w-auto w-full">
                <Link to={`/agent/${agentSlugUrl}`}>
                  <Avatar
                    src={profilePictureUrl}
                    alt={`${firstName} ${lastName}`}
                    className="bg-gray-300 border-2 border-white"
                    size={64}
                    icon="user"
                  />
                </Link>
                <div className="md:ml-4 ml-2 w-auto">
                  <Link to={`/agent/${agentSlugUrl}`}>
                    <h4 className="text-left text-gray-900">
                      {firstName} {lastName}
                    </h4>
                  </Link>
                  {cea && (
                    <div className="text-left text-sm text-gray-700">
                      <span>CEA #</span>{' '}
                      <a
                        href={`https://www.cea.gov.sg/public-register?category=Salesperson&registrationNumber=${cea}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {cea}
                      </a>
                    </div>
                  )}
                  <AgentContactNumber
                    className="block text-left"
                    agentId={agent.id}
                    propertyId={property.id}
                    contactNo={mobileNumber}
                    useTel
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xs={0}
            md={8}
            className="lg:flex items-center hidden pl-4 border-l border-gray-300"
          >
            <PropertyShare
              propertyShareLink={propertyShareLink}
              property={property}
              shortId={shortId}
              location={location}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

AgentBar.propTypes = {
  address: PropTypes.string,
  agency: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  agent: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  features: PropTypes.object,
  propertyId: PropTypes.string,
  scrolled: PropTypes.bool,
};

AgentBar.defaultProps = {
  address: '',
  agency: {},
  agent: {},
  features: {},
  propertyId: '',
  scrolled: false,
};

export default AgentBar;
