import React from 'react';
import { Input } from 'antd';

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const { onChange } = this.props;
    const reg = /^-?(0|[0-9][0-9]*)(\.[0-9]*)?$/;

    const newValue = value.replace(/\s+/g, ''); // Replace spaces with -
    if (
      (!Number.isNaN(newValue) && reg.test(newValue)) ||
      newValue === '' ||
      newValue === '-'
    ) {
      const formattedValue = newValue.replace(
        /(\d{4})(\d{3})(\d{3})/,
        '$1 $2 $3'
      );
      onChange(formattedValue);
    }
  };

  render() {
    const { value } = this.props;

    return (
      <Input
        {...this.props}
        value={value}
        onChange={this.onChange}
        placeholder="0491 570 156"
        maxLength={12}
      />
    );
  }
}

export default NumericInput;

// class NumericInputDemo extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = { value: '' };
//     }
//
//     onChange = (value) => {
//         this.setState({ value });
//     }
//
//     render() {
//         return <NumericInput style={{ width: 120 }} value={this.state.value} onChange={this.onChange} />;
//     }
// }

// export default NumericInputDemo;
