import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './AreaFilterRange.scss';

const SliderValue = ({ isFull, name, value }) => (
  <span>
    {' '}
    {isFull ? (
      <span>{name} sqft</span>
    ) : (
      <span>{numeral(value).format('(0a)')} sqft</span>
    )}
  </span>
);

class AreaFilterRange extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    defaultValue: PropTypes.arrayOf(PropTypes.number),
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: [0, 0],
    defaultValue: [150, 6000],
    min: 150,
    max: 6000,
    step: 150,
    onChange: () => {},
  };

  handleChange = value => {
    const { onChange } = this.props;
    this.setState(
      {
        valueMin: value[0],
        valueMax: value[1],
      },
      () => {
        onChange(value[0], value[1]);
      }
    );
  };

  constructor(props) {
    super(props);
    const { value, defaultValue } = this.props;

    this.state = {
      valueMin: value && value[0] ? value[0] : defaultValue[0],
      valueMax: value && value[1] ? value[1] : defaultValue[1],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.value[0] !== state.valueMin ||
      props.value[1] !== state.valueMax
    ) {
      return {
        valueMin:
          props.value && props.value[0]
            ? props.value[0]
            : props.defaultValue[0],
        valueMax:
          props.value && props.value[1]
            ? props.value[1]
            : props.defaultValue[1],
      };
    }
    return null;
  }

  render() {
    const { valueMin, valueMax } = this.state;
    const { min, max, step } = this.props;

    const handle = props => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Slider.Handle value={value} {...restProps} key={index}>
          {index === 0 && (
            <SliderValue isFull={value === min} value={value} name="Min" />
          )}
          {index === 1 && (
            <SliderValue isFull={value === max} value={value} name="Max" />
          )}
        </Slider.Handle>
      );
    };

    return (
      <div className="AreaFilterRange px-6">
        <div className="text-xs text-center mb-2" key="11">
          <SliderValue isFull={valueMin === min} value={valueMin} name="Min" />{' '}
          -{' '}
          <SliderValue isFull={valueMax === max} value={valueMax} name="Max" />
        </div>

        <div className="slider">
          <Slider.Range
            handle={handle}
            defaultValue={[min, max]}
            value={[valueMin, valueMax]}
            max={max}
            min={min}
            step={step}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default AreaFilterRange;
