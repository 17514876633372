import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faBath,
  faBed,
  faUsdCircle,
  faRulerCombined,
} from '@fortawesome/pro-regular-svg-icons';
import { FormattedPlural, FormattedNumber } from 'react-intl';
import cls from 'classnames';
import PropertyPrice from '../PropertyPrice/PropertyPrice';

export default class PropertyMainFeatures extends PureComponent {
  static propTypes = { features: PropTypes.object, compact: PropTypes.bool };

  static defaultProps = { features: {}, compact: false };

  render() {
    const { features, compact, segment } = this.props;

    const { bedrooms, bathrooms, psf, floorArea } = features || {};
    const bed = bedrooms > 0 ? bedrooms : 0;
    const baths = bathrooms > 0 ? bathrooms : 0;

    const containerCls = cls(
      'flex flex-wrap flex-row items-center content-center justify-start w-full',
      { 'mt-8 py-4 border-b border-t': !compact }
    );

    const featureWrapperCls = cls(
      'flex items-center content-center justify-start max-w-full text-gray-800',
      { 'my-4 mr-6 text-lg': !compact },
      { 'my-2 mr-4 text-base': compact }
    );

    return (
      <div className={containerCls}>
        {segment === 'RESIDENTIAL' ? (
          <span className={featureWrapperCls}>
            <FA icon={faBed} />{' '}
            <span className="ml-2 font-normal">
              {bed}{' '}
              {!compact && (
                <FormattedPlural value={bed} one="bedroom" other="bedrooms" />
              )}
            </span>
          </span>
        ) : (
          ''
        )}
        <span className={featureWrapperCls}>
          <FA icon={faBath} />{' '}
          <span className="ml-2 font-normal">
            {baths}{' '}
            {!compact && (
              <FormattedPlural value={bed} one="bathroom" other="bathrooms" />
            )}
          </span>
        </span>
        {floorArea && (
          <span className={featureWrapperCls}>
            <FA icon={faRulerCombined} />{' '}
            <span className="ml-2 font-normal">
              <FormattedNumber value={floorArea.area} /> {floorArea.unit}
            </span>
          </span>
        )}
        <span className={featureWrapperCls}>
          <FA icon={faUsdCircle} />{' '}
          <span className="ml-2 font-normal">
            <PropertyPrice
              price={psf}
              className="tracking-tight font-hairline"
            />{' '}
            psf
          </span>
        </span>
      </div>
    );
  }
}
