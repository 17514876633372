import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import LinkedInShareButton from '../LinkedInShareButton';
import PinterestShareButton from '../PinterestShareButton';
import CopyEventButton from '../CopyEventButton';
import TwitterShareButton from '../TwitterShareButton';
import FacebookShareButton from '../FacebookShareButton';
import './ShareModal.scss';

class ShareModal extends PureComponent {
  static propTypes = {
    handleCancel: PropTypes.func,
    id: PropTypes.string,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    handleCancel: () => {},
    id: '',
    property: {},
    saveShareEvent: () => {},
    uuid: '',
    visible: false,
  };

  render() {
    const {
      visible,
      handleCancel,
      uuid,
      id,
      property,
      saveShareEvent,
    } = this.props;

    return (
      <Modal
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        closable={false}
        className="bg-transparent p-0 share-modal"
        width={null}
        centered
        wrapClassName="bg-transparent"
      >
        <div className="h-12 bg-gray-200 rounded-full flex items-center overflow-hidden">
          <FacebookShareButton
            uuid={uuid}
            id={id}
            property={property}
            saveShareEvent={saveShareEvent}
          />
          <TwitterShareButton
            uuid={uuid}
            id={id}
            property={property}
            saveShareEvent={saveShareEvent}
          />
          <LinkedInShareButton
            id={id}
            uuid={uuid}
            property={property}
            saveShareEvent={saveShareEvent}
          />
          <PinterestShareButton
            id={id}
            uuid={uuid}
            property={property}
            saveShareEvent={saveShareEvent}
          />
          <CopyEventButton
            id={id}
            uuid={uuid}
            property={property}
            saveShareEvent={saveShareEvent}
          />
        </div>
      </Modal>
    );
  }
}

export default ShareModal;
