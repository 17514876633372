import React, { Component } from 'react';
import { Button, Checkbox, Collapse, Divider, Icon, Popover } from 'antd';
import { graphql } from 'react-apollo';
import toLower from 'lodash/toLower';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import './PropertyTypeFilterSelect.scss';
import LoadingDots from '../LoadingDots';
import PropertyCategoryValuesQuery from '../../queries/PropertyCategoryValuesQuery.graphql';

const { Panel } = Collapse;

class PropertyTypeFilterSelect extends Component {
  static defaultProps = {
    value: {
      group: undefined,
      category: [],
    },
  };

  emptySelection = () => {
    return { group: undefined, category: [] };
  };

  showAny = () => {
    const { onChange } = this.props;
    onChange(this.emptySelection());
  };

  handleGroupChange = (group, options) => {
    const { onChange } = this.props;
    onChange({
      group,
      category: options.reduce((selected, category) => {
        if (category.group === group) {
          selected.push(category.value);
        }
        return selected;
      }, []),
    });
  };

  handleCategoryChange = (checked, selected, options) => {
    const { value, onChange } = this.props;
    const categories = value.category ? [...value.category] : [];
    if (checked) {
      categories.push(selected);
    } else {
      categories.splice(categories.indexOf(selected), 1);
    }
    onChange({ ...value, category: categories });
  };

  renderPopoverContent = ({
    segment,
    propertyCategoryValues,
    categoriesByGroup,
    value,
  }) => {
    if (segment === 'COMMERCIAL') {
      return (
        <div className="filter-container">
          <Button
            className="border-none text-left shadow-none"
            block
            onClick={this.showAny}
          >
            Any
          </Button>
          <Divider className="mt-2 mb-0" />
          {Object.keys(categoriesByGroup).map(group => {
            return (
              <div key={group}>
                <Button
                  className="border-none text-left shadow-none"
                  block
                  onClick={e => {
                    e.preventDefault();
                    this.handleGroupChange(group, propertyCategoryValues);
                  }}
                >
                  {capitalize(group)}
                </Button>
                <Divider className="mt-2 mb-0" />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="filter-container">
        <Button
          className="border-none text-left shadow-none"
          block
          onClick={this.showAny}
        >
          Any
        </Button>
        <Divider className="mt-2 mb-0" />
        <Collapse
          accordion
          activeKey={value.group}
          bordered={false}
          show
          onChange={key => this.handleGroupChange(key, propertyCategoryValues)}
        >
          {Object.keys(categoriesByGroup).map(group => {
            return (
              <Panel
                key={group}
                header={<span>{startCase(toLower(group))}</span>}
                showArrow={false}
                forceRender
              >
                <ul className="ml-2">
                  {categoriesByGroup[group].map(category => {
                    return (
                      <li className="py-2" key={category.value}>
                        <Checkbox
                          checked={
                            value.category &&
                            value.category.includes(category.value)
                          }
                          onChange={event =>
                            this.handleCategoryChange(
                              event.target.checked,
                              category.value,
                              propertyCategoryValues
                            )
                          }
                        >
                          {category.label}
                        </Checkbox>
                      </li>
                    );
                  })}
                </ul>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    );
  };

  render = () => {
    const {
      value,
      data: { propertyCategoryValues, loading, error },
      segment,
    } = this.props;
    let popoverContent;

    if (loading) {
      popoverContent = <LoadingDots />;
    }

    if (error) {
      popoverContent = <div className="p4">Filter temporarily unavailable</div>;
    }

    if (
      !loading &&
      !error &&
      propertyCategoryValues &&
      propertyCategoryValues.length
    ) {
      const categoriesByGroup = propertyCategoryValues.reduce(
        (grouped, category) => {
          if (grouped[category.group]) {
            grouped[category.group].push(category);
          } else {
            grouped[category.group] = [];
          }
          return grouped;
        },
        {}
      );

      popoverContent = this.renderPopoverContent({
        segment,
        propertyCategoryValues,
        categoriesByGroup,
        value,
      });
    }

    const toggleLabel =
      value.group && value.group.length ? (
        startCase(toLower(value.group))
      ) : (
        <span className="placeholder">Property Type</span>
      );

    return (
      <Popover
        overlayClassName="PropertyTypeFilterSelect__Popover"
        content={popoverContent}
        trigger="click"
        placement="bottomLeft"
      >
        <div className="PropertyTypeFilterSelect w-full flex flex-col justify-center">
          <Button className="filter-toggle-btn shadow-sm" size="large">
            <div className="flex items-center ">
              <span className="w-full block truncate capitalize">
                {toggleLabel}
              </span>
              <Icon type="down" className="ml-2 text-sm block" />
            </div>
          </Button>
        </div>
      </Popover>
    );
  };
}

const PropertyTypeFilterSelectWithData = graphql(PropertyCategoryValuesQuery, {
  options: ({ segment }) => ({ variables: { segment } }),
})(PropertyTypeFilterSelect);

export default PropertyTypeFilterSelectWithData;
