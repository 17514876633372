import { from, split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { logoutUser } from './actions';
import introspectionQueryResultData from './fragmentTypes.json';

export default store => {
  const httpLink = createUploadLink({ uri: process.env.REACT_APP_CLIENT_API });

  const setAuthorizationLink = setContext((_, { headers }) => {
    const token = localStorage ? localStorage.getItem('access_token') : '';
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`);

    if (graphQLErrors) {
      const isProd = process.env.NODE_ENV === 'production';

      graphQLErrors.forEach(({ extensions, message, locations, path }) => {
        if (!isProd) {
          console.log(
            `[GraphQL error]: Message: ${message}, Code: ${
              extensions ? extensions.code : ''
            }, Location: ${locations}, Path: ${path}`
          );
        }
        if (extensions && extensions.code === 'UNAUTHENTICATED') {
          store.dispatch(logoutUser());
          // https://gist.github.com/alfonmga/9602085094651c03cd2e270da9b2e3f7
        }
      });
    }
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const cache = new InMemoryCache({
    addTypename: true,
    logger: console.log,
    loggerEnabled: true,
    fragmentMatcher,
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  let link = split(
    // split based on operation type
    ({ query }) => {
      const { kind } = getMainDefinition(query);
      return kind === 'OperationDefinition';
    },
    httpLink
  );

  if (window.WebSocket) {
    const token = localStorage ? localStorage.getItem('access_token') : '';

    const wsLink = new WebSocketLink(
      new SubscriptionClient(process.env.REACT_APP_CLIENT_SUBSCRIPTION_URI, {
        reconnect: true,
        connectionParams: { authorization: token ? `Bearer ${token}` : '' },
      })
    );
    link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink
    );
  }

  const client = new ApolloClient({
    link: from([onErrorLink, setAuthorizationLink, link]),
    cache,
    connectToDevTools: true,
  });

  return client;
};
