import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import { Avatar, Button, Select, Spin } from 'antd';
import './SearchAgentInput.scss';
import { AgentSearch } from '../AgentSearchResults/Operations.graphql';

const Option = Select.Option;

class SearchAgentInput extends Component {
  state = {
    data: [],
    value: [],
    fetching: false,
  };

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 0);
  }

  handleSearch = async value => {
    const { client, searchOptions } = this.props;

    this.setState({
      fetching: true,
      currentKeyword: value,
    });

    try {
      const {
        data: { agentSearch },
      } = await client.query({
        query: AgentSearch,
        variables: {
          filter: {
            first: 5,
            name: value,
            ...searchOptions,
          },
        },
      });

      this.setState({
        fetching: false,
        data: agentSearch.nodes || {},
      });
    } catch (err) {
      console.error(err);
    }
  };

  handleChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });

    this.props.onChange(value);
  };

  handleOnInputKeyDown = e => {
    if (e.which === 13) {
      const { currentKeyword } = this.state;
      this.props.onChange(currentKeyword);
    }
  };

  renderOptions = () => {
    const { data } = this.state;

    return data.map(d => (
      <Option key={d.id} value={`${d.firstName} ${d.lastName}`}>
        <div className="flex flex-row">
          <div className="mr-2">
            <Avatar src={d.profilePictureUrl} size="large">
              {d.initials}
            </Avatar>
          </div>
          <div>
            <p className="text-black">{`${d.firstName} ${d.lastName}`}</p>
            <p className="text-gray-600 text-xs">{d.agencyName}</p>
          </div>
        </div>
      </Option>
    ));
  };

  handleBtnOnClick = () => {
    const { currentKeyword } = this.state;
    this.props.onChange(currentKeyword);
  };

  render() {
    const { fetching } = this.state;
    const { initialValue: value } = this.props;

    return (
      <>
        <Select
          size="large"
          placeholder="Search by Agent or Agency name"
          className="agent-search-keyword shadow-sm"
          showSearch
          value={value || []}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          style={{ width: '100%' }}
          allowClear
          onInputKeyDown={this.handleOnInputKeyDown}
          showArrow={false}
        >
          {this.renderOptions()}
        </Select>
        <Button
          size="large"
          type="primary"
          icon="search"
          className="ml-1 shadow-sm"
          onClick={this.handleBtnOnClick}
          style={{ width: 70, height: 50 }}
        />
      </>
    );
  }
}

const SearchAgentInputWithRouter = compose(
  withApollo,
  withRouter
)(SearchAgentInput);

export default SearchAgentInputWithRouter;
