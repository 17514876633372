import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';

class HeroTagline extends PureComponent {
  state = { stat: false };

  componentDidMount() {
    this.setState({ stat: true });
  }

  render() {
    const {
      location: { pathname },
    } = this.props;
    const { stat } = this.state;
    let tagline = <span>Inspirations for your Home</span>;

    if (pathname.startsWith('/commercial')) {
      tagline = <span>Inspirations for your Work</span>;
    }

    return (
      <CSSTransition in={stat} classNames="fade" timeout={300}>
        <p className="tagline xxl:text-5xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl text-2xl font-semibold text-white w-full poppins my-3">
          {tagline}
        </p>
      </CSSTransition>
    );
  }
}

export default withRouter(HeroTagline);
