import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Button, Icon, Popover, Radio } from 'antd';

const RadioGroup = Radio.Group;

const PopoverWrapper = props => {
  return (
    <Media query="(max-width: 425px)">
      {matches =>
        matches ? (
          <Popover placement="bottomRight" {...props}>
            {props.children}
          </Popover>
        ) : (
          <Popover placement="bottom" {...props}>
            {props.children}
          </Popover>
        )
      }
    </Media>
  );
};

class PropertyTypeFilter extends Component {
  static propTypes = {
    typeGroup: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    typeGroup: '',
    onChange: () => {},
  };

  state = {
    typeGroup: '',
  };

  handleOnApply = () => {
    this.props.onChange(this.state.typeGroup);
  };

  handleOnClear = () => {
    this.props.onChange('');
  };

  render() {
    const { typeGroup } = this.props;

    const props = {};

    if (typeGroup) {
      props.defaultValue = typeGroup;
    }

    return (
      <PopoverWrapper
        content={
          <div>
            <div className="flex justify-start py-4 pr-12">
              <RadioGroup
                {...props}
                onChange={e => {
                  this.setState({ typeGroup: e.target.value });
                }}
              >
                <Radio value="HDB" className="block">
                  <span className="text-base">HDB</span>
                </Radio>
                <Radio value="Condominium" className="block">
                  <span className="text-base">Condominium</span>
                </Radio>
                <Radio value="Landed" className="block">
                  <span className="text-base">Landed</span>
                </Radio>
              </RadioGroup>
            </div>
            <div className="flex justify-end  mt-1">
              <Button className="mr-2" onClick={this.handleOnClear}>
                Clear
              </Button>
              <Button type="primary" onClick={this.handleOnApply}>
                Apply
              </Button>
            </div>
          </div>
        }
        trigger="click"
      >
        {typeGroup ? (
          <Button type="primary" size="large">
            {typeGroup} <Icon type="caret-down" />
          </Button>
        ) : (
          <Button size="large">
            Property Type <Icon type="caret-down" />
          </Button>
        )}
      </PopoverWrapper>
    );
  }
}

export default PropertyTypeFilter;
