import React, { PureComponent } from 'react';
import { Avatar, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';
import Scrollchor from 'react-scrollchor';
import './PropertyEnquiryPanel.scss';
import AgentContactNumber from '../AgentContactNumber';
import WhatsappButton from '../WhatsappButton';
import { trackPropertyPageView } from '../../utils/analytics';

class PropertyEnquiryPanel extends PureComponent {
  componentDidMount() {
    const { property, location, shortId } = this.props;

    trackPropertyPageView(location.pathname, shortId, property.agent.id);
  }

  render() {
    const { property, scrolled, trackEnquiry } = this.props;
    const { agency, agent } = property;
    const { name } = agency || {};
    const { bannerColor } = agent || {};
    const rounded = scrolled ? 'md:rounded-t-lg' : 'md:rounded-none';

    return (
      <div
        className={`property-enquiry-panel bg-white py-2 px-4 my-6 ${rounded} md:rounded-b-lg rounded-lg border shadow show-item`}
        style={{ border: `2px solid ${bannerColor || '#4ac7f4'}` }}
      >
        <div
          style={{
            backgroundColor: '#fff',
          }}
        >
          <div className="flex justify-center items-center content-center w-full p-4">
            <Link to={`/agent/${agent.slugUrl}`} className="mr-4 relative">
              <Avatar
                alt={`Agent ${agent.firstName}`}
                size={100}
                icon="user"
                src={agent.profilePictureUrl}
                className="shadow border-4 border-white bg-gray-300"
              />
              {agent.agencyLogoUrl && (
                <Avatar
                  alt={`Agency ${agent.agencyName}`}
                  size={32}
                  icon="team"
                  src={agent.agencyLogoUrl}
                  className="absolute left-0 bottom-0 -mb-1 border-1 border-white"
                />
              )}
            </Link>
            <div className="flex-1">
              <Link to={`/agent/${agent.slugUrl}`}>
                <span className="block lg:text-base text-sm text-black font-semibold">
                  {agent.firstName} {agent.lastName}
                </span>
              </Link>
              <span className="block lg:text-base text-sm text-gray-700">
                {name}
              </span>
              {agent.cea && (
                <div className="block lg:text-base text-sm text-gray-700">
                  <span>CEA #</span>{' '}
                  <a
                    href={`https://www.cea.gov.sg/public-register?category=Salesperson&registrationNumber=${
                      agent.cea
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {agent.cea}
                  </a>
                </div>
              )}
              <div className="flex items-center w-full py-2 text-base">
                <Icon type="phone" className="mr-2" />
                <AgentContactNumber
                  agentId={agent.id}
                  propertyId={property.id}
                  contactNo={agent.mobileNumber}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center px-4 pb-4 mt-6">
            <WhatsappButton
              contactNo={agent.mobileNumber}
              propertyTitle={property.title}
              trackWhatsAppEnquiry={() =>
                trackEnquiry('Attempt WhatsApp Enquiry')
              }
            />
            <span className="block my-2 text-gray-500 text-sm">Or</span>
            <Scrollchor to="#enquiry-form" animate={{ offset: -95 }}>
              <Button
                className="font-bold tracking-wide"
                type="primary"
                shape="round"
                onClick={() => trackEnquiry('Attempt Mail Enquiry')}
              >
                Send a direct message
              </Button>
            </Scrollchor>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyEnquiryPanel;
