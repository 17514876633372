import React, { Component } from 'react';
import { Affix, Col, Collapse, Divider, Row, Tag } from 'antd';
import { graphql } from 'react-apollo';
import Media from 'react-media';
import {
  faExclamationTriangle,
  faLayerGroup,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash/capitalize';
import PropertyImages from '../../components/PropertyImages';
import PropertyEnquiryPanel from '../../components/PropertyEnquiryPanel';
import PropertyAddress from '../../components/PropertyAddress';
import PropertyFeature from '../../components/PropertyFeature';
import PropertyEnquiryForm from '../../components/PropertyEnquiryForm';
import PropertyPriceParse from '../../components/PropertyPriceParse';
import SlugUrlParser from '../../components/SlugUrlParser';
import NotFound from '../../components/NotFound';
import PublicLayout from '../../layouts/PublicLayout';
import Loading from '../../components/Loading';
import AgentBar from '../../components/AgentBar';
import './PropertyPage.scss';
import PropertyQuery from '../../queries/PropertyQuery.graphql';
import PropertyMainFeatures from '../../components/PropertyMainFeatures';
import PropertyShare from '../../components/PropertyShare';
import NearbyPlaces from '../../components/NearbyPlaces';
import PropertyMap from '../../components/PropertyMap';
import SimilarPropertiesList from '../../components/SimilarPropertiesList';
import { trackAttemptPropertyEnquiry } from '../../utils/analytics';

class PropertyPage extends Component {
  state = {
    scrolled: false,
    center: null,
    defaultTab: 'schools',
  };

  affixState = affixed => {
    this.setState({ scrolled: affixed });
  };

  handleChangeMarkerCenter = center => this.setState({ center });

  handleTabOnChange = tab => {
    this.setState({ defaultTab: tab });
  };

  handleTrackAttemptPropEnquiry = eventAction => {
    const {
      shortId,
      data: {
        property: {
          agent: { id: agentId },
        },
      },
    } = this.props;

    trackAttemptPropertyEnquiry(shortId, agentId, eventAction);
  };

  render() {
    const {
      data: { loading, error, property },
      location,
      shortId,
    } = this.props;
    const { scrolled, center, defaultTab } = this.state;

    if (loading) {
      return (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      );
    }

    if (error || !property) {
      return <NotFound />;
    }

    const {
      id,
      lat,
      lng,
      description,
      agent,
      agency,
      floorArea,
      features,
      nearbySchools,
      nearbyMrtStations,
      nearbyPostOffices,
      address,
      listingType,
      availability,
      floorLevel,
      title,
      videoLink,
      segment,
      commercialType,
    } = property || {};

    const { origin } = window.location;
    const shareLink = `${origin}${location.pathname}`;

    return (
      <PublicLayout>
        {property.agent && (
          <Media query="(max-width: 768px)">
            {matches => (
              <AgentBar
                propertyShareLink={shareLink}
                property={property}
                agent={agent}
                agency={agency}
                propertyId={id}
                scrolled={matches ? true : scrolled}
                shortId={shortId}
                location={location}
                segment={segment}
                commercialType={commercialType}
              />
            )}
          </Media>
        )}
        <PropertyImages
          propertyId={id}
          shortId={shortId}
          address={address}
          title={title}
          property={property}
          videoLink={videoLink}
          location={location}
          agent={agent}
        />
        <Row type="flex" justify="center" className="mb-12 px-4">
          <Col xs={24} sm={24} md={24} xl={22} xxl={16}>
            <Row type="flex" justify="center">
              <Col
                xs={24}
                sm={24}
                md={16}
                xl={16}
                xxl={16}
                className="mt-6 lg:px-8 px-4 mb-8"
              >
                <Row>
                  <Col span={24} className="flex items-center my-6">
                    <span className="block mr-4 p-1 bg-gray-200 rounded text-blue-800 uppercase">
                      For {listingType}
                    </span>
                    <span className="block text-2xl">
                      <PropertyPriceParse property={property} />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="property-address">
                    {segment === 'RESIDENTIAL' ? (
                      <span className="block text-gray-800">
                        {property.typeGroup} &#x25CF;{' '}
                        {property.typeCategory.label}
                      </span>
                    ) : (
                      <span className="block text-gray-800">
                        {' '}
                        {commercialType}
                      </span>
                    )}
                    <span className="block text-3xl font-bold">
                      {property.title}
                    </span>
                    <PropertyAddress
                      property={property}
                      className="block text-base"
                    />
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24}>
                    <PropertyMainFeatures
                      features={{
                        ...features,
                        psf: property.psf,
                        floorArea,
                      }}
                      segment={segment}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <div className="mt-6 mb-4">
                      <h2 className="md:text-2xl text-lg">Overview</h2>
                      <div className="flex items-center mt-4">
                        <Tag className="text-sm text-gray-900 bg-gray-100 border-gray-300 shadow-inner">
                          <FA icon={faExclamationTriangle} size="sm" />{' '}
                          {capitalize(availability)}
                        </Tag>
                        <Tag className="text-sm text-gray-900 bg-gray-100 border-gray-300 shadow-inner">
                          <FA icon={faLayerGroup} size="sm" />{' '}
                          {capitalize(floorLevel)}
                        </Tag>
                      </div>
                      <div className="mt-6 text-base w-full property-description-wrapper false">
                        <div className="overflow-hidden transition property-description whitespace-pre-line break-word">
                          <div
                            className="text-justify"
                            dangerouslySetInnerHTML={{
                              __html: description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <PropertyFeature propertyId={id} />
                  </Col>
                </Row>
                {lat && lng && (
                  <>
                    <Row>
                      <Col>
                        <div id="property-map" className="my-8 property-map">
                          <PropertyMap
                            center={center}
                            lat={lat || -25.1547443}
                            lng={lng || 134.046491}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry`}
                            loadingElement={<div style={{ height: '100%' }} />}
                            containerElement={
                              <div
                                style={{
                                  height: '300px',
                                  width: '100%',
                                }}
                              />
                            }
                            mapElement={<div style={{ height: '100%' }} />}
                            isMarkerShown
                            defaultMarker={defaultTab}
                            nearbySchools={nearbySchools}
                            nearbyMrtStations={nearbyMrtStations}
                            nearbyPostOffices={nearbyPostOffices}
                            handleChangeMarkerCenter={
                              this.handleChangeMarkerCenter
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="mt-4">
                  <Col>
                    <NearbyPlaces
                      nearbySchools={nearbySchools}
                      nearbyMrtStations={nearbyMrtStations}
                      nearbyPostOffices={nearbyPostOffices}
                      handleTabOnChange={this.handleTabOnChange}
                      handleChangeMarkerCenter={this.handleChangeMarkerCenter}
                    />
                  </Col>
                </Row>
                {agent && (
                  <>
                    <Divider />
                    <Row>
                      <Col>
                        <div className="w-full flex justify-center flex-col items-center">
                          <div
                            className="mt-4 xxl:w-2/3 md:w-3/4 w-full"
                            id="enquiry-form"
                          >
                            <Collapse
                              className="property-enquiry-collapse"
                              bordered={false}
                              activeKey={['1']}
                            >
                              <Collapse.Panel
                                header={
                                  <span className="uppercase text-gray-900 font-bold text-center block">
                                    Send a Message
                                  </span>
                                }
                                key="1"
                                className="shadow bg-gray-200 mb-4 property-enquiry-panel"
                              >
                                <PropertyEnquiryForm propertyId={id} />
                              </Collapse.Panel>
                            </Collapse>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col
                xs={22}
                sm={18}
                md={8}
                xl={8}
                xxl={8}
                className="lg:px-4 z-20"
              >
                <Affix
                  className="mt-0 mx-0 md:block hidden"
                  onChange={this.affixState}
                  offsetTop={95}
                >
                  <PropertyEnquiryPanel
                    property={property}
                    scrolled={scrolled}
                    shortId={shortId}
                    location={location}
                    trackEnquiry={this.handleTrackAttemptPropEnquiry}
                  />
                  <div />
                </Affix>

                <div className="md:hidden block">
                  <PropertyEnquiryPanel
                    property={property}
                    scrolled={scrolled}
                    shortId={shortId}
                    location={location}
                    trackEnquiry={this.handleTrackAttemptPropEnquiry}
                  />
                </div>
                <PropertyShare
                  propertyShareLink={shareLink}
                  property={property}
                  shortId={shortId}
                  location={location}
                  className="items-center"
                />
              </Col>
            </Row>
            <SimilarPropertiesList property={property} />
          </Col>
        </Row>
      </PublicLayout>
    );
  }
}

const PropertyPageWithData = graphql(PropertyQuery, {
  options: ({ shortId }) => ({
    variables: { shortId },
  }),
})(PropertyPage);

export default props => (
  <SlugUrlParser {...props}>
    {shortId => <PropertyPageWithData shortId={shortId} {...props} />}
  </SlugUrlParser>
);
