import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AgentPropertyInfiniteList from '../AgencyPropertyInfiniteList';

class AgentPropertyTab extends Component {
  static propTypes = {
    id: PropTypes.string,
    group: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    group: 'BUY',
  };

  render() {
    const { id, group } = this.props;

    return (
      <Fragment>
        <AgentPropertyInfiniteList id={id} group={group} />
      </Fragment>
    );
  }
}

export default AgentPropertyTab;
