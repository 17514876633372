/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Avatar, Button, Dropdown, Icon, Menu } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import slice from 'lodash/slice';
import isEmpty from 'lodash.isempty';
import Linkify from 'react-linkify';
import ConsumerPostMeta from '../ConsumerPostMeta';
import DeletePostEvent from './DeletePostEvent';
import UpdatePostEvent from './UpdatePostEvent';
import './FeedPostEvent.scss';
import ImagesSliderModal from '../../ImagesSliderModal';

class FeedPostEvent extends Component {
  static propTypes = {
    event: PropTypes.object,
    pageId: PropTypes.string,
    consumerPageId: PropTypes.string,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    recalculateHeight: PropTypes.func,
  };

  static defaultProps = {
    event: {},
    pageId: null,
    consumerPageId: null,
    onDelete: () => {},
    onEdit: () => {},
    recalculateHeight: () => {},
  };

  state = {
    carouselVisible: false,
    carouselImages: null,
    initialSlide: null,
    contentHeight: 0,
  };

  componentDidMount = () => {
    const { recalculateHeight } = this.props;
    const { contentHeight } = this.state;
    if (contentHeight === 0 && this.content) {
      this.setState({ contentHeight: this.content.clientHeight });
      setTimeout(() => {
        recalculateHeight();
      }, 200);
    }
  };

  handleReadMoreContent = readMoreContent => {
    const { recalculateHeight } = this.props;

    if (readMoreContent) {
      this.setState({ readMoreContent });
      recalculateHeight();
    }
  };

  handleImageCarousel = (images, index) => {
    this.setState({
      carouselVisible: true,
      carouselImages: images,
      initialSlide: index,
    });
  };

  onImagesModalClose = () => {
    this.setState({ carouselVisible: false });
  };

  render() {
    const { onEdit, onDelete, consumerPageId, pageId, event } = this.props;
    const {
      carouselVisible,
      carouselImages,
      initialSlide,
      readMoreContent,
    } = this.state;
    const { createdAt, post } = event;
    const {
      contributor: {
        firstName,
        lastName,
        initials,
        profilePicture,
        name,
        // profileImage,
        profilePageId,
      },
      isEdited,
      isOwner,
      images,
      content,
      checkIn,
    } = post;

    const metas = {
      description: post.metaDescription,
      host: post.metaHost,
      image: post.metaImage,
      title: post.metaTitle,
      url: post.metaUrl,
    };

    const menu = (
      <Menu>
        <Menu.Item className="p-0">
          <UpdatePostEvent
            event={event}
            onEdit={onEdit}
            pageId={pageId}
            consumerPageId={consumerPageId}
          />
        </Menu.Item>
        <Menu.Item className="p-0">
          <DeletePostEvent
            event={event}
            onDelete={onDelete}
            pageId={pageId}
            consumerPageId={consumerPageId}
          />
        </Menu.Item>
      </Menu>
    );

    const displayContent =
      content && !readMoreContent ? content.slice(0, 500) : content;

    return (
      <>
        <section
          className="flex items-center justify-center FeedPostEvent px-4"
          ref={ref => {
            this.postFeed = ref;
          }}
        >
          <div className="mr-2 py-2 text-center block">
            <Link
              to={`/${pageId ||
                profilePageId ||
                name ||
                `${firstName}${lastName}`}`}
              className="text-gray-900 font-semibold"
            >
              <Avatar
                alt={`${firstName} ${lastName}`}
                size={52}
                src={profilePicture /* || profileImage */}
                style={{
                  color: '#4ac7f4',
                  backgroundColor: '#fff',
                  border: '2px solid #4ac7f4',
                  width: 52,
                  height: 52,
                  fontWeight: 500,
                }}
              >
                {initials}
              </Avatar>
            </Link>
          </div>
          <div className="flex flex-col flex-grow">
            <p className="font-normal text-sm">
              <Link
                to={`/${pageId ||
                  profilePageId ||
                  name ||
                  `${firstName}${lastName}`}`}
                className="text-gray-900 font-semibold"
              >
                {name}
                {firstName}
                &nbsp;
                {lastName}
              </Link>
              &nbsp;
              {checkIn ? (
                <>
                  is at &nbsp;
                  <span className="font-semibold">
                    <Icon type="environment" className="text-base" />
                    &nbsp;
                    {checkIn}
                  </span>
                </>
              ) : (
                'added a new post'
              )}
            </p>
            <div>
              <time className="text-sm">
                <Icon type="clock-circle" theme="outlined" />{' '}
                {moment(createdAt).fromNow()}
              </time>
              {isEdited && (
                <span className="text-sm text-gray-800">
                  &nbsp;&bull; Edited&nbsp;
                  {/* {moment(updatedAt).fromNow()} */}
                </span>
              )}
            </div>
          </div>
          {isOwner && (
            <div className="post-options">
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
              >
                <Button className="border-none ">
                  <FA icon={faEllipsisV} className="text-xl" />
                </Button>
              </Dropdown>
            </div>
          )}
        </section>
        <div
          className={`p-4 text-sm whitespace-pre-wrap feed-post-content content-wrapper ${readMoreContent &&
            'content-readmore'}`}
        >
          <div className="overflow-hidden transition">
            <Linkify properties={{ target: '_blank' }} className="break-all">
              {displayContent}
            </Linkify>
          </div>

          {content && content.length > 500 && !readMoreContent && (
            <p
              className="cursor-pointer text-blue-700 font-medium content-toggle"
              onClick={() => this.handleReadMoreContent(!readMoreContent)}
              aria-hidden
            >
              <span className="border border-blue-500 px-2 py-1 rounded-lg bg-white">
                {readMoreContent ? 'Read less' : 'Read more'}
              </span>
            </p>
          )}
        </div>
        {!isEmpty(images) && (
          <>
            {images.length === 1 &&
              images.map(({ url }) => (
                <div
                  key={url}
                  onClick={() => this.handleImageCarousel(images, 0)}
                  className="FeedSingleImage  pt-4  cursor-pointer text-center"
                  style={{
                    height: 500,
                    width: '100%',
                  }}
                >
                  <img
                    alt=""
                    src={url}
                    className="w-full"
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                      position: 'relative',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>
              ))}
            {images.length > 1 && images.length <= 3 && (
              <div className="FeedMultipleImages flex flex-wrap px-3 pt-4 pb-4">
                {images.map(({ url }, index) => {
                  const imageBoxWidth =
                    index === 0 ? 'FeedFullImageBox' : 'FeedHalfImageBox';

                  return (
                    <div
                      key={url}
                      style={{ backgroundImage: `url(${url})` }}
                      onClick={() => this.handleImageCarousel(images, index)}
                      className={`FeedImageBox ${imageBoxWidth} flex-grow flex-shrink-0 mx-1 mb-2 cursor-pointer`}
                    />
                  );
                })}
              </div>
            )}
            {images.length > 3 && (
              <div className="FeedMultipleImages flex flex-wrap px-3 pt-4 pb-4">
                {slice(images, 0, 3).map(({ url }, index) => {
                  const extraImagesCount = `+ ${images.length - 3}`;
                  const imageBoxWidth =
                    index === 0 ? 'FeedFullImageBox' : 'FeedHalfImageBox';
                  const imageLayout =
                    index === 2 ? (
                      <div
                        key={url}
                        style={{ backgroundImage: `url(${url})` }}
                        className={`FeedImageBox ${imageBoxWidth} relative flex-grow flex-shrink-0 mx-1 mb-2`}
                      >
                        <div
                          className="absolute w-full h-full z-20 cursor-pointer flex items-center justify-center"
                          onClick={() =>
                            this.handleImageCarousel(images, index)
                          }
                          style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                        >
                          <div className="text-white text-5xl font-thin font-bold">
                            {extraImagesCount}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={url}
                        style={{ backgroundImage: `url(${url})` }}
                        onClick={() => this.handleImageCarousel(images, index)}
                        className={`FeedImageBox ${imageBoxWidth} flex-grow flex-shrink-0 mx-1 mb-2 cursor-pointer`}
                      />
                    );

                  return imageLayout;
                })}
              </div>
            )}
          </>
        )}
        {post.metaDescription && (
          <div className="lg:py-0 ">
            <section className="">
              <ConsumerPostMeta
                {...metas}
                onRemoveMeta={this.onRemoveMeta}
                onRemoveImage={this.onRemoveImage}
              />
            </section>
          </div>
        )}

        <ImagesSliderModal
          visible={carouselVisible}
          images={carouselImages}
          initialSlide={initialSlide}
          onClose={this.onImagesModalClose}
        />
      </>
    );
  }
}

export default FeedPostEvent;
