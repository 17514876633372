import React, { Component } from 'react';
import omit from 'lodash/omit';
import Helmet from 'react-helmet';
import { Col, Row, Button } from 'antd';
import './AgentSearchPage.scss';
import PublicLayout from '../../layouts/PublicLayout';
import AgentSearchPageQueryParameterParser from '../../components/AgentSearchPageQueryParameterParser';
import AgentSearchResults from '../../components/AgentSearchResults';
import SearchAgentInput from '../../components/SearchAgentInput';
import PropertyListingFilter from '../../components/filters/agentsearch/PropertyListingFilter';
import PropertyTypeFilter from '../../components/filters/agentsearch/PropertyTypeFilter';
import PriceRangeFilter from '../../components/filters/agentsearch/PriceRangeFilter';
import LocationFilter from '../../components/filters/agentsearch/LocationFilter';

class AgentSearchPage extends Component {
  handleOnChange = values => {
    let params = values;

    if (!values.listingType) {
      params = omit(params, 'listingType');
    }

    if (!values.typeGroup) {
      params = omit(params, 'typeGroup');
    }

    if (!values.budgetRange || values.budgetRange.length === 0) {
      params = omit(params, 'budgetRange');
    }

    if (!values.region || values.region.length === 0) {
      params = omit(params, 'region');
    }

    this.props.changeUrl(params);
  };

  render() {
    const { searchOptions } = this.props;

    return (
      <PublicLayout>
        <div className="agent-search-page">
          <Helmet defer={false}>
            <title>Real Estate Singapore | Search for Agent</title>
          </Helmet>
        </div>
        <Row type="flex" justify="center">
          <Col
            xs={24}
            className="search-agent-input-wrapper flex justify-center pt-10 px-5"
          >
            <SearchAgentInput
              initialValue={searchOptions.name || ''}
              searchOptions={searchOptions}
              onChange={value => {
                this.handleOnChange({
                  ...searchOptions,
                  ...{ name: value },
                });
              }}
            />
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          className="mt-3 mb-3 w-full"
          gutter={8}
        >
          <Col className="mt-2 md:mt-0">
            <PropertyListingFilter
              listingType={searchOptions.listingType}
              onChange={filter => {
                this.handleOnChange({
                  ...searchOptions,
                  ...{ listingType: filter, budgetRange: [] },
                });
              }}
            />
          </Col>
          <Col className="mt-2 md:mt-0">
            <PropertyTypeFilter
              typeGroup={searchOptions.typeGroup}
              onChange={filter => {
                this.handleOnChange({
                  ...searchOptions,
                  ...{ typeGroup: filter },
                });
              }}
            />
          </Col>
          {searchOptions.listingType && (
            <Col className="mt-2 md:mt-0">
              <PriceRangeFilter
                listingType={searchOptions.listingType}
                budgetRange={searchOptions.budgetRange}
                onChange={filter => {
                  this.handleOnChange({
                    ...searchOptions,
                    ...{ budgetRange: filter },
                  });
                }}
              />
            </Col>
          )}
          <Col className="mt-2 md:mt-0">
            <LocationFilter
              region={searchOptions.region}
              onChange={filter => {
                this.handleOnChange({
                  ...searchOptions,
                  ...{ region: filter },
                });
              }}
            />
          </Col>
          <Col className="mt-2 md:mt-0">
            <Button
              size="large"
              onClick={() => {
                this.handleOnChange({
                  name: searchOptions.name,
                });
              }}
            >
              Clear Filters
            </Button>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          className="bg-gray-200 search-full-page"
        >
          <Col xs={22} sm={22} md={20} lg={20} xl={22} xxl={22}>
            <Row>
              <Col xs={24} key="2">
                <div className="md:my-6 my-4">
                  <AgentSearchResults searchOptions={searchOptions} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </PublicLayout>
    );
  }
}

export default props => (
  <AgentSearchPageQueryParameterParser {...props}>
    {({ searchOptions, changeUrl }) => (
      <AgentSearchPage
        searchOptions={searchOptions}
        changeUrl={changeUrl}
        {...props}
      />
    )}
  </AgentSearchPageQueryParameterParser>
);
