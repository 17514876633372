import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import * as TWEEN from '@tweenjs/tween.js/dist/tween.umd.js';
import { Swipeable } from 'react-swipeable';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import './ImagesSlider.scss';

export const Carouselmage = memo(({ imageUrl, width }) => (
  <div className="each-fade">
    <div className="image-container">
      <img
        alt=""
        key={imageUrl}
        data-src={`${imageUrl}?w=${width}`}
        src={`${imageUrl}?fit=crop&crop=entropy&px=1&blur=200&auto=format&w=70&height=50`}
        className="image-slider-image lazyload"
      />
    </div>
  </div>
));

class ImagesSlider extends Component {
  static defaultProps = {
    duration: 5000,
    transitionDuration: 1000,
    indicators: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    initialSlide: 0,
    style: {},
    className: '',
  };

  static propTypes = {
    duration: PropTypes.number,
    transitionDuration: PropTypes.number,
    indicators: PropTypes.bool,
    arrows: PropTypes.bool,
    autoplay: PropTypes.bool,
    infinite: PropTypes.bool,
    initialSlide: PropTypes.number,
    style: PropTypes.object,
  };

  state = {
    showArrows: false,
    children: [],
    index: 0,
  };
  setWidth = () => {
    this.width = document.querySelector(
      '.ImagesSlider .react-slideshow-fade-wrapper'
    ).clientWidth;
    this.applyStyle();
  };
  resizeListener = () => {
    this.setWidth();
  };
  goto = ({ target }) => {
    if (target.dataset.key !== this.state.index) {
      this.fadeImages(parseInt(target.dataset.key));
    }
  };
  preFadeNext = () => {
    const { index, children } = this.state;
    this.fadeImages((index + 1) % children.length);
  };
  preFadePrev = () => {
    const { index, children } = this.state;
    this.fadeImages(index === 0 ? children.length - 1 : index - 1);
  };
  showArrows = () => {
    const { arrows } = this.props;

    console.log(arrows);
    if (arrows) {
      this.setState({ showArrows: true });
    }
  };
  hideArrows = () => {
    const { arrows } = this.props;

    if (arrows) {
      this.setState({ showArrows: false });
    }
  };
  handleSwipe = throttle(
    isNext => {
      if (isNext) {
        this.preFadeNext();
      } else {
        this.preFadePrev();
      }
    },
    500,
    { trailing: false }
  );

  constructor(props) {
    super(props);
    this.width = 0;
    this.timeout = null;
    this.divsContainer = null;
    this.state = { index: props.initialSlide };
  }

  componentWillMount() {
    const { duration, children, autoplay } = this.props;
    if (autoplay) {
      this.timeout = setTimeout(() => this.fadeImages(1), duration);
    }
    this.setState({ children });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeListener);
    this.setWidth();
  }

  componentWillUnmount() {
    this.willUnmount = true;
    clearTimeout(this.timeout);
    window.removeEventListener('resize', this.resizeListener);
  }

  applyStyle() {
    const fullwidth = this.width * this.props.children.length;
    this.divsContainer.style.width = `${fullwidth}px`;
    for (let index = 0; index < this.divsContainer.children.length; index++) {
      const eachDiv = this.divsContainer.children[index];
      if (eachDiv) {
        eachDiv.style.width = `${this.width}px`;
        eachDiv.style.left = `${index * -this.width}px`;
      }
    }
  }

  render() {
    const { indicators, arrows, infinite, style, className } = this.props;
    const { children, index, showArrows } = this.state;

    return (
      <div
        style={style}
        className={`ImagesSlider ${className}`}
        onMouseEnter={this.showArrows}
        onMouseLeave={this.hideArrows}
      >
        <Swipeable
          onSwipingLeft={() => this.handleSwipe(true)}
          onSwipingRight={() => this.handleSwipe()}
        >
          <div className="react-slideshow-container">
            {arrows && (
              <div
                className={`nav nav-prev ${showArrows ? 'block' : 'hidden'} ${
                  index <= 0 && !infinite ? 'disabled' : ''
                }`}
                onClick={this.preFadePrev}
              >
                <FA
                  icon={faChevronLeft}
                  theme="outlined"
                  className="arrow prev"
                />
              </div>
            )}
            <div className="react-slideshow-fade-wrapper">
              <div
                className="react-slideshow-fade-images-wrap"
                ref={wrap => (this.divsContainer = wrap)}
              >
                {children && children.length > 0 ? (
                  children.map((each, key) => (
                    <div
                      style={{
                        opacity: key === index ? '1' : '0',
                        zIndex: key === index ? '1' : '0',
                      }}
                      data-index={key}
                      key={key}
                    >
                      {each}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      opacity: index === 0 ? '1' : '0',
                      zIndex: index === 0 ? '1' : '0',
                    }}
                    data-index={0}
                    key={0}
                  >
                    {children}
                  </div>
                )}
              </div>
            </div>
            {arrows && (
              <div
                className={`nav nav-next ${showArrows ? 'block' : 'hidden'} ${
                  index === children.length - 1 && !infinite ? 'disabled' : ''
                }`}
                onClick={this.preFadeNext}
              >
                <FA
                  icon={faChevronRight}
                  theme="outlined"
                  className="arrow next"
                />
              </div>
            )}
          </div>
          {indicators && (
            <div className="indicators">
              {children.map((each, key) => (
                <div
                  key={key}
                  data-key={key}
                  className={index === key ? 'active' : ''}
                  onClick={this.goto}
                />
              ))}
            </div>
          )}
        </Swipeable>
      </div>
    );
  }

  fadeImages(newIndex) {
    const { children, index } = this.state;
    const { autoplay, infinite, duration, transitionDuration } = this.props;
    clearTimeout(this.timeout);
    const value = { opacity: 0 };

    const animate = () => {
      if (this.willUnmount) {
        TWEEN.default.removeAll();
        return;
      }
      requestAnimationFrame(animate);
      TWEEN.default.update();
    };

    animate();

    const tween = new TWEEN.Tween(value)
      .to({ opacity: 1 }, transitionDuration)
      .onUpdate(value => {
        this.divsContainer.children[newIndex].style.opacity = value.opacity;
        this.divsContainer.children[index].style.opacity = 1 - value.opacity;
      })
      .start();

    tween.onComplete(() => {
      if (this.willUnmount) {
        return;
      }
      this.setState({ index: newIndex });
      if (autoplay && (infinite || newIndex < children.length - 1)) {
        this.timeout = setTimeout(() => {
          this.fadeImages((newIndex + 1) % children.length);
        }, duration);
      }
    });
  }
}

export default ImagesSlider;
