import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import AgentEventMutation from './Operations.graphql';

class AgentContactNumber extends PureComponent {
  static propTypes = {
    useTel: PropTypes.bool,
    agent: PropTypes.object,
    agentId: PropTypes.string,
    contactNo: PropTypes.string,
    agentContactNumberMutation: PropTypes.func,
    className: PropTypes.string,
    gaEventCategory: PropTypes.string,
    gaEventAction: PropTypes.string,
    propertyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    useTel: false,
    agent: {},
    className: '',
    gaEventCategory: 'Property Enquiry',
    gaEventAction: 'Click agent contact number',
    createAgentEvent: () => {},
  };

  constructor(props) {
    super(props);

    const { contactNo = '' } = props;
    const parseNumber = contactNo
      ? contactNo.substring(0, 4) + '•'.repeat(contactNo.length - 4)
      : '';
    this.state = { isFull: false, viewNumber: parseNumber };
  }

  handleClick = () => {
    const { agentId, contactNo, propertyId, createAgentEvent } = this.props;
    const { viewNumber } = this.state;

    this.setState({ viewNumber: contactNo, isFull: true });

    if (viewNumber !== contactNo) {
      createAgentEvent({
        agentId,
        propertyId,
        eventName: 'CONTACT_NUMBER_CLICK',
      })
        .then(() => {
          this.setState({ viewNumber: contactNo, isFull: true });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { className, useTel } = this.props;
    const { viewNumber, isFull } = this.state;

    return (
      <span
        onClick={this.handleClick}
        aria-hidden
        className={`cursor-pointer ${className}`}
      >
        {isFull ? (
          <>
            {useTel ? (
              <a href={`tel:${viewNumber}`}>{viewNumber}</a>
            ) : (
              viewNumber
            )}
          </>
        ) : (
          viewNumber
        )}
      </span>
    );
  }
}

const agentContactNumberWithMutation = compose(
  graphql(AgentEventMutation, {
    props: ({ mutate }) => ({
      createAgentEvent: input => mutate({ variables: { input } }),
    }),
  })
)(AgentContactNumber);

export default agentContactNumberWithMutation;
