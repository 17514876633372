/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import ConsumerPageQuery from './Operations.graphql';
import NotFound from '../NotFound';
import Error from '../Error';
import LoadingDots from '../LoadingDots';
// import BusinessPage from '../../pages/ContributorPage';
import ConsumerProfilePage from '../../pages/ConsumerProfilePage';
import PublicLayout from '../../layouts/PublicLayout';

class ConsumerPageRenderer extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
    pageId: PropTypes.string,
  };

  static defaultProps = {
    data: {},
    pageId: null,
  };

  render() {
    const {
      data: { loading, error, consumerPage },
    } = this.props;

    const {
      pageId,
      // type,
      isPageOwner,
    } = consumerPage || {};

    if (loading)
      return (
        <div className="h-screen flex items-center">
          <LoadingDots />
        </div>
      );
    if (error) return <Error error={error} />;

    if (!consumerPage) return <NotFound />;

    return (
      <PublicLayout>
        {/*{type === 'BUSINESS' && <BusinessPage pageId={pageId} isPageOwner={isPageOwner} />}*/}
        {/*{type === 'PROFILE' && <ConsumerProfilePage pageId={pageId} isPageOwner={isPageOwner} consumerPage={consumerPage} />}*/}
        <ConsumerProfilePage
          pageId={pageId}
          isPageOwner={isPageOwner}
          // consumerPage={consumerPage}
        />
      </PublicLayout>
    );
  }
}

const ConsumerPageRendererWithData = compose(
  graphql(ConsumerPageQuery, {
    options: ({ pageId }) => ({ variables: { pageId: pageId || null } }),
  })
)(ConsumerPageRenderer);

export default ({ match }) => (
  <ConsumerPageRendererWithData pageId={match.params.pageId} />
);
