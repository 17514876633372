import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LikeButton from './LikeButton';
import ShareActions from './ShareActions';

class ConsumerEventActions extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    property: PropTypes.object,
    uuid: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    property: {},
    uuid: '',
  };

  render() {
    const { id, uuid, property } = this.props;
    return (
      <div className="flex justify-center mb-4">
        <div className="flex justify-center bg-gray-200 rounded-full overflow-hidden">
          <LikeButton id={id} />
          {/* <Button className="rounded-none rounded-bl-lg border-none leading-loose h-12 px-4" icon="message">
                    0
                    </Button> */}
          <ShareActions id={id} uuid={uuid} property={property} />
        </div>
      </div>
    );
  }
}

export default ConsumerEventActions;
