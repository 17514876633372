import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';

// Polyfills
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'blueimp-canvas-to-blob/js/canvas-to-blob.min';
import './polyfills';

import 'lazysizes/plugins/respimg/ls.respimg.js';
import 'lazysizes/plugins/blur-up/ls.blur-up.js';
import 'lazysizes';

import App from './components/App';
import * as serviceWorker from './serviceWorker';

import './index.css';

import client from './client';
import createStore from './store';
// import 'canvas-toBlob';

const storeProvider = createStore();

const Root = () => (
  <ApolloProvider client={client(storeProvider)}>
    <ReduxProvider store={storeProvider}>
      <IntlProvider locale="en">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IntlProvider>
    </ReduxProvider>
  </ApolloProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
