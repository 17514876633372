import React, { Component } from 'react';
import { List } from 'antd';

class NearbyPostOffices extends Component {
  handleOnClick = postOffice => {
    this.props.handleChangeMarkerCenter({
      id: postOffice.id,
      lat: postOffice.lat,
      lng: postOffice.lng,
    });
  };

  render() {
    const { postOffices } = this.props;

    return (
      <div>
        <List
          dataSource={postOffices}
          renderItem={postOffice => (
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.handleOnClick(postOffice);
                    }}
                  >
                    {postOffice.branch}
                  </a>
                }
              />
              <div>
                <p>{postOffice.distance.toFixed(2)} km</p>
              </div>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

export default NearbyPostOffices;
