/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Avatar } from 'antd';
import { graphql } from 'react-apollo';
import { CurrentConsumer } from './Operations.graphql';

class ConsumerProfilePostAvatar extends PureComponent {
  render() {
    const {
      data: { loading, viewer },
    } = this.props;

    if (loading) {
      return (
        <Avatar
          style={{
            color: '#4ac7f4',
            backgroundColor: '#fff',
            border: '2px solid #4ac7f4',
            width: 43,
            height: 43,
          }}
          icon="user"
          size="large"
        />
      );
    }

    const { profilePicture, initials } = viewer || {};

    return (
      <Avatar
        style={{
          color: '#4ac7f4',
          backgroundColor: '#fff',
          border: '2px solid #4ac7f4',
          width: 43,
          height: 43,
        }}
        size="large"
        src={profilePicture}
        alt="avatar"
      >
        {initials}
      </Avatar>
    );
  }
}

export default graphql(CurrentConsumer)(ConsumerProfilePostAvatar);
