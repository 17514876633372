/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Icon } from 'antd';
import { ConsumerPageQuery } from './Operations.graphql';
import Error from '../../Error';

class ConsumerAbout extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    editable: PropTypes.bool,
  };

  static defaultProps = {
    data: {},
    editable: null,
  };

  state = {};

  render() {
    const {
      data: { loading, error, consumerPage },
      editable,
    } = this.props;
    if (error) return <Error error={error} />;

    const { about } = consumerPage || {};

    if (!loading && !about) {
      return null;
    }

    return (
      <div className="pt-3">
        <Card
          loading={loading}
          bodyStyle={{ padding: 0 }}
          bordered={false}
          className="shadow rounded"
          title={<span className="font-normal">About Me</span>}
          extra={
            editable && (
              <Link to="/_/consumer/profile" className="rounded-full p-2">
                <Icon type="edit" />
              </Link>
            )
          }
        >
          <main className="flex flex-wrap py-3 px-6">{about}</main>
        </Card>
      </div>
    );
  }
}

const ConsumerAboutWithData = compose(
  graphql(ConsumerPageQuery, {
    options: ({ pageId }) => ({ variables: { pageId } }),
  })
)(ConsumerAbout);

export default ConsumerAboutWithData;
