import React from 'react';
import { Col, Row, Spin } from 'antd';

function LoadingScreen() {
  return (
    <Row className="flex justify-center mt-20">
      <Col>
        <div>
          <Spin
            size="large"
            tip={
              <span className="text-2xl text-center text-white px-4">
                Finding agents perfectly matched for you.
              </span>
            }
          />
        </div>
      </Col>
    </Row>
  );
}

export default LoadingScreen;
