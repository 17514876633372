import React, { Component } from 'react';
import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import ModalBuyPriceFilterRangeFormItem from '../ModalBuyPriceFilterRangeFormItem';
import ModalAreaFilterRangeFormItem from '../ModalAreaFilterRangeFormItem';
import TagSelect from '../TagSelect';
import ModalRadio from './ModalRadio';

const { TagSelectOption } = TagSelect;

class SearchMoreFiltersModal extends Component {
  clearPropertyFeatures = () => {
    const {
      form: { resetFields },
    } = this.props;

    resetFields(['bed', 'bathroom']);
  };

  render() {
    const {
      visible,
      form: { getFieldDecorator, getFieldsValue, getFieldValue },
      data,
      type,
      onOk,
      onClose,
      segment,
    } = this.props;
    const { furnishType, price, bed, bathroom, floorArea, landArea } = data;

    if (!visible) return null;

    const hasValue = getFieldValue('bed') || getFieldValue('bathroom');

    return (
      <Form>
        <Modal
          title="More Filters"
          width={700}
          visible={visible}
          okText="Show results"
          onCancel={onClose}
          onOk={e =>
            onOk(
              e,
              getFieldsValue([
                'furnishTypeModal',
                'priceModal',
                'bedModal',
                'bathroomModal',
                'floorAreaModal',
                'landAreaModal',
              ])
            )
          }
        >
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <span className="block font-bold text-base">Furnishing</span>
            </Col>
          </Row>
          <Form.Item className="w-auto mt-6">
            {getFieldDecorator('furnishTypeModal', {
              initialValue: furnishType,
            })(
              <TagSelect>
                <TagSelectOption value="FULL">Fully furnished</TagSelectOption>
                <TagSelectOption value="SEMI">Semi-furnished</TagSelectOption>
                <TagSelectOption value="NONE">Unfurnished</TagSelectOption>
              </TagSelect>
            )}
          </Form.Item>

          <Form.Item className="w-auto form-item search-option sm:hidden block mt-6">
            {getFieldDecorator('priceModal', { initialValue: price })(
              <ModalBuyPriceFilterRangeFormItem type={type} />
            )}
          </Form.Item>
          <Divider className="sm:hidden block" />
          {segment === 'RESIDENTIAL' && (
            <>
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <span className="inline-block lg:font-medium font-bold text-base">
                    Property Features
                  </span>
                </Col>
                <Col>
                  <Button
                    disabled={!hasValue}
                    className="border-0"
                    onClick={this.clearPropertyFeatures}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
              <Form.Item className="w-auto mt-6">
                {getFieldDecorator('bedModal', { initialValue: bed })(
                  <ModalRadio name="Bed" />
                )}
              </Form.Item>
              <Form.Item className="w-auto mt-6">
                {getFieldDecorator('bathroomModal', { initialValue: bathroom })(
                  <ModalRadio name="Bath" type="bath" />
                )}
              </Form.Item>
            </>
          )}
          <Form.Item className="w-auto form-item search-option mt-6">
            {getFieldDecorator('floorAreaModal', { initialValue: floorArea })(
              <ModalAreaFilterRangeFormItem type="floor" />
            )}
          </Form.Item>
          <Form.Item className="w-auto form-item search-option mt-6">
            {getFieldDecorator('landAreaModal', { initialValue: landArea })(
              <ModalAreaFilterRangeFormItem />
            )}
          </Form.Item>
        </Modal>
      </Form>
    );
  }
}

export default Form.create()(SearchMoreFiltersModal);
