import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faImages,
  faThLarge,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import replace from 'lodash/replace';
import slice from 'lodash/slice';
import { buildURL } from '../../utils/realty-cdn';
import PropertyObjectsQuery from '../../queries/PropertyObjectsQuery.graphql';
import LoadingDots from '../LoadingDots';
import defaultImage from '../../assets/default-property-image.svg';
import PropertyViewImagesModal from '../PropertyViewImagesModal';
import PropertyViewVideos from '../modals/PropertyViewVideos/PropertyViewVideos';
import BookmarkProperty from '../BookmarkProperty';
import PropertyCreateTopic from '../PropertyCreateTopic';
import './PropertyImages.scss';
import {
  trackPropertyVideoView,
  trackPropertyPageImages,
  trackPropertyPageImagesModal,
} from '../../utils/analytics';

const ButtonGroup = Button.Group;

class PropertyImages extends Component {
  static propTypes = {
    data: PropTypes.object,
    propertyId: PropTypes.string,
    address: PropTypes.string,
    title: PropTypes.string,
    agent: PropTypes.object,
    location: PropTypes.object,
  };

  static defaultProps = {
    data: {},
    propertyId: '',
    address: '',
    title: '',
    agent: {},
    location: {},
  };

  state = {
    showPropImgModal: false,
    isShowViewVideosModal: false,
    floorPlan: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      data: { propertyObjects },
      agent,
      shortId,
    } = props;

    const mappedPropertyObjects =
      propertyObjects &&
      propertyObjects.map(o => ({
        ...o,
        url: buildURL(o.url, o.params),
      }));

    const primaryImage =
      mappedPropertyObjects &&
      (mappedPropertyObjects.find(
        propertyObject => propertyObject.key === 'm'
      ) ||
        mappedPropertyObjects[0]);

    const images =
      mappedPropertyObjects &&
      mappedPropertyObjects.filter(
        propertyObject =>
          propertyObject.key !== 'm' && propertyObject.type === 'IMAGE'
      );

    if (primaryImage) {
      const objectId = replace(primaryImage.objectId, 'properties/', '');
      trackPropertyPageImages(shortId, objectId, agent.id, primaryImage.type);
    }

    if (images) {
      slice(images, 0, 2).map(image => {
        const objectId = replace(image.objectId, 'properties/', '');
        trackPropertyPageImages(shortId, objectId, agent.id, image.type);
      });
    }
    return null;
  }

  onCloseViewVideosModal = () => {
    this.setState({ isShowViewVideosModal: false });
  };

  showViewVideosModal = floorplan => {
    const { location, agent, shortId } = this.props;

    this.setState({
      isShowViewVideosModal: true,
      floorplan,
    });

    trackPropertyVideoView(location.pathname, shortId, agent.id);
  };

  handlePropImgModalVisibility = floorPlan => {
    // const { data: { propertyObjects } } = this.props;
    this.setState(prevState => ({
      showPropImgModal: !prevState.showPropImgModal,
      floorPlan,
    }));
    // console.log('prop obj ', propertyObjects);
  };

  render() {
    const {
      data: { loading, propertyObjects },
      propertyId,
      agent,
      address,
      title,
      shortId,
      videoLink,
    } = this.props;

    const { isShowViewVideosModal } = this.state;

    if (loading) {
      return (
        <div className="property-image-wrapper flex justify-center items-center">
          <LoadingDots />
        </div>
      );
    }

    const mappedPropertyObjects =
      propertyObjects &&
      propertyObjects.map(o => ({
        ...o,
        url: buildURL(o.url, o.params),
      }));

    const primaryImage =
      mappedPropertyObjects &&
      (mappedPropertyObjects.find(
        propertyObject => propertyObject.key === 'm'
      ) ||
        mappedPropertyObjects[0]);

    const images =
      mappedPropertyObjects &&
      mappedPropertyObjects.filter(
        propertyObject =>
          propertyObject.key !== 'm' && propertyObject.type === 'IMAGE'
      );

    const hasFloorPlan =
      mappedPropertyObjects &&
      mappedPropertyObjects.find(
        propertyObj => propertyObj.type === 'FLOORPLAN'
      );

    return (
      <>
        <PropertyViewVideos
          video={videoLink}
          showModal={isShowViewVideosModal}
          onClose={this.onCloseViewVideosModal}
        />
        <PropertyViewImagesModal
          agent={agent}
          shortId={shortId}
          objects={mappedPropertyObjects}
          propertyObjects={propertyObjects}
          floorPlan={this.state.floorPlan}
          visible={this.state.showPropImgModal}
          closeModal={this.handlePropImgModalVisibility}
        />

        <div className="relative property-banner">
          {primaryImage ? (
            <div className="property-image h-full w-full flex justify-center border-b border-gray-300">
              <div className="lg:h-full border-r w-full border-blue-100 flex-1 relative">
                <img
                  className="property-image h-full lazyload cover-img"
                  src={`${primaryImage.url}`}
                  alt={address}
                  onClick={this.handlePropImgModalVisibility}
                />
              </div>
              {images.length >= 2 && (
                <div className="sub-img w-1/3 lg:block hidden h-full">
                  <div className="border-l border-b rounded-tr border-blue-100">
                    <img
                      className="w-full"
                      src={`${images[0].url}&fit=crop&crop=entropy&px=1&w=700&h=350`}
                      alt={address}
                      onClick={this.handlePropImgModalVisibility}
                    />
                  </div>
                  <div className="border-l border-t border-blue-100">
                    <img
                      className="w-full"
                      src={`${images[1].url}&fit=crop&crop=entropy&px=1&w=700&h=350`}
                      alt={address}
                      onClick={this.handlePropImgModalVisibility}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <img
              className="property-image m-auto block"
              src={defaultImage}
              alt="Property Image"
            />
          )}
          <div className="mb-4 absolute center-x-align bottom-0 z-10 w-full text-center">
            <Row type="flex" justify="center" items="center">
              {mappedPropertyObjects.length ? (
                <>
                  <Col xs={24} md={12}>
                    <ButtonGroup size="large">
                      <Button
                        onClick={() =>
                          this.handlePropImgModalVisibility(undefined)
                        }
                      >
                        <FA icon={faImages} className="text-lg" />{' '}
                        <span className="ml-1 px-1 font-normal">
                          {mappedPropertyObjects.length > 1
                            ? `${mappedPropertyObjects.length} Images`
                            : '1 Image'}
                        </span>
                      </Button>
                      {hasFloorPlan && (
                        <Button
                          onClick={() =>
                            this.handlePropImgModalVisibility('FLOORPLAN')
                          }
                        >
                          <FA icon={faThLarge} className="text-lg" />{' '}
                          <span className="ml-1 px-1 font-normal">
                            Floor Plan
                          </span>
                        </Button>
                      )}
                      {videoLink && (
                        <Button
                          size="large"
                          title="Video"
                          className="font-bold"
                          onClick={() => this.showViewVideosModal('')}
                        >
                          <FA icon={faVideo} className="text-lg" />
                        </Button>
                      )}
                    </ButtonGroup>
                  </Col>
                </>
              ) : null}
              <Col xs={24} md={12} className="mt-2 md:mt-0">
                <ButtonGroup size="large">
                  <BookmarkProperty
                    propertyId={propertyId}
                    title={title}
                    propPage
                  />
                  <PropertyCreateTopic propertyId={propertyId} />
                </ButtonGroup>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const PropertyImagesWithData = graphql(PropertyObjectsQuery, {
  options: ({ propertyId }) => ({ variables: { propertyId } }),
})(PropertyImages);

export default PropertyImagesWithData;
