import React from 'react';
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import './FeaturedDistricts.scss';
import images from './images';
import TopDistrictsByPopularityQuery from '../../queries/TopDistrictsByPopularityQuery.graphql';
import LoadingDots from '../LoadingDots';

function FeaturedDistricts({
  data: { loading, error, topDistrictsByPopularity },
}) {
  if (
    loading ||
    (topDistrictsByPopularity && !topDistrictsByPopularity.length) ||
    error
  ) {
    return <LoadingDots />;
  }

  return (
    <div className="my-4">
      <div className="w-full max-w-6xl mx-auto p-4">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg sm:text-3xl font-semibold">
            Discover your neighbourhood
          </h3>
          <Link
            className="text-blue-500 text-base font-medium whitespace-no-wrap"
            to="/districts"
          >
            View All
          </Link>
        </div>
        <div>
          <div className="relative z-0">
            <div className="-ml-4 -mr-4 sm:ml-0 sm:mr-0 overflow-y-hidden">
              <div>
                <div className="featured-district-list overflow-x-auto whitespace-no-wrap">
                  {topDistrictsByPopularity.map(district => {
                    const coverUrl = `${
                      process.env.REACT_APP_CDN_URI
                    }/planning-areas/${
                      images[district.districtNumber]
                    }?w=300&h=300&fit=crop&crop=entropy&auto=format&fm=pjpg`;

                    return (
                      <div
                        key={district.id}
                        className="featured-district inline-block w-1/2 sm:w-1/5 align-top whitespace-normal"
                      >
                        <div className="w-full h-full px-1">
                          <Link
                            className="block w-full bg-transparent overflow-hidden"
                            to={`/buy/search?location=${encodeURI(
                              district.full
                            )}&locationType=DISTRICT`}
                          >
                            <div className="featured-district-card relative w-full rounded overflow-hidden z-0">
                              <div className="absolute inset-0 w-full h-full">
                                <div className="cover-image-container relative">
                                  <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                                    <img
                                      className="cover-image absolute inset-0 w-full h-full"
                                      src={coverUrl}
                                      alt={district.full}
                                    />
                                  </div>
                                </div>
                                <div className="overlay-background absolute w-full left-0 bottom-0" />
                                <div className="overlay-content absolute right-0 bottom-0 left-0 px-6 pb-8">
                                  <div className="featured-district-name m-0 text-sm sm:text-base font-bold text-center text-white">
                                    {district.full}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const FeaturedDistrictsWithData = graphql(TopDistrictsByPopularityQuery, {
  options: () => ({
    variables: { limit: 5 },
  }),
})(FeaturedDistricts);

export default FeaturedDistrictsWithData;
