import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

function PropertyTypeOption(props) {
  return (
    <Radio value={props.value} className="px-2">
      <div className="option flex flex-col items-center justify-center py-6 px-4 text-base border w-32 wrapper">
        <FA icon={props.icon} size="3x" style={{ color: '#4ac7f4' }} />
        <span className="block mt-2 truncate">{props.label}</span>
      </div>
    </Radio>
  );
}

PropertyTypeOption.propTypes = {
  value: PropTypes.any,
  icon: PropTypes.any,
  label: PropTypes.any,
};

export default PropertyTypeOption;
