import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PublicHeader from '../../components/PublicHeader';
import PublicFooter from '../../components/PublicFooter';
import AgentPageSlugUrlParser from '../../components/SlugUrlParser';
import AgentProfile from '../../components/AgentProfile';
import AgentProperty from '../../components/AgentProperty';
import AgentEnquiry from '../../components/AgentEnquiry';
import { trackAttemptAgentEnquiry } from '../../utils/analytics';
import './AgentPage.scss';

class AgentPage extends Component {
  static propTypes = {
    id: PropTypes.string,
    match: PropTypes.object,
  };

  static defaultProps = {
    id: '',
    match: {},
  };

  handleTrackAgentEnquiry = eventAction => {
    const { id: agentId } = this.props;

    trackAttemptAgentEnquiry(agentId, eventAction);
  };

  render() {
    const {
      id,
      match: {
        params: { slugUrl },
      },
    } = this.props;

    return (
      <div className="default-theme AgentPage">
        <PublicHeader />
        <div className="agent">
          <AgentProfile
            id={id}
            agentUrl={slugUrl}
            trackEnquiry={this.handleTrackAgentEnquiry}
          />
          <AgentProperty id={id} />
          <AgentEnquiry id={id} trackEnquiry={this.handleTrackAgentEnquiry} />
        </div>
        <PublicFooter />
      </div>
    );
  }
}

export default props => (
  <AgentPageSlugUrlParser {...props}>
    {id => <AgentPage id={id} {...props} />}
  </AgentPageSlugUrlParser>
);
