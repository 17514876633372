import React, { Component } from 'react';
import Select from 'rc-select';
import 'rc-select/assets/index.css';
import debounce from 'lodash.debounce';
import { withApollo } from 'react-apollo';
import isEmpty from 'lodash/isEmpty';
import './LocationFilterSelect.scss';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faTrain,
  faSchool,
} from '@fortawesome/pro-light-svg-icons';
import PropertyLocationSearchQuery from '../../queries/PropertyLocationSearchQuery.graphql';
import LoadingDots from '../LoadingDots';

const { Option, OptGroup } = Select;

class LocationFilterSelect extends Component {
  state = {
    isLoading: false,
    dataSource: {},
    placeholder: 'Search by Location, MRT, School',
    defaultPlaceholder: 'Search by Location, MRT, School',
    searchStyle: '',
  };

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.showEmptySearchMessage);
  }

  onInputKeyDown = keyEvent => {
    const { value, onPressEnter } = this.props;

    const hasSearchValue = !isEmpty(value) ? value : [];

    if (keyEvent.which === 13) {
      if (!(hasSearchValue && hasSearchValue.length > 0)) {
        keyEvent.preventDefault();
        this.showEmptySearchMessage();
      } else {
        onPressEnter && onPressEnter();
      }
    }
  };

  handleSearch = async value => {
    const { client } = this.props;
    const keyword = value;

    this.setState({
      isLoading: true,
      currentKeyword: value,
    });

    try {
      const {
        data: { propertyLocationSearch },
      } = await client.query({
        query: PropertyLocationSearchQuery,
        variables: {
          filter: {
            keyword,
            first: 5,
          },
        },
      });
      this.setState({
        isLoading: false,
        dataSource: propertyLocationSearch || {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  showEmptySearchMessage = () => {
    this.setState({
      searchStyle: 'search-error',
      placeholder: 'Please select a location',
    });

    const { defaultPlaceholder } = this.state;

    return setTimeout(
      () =>
        this.setState({
          searchStyle: '',
          placeholder: defaultPlaceholder,
        }),
      5000
    );
  };

  renderOptions = (dataSource, keyword) => {
    const types = Object.keys(dataSource).filter(type =>
      [
        'area',
        'district',
        'school',
        'mrt',
        'hdbStreet',
        'hdbTown',
        'hdbBlockNumber',
      ].includes(type)
    );
    const hasResults =
      types.length > 0 && types.some(type => dataSource[type].length > 0);

    if (!hasResults && keyword) {
      return [
        <Option
          label={keyword}
          key={keyword}
          className="show-all py-3"
          value={`k:${keyword}`}
          title={keyword}
        >
          <span className="text-base location-item">{keyword}</span>
        </Option>,
      ];
    }

    return types.map(type => {
      let locationType = type.toUpperCase();
      let options = dataSource[type];
      let label;
      let icon;
      switch (type) {
        case 'area':
          options = options.map(option => ({ ...option, name: option.full }));
          label = 'Area';
          icon = faMapMarkerAlt;
          break;
        case 'district':
          options = options.map(option => ({ ...option, name: option.full }));
          label = 'District';
          icon = faMapMarkerAlt;
          break;
        case 'mrt':
          label = 'MRT';
          icon = faTrain;
          break;
        case 'school':
          label = 'School';
          icon = faSchool;
          break;
        case 'hdbStreet':
          options = options.map(option => ({ ...option, name: option.street }));
          label = 'HDB Street';
          icon = faMapMarkerAlt;
          break;
        case 'hdbTown':
          options = options.map(option => ({ ...option, name: option.town }));
          label = 'HDB Town';
          icon = faMapMarkerAlt;
          break;
        case 'hdbBlockNumber':
          options = options.map(option => ({
            ...option,
            name: option.blockNumber,
          }));
          label = 'HDB Block Number';
          icon = faMapMarkerAlt;
          break;
        default:
          break;
      }

      return (
        <OptGroup key={locationType} label={label}>
          {options.map(option => (
            <Option key={option.id} value={`${locationType}:${option.name}`}>
              <div className="flex items-center location-item">
                <span>
                  <span>
                    <FA
                      icon={icon}
                      style={{ color: '#4ac7f4', marginRight: '7px' }}
                    />
                  </span>
                  <span className="text-base">{option.name}</span>
                  <span className="ml-1 text-xs text-gray-500">
                    <span className="mr-1">&bull;</span>
                    {locationType}
                  </span>
                </span>
              </div>
            </Option>
          ))}
        </OptGroup>
      );
    });
  };

  render() {
    const { value, onChange, className } = this.props;
    const {
      placeholder,
      searchStyle,
      currentKeyword,
      dataSource,
      isLoading,
    } = this.state;

    return (
      <div
        className={`${className ||
          ''} location-filter-select flex flex-col justify-center ${searchStyle}`}
      >
        <Select
          className="w-full h-full"
          dropdownClassName="property-search-dropdown"
          allowClear
          labelInValue
          placeholder={placeholder}
          showArrow={false}
          notFoundContent={isLoading ? <LoadingDots /> : null}
          onSearch={this.handleSearch}
          onChange={onChange}
          onInputKeyDown={this.onInputKeyDown}
          value={value}
        >
          {this.renderOptions(dataSource, currentKeyword)}
        </Select>
      </div>
    );
  }
}

export default withApollo(LocationFilterSelect);
