import React, { Component } from 'react';
import { Modal, Carousel } from 'antd';
import orderBy from 'lodash/orderBy';
import findLastIndex from 'lodash/findLastIndex';
import './PropertyViewImagesModal.scss';
import PropTypes from 'prop-types';
import { CarouselNextArrow, CarouselPrevArrow } from '../Buttons';
import { winWidth } from '../../utils/windowDimensions';
import ProgressiveLoading from '../ProgressiveLoading';
import { trackPropertyPageImagesModal } from '../../utils/analytics';
import replace from 'lodash/replace';

class PropertyViewImagesModal extends Component {
  state = { isShowPropertyEnquiryModal: false };

  static getDerivedStateFromProps(props, state) {
    const { floorPlan, shortId, agent, propertyObjects, visible } = props;
    const objectsIndex = findLastIndex(propertyObjects);
    if (visible) {
      const objectId = replace(
        propertyObjects[objectsIndex].objectId,
        'properties/',
        ''
      );

      trackPropertyPageImagesModal(
        shortId,
        objectId,
        agent.id,
        propertyObjects[objectsIndex].type
      );
    }

    return null;
  }
  componentDidMount = () => {
    window.addEventListener('keydown', this.handleCarouselControl);
  };

  componentWillUnmount = () => {
    window.addEventListener('keydown', this.handleCarouselControl);
  };

  handleCarouselControl = e => {
    if (this.carousel && this.carouselNav) {
      if (e.keyCode === 39 || e.code === 'ArrowRight') {
        this.carousel.slick.slickNext();
        this.carouselNav.slick.slickNext();
      } else if (e.keyCode === 37 || e.code === 'ArrowLeft') {
        this.carousel.slick.slickPrev();
        this.carouselNav.slick.slickPrev();
      }
    }
  };

  setImageGoogleAnalytics = currentIndex => {
    const { floorPlan, shortId, agent, propertyObjects } = this.props;
    const objectId = replace(
      propertyObjects[currentIndex].objectId,
      'properties/',
      ''
    );
    trackPropertyPageImagesModal(
      shortId,
      objectId,
      agent.id,
      propertyObjects[currentIndex].type
    );
  };

  render() {
    const { visible, closeModal, floorPlan, objects } = this.props;
    const sortedObjects = floorPlan
      ? orderBy(objects, ['type', 'isMain'], ['asc', 'desc'])
      : orderBy(objects, ['type', 'isMain'], ['desc', 'desc']);

    const objectsIndex = sortedObjects.findIndex(
      ({ type, isMain }) => type === (floorPlan || 'IMAGE') && isMain === true
    );

    return (
      <Modal
        className="w-full property-photos-modal property-photos"
        width={null}
        visible={visible}
        destroyOnClose
        onCancel={() => closeModal(undefined)}
        header={null}
        footer={null}
        wrapClassName="property-modal-wrapper"
      >
        <Carousel
          dots={false}
          initialSlide={objectsIndex}
          beforeChange={(current, next) => {
            this.setImageGoogleAnalytics(next);
            this.carouselNav.slick.slickGoTo(next);
          }}
          ref={carousel => {
            this.carousel = carousel;
          }}
          lazyLoad="progressive"
          className="property-modal-carousel"
          arrows
          swipeToSlide
          nextArrow={<CarouselNextArrow />}
          prevArrow={<CarouselPrevArrow />}
        >
          {sortedObjects &&
            sortedObjects.map(({ id, url, objectId }) => (
              <div
                className="z-50 relative property-modal-carousel-item"
                key={url}
              >
                <div className="flex items-center justify-center">
                  <img
                    alt={objectId}
                    className="property-image lazyload"
                    key={url}
                    sizes="auto"
                    srcSet={`${url}&w=300&auto=format 300w, ${url}&w=400&auto=format 400w, ${url}&w=500&auto=format 500w, ${url}&w=600&auto=format 600w,${url}&w=800&auto=format 800w, ${url}&w=1000&auto=format 1000w, ${url}&w=1500&auto=format 1500w `}
                    src={`${url}&fit=crop&crop=entropy&px=16&blur=200&auto=format&w=100&height=50`}
                  />
                </div>
              </div>
            ))}
        </Carousel>
        <Carousel
          dots={false}
          initialSlide={objectsIndex}
          slidesToScroll={1}
          slidesToShow={winWidth > 1024 ? 12 : 6}
          beforeChange={(current, next) => {
            this.carousel.slick.slickGoTo(next);
          }}
          ref={carousel => {
            this.carouselNav = carousel;
          }}
          lazyLoad="ondemand"
          className="property-modal-nav"
          focusOnSelect
          swipeToSlide
        >
          {sortedObjects &&
            sortedObjects.map(({ id, url }) => (
              <div key={id} className="property-modal-carousel-item">
                <ProgressiveLoading
                  alt="property"
                  src={`${url}&h=200&auto=format`}
                  thumb={`${url}&fit=crop&crop=entropy&px=16&blur=100&auto=format`}
                />
              </div>
            ))}
        </Carousel>
      </Modal>
    );
  }
}

export default PropertyViewImagesModal;
