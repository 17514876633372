/* eslint-disable react/forbid-prop-types */

import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Alert, Button, Col, Form, Icon, Input, Row, Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CreateAgentForgotPasswordPage.scss';
import MobileNumberInput from '../../../components/MobileNumberInput';
import {
  VerifyMobileNumber,
  AgentMobileNumberVerify,
} from './Operations.graphql';

class CreateAgentForgotPasswordPage extends Component {
  static propTypes = {
    verifyMobileNumber: PropTypes.func,
    agentMobileNumberVerify: PropTypes.func,
  };

  static defaultProps = {
    verifyMobileNumber: () => {},
    agentMobileNumberVerify: () => {},
  };

  state = {
    forgotPasswordSelection: null,
    step: 1,
    isSuccess: false,
    isLoadingCreate: false,
    mobileNumber: null,
    userName: null,
    verificationCode: null,
  };

  getErrorMessage = () => {
    let message = null;
    const { formErrorCode } = this.state;

    switch (formErrorCode) {
      case 'MOBILE_DOES_NOT_EXIST': {
        message = 'It seems that your mobile number is not yet registered ';
        break;
      }
      case 'CODE_DOES_NOT_EXIST': {
        message = 'Invalid verification code';
        break;
      }
      default: {
        message = 'Unable to reset password ';
        break;
      }
    }

    return message;
  };

  userNameField = () => {
    this.setState({
      forgotPasswordSelection: 'username',
      step: 2,
    });
  };

  mobileNumberField = () => {
    this.setState({
      forgotPasswordSelection: 'mobileNumber',
      step: 2,
    });
  };

  onSubmitVerificationForm = values => {
    const { createAgentNewPassword, history } = this.props;
    const { mobileNumber } = this.state;
    const { password, userName } = values;

    const stripSpaceMobileNumber = mobileNumber.replace(/\s+/g, '');

    const createData = {
      password,
      mobileNumber: stripSpaceMobileNumber,
      userName,
    };

    createAgentNewPassword(createData).then(() => {
      this.setState({ isLoadingCreate: false }, () => {
        Modal.success({
          title: 'Thank you',
          content:
            'You have successfully updated your password. You can now login.',
          onOk() {
            history.push('/agent-login');
          },
        });
      });
    });
  };

  onSubmitMobileVerification = values => {
    const { createAgentForgotPassword } = this.props;
    const { mobileNumber, userName } = this.state;
    const { isCodeInvalid } = values;

    const stripSpaceMobileNumber = mobileNumber.replace(/\s+/g, '');

    const createData = {
      mobileNumber: stripSpaceMobileNumber,
      userName,
      isCodeInvalid,
    };

    createAgentForgotPassword(createData)
      .then(({ data }) => {
        console.log(data);
        this.setState({
          isLoadingCreate: false,
          step: 4,
          formErrorCode: false,
          userName: data.createAgentForgotPassword.userName,
        });
      })
      .catch(err => {
        const errors = err.graphQLErrors || [];
        const formErrorCode =
          errors.length > 0 ? errors[0].extensions.code : null;
        this.setState({
          formErrorCode,
          confirmLoading: false,
          isSuccess: false,
        });
      });
  };

  onCancelVerificationForm = () => {
    this.setState({ step: 1 });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      formErrorCode: null,
      confirmLoading: true,
    });
    const {
      form: { validateFields },
      verifyMobileNumber,
      agentMobileNumberVerify,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        const { mobileNumber, userName } = values;

        agentMobileNumberVerify(mobileNumber.replace(/\s+/g, ''))
          .then(({ data }) => {
            if (data) {
              verifyMobileNumber(mobileNumber).then(({ data }) => {
                if (
                  data &&
                  data.verifyMobileNumber &&
                  data.verifyMobileNumber.code
                ) {
                  this.setState({
                    step: 3,
                    mobileNumber,
                    userName,
                    isSuccess: true,
                    verificationCode: data.verifyMobileNumber.code,
                  });
                }
              });
            }
          })
          .catch(err => {
            const errors = err.graphQLErrors || [];
            const formErrorCode =
              errors.length > 0 ? errors[0].extensions.code : null;
            this.setState({
              formErrorCode,
              confirmLoading: false,
              isSuccess: false,
            });
          });
      }
    });
  };

  onClickBack = () => {
    this.setState({ step: 1 });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const {
      step,
      forgotPasswordSelection,
      verificationCode,
      mobileNumber,
      userName,
      isLoadingCreate,
      formErrorCode,
      isSuccess,
    } = this.state;

    return (
      <Row
        align="middle"
        justify="center"
        type="flex"
        className="login-form-holder h-screen CreateAgentForgotPassword"
      >
        <Col xs={22} sm={20} md={13} lg={10} xl={8} xxl={6}>
          {formErrorCode && (
            <Alert
              message={this.getErrorMessage()}
              type="error"
              closable
              showIcon
            />
          )}
          {isSuccess && (
            <Alert
              message="We sent you a verification code to your mobile"
              type="success"
              showIcon
            />
          )}
          <main className="pt-12 pb-6 sm:px-8 px-4 lg:my-0 md:my-4 sm:shadow-lg relative CreateAgentForgotPasswordForm">
            {step === 1 && (
              <Form>
                <h2 className="text-center text-white text-lg mb-4">
                  Account recovery
                </h2>
                <div className="flex justify-center mb-2">
                  {/* <div className="fb-btn-container"> */}
                  {/* <Button size="large" className="fb-btn rounded" onClick={this.userNameField}> */}
                  {/* {' '} */}
                  {/* via Username */}
                  {/* </Button> */}
                  {/* </div> */}
                  <div className="mobile-btn-container">
                    <Button
                      className="rounded border-2 border-gray-200 mobile-btn"
                      onClick={this.mobileNumberField}
                    >
                      {' '}
                      <span>via Mobile Number</span>
                    </Button>
                  </div>
                </div>

                <div className="flex justify-center mb-4">
                  <span>
                    <Link to="/agent-login"> I remembered my password</Link>
                  </span>
                </div>
              </Form>
            )}

            {step === 2 && (
              <Form
                onSubmit={this.handleSubmit}
                className="agent-forgot-password-form"
              >
                <div className="mb-4">
                  <Button onClick={this.onClickBack}>
                    <Icon type="left" />
                    Back
                  </Button>
                </div>
                {forgotPasswordSelection === 'username' && (
                  <Form.Item>
                    {getFieldDecorator('userName', {
                      rules: [
                        {
                          required: true,
                          message: 'Username is required!',
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        placeholder="Username"
                      />
                    )}
                  </Form.Item>
                )}

                {forgotPasswordSelection === 'mobileNumber' && (
                  <Form.Item>
                    <h4 className="text-center text-white mb-3">
                      Please input your mobile number for verification
                    </h4>
                    {getFieldDecorator('mobileNumber', {
                      rules: [
                        { transform: value => value.replace(/\s+/g, '') },
                        // { validator: this.checkMobileNumber },
                        {
                          required: true,
                          message: 'Mobile Number is required',
                        },
                        {
                          max: 10,
                          message: 'Max of 12 characters',
                        },

                        {
                          pattern: '^04[0-9]{8}$',
                          message: 'Mobile Number must start with 04',
                        },
                      ],
                    })(
                      <MobileNumberInput
                        size="large"
                        placeholder="ex. 0491 570 156"
                        prefix={
                          <Icon
                            type="phone"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                )}

                <Form.Item>
                  <Button
                    className="w-full"
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            )}
            {/* step 3 */}
            {step === 3 && (
              <AgentMobileVerificationFormWithForm
                loading={isLoadingCreate}
                mobileNumber={mobileNumber}
                userName={userName}
                code={verificationCode}
                onSubmit={this.onSubmitMobileVerification}
              />
            )}
            {/* step 4 */}
            {step === 4 && (
              <AgentChangePasswordFormWithForm
                loading={isLoadingCreate}
                mobileNumber={mobileNumber}
                userName={userName}
                onSubmit={this.onSubmitVerificationForm}
                onCancel={this.onCancelVerificationForm}
              />
            )}
          </main>
        </Col>
      </Row>
    );
  }
}

const CREATE_AGENT_FORGOT_PASSWORD_MUTATION = gql`
  mutation createAgentForgotPassword($input: CreateAgentForgotPasswordInput!) {
    createAgentForgotPassword(input: $input) {
      userName
      mobileNumber
    }
  }
`;

const CREATE_AGENT_NEW_PASSWORD = gql`
  mutation createAgentNewPassword($input: CreateAgentNewPasswordInput!) {
    createAgentNewPassword(input: $input)
  }
`;

const CreateAgentForgotPasswordMutation = compose(
  withRouter,
  Form.create(),
  graphql(CREATE_AGENT_FORGOT_PASSWORD_MUTATION, {
    props: ({ mutate }) => ({
      createAgentForgotPassword: input => mutate({ variables: { input } }),
    }),
  }),
  graphql(CREATE_AGENT_NEW_PASSWORD, {
    props: ({ mutate }) => ({
      createAgentNewPassword: input => mutate({ variables: { input } }),
    }),
  }),
  graphql(VerifyMobileNumber, {
    props: ({ mutate }) => ({
      verifyMobileNumber: mobileNumber =>
        mutate({ variables: { mobileNumber } }),
    }),
  }),
  graphql(AgentMobileNumberVerify, {
    props: ({ mutate }) => ({
      agentMobileNumberVerify: mobileNumber =>
        mutate({ variables: { mobileNumber } }),
    }),
  })
)(CreateAgentForgotPasswordPage);

class AgentMobileVerificationForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    mobileNumber: PropTypes.string,
    userName: PropTypes.string,
    code: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
    isCodeValid: PropTypes.bool,
  };

  static defaultProps = {
    form: {},
    code: null,
    loading: false,
    mobileNumber: null,
    userName: null,
    isCodeValid: false,
    onSubmit: () => {},
    onCancel: () => {},
  };

  onFormSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      code,
      onSubmit,
    } = this.props;
    validateFields((err, values) => {
      if (!err && code === values.verificationCode) {
        onSubmit({ isCodeInvalid: false });
      } else {
        onSubmit({ isCodeInvalid: true });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      loading,
      mobileNumber,
    } = this.props;

    return (
      <main className="AgentMobileVerificationForm ">
        <div className="flex justify-center mb-2">
          <Form className="w-full" onSubmit={this.onFormSubmit}>
            <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
              PLease input Verification Code that has been sent to your mobile
              number{' '}
              <div className="font-black text-base">( {mobileNumber} )</div>
            </span>

            <Form.Item className="w-1/4">
              {getFieldDecorator('verificationCode', {
                rules: [
                  {
                    required: true,
                    message: 'Verification Code is required',
                  },
                  {
                    enum: '0123456789',
                    message: 'Invalid Verification Code',
                  },
                ],
              })(<Input size="large" placeholder="1234" maxLength={4} />)}
            </Form.Item>
            <Form.Item className="text-right">
              <Button htmlType="submit" loading={loading}>
                {loading ? 'Creating your account' : 'Submit'}
                <Icon type="right" />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </main>
    );
  }
}

const AgentMobileVerificationFormWithForm = Form.create()(
  AgentMobileVerificationForm
);

class AgentChangePasswordForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    mobileNumber: PropTypes.string,
    userName: PropTypes.string,
    password: PropTypes.string,
    code: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    form: {},
    code: null,
    loading: false,
    mobileNumber: null,
    password: null,
    userName: null,
    onSubmit: () => {},
    onCancel: () => {},
  };

  onFormSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      onSubmit,
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        const { password, mobileNumber, userName } = values;
        onSubmit({
          password,
          mobileNumber,
          userName,
        });
      }
    });
  };

  onClickBack = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleConfirmPassword = (rule, value, callback) => {
    const {
      form: { getFieldValue },
    } = this.props;
    if (value && value !== getFieldValue('password')) {
      callback('Password mismatch');
    }
    callback();
  };

  render() {
    const {
      form: { getFieldDecorator },
      loading,
      userName,
    } = this.props;

    return (
      <main className="AgentChangePasswordForm ">
        <div className="flex justify-center mb-2">
          <Form className="w-full" onSubmit={this.onFormSubmit}>
            <h2 className="text-center text-white text-lg mb-8">
              Please input your new password
            </h2>
            <Input.Group>
              <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
                User Name*
              </span>
              <Form.Item>
                {getFieldDecorator('userName', {
                  initialValue: userName,
                  getValueFromEvent: e => e.target.value.replace(/\s+/g, ''),
                  rules: [
                    // { validator: this.checkUsername },
                    { transform: value => (value ? value.trim() : value) },
                    {
                      required: true,
                      message: 'Please input username!',
                    },
                  ],
                })(
                  <Input
                    size="large"
                    placeholder="john.smith"
                    prefix={<Icon type="mail" />}
                  />
                )}
              </Form.Item>
              <Col xs={24} sm={24} md={24} className="password-padding">
                <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
                  Password*
                </span>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        min: 6,
                        message: 'Password should at least have 6 characters',
                      },
                      {
                        required: true,
                        message: 'Your password is required',
                      },
                    ],
                  })(
                    <Input.Password
                      size="large"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <span className="text-gray-200 text-xs uppercase font-semibold ml-4 py-1 inline-block">
                  Confirm Password*
                </span>
                <Form.Item>
                  {getFieldDecorator('confirmPassword', {
                    rules: [
                      {
                        required: true,
                        message: 'Please type your password again',
                      },
                      { validator: this.handleConfirmPassword },
                    ],
                  })(
                    <Input.Password
                      size="large"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                    />
                  )}
                </Form.Item>
                <Form.Item className="text-right">
                  <Button htmlType="submit" loading={loading}>
                    {loading ? 'Creating your account' : 'Submit'}
                    <Icon type="right" />
                  </Button>
                </Form.Item>
              </Col>
            </Input.Group>
          </Form>
        </div>
      </main>
    );
  }
}

const AgentChangePasswordFormWithForm = Form.create()(AgentChangePasswordForm);

export default Form.create()(CreateAgentForgotPasswordMutation);
