import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Icon, Row, Select } from 'antd';
import { compose, graphql } from 'react-apollo';
import Media from 'react-media';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';
import { FormattedNumber } from 'react-intl';
import PropertySearchResultGridVirtualize from '../PropertySearchResultGridVirtualize';
import { PropertiesQuery } from './Operations.graphql';
import Loading from '../LoadingDots';
import emptySearch from './search.png';
import './PropertySearchResults.scss';
import PropertyMapWithMarkerClusterer from '../PropertyMapWithMarkerClusterer';

const { Option } = Select;

const LoadingValue = ({ loading, value }) =>
  loading ? <Icon type="loading" /> : <FormattedNumber value={value} />;

function LoadingResultWithTotal(props) {
  return (
    <div className="text-sm text-center text-gray-700 uppercase">
      Showing{' '}
      <span className="font-black">
        {' '}
        <LoadingValue loading={props.loading} value={props.value} />
      </span>{' '}
      of
      <span className="font-black">
        {' '}
        <LoadingValue loading={props.loading} value={props.totalCount} />
      </span>{' '}
      Properties
    </div>
  );
}

LoadingResultWithTotal.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.number,
  totalCount: PropTypes.number,
};

function LoadingResult(props) {
  return (
    <div className="text-sm text-center text-gray-700 uppercase">
      Showing{' '}
      <span className="font-black">
        {' '}
        <LoadingValue loading={props.loading} value={props.value} />
      </span>{' '}
      of Total Results
    </div>
  );
}

LoadingResult.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.number,
};

class PropertySearchResults extends Component {
  static propTypes = {
    searchOptions: PropTypes.shape({
      keyword: PropTypes.string,
      showMap: PropTypes.bool,
      ofiTime: PropTypes.string,
      bed: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      price: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      parking: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      landArea: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      floorArea: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      bathroom: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      mapLocation: PropTypes.objectOf({
        radius: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      type: PropTypes.shape({
        group: PropTypes.arrayOf(PropTypes.string),
        category: PropTypes.arrayOf(PropTypes.string),
      }),
      location: PropTypes.any,
      locationData: PropTypes.any,
      includeSurrounding: PropTypes.bool,
      sorting: PropTypes.string,
    }),
    data: PropTypes.object,
  };

  static defaultProps = {
    searchOptions: {},
    data: {},
  };

  state = {
    loadingMore: false,
    show: false,
  };

  handleLoadMore = async () => {
    const {
      data: { fetchMore, propertiesConnection },
      searchOptions: {
        keyword,
        price,
        floorArea,
        landArea,
        bed,
        mapLocation,
        bathroom,
        ofiTime,
        group,
        type,
        locationData,
        isIncludeSurrounding,
        sorting,
      },
    } = this.props;

    this.setState({ loadingMore: true });

    return new Promise(async resolve => {
      try {
        await fetchMore({
          variables: {
            featuredBrokerLocationIds: locationData ? [locationData.id] : [],
            datesFrom: ofiTime,
            filter: {
              bathroom,
              bed,
              first: 36,
              group,
              isIncludeSurrounding,
              sorting,
              keyword,
              floorArea,
              landArea,
              location: locationData && locationData.id,
              locationType: locationData && locationData.type,
              mapLocation,
              ofiTime,
              price,
              typeGroup: type.group,
              typeCategory: type.category,
              after: propertiesConnection.pageInfo.endCursor,
            },
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const { nodes } = fetchMoreResult.propertiesConnection;

            return nodes.length
              ? {
                  ...previousResult,
                  propertiesConnection: {
                    ...fetchMoreResult.propertiesConnection,
                    nodes: [
                      ...previousResult.propertiesConnection.nodes,
                      ...nodes,
                    ],
                  },
                }
              : previousResult;
          },
        });
        resolve(true);
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ loadingMore: false });
      }
    });
  };

  render() {
    const {
      searchOptions,
      searchOptions: { showMap = true, ofiTime, locationsData, sorting },
      data: { loading, propertiesConnection, featuredBrokerByLocation },
      onChangeSorting,
      searchParam,
    } = this.props;

    const { loadingMore } = this.state;
    const showLoadingMore = propertiesConnection
      ? propertiesConnection.pageInfo.hasNextPage
      : false;

    let dataSource = propertiesConnection ? propertiesConnection.nodes : [];

    const propertyLocations = dataSource.filter(
      ({ lat, lng }) => lat !== null && lng !== null
    );

    if (loading) dataSource = [];

    const count = dataSource.length;
    const totalCount = propertiesConnection
      ? propertiesConnection.totalCount
      : 0;

    const hasFeaturedBroker =
      featuredBrokerByLocation && featuredBrokerByLocation.broker;

    return (
      <>
        <Row
          className={`${classnames('justify-center', {
            'xl:block flex': showMap,
            flex: !showMap,
          })}`}
        >
          <Col
            xs={showMap ? 23 : 23}
            sm={showMap ? 23 : 23}
            md={showMap ? 23 : 20}
            lg={showMap ? 23 : 20}
            xl={showMap ? 14 : 20}
            xxl={showMap ? 14 : 15}
            className="advance-search-result sm:p-41 sm:pr-11 "
          >
            <div className="flex flex-wrap items-center mb-1">
              <div className="flex-1 text-sm text-gray-700 uppercase">
                {/*<FA icon={faSortAmountDown} className="mr-2 text-lg"/>*/}
                {/*<Select*/}
                {/*defaultValue={sorting || 'featured'}*/}
                {/*style={{width: 220}}*/}
                {/*onChange={onChangeSorting}*/}
                {/*>*/}
                {/*<Option value="featured">Featured</Option>*/}
                {/*<Option value="price_high_low">Highest to Lowest*/}
                {/*Price</Option>*/}
                {/*<Option value="price_low_high">Lowest to Highest*/}
                {/*Price</Option>*/}
                {/*</Select>*/}
              </div>
              <div className="mr-4">
                {locationsData && locationsData.length > 0 ? (
                  <LoadingResultWithTotal
                    loading={loading}
                    value={count}
                    totalCount={totalCount}
                  />
                ) : (
                  <LoadingResult loading={loading} value={count} />
                )}
              </div>
            </div>

            <>
              {!loading && dataSource.length === 0 && (
                <div
                  className="p-6 rounded bg-white text-center empty-search relative mt-2"
                  style={{ height: 'calc(100vh - 208px)' }}
                >
                  <div className="center-align">
                    <img
                      src={emptySearch}
                      alt="empty search results"
                      className="w-5/6"
                    />
                    <h2 className="font-thin mt-2 text-gray-500">
                      'Sorry, no property results found'
                    </h2>
                  </div>
                </div>
              )}
              {loading && <Loading />}

              <Media query="(max-width:768px)">
                {matches =>
                  matches ? (
                    <PropertySearchResultGridVirtualize
                      resultList={dataSource}
                      searchOptions={searchOptions}
                      loadMore={this.handleLoadMore}
                      showMap={showMap}
                      loading={loading}
                      smLayout
                      showLoadingMore={showLoadingMore}
                      isLoadingMore={loadingMore}
                      searchParam={searchParam}
                    />
                  ) : (
                    <PropertySearchResultGridVirtualize
                      resultList={dataSource}
                      searchOptions={searchOptions}
                      loadMore={this.handleLoadMore}
                      showMap={showMap}
                      smLayout={false}
                      loading={loading}
                      showLoadingMore={showLoadingMore}
                      isLoadingMore={loadingMore}
                      searchParam={searchParam}
                    />
                  )
                }
              </Media>
            </>
          </Col>
          {showMap && (
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={0}
              xl={10}
              xxl={10}
              className=" advance-search-map lg:block hidden"
            >
              <div className=" relative h-full">
                <PropertyMapWithMarkerClusterer
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={
                    <div
                      className="advance-search-map-container"
                      style={{
                        width: '100%',
                        height: hasFeaturedBroker
                          ? 'calc(100% - 147px)'
                          : '100%',
                      }}
                    />
                  }
                  mapElement={<div style={{ height: '100%' }} />}
                  markers={propertyLocations}
                  searchParam={searchParam}
                />
              </div>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

const PropertySearchResultsWithData = compose(
  graphql(PropertiesQuery, {
    options: (
      {
        searchOptions: {
          keyword,
          price,
          bed,
          bathroom,
          ofiTime,
          group,
          furnishType,
          type,
          floorArea,
          landArea,
          sorting,
          isIncludeSurrounding,
          locationData,
          segment,
          commercialType,
        } = {},
      } = { searchOptions: {} }
    ) => ({
      variables: {
        filter: {
          bathroom,
          bed,
          first: 36,
          group,
          keyword,
          price,
          typeGroup: type.group,
          typeCategory: type.category,
          furnishType,
          floorArea,
          landArea,
          sorting,
          isIncludeSurrounding,
          location: locationData && locationData.id,
          locationType: locationData && locationData.type,
          hdbTown: locationData && locationData.hdbTown,
          hdbBlockNumber: locationData && locationData.hdbBlockNumber,
          hdbStreet: locationData && locationData.hdbStreet,
          segment,
          commercialType,
        },
      },
    }),
  })
)(PropertySearchResults);

export default PropertySearchResultsWithData;
