import React, { Component } from 'react';
import { Col, Row, Tabs } from 'antd';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import AgentPropertyTab from '../AgentPropertyTab';
import LoadingDots from '../LoadingDots';
import Error from '../Error';

class AgentProperty extends Component {
  static propTypes = {
    id: PropTypes.string,
    data: PropTypes.object,
  };

  static defaultProps = {
    id: null,
    data: {},
  };

  setActiveTab = () => {
    const { data } = this.props;

    const statistics = data && data.agent ? data.agent.statistics : {};

    const forSaleCount =
      statistics && statistics.forSaleCount ? statistics.forSaleCount : 0;
    const forRentCount =
      statistics && statistics.forRentCount ? statistics.forRentCount : 0;

    let activeTab = 'sold';

    if (forSaleCount > 0) {
      activeTab = 'buy';
    } else if (forRentCount > 0) {
      activeTab = 'rent';
    }

    return activeTab;
  };

  render() {
    const {
      data: { agent, loading, error },
      id,
    } = this.props;

    if (loading) return <LoadingDots />;
    if (error) return <Error error={error} />;

    const { firstName, lastName, agencyName, statistics } = agent;
    const { forRentCount, forSaleCount, soldCount } = statistics || {};

    const activeTab = this.setActiveTab();
    const hideSection =
      forRentCount + forSaleCount + soldCount === 0 ? 'hidden' : '';

    return (
      <section className={`py-12 ${hideSection}`}>
        <div id="properties" className={hideSection}>
          <h2 className="text-center md:mb-4 mb-2 md:text-xl text-lg">
            {firstName}
            &#39;s Properties
          </h2>
        </div>
        <Row justify="center" type="flex" className={`${hideSection}`}>
          <Col
            className="text-center "
            xs={22}
            sm={22}
            md={22}
            lg={18}
            xl={14}
            xxl={10}
          >
            <h3 className="text-gray-700 font-normal text-indent text-left md:text-base text-sm">
              This list contains all property listed, sold and rented by{' '}
              {firstName || ''} {lastName || ''}{' '}
              {agencyName ? `of ${agencyName}` : ''} on singapore.realty.com.au
              - the list will not contain any private or off-market real estate
              for sale or rent.
            </h3>
          </Col>
        </Row>
        {!hideSection && (
          <Row className="mb-6">
            <Col xs={24}>
              <>
                <Tabs
                  defaultActiveKey={activeTab}
                  size="large"
                  className="property-tabs"
                >
                  <Tabs.TabPane
                    tab={
                      <span>
                        For Sale{' '}
                        <span className="text-xs text-gray-500">
                          ({forSaleCount})
                        </span>
                      </span>
                    }
                    key="buy"
                  >
                    <AgentPropertyTab group="BUY" id={id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span>
                        Sold{' '}
                        <span className="text-xs text-gray-500">
                          ({soldCount})
                        </span>
                      </span>
                    }
                    key="sold"
                  >
                    <AgentPropertyTab group="SOLD" id={id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span>
                        For Rent{' '}
                        <span className="text-xs text-gray-500">
                          ({forRentCount})
                        </span>
                      </span>
                    }
                    key="rent"
                  >
                    <AgentPropertyTab group="RENT" id={id} />
                  </Tabs.TabPane>
                </Tabs>
              </>
            </Col>
          </Row>
        )}
        {hideSection && (
          <div className="text-center text-gray-500 py-8 my-32">
            Sorry,
            {firstName} has no properties yet.
          </div>
        )}
      </section>
    );
  }
}

const AGENT_DATA = gql`
  query userQuery($id: ID!) {
    agent(id: $id) {
      id
      firstName
      lastName
      agencyName
      statistics {
        forRentCount
        forSaleCount
        soldCount
      }
    }
  }
`;

const AgentPropertyWithData = compose(
  graphql(AGENT_DATA, { options: ({ id }) => ({ variables: { id } }) })
)(AgentProperty);

export default AgentPropertyWithData;
