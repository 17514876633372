import React, { Component } from 'react';
import { Modal, Form, Input, Row, Col, Icon, Button, Alert } from 'antd';
import { compose, graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

class ConsumerLoginModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    loginConsumerMutation: PropTypes.func,
    form: PropTypes.object,
  };

  static defaultProps = {
    visible: false,
    onCancel: () => {},
    onSuccess: () => {},
    loginConsumerMutation: () => {},
    form: {},
  };

  state = {
    isLoading: false,
  };

  handleSubmit = e => {
    const { form, onSuccess, loginConsumerMutation } = this.props;

    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        const { email, password } = values;

        this.setState({ isLoading: true });

        try {
          const {
            data: { createConsumerToken },
          } = await loginConsumerMutation(email.trim(), password);
          onSuccess(createConsumerToken);
        } catch (error) {
          const errors = error.graphQLErrors || [];
          const formErrorCode =
            errors.length > 0 ? errors[0].extensions.code : null;

          this.setState({
            formErrorCode,
            isLoading: false,
          });
        }
      }
    });
  };

  getErrorMessage = () => {
    let message = null;
    const { formErrorCode } = this.state;

    switch (formErrorCode) {
      case 'CONSUMER_NOT_EXISTS': {
        message = 'It seems that your email is not yet registered ';
        break;
      }
      case 'PASSWORD_MISMATCH': {
        message = 'It seems that your password is incorrect ';
        break;
      }
      case 'CONSUMER_NOT_ACTIVE': {
        message = 'It seems that the account you provided is inactive  ';
        break;
      }
      default: {
        message = 'Unable to login';
        break;
      }
    }

    return message;
  };

  render() {
    const {
      form: { getFieldDecorator },
      visible,
      onCancel,
    } = this.props;
    const { isLoading, formErrorCode } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={null}
        closable={false}
      >
        <Form className="p-4" onSubmit={this.handleSubmit}>
          <Row type="flex" justify="center" items="center">
            <Col xs={24}>
              <h2 className="text-center text-lg mb-2 text-gray-900 mb-6 uppercase">
                Sign in to your account
              </h2>
            </Col>
            <Col xs={24}>
              <span className="text-xs uppercase font-semibold ml-4 py-1 inline-block">
                Email
              </span>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    { transform: value => (value ? value.trim() : value) },
                    {
                      type: 'email',
                      message: 'Please enter a valid email',
                    },
                    {
                      required: true,
                      message: 'Email is required!',
                    },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    className="input-field"
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <span className="text-xs uppercase font-semibold ml-4 py-1 inline-block">
                Password
              </span>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Password is required!',
                    },
                  ],
                })(
                  <Input.Password
                    size="large"
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    className="input-field"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="my-4">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              block
              loading={isLoading}
            >
              {isLoading ? 'Signing you in' : 'Sign in'}
            </Button>
            <div className="h-6 py-2">
              {formErrorCode && (
                <Alert message={this.getErrorMessage()} type="error" showIcon />
              )}
            </div>
          </Form.Item>
          <Row type="flex" justify="space-between" className="mb-8">
            <Col>
              Need Account?
              <Link to="/sign-up"> Get Started!</Link>
            </Col>
            <Col>
              <Link to="/forgot-password"> Forgot Password?</Link>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const LOGIN_MUTATION = gql`
  mutation createConsumerToken($email: String!, $password: String!) {
    createConsumerToken(email: $email, password: $password) {
      id
      accessToken
      expiresIn
      refreshToken
      type
      user {
        ... on Consumer {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

const WrappedConsumerLoginFormWithProps = compose(
  Form.create(),
  graphql(LOGIN_MUTATION, {
    props: ({ mutate }) => ({
      loginConsumerMutation: (email, password) =>
        mutate({
          variables: {
            email,
            password,
          },
        }),
    }),
  })
)(ConsumerLoginModal);

export default WrappedConsumerLoginFormWithProps;
