import React, { Component } from 'react';
import { Button, Col, Row } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import qs from 'query-string';
import './PropertySearchSelect.scss';
import SearchPageQueryParameterParser from '../SearchPageQueryParameterParser';
import LocationFilterSelect from '../LocationFilterSelect';
import PropertyTypeFilterSelect from '../PropertyTypeFilterSelect';

class PropertySearchSelect extends Component {
  state = {
    keywords: undefined,
    type: {},
  };

  handleChangeKeyword = keywords => {
    this.setState({ keywords });
  };

  handleChangeType = type => {
    this.setState({ type });
  };

  buildQueryString = () => {
    const {
      searchOptions: { segment },
    } = this.props;
    const { keywords, type } = this.state;
    let params;

    const searchData = keywords;

    let keyword = [],
      locationType;

    if (searchData) {
      if (searchData.key.startsWith('k:')) {
        keyword.push(searchData.key.slice(2));
      } else {
        const splitKey = searchData.key.split(':');
        locationType = { name: splitKey[1], type: splitKey[0] };
      }
    }

    if (segment === 'RESIDENTIAL') {
      const propertyGroup = type.group || [];
      const propertyCategory = type.category || [];

      params = {
        keyword,
        propertyGroup,
        propertyCategory,
        locationType: JSON.stringify(locationType),
      };
    } else {
      const commercialType = type.group;

      params = {
        keyword,
        commercialType,
        locationType: JSON.stringify(locationType),
      };
    }

    return qs.stringify(params);
  };

  render() {
    const {
      searchOptions: { group },
      location: { pathname },
    } = this.props;
    const { type, keywords } = this.state;

    let segment = 'RESIDENTIAL';

    if (pathname.startsWith('/commercial')) {
      segment = 'COMMERCIAL';
    }

    return (
      <Row
        type="flex"
        justify="center"
        className="PropertySearchSelect rounded-lg"
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          xxl={6}
          className="search-type pb-2 lg:pr-2"
          id="search-type"
        >
          <PropertyTypeFilterSelect
            value={type}
            segment={segment}
            onChange={this.handleChangeType}
            className="property-type-select"
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={18}
          xl={14}
          xxl={14}
          className="search-keyword pb-2 xl:pr-2"
          id="keyword"
        >
          <LocationFilterSelect
            value={keywords}
            onChange={this.handleChangeKeyword}
            className="search-select"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={4}
          xxl={4}
          className="search-button-container"
        >
          <div className="flex xl:items-start items-center justify-center h-full ">
            <Link
              className="search-button w-full"
              to={{
                pathname: `${
                  pathname.startsWith('/commercial') ? '/commercial' : ''
                }/${group.toLowerCase()}/search`,
                search: this.buildQueryString(),
                state: {
                  fromHomePage: true,
                },
              }}
            >
              <Button
                className="find-homes-btn w-full h-full tracking-wide"
                size="large"
                type="primary"
                htmlType="submit"
              >
                {segment === 'COMMERCIAL' ? 'Find Spaces' : 'Find Homes'}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(props => (
  <SearchPageQueryParameterParser {...props}>
    {searchOptions => <PropertySearchSelect {...searchOptions} {...props} />}
  </SearchPageQueryParameterParser>
));
