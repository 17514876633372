import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import {
  RENT_PRICE_RANGE,
  BUY_PRICE_RANGE,
} from '../../../../constants/budget-ranges';
import Media from 'react-media';
import { Button, Icon, Popover, Radio } from 'antd';
import PropTypes from 'prop-types';

const RadioGroup = Radio.Group;

const PopoverWrapper = props => {
  return (
    <Media query="(max-width: 425px)">
      {matches =>
        matches ? (
          <Popover placement="bottomRight" {...props}>
            {props.children}
          </Popover>
        ) : (
          <Popover placement="bottom" {...props}>
            {props.children}
          </Popover>
        )
      }
    </Media>
  );
};

class PriceRangeFilter extends Component {
  static propTypes = {
    listingType: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    listingType: '',
    onChange: () => {},
  };

  state = {
    budgetRange: '',
  };

  handleOnApply = () => {
    const { listingType } = this.props;
    const defaultBudgetRange = this.getBudgetRange(listingType);

    this.props.onChange(
      defaultBudgetRange.find(br => br.key === this.state.budgetRange).value
    );
  };

  handleOnClear = () => {
    this.props.onChange([]);
  };

  getBudgetRange = listingType => {
    switch (listingType) {
      case 'RENT':
        return RENT_PRICE_RANGE;
      case 'SALE':
        return BUY_PRICE_RANGE;
    }
  };

  render() {
    let { listingType, budgetRange } = this.props;
    const defaultBudgetRange = this.getBudgetRange(listingType);
    let defaultValue = [];

    const props = {};

    if (budgetRange && budgetRange.length > 0) {
      if (!Array.isArray(budgetRange)) {
        budgetRange = [budgetRange];
      }

      defaultValue = defaultBudgetRange.find(br =>
        isEqual(br.value.map(String), budgetRange)
      );

      if (defaultValue) {
        props.defaultValue = defaultValue.key;
      }
    }

    return (
      <PopoverWrapper
        content={
          <>
            <div className="flex justify-center py-4 pr-12">
              <RadioGroup
                {...props}
                onChange={e => {
                  this.setState({ budgetRange: e.target.value });
                }}
              >
                {defaultBudgetRange.map(br => (
                  <Radio className="block" value={br.key} key={br.key}>
                    <span className="text-base">{br.description}</span>
                  </Radio>
                ))}
              </RadioGroup>
            </div>
            <div className="flex justify-end mt-2">
              <Button className="mr-2" onClick={this.handleOnClear}>
                Clear
              </Button>
              <Button type="primary" onClick={this.handleOnApply}>
                Apply
              </Button>
            </div>
          </>
        }
        trigger="click"
      >
        {budgetRange && budgetRange.length > 0 ? (
          <Button type="primary" size="large">
            {defaultValue.description}
            <Icon type="caret-down" />
          </Button>
        ) : (
          <Button size="large">
            Price <Icon type="caret-down" />
          </Button>
        )}
      </PopoverWrapper>
    );
  }
}

export default PriceRangeFilter;
