import React, { Component } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import { compose, graphql } from 'react-apollo';
import { CreateAgentEnquiry } from './Operations.graphql';
import './LandingPageLearnMoreModal.scss';

class LandingPageLearnMoreModal extends Component {
  state = {
    visible: false,
  };

  showModalSuccess = () => {
    const { onClose } = this.props;
    Modal.success({
      title: 'Thank you for interest in realeatste.sg',
      content: 'We will contact you as soon as we can',
      onOk: () => {
        onClose();
      },
    });
  };

  showModalError = () => {
    Modal.error({
      title: 'This was a problem with your request',
      content: 'Please try again later...',
    });
  };

  handleSubmit = ev => {
    ev.preventDefault();

    const {
      createAgentEnquiry,
      form: { validateFields, resetFields },
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const {
          lastName,
          firstName,
          agencyName,
          email,
          mobileNumber,
          message,
        } = values;

        const agentDetails = Object.assign(
          {},
          {
            lastName,
            firstName,
            agencyName,
            email,
            mobileNumber,
            message,
          }
        );

        createAgentEnquiry(agentDetails)
          .then(({ data }) => {
            // console.log(data);
            this.setState({ isLoading: false });
            resetFields();
            this.showModalSuccess();
          })
          .catch(() => {
            this.showModalError();
            this.setState({ isLoading: false });
          });
      }
    });
  };

  render() {
    const {
      isVisible,
      onClose,
      form: { getFieldDecorator },
    } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title="Enquiry Details"
        visible={isVisible}
        onOk={this.handleSubmit}
        confirmLoading={isLoading}
        onCancel={onClose}
        maskClosable={false}
      >
        <Form onSubmit={this.handleSubmit} className="StripeCheckout">
          <Row type="flex" justify="start" align="middle" gutter={20}>
            <Col span={12}>
              <label>First Name</label>
              <Form.Item>
                {getFieldDecorator('firstName', {
                  rules: [
                    { required: true, message: 'First Name is required' },
                  ],
                })(<Input placeholder="John" name="firstName" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <label>Last Name</label>
              <Form.Item>
                {getFieldDecorator('lastName', {
                  rules: [{ required: true, message: 'Last Name is required' }],
                })(<Input placeholder="Smith" name="lastName" />)}
              </Form.Item>
            </Col>
          </Row>

          <label>
            <label>Mobile Number</label>

            <Form.Item>
              {getFieldDecorator('mobileNumber', {
                rules: [
                  { required: true, message: 'Mobile Number is required' },
                ],
              })(<Input placeholder="65 6906 9747" name="mobileNumber" />)}
            </Form.Item>
          </label>

          <label>
            <label>Email</label>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { type: 'email', message: 'The input is not valid E-mail!' },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ],
              })(<Input placeholder="john.smith@example.com" name="email" />)}
            </Form.Item>
          </label>
          <label>
            <label>Agency Name</label>

            <Form.Item>
              {getFieldDecorator('agencyName', {
                rules: [{ required: true, message: 'Agency Name is required' }],
              })(<Input placeholder="Realty Sg" name="mobileNumber" />)}
            </Form.Item>
          </label>
          <label>
            <label>Message</label>

            <Form.Item>
              {getFieldDecorator('message')(
                <Input.TextArea
                  rows={4}
                  placeholder="Your Message"
                  name="message"
                />
              )}
            </Form.Item>
          </label>
          {/*<Button type="primary" loading={isLoading} htmlType="submit">Enquire</Button>*/}
        </Form>
      </Modal>
    );
  }
}

export default compose(
  Form.create(),
  graphql(CreateAgentEnquiry, {
    props: ({ mutate }) => ({
      createAgentEnquiry: input => mutate({ variables: { input } }),
    }),
  })
)(LandingPageLearnMoreModal);
