import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';

class StripeLoader extends Component {
    state = { stripe: null };

    componentDidMount() {
        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_KEY) });
        } else {
            document.querySelector('#stripe-js')
                .addEventListener('load', () => {
                    // Create Stripe instance once Stripe.js loads
                    this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_KEY) });
                });
        }
    }

    render() {
        return (
            <StripeProvider stripe={this.state.stripe}>
                {this.props.children}
            </StripeProvider>
        );
    }
}

export default StripeLoader;
