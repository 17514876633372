import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Carousel } from 'antd';
import PropTypes from 'prop-types';
import { CarouselNextArrow, CarouselPrevArrow } from '../Buttons';
import Error from '../Error';
import Loading from '../Loading';
import SimilarPropertiesQuery from '../../queries/SimilarPropertiesQuery.graphql';
import SimilarPropertyCard from './SimilarPropertyCard';
import './SimilarPropertiesList.scss';

class SimilarPropertiesList extends Component {
  static propTypes = {
    property: PropTypes.object,
    data: PropTypes.object,
  };

  static defaultProps = {
    property: {},
    data: {},
  };

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleCarouselControl);
  };

  componentWillUnmount = () => {
    window.addEventListener('keydown', this.handleCarouselControl);
  };

  handleCarouselControl = e => {
    if (this.carousel) {
      if (e.keyCode === 39 || e.code === 'ArrowRight') {
        this.carousel.slick.slickNext();
      } else if (e.keyCode === 37 || e.code === 'ArrowLeft') {
        this.carousel.slick.slickPrev();
      }
    }
  };

  render() {
    const {
      data: { loading, error, similarProperties },
    } = this.props;

    if (loading) return <Loading className="full-page" />;
    if (error) return <Error error={error} />;

    const properties = similarProperties;

    if (properties && properties.length) {
      const carouselSettings = {
        className: 'similar-properties-carousel',
        dots: false,
        lazyLoad: true,
        arrows: true,
        swipeToSlide: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        adaptiveHeight: true,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

      return (
        <div className="similar-properties-list-wrapper w-full mt-6 lg:px-8 px-4 mb-8">
          <h2 className="mb-4 sm:text-2xl text-lg">
            Similar {properties.length > 1 ? 'Listings' : 'Listing'}
          </h2>
          <Carousel
            {...carouselSettings}
            ref={carousel => {
              this.carousel = carousel;
            }}
          >
            {similarProperties.map(item => (
              <SimilarPropertyCard key={item.id} property={item} />
            ))}
          </Carousel>
        </div>
      );
    }

    return null;
  }
}

const SimilarPropertiesListWithData = graphql(SimilarPropertiesQuery, {
  options: ({
    property: {
      id,
      area,
      district,
      typeGroup,
      typeCategory,
      features: { bathrooms, bedrooms },
    },
  }) => {
    return {
      variables: {
        filter: {
          propertyId: id,
          area,
          district,
          typeGroup,
          typeCategory: typeCategory ? typeCategory.label : typeCategory,
          bathrooms: bathrooms || 0,
          bedrooms: bedrooms || 0,
        },
      },
    };
  },
})(SimilarPropertiesList);

export default SimilarPropertiesListWithData;
