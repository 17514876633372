import React, { Component } from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import PropertyAdvancedSearch from '../../components/PropertyAdvancedSearch';
import PropertySearchResults from '../../components/PropertySearchResults';
import './SearchPage.scss';
import SearchPageQueryParameterParser from '../../components/SearchPageQueryParameterParser';
import LocationResolver from '../../components/LocationResolver';
import PublicHeader from '../../components/PublicHeader';
import omit from 'lodash/omit';

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.onChange = debounce(this.onChange, 1000);
  }

  onChangeSorting = sorting => {
    const { changeUrl, searchOptions } = this.props;
    changeUrl(Object.assign(searchOptions, { sorting }));
  };

  buildSearchOptions = values => {
    const { searchOptions } = this.props;
    const searchData = values.keyword;
    const { showMap, ofiTime, group, sorting } = searchOptions;
    let keyword;
    if (searchData && searchData.key.startsWith('k:')) {
      keyword = searchData.key.slice(2);
    }
    let location = {};
    if (searchData && !searchData.key.startsWith('k:')) {
      const parts = searchData.key.split(':');
      location = { type: parts[0], name: parts[1] };
    }

    let filters = {
      ...values,
      keyword,
      location,
      floorArea: {
        max: values.floorArea
          ? values.floorArea.max === 6000
            ? undefined
            : values.floorArea.max
          : undefined,
        min: values.floorArea
          ? values.floorArea.min === 150
            ? undefined
            : values.floorArea.min
          : undefined,
      },
      landArea: {
        max: values.landArea
          ? values.landArea.max === 6000
            ? undefined
            : values.landArea.max
          : undefined,
        min: values.landArea
          ? values.landArea.min === 150
            ? undefined
            : values.landArea.min
          : undefined,
      },
      bed: {
        max: values.bed ? values.bed.max : undefined,
        min: values.bed
          ? values.bed.min === 0
            ? undefined
            : values.bed.min
          : undefined,
      },
      bathroom: {
        max: values.bathroom ? values.bathroom.max : undefined,
        min: values.bathroom
          ? values.bathroom.min === 0
            ? undefined
            : values.bathroom.min
          : undefined,
      },
      showMap: showMap === true ? true : undefined,
      ofiTime,
      sorting,
      group: searchOptions.group,
      segment: searchOptions.segment,
      commercialType: values.type.group,
    };

    if (searchOptions.segment === 'COMMERCIAL') {
      filters = omit(filters, 'type');

      filters = {
        ...filters,
        commercialType: values.type.group,
      };
    }

    if (group.toUpperCase() === 'RENT') {
      filters = {
        ...filters,
        price: {
          max: values.price
            ? values.price.max === 2000
              ? undefined
              : values.price.max
            : undefined,
          min: values.price
            ? values.price.min === 10
              ? undefined
              : values.price.min
            : undefined,
        },
      };
    } else {
      filters = {
        ...filters,
        price: {
          max: values.price
            ? values.price.max === 5000000
              ? undefined
              : values.price.max
            : undefined,
          min: values.price
            ? values.price.min === 100000
              ? undefined
              : values.price.min
            : undefined,
        },
      };
    }

    return filters;
  };

  onChange = (changedValues, allValues) => {
    const { changeUrl } = this.props;

    if (
      changedValues.keyword ||
      changedValues.keyword === undefined ||
      changedValues.type ||
      changedValues.bed ||
      changedValues.price ||
      changedValues.bathroom ||
      changedValues.floorArea ||
      changedValues.landArea ||
      changedValues.isIncludeSurrounding !== undefined
    ) {
      const options = this.buildSearchOptions({
        ...allValues,
      });
      changeUrl(options);
    }
  };

  render() {
    const { searchOptions, changeUrl, location } = this.props;

    return (
      <div className="default-theme SearchPage">
        <Helmet defer={false}>
          <title>Real Estate Singapore | Search for property</title>
        </Helmet>

        <div className="header bg-blue-500">
          <PublicHeader />
        </div>
        <div className="content-wrapper">
          <div className="content">
            <div className="search-filter">
              <PropertyAdvancedSearch
                changeUrl={changeUrl}
                onChange={this.onChange}
              />
            </div>
            <div className="search-results-wrapper">
              <PropertySearchResults
                searchOptions={searchOptions}
                onChangeSorting={this.onChangeSorting}
                searchParam={get(location, 'search')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => (
  <SearchPageQueryParameterParser {...props}>
    {({ searchOptions, changeUrl }) => (
      <LocationResolver location={searchOptions.location}>
        {location => (
          <SearchPage
            searchOptions={{
              ...searchOptions,
              locationData: location,
            }}
            changeUrl={changeUrl}
            {...props}
          />
        )}
      </LocationResolver>
    )}
  </SearchPageQueryParameterParser>
);
