import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Input, Popover } from 'antd';
import { FormattedNumber } from 'react-intl';
import cls from 'classnames';
import './NumericRange.scss';

export default class NumericRange extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.number),
    children: PropTypes.func,
  };

  static defaultProps = {
    children: () => null,
    options: [],
  };

  state = {
    isDisplayMin: true,
  };

  setAmount = amt => {
    const { value, onChange } = this.props;
    const { isDisplayMin } = this.state;
    const newValue = { ...value };
    if (isDisplayMin) {
      newValue.min = amt;
    } else {
      newValue.max = amt;
    }
    onChange(newValue);
  };

  showMin = () => {
    this.setState({
      isDisplayMin: true,
    });
  };

  showMax = () => {
    this.setState({
      isDisplayMin: false,
    });
  };

  render = () => {
    const { children, value, options } = this.props;
    const { min, max } = value;
    const { isDisplayMin } = this.state;

    const fixedAmountsCls = cls('fixed-amount', {
      'text-left': isDisplayMin,
      'text-right': !isDisplayMin,
    });

    const amountClickCls = cls([
      'block',
      'w-full',
      'h-full',
      'px-4',
      'py-2',
      'text-gray-800',
      'hover:text-gray-800',
      'focus:text-gray-800',
    ]);

    const popoverContent = (
      <div className="filter-container">
        <div className="flex items-center justify-between p-4">
          <Input
            className="min-range"
            value={min}
            onClick={this.showMin}
            onChange={evt => this.setAmount(evt.target.value)}
            placeholder="MIN"
          />
          <span className="flex-1 text-center">-</span>
          <Input
            className="max-range"
            value={max}
            onClick={this.showMax}
            onChange={evt => this.setAmount(evt.target.value)}
            placeholder="MAX"
          />
        </div>
        <ul>
          <li className={fixedAmountsCls}>
            <a
              href="#"
              className={amountClickCls}
              onClick={() => this.setAmount(undefined)}
            >
              No {isDisplayMin ? 'Min' : 'Max'}
            </a>
          </li>
          <li>
            <Divider className="my-2" />
          </li>
          {options.map(amt => {
            const isMinAmtGreaterThanMax = isDisplayMin && amt > max;
            const isMaxAmtLessThanMin = !isDisplayMin && amt < min;
            const amountCls = cls(amountClickCls, {
              'cursor-default text-gray-300 hover:text-gray-300 focus:text-gray-300 pointer-events-none':
                isMinAmtGreaterThanMax || isMaxAmtLessThanMin,
            });
            return (
              <li className={fixedAmountsCls} key={amt}>
                <a
                  className={amountCls}
                  href="#"
                  onClick={() => this.setAmount(amt)}
                >
                  S$ <FormattedNumber value={amt} currency="SGD" />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );

    return (
      <Popover
        content={popoverContent}
        overlayClassName="NumericRange"
        placement="bottomLeft"
        trigger="click"
      >
        {children({
          min,
          max,
        })}
      </Popover>
    );
  };
}
