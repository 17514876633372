import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Tooltip, Button, message } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { loginUser } from '../../actions';
import ConsumerLoginModal from './ConsumerLoginModal';
import ConsumerPropertyBookmarkQuery from '../../queries/ConsumerPropertyBookmarkQuery.graphql';
import CreateBookmarkPropertyMutation from '../../mutations/CreateBookmarkPropertyMutation.graphql';
import './BookmarkProperty.scss';

class BookmarkProperty extends Component {
  static propTypes = {
    propertyId: PropTypes.string,
    title: PropTypes.string,
    bookmarkProperty: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    userType: PropTypes.string,
    propPage: PropTypes.bool,
    data: PropTypes.object,
  };

  static defaultProps = {
    propertyId: '',
    title: '',
    bookmarkProperty: () => {},
    isAuthenticated: false,
    userType: '',
    propPage: false,
    data: {},
  };

  state = {
    showLoginModal: false,
    propertyId: undefined,
    propertyMarked: false,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = state;

    if (
      props.propertyId !== state.propertyId &&
      props.data &&
      !props.data.loading
    ) {
      newState = {
        propertyId: props.propertyId,
        propertyMarked: props.data.consumerPropBookmark ? true : false,
      };
    }

    return newState;
  }

  handleBookmarkProperty = () => {
    const { propertyId, bookmarkProperty } = this.props;
    const { propertyMarked } = this.state;

    if (propertyMarked) {
      message.info('This property is already bookmarked.');
      return;
    }

    bookmarkProperty(propertyId)
      .then(() => {
        message.success('Successfully bookmarked property');
        this.setState({ propertyMarked: true });
      })
      .catch(() => {
        message.error('Something went wrong in bookmarking this property');
      });
  };

  handleLoginModalVisibility = () => {
    this.setState(prevState => ({
      showLoginModal: !prevState.showLoginModal,
    }));
  };

  handleLoginConsumer = loginData => {
    const { onLoginUser } = this.props;
    const { accessToken, expiresIn, refreshToken, user, type } = loginData;

    onLoginUser({
      accessToken,
      expiresIn,
      refreshToken,
      user,
      type,
    });

    this.handleLoginModalVisibility();
    this.handleBookmarkProperty();
  };

  render() {
    const { title, isAuthenticated, userType, propPage } = this.props;
    const { showLoginModal, propertyMarked } = this.state;
    const btnClassname = propertyMarked ? 'bookmark is-marked' : 'bookmark';
    const btnAction =
      isAuthenticated && userType === 'CONSUMER'
        ? this.handleBookmarkProperty
        : this.handleLoginModalVisibility;
    const tooltipTitle = propertyMarked ? 'Bookmarked' : `Boookmark ${title}`;

    return (
      <>
        {propPage ? (
          <Button
            className={`${btnClassname} page`}
            onClick={btnAction}
            disabled={propertyMarked}
          >
            <FA icon={faBookmark} className="text-lg" />
          </Button>
        ) : (
          <Tooltip placement="bottomRight" title={tooltipTitle}>
            <Button
              style={{
                border: '0px',
                fontSize: 20,
              }}
              size="large"
              shape="circle"
              className={`${btnClassname} nonpage`}
              onClick={btnAction}
              disabled={propertyMarked}
            >
              <FA icon={faBookmark} className="text-lg" />
            </Button>
          </Tooltip>
        )}
        <ConsumerLoginModal
          visible={showLoginModal}
          onCancel={this.handleLoginModalVisibility}
          onSuccess={this.handleLoginConsumer}
        />
      </>
    );
  }
}

const BookmarkPropertyWithMutation = compose(
  graphql(ConsumerPropertyBookmarkQuery, {
    options: ({ propertyId }) => ({
      variables: { propertyId },
    }),
  }),
  graphql(CreateBookmarkPropertyMutation, {
    props: ({ mutate }) => ({
      bookmarkProperty: propertyId => mutate({ variables: { propertyId } }),
    }),
  }),
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      userType: state.auth.type,
    }),
    dispatch => ({
      onLoginUser(accessToken) {
        dispatch(loginUser(accessToken));
      },
    })
  )
)(BookmarkProperty);

export default BookmarkPropertyWithMutation;
