import React from 'react';
import PropTypes from 'prop-types';
import {
  faBuilding,
  faCity,
  faHomeLg,
} from '@fortawesome/pro-regular-svg-icons';
import { Col, Radio, Row } from 'antd';
import PropertyTypeOption from '../PropertyTypeOption';

const { Group: RadioGroup } = Radio;

function PropertyTypeStep(props) {
  return (
    <>
      <Row className="flex justify-center">
        <Col>
          <p className="text-2xl md:text-3xl text-white text-center px-4">
            What type of property are you looking for?
          </p>
        </Col>
      </Row>
      <Row className="flex justify-center">
        <Col className="mt-8">
          <RadioGroup
            className="option-group text-center md:flex"
            onChange={props.onChange}
          >
            <PropertyTypeOption value="HDB" icon={faBuilding} label="HDB" />
            <PropertyTypeOption
              value="Condominium"
              icon={faCity}
              label="Condominium"
            />
            <PropertyTypeOption value="Landed" icon={faHomeLg} label="Landed" />
          </RadioGroup>
        </Col>
      </Row>
    </>
  );
}

PropertyTypeStep.propTypes = { onChange: PropTypes.func };

PropertyTypeStep.defaultProps = {
  onChange: () => {},
};

export default PropertyTypeStep;
