/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Icon, message } from 'antd';
import {
  findIndexById,
  removeRecordFromCache,
} from '../../../../utils/graphql';
import EventsConnectionQuery from '../../../../pages/consumer/ConsumerEvents/Operations.graphql';
import ConsumerPageEventsConnection from '../../../../pages/ContributorPage/ContributorPosts/Operations.graphql';
import ConsumerProfilePageEventsConnection from '../../ConsumerProfilePosts/Operations.graphql';
import {
  FeedEventsNames,
  withEmitterContext,
} from '../../../../context/emitter-context';

class DeletePostEvent extends Component {
  static propTypes = {
    event: PropTypes.object,
    deletePostEvent: PropTypes.func,
    onDelete: PropTypes.func,
    pageId: PropTypes.string,
    consumerPageId: PropTypes.string,
  };

  static defaultProps = {
    event: {},
    deletePostEvent: () => {},
    onDelete: () => {},
    pageId: null,
    consumerPageId: null,
  };

  state = { icon: 'delete' };

  handleDelete = () => {
    message.config({ top: 24 });
    const {
      event: {
        id,
        post: { id: postId },
      },
      deletePostEvent,
      onDelete,
      pageId,
      consumerPageId,
    } = this.props;

    this.setState({ icon: 'loading' });
    deletePostEvent({
      eventId: id,
      postId,
      pageId,
      consumerPageId,
    })
      .then(({ data: { deletePostEvent: deletePostEventResult } }) => {
        if (deletePostEventResult) {
          onDelete();
          message.success('Your post has been deleted');
        } else {
          message.error('Something went wrong deleting your post');
        }
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({ icon: 'delete' });
  };

  render() {
    const { icon } = this.state;
    return (
      <div aria-hidden onClick={this.handleDelete} className="py-1 px-2">
        <Icon type={icon} />
        &nbsp;Delete
      </div>
    );
  }
}

const DELETE_POST_EVENT = gql`
  mutation deletePostEvent($eventId: ID!, $postId: ID!) {
    deletePostEvent(eventId: $eventId, postId: $postId)
  }
`;

const DeletePostEventWithMutation = compose(
  withEmitterContext,
  graphql(DELETE_POST_EVENT, {
    props: ({ mutate, ownProps }) => ({
      deletePostEvent: ({ eventId, postId, pageId, consumerPageId }) =>
        mutate({
          variables: {
            eventId,
            postId,
            pageId,
            consumerPageId,
          },
          update: (store, { data: { deletePostEvent } }) => {
            if (pageId && consumerPageId) {
              try {
                if (!deletePostEvent) return;

                removeRecordFromCache(
                  store,
                  ConsumerPageEventsConnection,
                  {
                    pageId,
                    consumerPageId,
                    filter: { first: 20 },
                  },
                  data => data.consumerPage.consumerPageEventsConnection.nodes,
                  data => findIndexById(data, eventId)
                );

                ownProps.emitter.emit(FeedEventsNames.ON_POST_DELETED);
              } catch (err) {
                console.log(err);
              }
            } else if (pageId && !consumerPageId) {
              try {
                if (!deletePostEvent) return;

                removeRecordFromCache(
                  store,
                  ConsumerProfilePageEventsConnection,
                  {
                    pageId,
                    filter: { first: 20 },
                  },
                  data => data.consumerPage.profilePostsConnection.nodes,
                  data => findIndexById(data, eventId)
                );

                ownProps.emitter.emit(FeedEventsNames.ON_POST_DELETED);
              } catch (err) {
                console.log(err);
              }
            } else {
              try {
                if (!deletePostEvent) return;

                removeRecordFromCache(
                  store,
                  EventsConnectionQuery,
                  {
                    filter: { first: 20 },
                    contributorId: null,
                  },
                  data => data.viewer.eventsConnection.nodes,
                  data => findIndexById(data, eventId)
                );

                ownProps.emitter.emit(FeedEventsNames.ON_POST_DELETED);
              } catch (err) {
                console.log(err);
              }
            }
          },
        }),
    }),
  })
)(DeletePostEvent);

export default DeletePostEventWithMutation;
