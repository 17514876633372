import {
  faDumbbell,
  faBells,
  faKeyboard,
  faWind,
  faHouseFlood,
  faBroadcastTower,
  faCabinetFiling,
  faTrees,
  faUmbrellaBeach,
  faUtensils,
  faAlignJustify,
  faCarGarage,
  faFireAlt,
  faTemperatureLow,
  faTemperatureHigh,
  faMugHot,
  faFireplace,
  faHotTub,
  faBurn,
  faStreetView,
  faCampground,
  faTvRetro,
  faSwimmingPool,
  faTasksAlt,
  faHurricane,
  faDice,
  faParking,
  faWarehouse,
  faSolarPanel,
  faColumns,
  faGlasses,
  faTennisBall,
  faSnowBlowing,
  faSnowplow,
  faTint,
  faChalkboardTeacher,
  faClipboardList,
  faWater,
  faFlame,
  faCauldron,
  faTreeAlt,
  faTombstoneAlt,
  faSunDust,
  faTv,
  faIcicles,
  faTshirt,
  faKnifeKitchen,
  faBoothCurtain,
  faDungeon,
  faBath,
  faCity,
  faDumpsterFire,
  faClub,
  faMicrophone,
  faSpa,
  faChild,
  faSeedling,
  faHeartbeat,
  faRunning,
  faShieldAlt,
  faSpade,
  faLandmark,
  faStore,
  faCouch,
  faLoveseat,
  faBasketballBall,
  faCoffee,
  faHomeAlt,
  faVolumeUp,
  faWifi,
  faBlanket,
} from '@fortawesome/pro-regular-svg-icons';

export const featureOptions = [
  { key: 'airConditioning', label: 'Air Conditioned', icon: faWind },
  { key: 'alarmSystem', label: 'Alarm System', icon: faBells },
  { key: 'balcony', label: 'Balcony', icon: faHouseFlood },
  { key: 'broadband', label: 'Broadband', icon: faBroadcastTower },

  { key: 'builtInRobes', label: 'Built-in Robes', icon: faCabinetFiling },
  { key: 'courtyard', label: 'Courtyard', icon: faTrees },
  { key: 'deck', label: 'Deck', icon: faUmbrellaBeach },
  { key: 'dishwasher', label: 'Dishwasher', icon: faUtensils },
  { key: 'ductedCooling', label: 'Ducted Cooling', icon: faTemperatureLow },
  {
    key: 'ductedHeating',
    label: 'Ducted Heating',
    icon: faTemperatureHigh,
  },
  {
    key: 'evaporativeCooling',
    label: 'Evaporative Cooling',
    icon: faSnowBlowing,
  },
  { key: 'floorboards', label: 'Floor Boards', icon: faTasksAlt },
  { key: 'fullyFenced', label: 'Fully Fenced', icon: faAlignJustify },
  { key: 'gasHeating', label: 'Gas Heating', icon: faFireAlt },
  { key: 'greyWaterSystem', label: 'Grey Water System', icon: faWater },
  { key: 'gym', label: 'Gym', icon: faDumbbell },
  { key: 'hotWaterService', label: 'Hot Water Service', icon: faMugHot },
  { key: 'hydronicHeating', label: 'Hydronic Heating', icon: faBurn },
  { key: 'insideSpa', label: 'Inside Spa', icon: faHotTub },
  { key: 'intercom', label: 'Intercom', icon: faKeyboard },
  { key: 'openFirePlace', label: 'Open Fire Place', icon: faFireplace },

  { key: 'openSpaces', label: 'Open Spaces', icon: faStreetView },
  { key: 'outdoorEnt', label: 'Outside Spa', icon: faCampground },
  { key: 'outsideSpa', label: 'Pay TV', icon: faTvRetro },
  { key: 'payTv', label: 'Pool Above Ground', icon: faSwimmingPool },
  {
    key: 'poolAboveGround',
    label: 'Pool Inside Ground',
    icon: faSwimmingPool,
  },
  { key: 'remoteGarage', label: 'Remote Garage', icon: faCarGarage },
  {
    key: 'reverseCycleAircon',
    label: 'Reverse Cycle Aircon',
    icon: faHurricane,
  },
  { key: 'rumpusRoom', label: 'Rumpus Room', icon: faDice },
  { key: 'secureParking', label: 'Secure Parking', icon: faParking },
  { key: 'shed', label: 'Shed', icon: faWarehouse },

  { key: 'solarHotWater', label: 'Solar Hot Water', icon: faSolarPanel },
  { key: 'solarPanels', label: 'Solar Panel', icon: faSolarPanel },
  {
    key: 'splitsystemAircon',
    label: 'Split System Aircon',
    icon: faColumns,
  },
  {
    key: 'splitsystemHeating',
    label: 'Split System Heating',
    icon: faColumns,
  },
  { key: 'study', label: 'Study', icon: faGlasses },
  { key: 'tennisCourt', label: 'Tennis Court', icon: faTennisBall },
  { key: 'vacuumSystem', label: 'Vacuum System', icon: faSnowplow },
  { key: 'waterTank', label: 'Water Tank', icon: faTint },
  { key: 'workshop', label: 'Workshop', icon: faChalkboardTeacher },
  { key: 'otherFeatures', label: 'Other Features', icon: faClipboardList },
  { key: 'waterHeater', label: 'Water Heater', icon: faFlame },
  { key: 'cookerStove', label: 'Cooker Stove', icon: faCauldron },
  { key: 'cookerHob', label: 'Cooker Hob', icon: faFireAlt },
  { key: 'park', label: 'Park', icon: faTreeAlt },
  { key: 'greeneryView', label: 'Greenery View', icon: faTrees },
  { key: 'bombShelter', label: 'Bomb Shelter', icon: faTombstoneAlt },
  { key: 'dryer', label: 'Dryer', icon: faSunDust },
  { key: 'cableTv', label: 'Cable TV', icon: faTv },
  { key: 'cornerUnit', label: 'Corner Unit', icon: faCabinetFiling },
  { key: 'fridge', label: 'Fridge', icon: faIcicles },
  { key: 'walkInWardrobe', label: 'Walkin Wardrobe', icon: faCabinetFiling },
  { key: 'iron', label: 'Iron', icon: faTshirt },
  { key: 'ironingBoard', label: 'Ironing Boarding', icon: faTshirt },
  { key: 'kitchenUtensils', label: 'Kitchen Utensils', icon: faKnifeKitchen },
  { key: 'maidRoom', label: 'Maid Room', icon: faBoothCurtain },
  { key: 'television', label: 'Television', icon: faTv },
  { key: 'oven', label: 'Oven', icon: faFlame },
  { key: 'microwave', label: 'Microwave', icon: faFlame },
  { key: 'terrace', label: 'Terrace', icon: faDungeon },
  { key: 'bathTub', label: 'Bath Tub', icon: faBath },
  { key: 'outdoorPatio', label: 'Outdoor Patio', icon: faUmbrellaBeach },
  { key: 'cityView', label: 'City View', icon: faCity },
  { key: 'barbecueArea', label: 'Barbecue Area', icon: faDumpsterFire },
  { key: 'clubHouse', label: 'Club House', icon: faClub },
  { key: 'functionRoom', label: 'Function Room', icon: faMicrophone },
  { key: 'jacuzzi', label: 'Jacuzzi', icon: faHotTub },
  { key: 'lapPool', label: 'Lap Pool', icon: faSwimmingPool },
  {
    key: 'multiStoreyCarPark',
    label: 'Multi Storey Car Park',
    icon: faParking,
  },
  { key: 'reflexologyPath', label: 'Reflexology Path', icon: faSpa },
  { key: 'childrenPlayground', label: 'Children Playground', icon: faChild },
  { key: 'communityGarden', label: 'Community Garden', icon: faSeedling },
  { key: 'fitnessCorner', label: 'Fitness Corner', icon: faHeartbeat },
  { key: 'gymnasiumRoom', label: 'Gymnasium Room', icon: faDumbbell },
  { key: 'joggingTrack', label: 'Jogging Track', icon: faRunning },
  { key: 'pavillion', label: 'Pavillion', icon: faHomeAlt },
  { key: 'playground', label: 'Playground', icon: faChild },
  { key: 'wadingPool', label: 'Wading Pool', icon: faSwimmingPool },
  { key: 'security', label: 'Security', icon: faShieldAlt },
  { key: 'steamBath', label: 'Steam Bath', icon: faHotTub },
  { key: 'gameRoom', label: 'Game Room', icon: faSpade },
  { key: 'multiPurposeHall', label: 'Multi purpose Hall', icon: faLandmark },
  { key: 'miniMart', label: 'Mini Mart', icon: faStore },
  { key: 'swimmingPool', label: 'Swimming Pool', icon: faSwimmingPool },
  { key: 'basementCarPark', label: 'Basement Car Park', icon: faParking },
  { key: 'spaPool', label: 'Spa Pool', icon: faSwimmingPool },
  { key: 'lounge', label: 'Lounge', icon: faCouch },
  { key: 'skyLounge', label: 'Sky Lounge', icon: faLoveseat },
  { key: 'basketballCourt', label: 'Basketball Court', icon: faBasketballBall },
  { key: 'funPool', label: 'Fun Pool', icon: faSwimmingPool },
  { key: 'cafes', label: 'Cafes', icon: faCoffee },
  { key: 'carPark', label: 'Car Park', icon: faParking },
  { key: 'bbq', label: 'Barbeque', icon: faDumpsterFire },
  { key: 'washingMachine', label: 'Washing Machine', icon: faBlanket },
  { key: 'audioSystem', label: 'Audio System', icon: faVolumeUp },
  { key: 'internetConnection', label: 'Internet Connection', icon: faWifi },
];
