import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Divider, Icon, Modal, Radio, Row } from 'antd';
import { ApolloProvider, Query } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import qs from 'query-string';
import gql from 'graphql-tag';
import { FormattedNumber } from 'react-intl';
import Hero from '../../components/Hero';
import './ExclusiveOpportunityPage.scss';
import alexaLogo from './images/alexa.png';
import premierAd from './images/premier-ad.png';
import standardAd from './images/standard-ad.png';
import mortgageChoice from './images/mortgage-choice.png';
import nectar from './images/nectar.png';
import yellowBrickRoad from './images/yellow-brick-road.png';
import loanMarket from './images/loan-market.png';
import rayWhite from './images/ray-white.png';
import raineHorne from './images/raine-horne.png';
import ljHooker from './images/ljhooker.png';
import harcourts from './images/harcourts.png';
import howImage from './images/leads-prospect-buyers.jpg';
import adam from './images/adam.png';
import whatsApp from './images/whatsapp.png';
import fb from './images/fb.png';
import fbPost from './images/fb-post.png';
import crm from './images/crm.png';
import crmIcon from './images/crm-icon.png';
import buyers from './images/profits.png';
import buyerMatch from './images/jigsaw.png';
import sgMap from './images/sg-map.png';
import pinGold from './images/pin-gold.png';
import { StatisticsData } from './Operations.graphql';

import LandingPageLearnMoreModal from '../../components/LandingPageLearnMoreModal';

const SG_AGENT_COUNT = gql`
  query SgAgentCount {
    agentCount
  }
`;

const clients = [
  {
    img: mortgageChoice,
    text: 'mortgage-choice',
  },
  {
    img: nectar,
    text: 'nectar',
  },
  {
    img: yellowBrickRoad,
    text: 'yellow-brick-road',
  },
  {
    img: loanMarket,
    text: 'loan-market',
  },
  {
    img: rayWhite,
    text: 'ray-white',
  },
  {
    img: raineHorne,
    text: 'raine-horne',
  },
  {
    img: ljHooker,
    text: 'lj-hooker',
  },
  {
    img: harcourts,
    text: 'harcourts',
  },
];

class ExclusiveOpportunityPage extends Component {
  state = {
    isVisible: false,
    availedPackage: 'monthly',
    type: 'agent',
    showThankYou: false,
  };

  handleChangeType = type => {
    this.setState({ type });
  };

  showModal = () => {
    this.setState({
      isVisible: true,
    });
  };

  hideModal = () => {
    this.setState({
      isVisible: false,
    });
  };

  selectedPackage = e => {
    this.setState({
      availedPackage: e.target.value,
    });
  };

  static getDerivedStateFromProps(props) {
    const { location } = props;

    const searchOption = qs.parse(location.search);

    if (searchOption.fromVerify === 'true') {
      return { showThankYou: true };
    }

    return null;
  }

  showThankYouModal = () => {
    const modal = Modal.success({
      title: 'Thank you for verifying your account',
    });

    setTimeout(() => {
      modal.destroy();
    }, 5000);
  };

  componentDidMount() {
    if (this.state.showThankYou) {
      this.showThankYouModal();
    }
  }

  render() {
    const {
      match: {
        params: { plan },
      },
      location: { pathname },
    } = this.props;

    let price;
    let spots;
    let offer;
    let coupon;
    const monthlyPrice = 39.9;

    if (pathname === '/united') {
      price = 249;
      spots = 1000;
      offer = 'United';
      coupon = 'UNITED';
    } else if (pathname === '/knightfrank') {
      price = 299;
      spots = 500;
      offer = 'KnightFrank';
      coupon = 'KNIGHTFRANK';
    } else {
      price = 399;
      spots = 1500;
      offer = 'Exclusive';
      coupon = 'EARLY';
    }
    const { isVisible, availedPackage, type } = this.state;

    return (
      <>
        <Hero />
        <section className="bg-primary flex justify-center question py-8 sm:flex-row flex-col items-center">
          <h4 className="md:text-4xl sm:text-2xl text-xl text-white mr-2">
            I am
          </h4>
          <div className="flex items-center choice sm:flex-row flex-col">
            <span
              className="md:text-2xl sm:text-lg text-base bg-white px-4 py-1 rounded-full transition cursor-pointer hover:shadow-lg"
              onClick={() => this.handleChangeType('agent')}
              aria-hidden
            >
              <Icon
                type="check"
                className={`${
                  type === 'agent' ? 'opacity-100' : 'opacity-0'
                } hover:opacity-100 text-green-700 transition`}
              />
              an agent
            </span>
            <span className="md:text-2xl sm:text-lg text-base mx-2 text-white text-bold">
              or
            </span>
            <span
              className="md:text-2xl sm:text-lg text-base bg-white px-4 py-1 rounded-full transition cursor-pointer hover:shadow-lg"
              onClick={() => this.handleChangeType('property manager')}
              aria-hidden
            >
              <Icon
                type="check"
                className={`${
                  type === 'property manager' ? 'opacity-100' : 'opacity-0'
                } hover:opacity-100 text-green-700 transition`}
              />
              a property manager
            </span>
            <span className="md:text-2xl sm:text-lg text-base mx-2 text-white text-bold">
              or
            </span>
            <span
              className="md:text-2xl sm:text-lg text-base bg-white px-4 py-1 rounded-full transition cursor-pointer hover:shadow-lg"
              onClick={() => this.handleChangeType('both')}
              aria-hidden
            >
              <Icon
                type="check"
                className={`${
                  type === 'both' ? 'opacity-100' : 'opacity-0'
                } hover:opacity-100 text-green-700 transition`}
              />
              both
            </span>
          </div>
        </section>
        <section className="flex justify-center py-16 alexa">
          <div className="w-full flex items-center justify-center lg:flex-row flex-col">
            <div className="mx-12">
              <Link
                to="//www.alexa.com/siteinfo/singapore.realty.com.au"
                className="lg:h-48 lg:w-48 md:w-32 md:h-32 h-24 w-24 bg-blue-100 block rounded-full relative border-4 border-blue-200"
              >
                <img
                  src={alexaLogo}
                  alt="alexa"
                  className="center-align w-5/6"
                />
              </Link>
            </div>
            <div className="flex justify-center flex-col items-center w-full">
              <h2 className="lg:text-4xl md:text-3xl text-2xl text-gray-200 py-4 px-2 text-center">
                Find more buyers for less with the&nbsp;
                <span className="text-white">
                  World&apos;s fastest growing website
                </span>
                &nbsp;team
              </h2>
              <p className="md:text-2xl text-lg inline-block w-4/5 mb-8 mt-4 text-center break-word text-gray-200">
                According to &nbsp;
                <Link
                  to="//www.alexa.com/siteinfo/singapore.realty.com.au"
                  className="text-gray-200 font-semibold border-b-2 border-blue-700"
                >
                  Alexa.com
                </Link>
                ,&nbsp;
                <Link
                  to="//www.singapore.realty.com.au"
                  className="text-gray-200 font-semibold border-b-2 border-blue-700"
                >
                  Singapore.realty.com.au
                </Link>
                &nbsp;is the fastest growing website in Singapore for the last
                90 days.&nbsp;
              </p>

              <Link
                to="//www.alexa.com/siteinfo/singapore.realty.com.au"
                className="block poppins text-gray-900 bg-white rounded-full px-4 py-1 break-all sm:text-base text-sm"
              >
                https://www.alexa.com/siteinfo/singapore.realty.com.au
              </Link>
            </div>
          </div>
        </section>
        <section className="py-24 flex justify-center">
          <h2 className="text-justify lg:text-2xl md:text-lg text-base font-normal xxl:w-2/3 lg:w-3/4 w-5/6">
            {(type === 'agent' || type === 'both') &&
              "In a slower market it's critical you stand out from the pack!"}
            {(type === 'property manager' || type === 'both') &&
              ' To grow your rent roll you need a strategy where you stand out!'}
            <br />
            We employ the exact psychology Google does with top of page search
            placement.
            <br />
            <span className="font-semibold">Real Estate Accelerate</span>
            &nbsp;will deliver more eyes on your property portfolio by
            positioning!
            {/* you as the #1&nbsp;
            {type === 'both' ? 'agent/property manager' : type}
            &nbsp;in your local area! */}
          </h2>
        </section>

        <section className="py-2 bg-blue-800 flex flex-col items-center">
          <h2 className="text-white md:text-4xl sm:text-3xl text-2xl pt-6 w-3/4">
            Our achievements so far...
          </h2>
          <ApolloProvider
            client={
              new ApolloClient({
                link: createUploadLink({
                  uri: 'https://api.realty.com.au/graphql',
                }),
                cache: new InMemoryCache({
                  addTypename: true,
                  logger: console.log,
                  loggerEnabled: true,
                }),
              })
            }
          >
            <Query query={StatisticsData}>
              {({ loading, error, data }) => {
                let propertiesCount = 31805;
                let agentsCount = 1115;
                let agenciesCount = 8933;

                console.log(error);
                console.log(loading);
                if (
                  !loading &&
                  !error &&
                  data &&
                  data.adminPropertyStatistics
                ) {
                  propertiesCount =
                    data.adminPropertyStatistics.propertiesCount;
                  agentsCount = data.adminPropertyStatistics.agentsCount;
                  agenciesCount = data.adminPropertyStatistics.agenciesCount;
                }

                return (
                  <div className="flex justify-center mb-4 bottom-0 w-full">
                    <div className="xl:w-1/2 lg:w-3/4 md:w-4/8 w-full px-2">
                      <div className="flex flex-wrap justify-center py-8">
                        <div className="border-l-4 border-yellow-500 pl-4 mr-4 sm:mb-0 mb-4 flex-grow-0 sm:w-48 w-1/2 text-shadow">
                          <h2 className="text-white lg:text-4xl md:text-2xl text-xl mb-0">
                            <FormattedNumber value={propertiesCount} />+
                          </h2>
                          <h5 className="uppercase text-blue-100 text-shadow-sm md:text-xl text-base">
                            Listings
                          </h5>
                        </div>
                        <div className="border-l-4 border-green-500 pl-4 mr-4 sm:mb-0 mb-4 flex-grow-0 sm:w-48 w-1/2 text-shadow">
                          <h2 className="text-white lg:text-4xl md:text-2xl text-xl mb-0">
                            <FormattedNumber value={agentsCount} />+
                          </h2>
                          <h5 className="uppercase text-blue-100 text-shadow-sm md:text-xl text-base">
                            Agencies
                          </h5>
                        </div>
                        <div className="border-l-4 border-orange-500 pl-4 mr-4 sm:mb-0 mb-4 flex-grow-0 sm:w-48 w-1/2 text-shadow">
                          <h2 className="text-white lg:text-4xl md:text-2xl text-xl mb-0">
                            <FormattedNumber value={agenciesCount} />+
                          </h2>
                          <h5 className="uppercase text-blue-100 text-shadow-sm md:text-xl text-base">
                            Agents
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Query>
          </ApolloProvider>
        </section>

        <Row type="flex" justify="center" className="section px-2">
          <Col xs={23} sm={22} md={18} lg={18} xl={18}>
            <div className="font-semibold text-4xl text-center mb-12 mx-4">
              Singapore.realty.com.au is a new entrant to the Singaporean
              property portal market, expanding from Australia (realty.com.au)
              which also offers a social media focus for the “home owner”
            </div>
            <h2 className="header-title text-4xl my-8">Why US?</h2>

            <ul className="ml-5 font-normal text-2xl mt-4 mb-14">
              <li>
                <Icon type="check-circle" className="text-green-300 mr-4" />{' '}
                Unique opportunity for Agents to connect with home buyers,
                sellers &amp; renters.
              </li>
              <li>
                <Icon type="check-circle" className="text-green-300 mr-4" />{' '}
                Improved features &amp; social engagement with Home Owners
                throughout “home” lifecycle;
              </li>
              <li>
                <Icon type="check-circle" className="text-green-300 mr-4" />{' '}
                Leveraging the fastest-growing property marketing platform in
                Australia model
              </li>
            </ul>

            <h2 className="header-title text-4xl mt-12 mb-4">How?</h2>
            <div className="font-semibold text-3xl text-center mb-2">
              Singapore.realty.com.au is more than just a property portal, it's
              a digital lifestyle channel for the home owner!
            </div>

            <p className="text-2xl mb-6 text-center">
              We strategically engage buyers with 4 key marketing approaches:
            </p>
            <div className="font-normal text-2xl md:flex justify-center mb-12 mx-12">
              <li className="mr-12">Social</li>
              <li className="mr-12">Local</li>
              <li className="mr-12">Organic</li>
              <li className="mr-12">Intent</li>
            </div>
            <img
              src={howImage}
              alt="leads-prospect-buyers"
              className="block m-auto "
            />
          </Col>
        </Row>

        <Row type="flex" justify="center" className="social fb py-10">
          <Col xs={24} sm={24} md={16} lg={9} xl={11}>
            <div className="align-middle block py-12">
              <h1 className="text-4xl text-white mb-4 text-center px-2">
                Be an instant Social Hero
              </h1>
              <p className="text-white text-xl text-center px-8">
                Leverage the massive power of Social Media by boosting your
                listings instantly to Facebook straight from your profile on
                singapore.realty.com.au
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={9} xl={7}>
            <div className="socialImage">
              <img src={fb} alt="realestate-fb" className="w-1/2" />
            </div>
          </Col>
        </Row>

        <Row
          type="flex"
          justify="center"
          className="section bg-blue-100 py-10 px-2"
        >
          <Col xs={24} sm={24} md={23} lg={23} xl={18}>
            <h2 className="text-5xl mb-0 text-center">Agent Maximiser</h2>

            <p className="text-4xl text-center mb-4">
              {' '}
              A real estate CRM built for sales agents
            </p>

            <p className="text-xl mb-4">
              We believe a good real estate CRM is the planet around which your
              entire real estate system revolves. It’s the central engine that
              drives the one thing that breeds long and lasting success:
              Relationships – leveraging technology to maintain these
              relationships will give you the edge over your competition.
            </p>

            <div className="md:flex text-xl mb-8 text-justify">
              <div className=" p-4">
                <img
                  src={crmIcon}
                  alt="realestate crm"
                  className="m-auto h-24 block my-6"
                />
                <div className="font-bold text-center mb-3">
                  Property Listings
                </div>
                Upload, manage &amp; distribute your property listings, with a
                few clicks. You can upload floor plans, pictures, videos &amp;
                documents all seamlessly and conveniently in one platform.
              </div>
              <div className="p-4">
                <img
                  src={buyers}
                  alt="realestate crm"
                  className="m-auto h-24 block my-6"
                />
                <div className="font-bold text-center mb-3">Buyers</div>
                Take more control of your buyer enquiries, automate management
                and communications to ensure you maximum your marketing efforts.
              </div>
              <div className=" p-4">
                <img
                  src={buyerMatch}
                  alt="realestate crm"
                  className="m-auto h-24 block my-6"
                />
                <div className="font-bold text-center mb-3">Buyer Matching</div>
                Auto match new listings against the profile or previous buyer
                enquiries, this allows you to engage prospects much quicker, to
                get that sale underway much faster.
              </div>
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="center" className="pb-16 bg-blue-100">
          <h1 className="text-4xl text-center">
            Your CRM Dashboard - Everything you need at the click of a button
          </h1>
          <Col xs={23} sm={23} md={12} lg={12} xl={13}>
            <img src={crm} alt="crm-realestate" />
          </Col>
        </Row>

        <Row type="flex" justify="center" className="social whatsapp py-10">
          <Col xs={24} sm={24} md={16} lg={9} xl={7}>
            <div className="socialImage">
              <img src={whatsApp} alt="realestate-whatsapp" className="w-1/2" />
            </div>
          </Col>

          <Col xs={24} sm={24} md={16} lg={9} xl={11}>
            <div className="align-middle block py-12">
              <h1 className="text-4xl text-white mb-4 text-center px-2">
                Whatsapp Integration
              </h1>
              <p className="text-white text-xl text-center px-2">
                Receive instant property enquiry alerts like never before! -
                Singapore.realty.com.au has integrated your favourite
                communication app to allow lightning response times.
              </p>
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="center" className="px-2 py-6">
          <Col xs={24} sm={24} md={23} lg={23} xl={18}>
            <blockquote>
              <p className="mt-4 quotation text-center">
                Today, real estate’s top agents focus on relationships. It’s who
                you’re talking to and the relationships you’re forging today
                that result in listings and sales tomorrow.
              </p>
              <div className="w-3/4 qfooter">~ Adam Crocker</div>
            </blockquote>
          </Col>
        </Row>
        <div className="my-8 property-listing">
          <Row type="flex" justify="center" className="px-2">
            <Col xs={24} sm={24} md={23} lg={23} xl={18}>
              <h2 className="uppercase text-5xl md:text-3xl my-8">
                property display options{' '}
                <span className="font-medium text-xl align-middle">
                  (Launching May)
                </span>
              </h2>
            </Col>
          </Row>
          <Row type="flex" justify="center" className="px-2 py-12 bg-blue-100">
            <Col xs={24} sm={24} md={18} lg={9} xl={7} className="mb-12 pr-2">
              <h2 className="header-title text-4xl">Premier Ad</h2>
              <div className="text-left">
                <div className="text-2xl inline-block mt-2 align-top">SGD</div>
                <h1 className="text-4xl pt-2 inline-block font-semibold md:text-6xl">
                  299
                </h1>
                <span className="text-sm inline-block">+gst </span>
              </div>
              <p className="text-base font-medium mb-2">
                Appear at the top of all search result so buyers see your
                property first
              </p>
              <ul className="text-lg">
                <li>Unlimited Days Listing</li>
                <li>Agency &amp; Agent Branding</li>
                <li>
                  Homepage feature -{' '}
                  <a href="singapore.realty.com.au" target="_blank">
                    Singapore.realty.com.au
                  </a>
                </li>
                <li>Gold bouncing Map Marker</li>
                <li>Facebook feature post</li>
                <li>Instagram feature post</li>
                <li>Pinterest feature post</li>
                <li>Twitter feature post</li>
              </ul>
              <Button
                className="my-4 mr-6 text-right rounded-full"
                type="primary"
                size="large"
              >
                <a
                  href="https://realty.com.au/buy/search?location=Umina%20Beach%2C%20NSW%202257"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white "
                >
                  See live listing
                </a>
              </Button>
            </Col>

            <Col xs={24} sm={24} md={24} lg={15} xl={11}>
              <img
                src={premierAd}
                alt="realestate-premier"
                className="shadow-md"
              />
            </Col>

            <div className="md:flex mt-8">
              <div className="flex-1 text-center relative mx-12 mb-4">
                <p className="text-2xl font-bold mb-4">Bouncing Map Marker</p>
                <img src={pinGold} alt="" className="pinGold animated bounce" />
                <img src={sgMap} alt="realestate marker" />
              </div>
              <div className="flex-1 text-center mx-12 mb-4">
                <p className="text-2xl text-center font-bold mb-4">
                  Facebook Feature Post
                </p>
                <img src={fbPost} alt="realestate fbpost" />
              </div>
            </div>
          </Row>

          <Row type="flex" justify="center" className="px-2 pt-12">
            <Col xs={24} sm={24} md={18} lg={9} xl={7} className="mb-12 pr-2">
              <h2 className="header-title text-4xl">Standard Ad</h2>
              <div className="text-left">
                <div className="text-2xl inline-block mt-2 align-top">SGD</div>
                <h1 className="text-4xl pt-2 inline-block font-semibold md:text-6xl">
                  0
                </h1>
                <span className="text-sm inline-block">+gst </span>
              </div>
              <p className="text-base font-medium mb-2">
                Standard ads are the basic advertising option to get your
                property online
              </p>
              <ul className="text-lg">
                <li>Unlimited Days Listing</li>
                <li>Agent and Agency Branding</li>
                <li>Unlimited Listings with Subscription</li>
                <li>Blue Map marker</li>
              </ul>
              <Button
                className="my-4 mr-6 text-right rounded-full"
                type="primary"
                size="large"
              >
                <a
                  href="https://realty.com.au/buy/search?location=Umina%20Beach%2C%20NSW%202257"
                  target="_blank"
                  className="text-white"
                >
                  See live listing
                </a>
              </Button>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={15}
              xl={11}
              className="md:text-left text-center"
            >
              <img
                src={standardAd}
                alt="realestate-standard"
                className="shadow-md standard-image"
              />
            </Col>
          </Row>
        </div>
        <Divider className="bg-gray-200 m-0" />
        <div className="mt-12">
          <h1 className="text-center text-3xl pb-2">
            Special Offer for Agents {offer}
          </h1>
          <h1 className="text-center text-5xl px-4">
            <span className="highlight">
              Join today and receive a 2 year price freeze guarantee
            </span>
          </h1>
          <h2 className="text-center text-3xl my-12">
            Normally SGD399 + SGD199 CRM - Total SGD599
            <br />
            Use coupon code:{' '}
            <span className="text-red-500 font-extrabold underline">
              {coupon}
            </span>
          </h2>

          <Row type="flex" justify="center" className="px-2">
            {pathname !== '/united' && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={7}
                className="px-2 flex flex-col mb-8 pt-12"
              >
                <div className="bg-gray-900 rounded-lg text-center p-2">
                  <p className="text-2xl py-2 text-white border-gray-500 border-b">
                    FREE
                  </p>
                  <div className="text-white text-4xl inline-block align-top">
                    SGD
                  </div>
                  <h1 className="text-6xl pt-2 text-white inline-block font-light md:text-7xl">
                    0{' '}
                  </h1>
                  <span className="text-white text-sm inline-block">
                    +gst (annual)
                  </span>
                  <p className="text-white text-2xl text-center mb-4 pb-8 h-24">
                    A great package to get your properties online
                  </p>
                  <div className="pb-12" />
                </div>
                <div className="bg-gray-300 text-left rounded-lg p-4 mt-2 mb-4 flex-1">
                  <ul className="font-medium text-lg pr-2">
                    <li className="py-4 border-b border-gray-500">
                      Upload up to 10 listings
                    </li>
                    <li className="py-4 border-b border-gray-500">
                      Unlimited pics, videos &amp; floorplans
                    </li>
                    <li className="py-4 border-b border-gray-500">
                      Buyer enquiries to your email or phone.
                    </li>
                  </ul>
                </div>
                <Button
                  className="block text-center m-auto rounded-lg text-white font-bold text-2xl w-3/5"
                  type="primary"
                  size="large"
                >
                  <Link
                    to="/free-agent-signup"
                    target="blank"
                    className="text-white"
                  >
                    Signup Today!
                  </Link>
                </Button>
              </Col>
            )}

            <Col xs={24} sm={24} md={24} lg={12} xl={7} className="px-2 mb-8">
              <div className="text-center mb-2 block">
                <Radio.Group
                  defaultValue="monthly"
                  buttonStyle="solid"
                  size="large"
                  onChange={this.selectedPackage}
                >
                  <Radio.Button value="annual" size="large">
                    {' '}
                    Annual{' '}
                  </Radio.Button>
                  <Radio.Button value="monthly" size="large">
                    {' '}
                    Monthly{' '}
                  </Radio.Button>
                </Radio.Group>
              </div>
              {/*here */}
              {availedPackage === 'annual' ? (
                <div>
                  {' '}
                  <div className="bg-gray-900 rounded-lg text-center p-2">
                    <p className="text-2xl py-2 text-white border-gray-500 border-b">
                      LIMITED
                    </p>
                    <div className="text-white text-4xl inline-block align-top">
                      SGD
                    </div>
                    <h1 className="text-6xl pt-2 text-white inline-block font-light md:text-7xl">
                      {price}
                    </h1>
                    <span className="text-white text-sm inline-block">
                      +gst (annual)
                    </span>
                    <p className="text-white text-2xl text-center">
                      A great package to sell more properties &amp; maximise
                      your leads
                    </p>
                    <span className="text-white font-bold text-3xl px-6 block">
                      {/* {' '}
                      <Query query={SG_AGENT_COUNT}>
                        {({ data: { agentCount }, loading, error }) => {
                          if (loading) return <LoadingDots />;
                          if (error) return <Error error={error} />;

                          return (
                            <>Only {spots - agentCount} spots available!</>
                          );
                        }}
                      </Query> */}
                    </span>
                    <p className="text-white text-normal mt-2 pb-4">
                      * Offer expires 12th of April 2019
                    </p>
                  </div>
                  <div className="bg-gray-300 text-left rounded-lg p-4 mt-2 mb-4">
                    <ul className="font-medium text-lg pr-2">
                      <li className="py-4 border-b border-gray-500">
                        Upload all of your standard listings for free
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Run it until you sell it
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Dynamic CRM integration
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Unlimited pics, videos, floor plans
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Build your personal brand
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Whatsapp Integration
                      </li>
                      <li className="py-4">FB Push</li>
                    </ul>
                  </div>{' '}
                </div>
              ) : (
                <div>
                  <div className="bg-gray-900 rounded-lg text-center p-2">
                    <p className="text-2xl py-2 text-white border-gray-500 border-b">
                      LIMITED
                    </p>
                    <div className="text-white text-4xl inline-block align-top">
                      SGD
                    </div>
                    <h1 className="text-6xl pt-2 text-white inline-block font-light md:text-7xl">
                      {monthlyPrice.toFixed(2)}
                    </h1>
                    <span className="text-white text-sm inline-block">
                      +gst (monthly)
                    </span>
                    <p className="text-white text-2xl text-center">
                      A great package to sell more properties &amp; maximise
                      your leads
                    </p>
                    <span className="text-white font-bold text-3xl px-6 block">
                      {' '}
                      {/* Only {spots - agentCount} spots available! */}
                    </span>
                    <p className="text-white text-normal mt-2 pb-4">
                      * Offer expires 12th of April 2019
                    </p>
                  </div>
                  <div className="bg-gray-300 text-left rounded-lg p-4 mt-2 mb-4">
                    <ul className="font-medium text-lg pr-2">
                      <li className="py-4 border-b border-gray-500">Monthly</li>
                      <li className="py-4 border-b border-gray-500">
                        Run it until you sell it
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Dynamic CRM integration
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Unlimited pics, videos, floor plans
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Build your personal brand
                      </li>
                      <li className="py-4 border-b border-gray-500">
                        Whatsapp Integration
                      </li>
                      <li className="py-4">FB Push</li>
                    </ul>
                  </div>{' '}
                </div>
              )}

              <Button
                className="block text-center m-auto rounded-lg text-white font-bold text-2xl w-3/5"
                type="primary"
                size="large"
              >
                <Link
                  to={{
                    pathname: `/exclusive-agent-signup`,
                    search: `?plan=${availedPackage}`,
                  }}
                >
                  Secure now!
                </Link>
              </Button>
            </Col>
          </Row>
        </div>

        <Divider />

        <h2 className="text-center my-12 text-3xl">
          Major Clients in Australia
        </h2>
        <Row type="flex" className="text-center mb-8" justify="center px-2">
          {clients.map(({ img, text }) => (
            <Col
              xs={24}
              sm={12}
              md={9}
              lg={6}
              xl={5}
              className="text-center border border-dashed border-gray-500 m-2 align-middle"
            >
              <div className="">
                <img src={img} alt={text} className="md:h-8 h-6 w-auto my-6 " />
              </div>
            </Col>
          ))}
        </Row>

        <Row
          type="flex"
          className="text-center bg-gray-900"
          justify="center px-2"
        >
          <Col
            xs={24}
            sm={12}
            md={9}
            lg={6}
            xl={6}
            className="bg-gray-900 rounded-lg text-white py-6"
          >
            <div className="inline-block align-top mr-4">
              <img
                src={adam}
                alt="realestate-adam"
                className="mb-2 inline-block"
              />
            </div>
            <div className="text-white text-lg inline-block">
              Adam Crocker | Sales Director - Asia Pac <br />
              <span className="font-bold">Realty Group</span> <br />
              P: +65 6818 5740
            </div>
          </Col>

          <Col xs={24} sm={24} md={9} lg={6} xl={6}>
            <Button
              className="text-center mt-10 rounded-lg text-white text-xl"
              type="primary"
              size="large"
              onClick={this.showModal}
            >
              Contact me for more information
            </Button>
            <LandingPageLearnMoreModal
              isVisible={isVisible}
              onClose={this.hideModal}
            />
          </Col>

          <Col xs={24} sm={12} md={9} lg={6} xl={6} className="text-white py-6">
            <p className="text-lg md:py-4">
              Level 39, Marina Bay Financial Centre, Tower 2 <br />
              10 Marina Boulevard <br />
              Singapore 018983
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

export default ExclusiveOpportunityPage;
