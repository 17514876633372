import React from 'react';
import {
  BUY_PRICE_RANGE,
  RENT_PRICE_RANGE,
} from '../../../constants/budget-ranges';
import { Col, Radio, Row } from 'antd';
import PropTypes from 'prop-types';

const { Group: RadioGroup, Button: RadioButton } = Radio;

function BudgetRangeStep({ listingType, onChange }) {
  let radioOptions = getBudgetRange(listingType);

  return (
    <>
      <Row className="flex justify-center">
        <Col>
          <p className="text-2xl md:text-3xl text-white text-center px-4">
            What is your budget range?
          </p>
        </Col>
      </Row>
      <Row className="flex justify-center">
        <Col className="mt-8">
          <RadioGroup buttonStyle="solid" size="large" onChange={onChange}>
            {radioOptions}
          </RadioGroup>
        </Col>
      </Row>
    </>
  );
}

function getBudgetRange(listingType) {
  switch (listingType) {
    case 'SALE':
      return BUY_PRICE_RANGE.map(range => (
        <RadioButton
          key={range.key}
          className="block text-center mb-2"
          value={range.value}
        >
          {range.description}
        </RadioButton>
      ));

    case 'RENT':
      return RENT_PRICE_RANGE.map(range => (
        <RadioButton
          key={range.key}
          className="block text-center mb-2"
          value={range.value}
        >
          {range.description}
        </RadioButton>
      ));
  }
}

BudgetRangeStep.propTypes = {
  listingType: PropTypes.string,
  onChange: PropTypes.func,
};

BudgetRangeStep.defaultProps = {
  listingType: '',
  onChange: () => {},
};

export default BudgetRangeStep;
