import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { graphql } from 'react-apollo';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { featureOptions } from '../../constants/property-feature-options';
import PropertyFeatureQuery from '../../queries/PropertyFeatureQuery.graphql';

class PropertyFeature extends Component {
  render() {
    const {
      data: { loading, propertyFeature },
    } = this.props;

    if (loading) {
      return 'Fetching ...';
    }

    return (
      <div>
        <h2 className="mb-4 sm:text-2xl text-lg">General Features</h2>
        <Row type="flex" gutter={8}>
          {Object.keys(propertyFeature).map(feature => {
            const featureOption = featureOptions.find(
              option => propertyFeature[feature] && option.key === feature
            );

            if (featureOption) {
              return (
                <Col
                  key={featureOption.key}
                  xs={24}
                  sm={12}
                  lg={8}
                  className="mt-4"
                >
                  <Row className="flex text-base font-semibold">
                    <Col span={4} className="flex justify-center">
                      <FA icon={featureOption.icon} className="text-xl" />
                    </Col>
                    <Col span={20}>
                      <span className="block">{featureOption.label}</span>
                    </Col>
                  </Row>
                </Col>
              );
            }

            return null;
          })}
        </Row>
      </div>
    );
  }
}

const PropertyFeatureWithData = graphql(PropertyFeatureQuery, {
  options: ({ propertyId }) => ({
    variables: { propertyId },
  }),
})(PropertyFeature);

export default PropertyFeatureWithData;
