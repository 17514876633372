import React, { Component, PureComponent } from 'react';
import findLast from 'lodash/findLast';
import PropTypes from 'prop-types';
import { Button, Col, Drawer, Icon, Layout, Menu, Row, Dropdown } from 'antd';
import { NavLink, withRouter, Link } from 'react-router-dom';
import './PublicHeader.scss';
import IsAuthenticated from '../IsAuthenticated';
import logoWhite from '../../assets/logo-white.png';
import icon from '../../assets/icon.png';
import au from './au.svg';
import sg from './sg.svg';
import us from './us.svg';

const searchMenu = [
  {
    link: '/',
    text: 'Residential',
  },
  {
    link: '/commercial',
    text: 'Commercial',
  },
  {
    link: '/find-agent',
    text: 'Agents',
  },
];

class PublicHeaderMainMenu extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    textColor: PropTypes.string,
  };

  static defaultProps = {
    location: {},
    textColor: '',
  };

  getSelectedKeys = pathname => {
    if (pathname.startsWith('/agents')) {
      return { link: '/find-agent' };
    }

    return findLast(searchMenu, ({ link }) => pathname.startsWith(link));
  };

  render() {
    const {
      location: { pathname },
      textColor,
    } = this.props;
    const selectedKeys = this.getSelectedKeys(pathname);

    return (
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['/']}
        selectedKeys={[selectedKeys ? selectedKeys.link : '/']}
        className="bg-transparent mb-0 ml-3 text-left search-type-list inline-block public-menu"
      >
        {searchMenu.map(({ text, link }) => {
          if (link === '/find-agent') {
            return (
              <Menu.Item
                key={link}
                className={`md:mr-1 md:text-base text-sm px-3 ml-3 uppercase search-type poppins public-menu-item ${textColor}`}
              >
                <NavLink to={link}>{text}</NavLink>
              </Menu.Item>
            );
          }

          return (
            <Menu.Item
              key={link}
              className={`md:mr-1 md:text-base text-sm px-3 ml-3 uppercase search-type poppins public-menu-item ${textColor}`}
            >
              <NavLink to={`${link}`}>{text}</NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }
}

class PublicHeaderUserMenu extends Component {
  static propTypes = {
    pathname: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    textColor: PropTypes.string,
    type: PropTypes.any,
    onLogout: PropTypes.func,
    buttonType: PropTypes.string,
    isHome: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    textColor: '',
    type: null,
    onLogout: () => {},
    buttonType: '',
    isHome: false,
  };

  render() {
    const { isAuthenticated, textColor, onLogout, type } = this.props;

    const menu = isAuthenticated ? (
      <Menu.Item
        key="/logout"
        className={`text-gray-500 poppins public-menu-item ${textColor}`}
      >
        <NavLink to="/" onClick={onLogout}>
          Logout
        </NavLink>
      </Menu.Item>
    ) : (
      <>
        <Menu.Item
          key="/agent-login"
          className={`text-gray-500 poppins public-menu-item mr-6 ${textColor}`}
        >
          <NavLink to="/agent-login">For Agents</NavLink>
        </Menu.Item>
        <Menu.Item
          key="/login"
          className={`text-gray-500 poppins public-menu-item ${textColor}`}
        >
          <NavLink to="/login">Login</NavLink>
        </Menu.Item>
      </>
    );

    return (
      <Menu
        theme="dark"
        mode="horizontal"
        className="bg-white public-menu md:flex hidden flex-1 justify-end"
      >
        {menu}
      </Menu>
    );
  }
}

class PublicHeaderUserMenuMobile extends Component {
  static propTypes = {
    pathname: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    textColor: PropTypes.string,
    type: PropTypes.string,
    onLogout: PropTypes.func,
    menuIconClassname: PropTypes.string,
  };

  static defaultProps = {
    pathname: '/',
    isAuthenticated: false,
    textColor: '',
    type: null,
    onLogout: () => {},
    menuIconClassname: '',
  };

  state = { visible: false };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onCloseDrawer = () => {
    this.setState({ visible: false });
  };

  getSelectedKeys = pathname => {
    if (pathname.startsWith('/agents')) {
      return { link: '/find-agent' };
    }
    return findLast(searchMenu, ({ link }) => pathname.startsWith(link));
  };

  render() {
    const {
      isAuthenticated,
      textColor,
      menuIconClassname,
      onLogout,
      pathname,
      type,
    } = this.props;
    const { visible } = this.state;
    const selectedKeys = this.getSelectedKeys(pathname);

    const mobileMenu = isAuthenticated ? (
      <Menu
        size="small"
        bordered="false"
        defaultSelectedKeys={['/']}
        selectedKeys={[pathname]}
        className="public-menu mobile-menu-dropdown border-none"
      >
        <Menu.Item
          key="/logout"
          className={`text-gray-500 text-right py-3 public-menu-item ${textColor}`}
        >
          <NavLink to="/" onClick={onLogout}>
            Logout
          </NavLink>
        </Menu.Item>
      </Menu>
    ) : (
      <Menu
        size="small"
        bordered="false"
        defaultSelectedKeys={['/']}
        selectedKeys={[pathname]}
        className="public-menu mobile-menu-dropdown border-none"
      >
        <Menu.Item
          key="/login"
          className={`text-gray-500 py-3 text-right poppins public-menu-item ${textColor}`}
        >
          <NavLink to="/login">Login </NavLink>
        </Menu.Item>
      </Menu>
    );

    const mobileSearchMenu = (
      <Menu
        defaultSelectedKeys={['/']}
        selectedKeys={[selectedKeys ? selectedKeys.link : '/']}
        className=""
      >
        {searchMenu.map(({ text, link }) => {
          if (link === '/find-agent') {
            return (
              <Menu.Item key={link}>
                <NavLink to={link}>{text}</NavLink>
              </Menu.Item>
            );
          }

          return (
            <Menu.Item key={link}>
              <NavLink to={`${link}`}>{text}</NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={['/']}
          selectedKeys={[pathname]}
          className="bg-white public-menu"
        >
          {!isAuthenticated && (
            <Menu.Item
              key="/login"
              className={`text-gray-500 poppins public-menu-item ${textColor}`}
            >
              <NavLink to="/agent-login"> For Agents </NavLink>
            </Menu.Item>
          )}
        </Menu>
        <Button
          className="text-2xl mobile-menu-btn active:bg-transparent hover:bg-transparent rounded-none bg-transparent border-0 h-full"
          onClick={this.showDrawer}
        >
          <Icon
            type="bars"
            className={`${menuIconClassname} text-xl align-middle`}
          />
        </Button>
        <Drawer
          title={false}
          placement="right"
          closable={false}
          onClose={this.onCloseDrawer}
          visible={visible}
        >
          {mobileMenu}
          {mobileSearchMenu}
        </Drawer>
      </>
    );
  }
}

class PublicHeader extends PureComponent {
  static propTypes = { location: PropTypes.object.isRequired };

  render() {
    const {
      location: { pathname },
      location,
    } = this.props;

    const heroPage = [
      '/',
      '/buy',
      '/rent',
      '/sold',
      '/find-agent',
      '/commercial',
      '/commercial/buy',
      '/commercial/rent',
    ].includes(pathname);
    const logoImg = logoWhite;
    const menuIcon = heroPage ? 'text-white' : 'text-blue-300';
    const textColor = !heroPage ? 'dark' : '';
    const bgColor = !heroPage ? 'header-dark' : '';
    const buttonType = heroPage ? 'default' : 'primary';
    const menuShadow =
      (pathname.indexOf('/agency/') >= 0 || pathname.indexOf('/agent/') >= 0) &&
      'shadow-sm';

    const menu = (
      <Menu
        theme="dark"
        selectedKeys={['SG']}
        style={{ backgroundColor: '#25435d' }}
      >
        <Menu.Item key="SG" className="font-bold flex">
          <img className="mr-2" style={{ height: 20 }} src={sg} alt="sg" />
          <span>SG</span>
        </Menu.Item>
        <Menu.Item key="AU" className="font-bold flex">
          <a
            href="https://www.realty.com.au/"
            style={{ color: '#fff' }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="flex">
              <img className="mr-2" style={{ height: 20 }} src={au} alt="au" />
              <span>AU</span>
            </div>
          </a>
        </Menu.Item>
        <Menu.Item key="US" className="font-bold flex">
          <a
            href="https://www.realty.com/"
            style={{ color: '#fff' }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="flex">
              <img className="mr-2" style={{ height: 20 }} src={us} alt="us" />
              <span>US</span>
            </div>
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <IsAuthenticated>
        {({ isAuthenticated, logoutUser, type }) => (
          <Layout.Header className={`header ${bgColor} z-20 ${menuShadow}`}>
            <Row
              type="flex"
              justify="space-between"
              className="md:px-6 sm:pl-4"
            >
              <Col className="logo-cont sm:flex hidden">
                <NavLink to="/" className="xl:inline-block hidden">
                  <img
                    src={logoImg}
                    className="inline-block logo p-1 h-12"
                    alt="Realty.com.au"
                  />
                </NavLink>
                <PublicHeaderMainMenu
                  location={location}
                  textColor={textColor}
                />
              </Col>
              <NavLink to="/" className="icon-cont md:hidden inline-block">
                <img
                  src={icon}
                  className="inline-block logo p-1 h-12"
                  alt="Realty.com.au"
                />
              </NavLink>
              <div className="flex flex-1 justify-end items-center">
                <PublicHeaderUserMenu
                  textColor={textColor}
                  isAuthenticated={isAuthenticated}
                  type={type}
                  onLogout={logoutUser}
                  buttonType={buttonType}
                  isHome={heroPage}
                />
                <Dropdown
                  className="text-grey-dark border-grey-dark border-2 font-bold ml-6 flex items-center"
                  overlay={menu}
                  trigger={['click', 'hover']}
                >
                  <Button ghost>
                    <img
                      className="mr-2"
                      style={{ height: 20 }}
                      src={sg}
                      alt="sg"
                    />
                    SG <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
              <div className="md:hidden inline-block mobile-menu flex">
                <PublicHeaderUserMenuMobile
                  pathname={location.pathname}
                  isAuthenticated={isAuthenticated}
                  textColor={textColor}
                  type={type}
                  onLogout={logoutUser}
                  menuIconClassname={menuIcon}
                />
              </div>
            </Row>
          </Layout.Header>
        )}
      </IsAuthenticated>
    );
  }
}

export default withRouter(PublicHeader);
