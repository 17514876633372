import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import PublicHeader from '../../components/PublicHeader';
import FindAgentWizard from '../../components/FindAgentWizard';
import './FindAgentPage.scss';
import qs from 'query-string';
import { winHeight } from '../../utils/windowDimensions';

class FindAgentPage extends Component {
  buildQueryString = searchOptions =>
    qs.stringify(
      {
        budgetRange: searchOptions.budgetRange,
        listingType: searchOptions.listingType,
        typeGroup: searchOptions.typeGroup,
      },
      { arrayFormat: 'repeat' }
    );

  handleOnSubmit = values => {
    const { history } = this.props;

    history.push(`/agents?${this.buildQueryString(values)}`);
  };

  render() {
    return (
      <div className="default-theme FindAgentPage">
        <div className="page">
          <div className="page-wrapper">
            <div
              className="hero-b w-full relative"
              style={{ height: winHeight + 50 }}
            >
              <div className="hero-bg-mask">
                <Helmet defer={false}>
                  <title>Real Estate Singapore | Find Agent</title>
                </Helmet>
                <div className="header">
                  <PublicHeader />
                </div>
                <div className="content-wrapper">
                  <div className="content">
                    <FindAgentWizard onSubmit={this.handleOnSubmit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FindAgentPage);
