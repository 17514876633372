import { Component } from 'react';

class SlugUrlParser extends Component {
  getId = () => {
    const { match: { params: { slugUrl } } } = this.props;
    return slugUrl.split('-')[slugUrl.split('-').length - 1];
  };

  render() {
    const id = this.getId();
    return this.props.children(id);
  }
}

export default SlugUrlParser;
